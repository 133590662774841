import BaseApi from '../../BaseApi';

import { BlobServiceClient } from '@azure/storage-blob';

import type { Container } from './FileApi.types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

class FileApi extends BaseApi {
  constructor() {
    super();
  }

  async post(
    fileList: FileList,
    container: Container = 'oneapp-images',
  ): Promise<string[]> {
    const imageCompression = (await import('browser-image-compression'))
      .default;

    //* FileList needs to be cast to an aray to iterate over it easily.
    const files = await Promise.all(
      Array.from(fileList).map(async (file) => {
        const fileCompressed = await imageCompression(file, {
          maxSizeMB: 3.1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        const fileAppend = `.${file.name.split('.').pop()}`;
        const blobName =
          file.name.split('.')[0] + new Date().getTime() + fileAppend;

        const { url: blobUrl } = await this.postBase<{ url: string }>(
          `/api/v1/file-upload`,
          {
            blobName,
            container,
          },
        );

        const blobService = new BlobServiceClient(blobUrl);

        const containerClient = blobService.getContainerClient(container);

        await containerClient.create;

        const blockBlobClient = blobService
          .getContainerClient(container)
          .getBlockBlobClient(blobName);

        await blockBlobClient.uploadData(fileCompressed, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
        });
        const url = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/${container}/${blobName}`;
        return url;
      }),
    );

    return files;
  }
}

export default FileApi;
