import BaseApi from './BaseApi';

class Api extends BaseApi {
  constructor() {
    super();
  }
  appi = this._api;
  async get(url: string, params = {}, headers = {}) {
    return await super.getBase<any>(url, params, headers);
  }
  async post(url: string, params = {}, headers = {}) {
    return await super.postBase<any>(url, params, headers);
  }
  async put(url: string, params = {}, headers = {}) {
    return await super.putBase<any>(url, params, headers);
  }
  // async patch(url: string, params = {}, headers = {}) {
  //   return await super.(url, params, headers);
  // }
  async delete(url: string, headers = {}) {
    return await super.deleteBase<any>(url, headers);
  }
}

const api = new Api;

export const apiFuncs = {
  async get(url: string, params = {}, headers = {}) {
    headers = api.addHeaders(headers);
    try {
      const response = await api.appi.get(url, {
        headers,
        params,
      });
      api.checkJwtToken(response.headers);
      return response.data;
    } catch (err) {
      console.log(err);
      const response = await api.appi.get(url, {
        headers,
        params,
      });
      api.checkJwtToken(response.headers);
      return response.data;
    }
  },
  async post(url: string, params = {}, headers = {}) {
    headers = api.addHeaders(headers);
    const response = await api.appi.post(url, params, {
      headers,
    });
    api.checkJwtToken(response.headers);
    return response.data;
  },
  async put(url: string, params = {}, headers = {}) {
    headers = api.addHeaders(headers);
    const response = await api.appi.put(url, params, {
      headers,
    });
    api.checkJwtToken(response.headers);
    return response.data;
  },
  async patch(url: string, params = {}, headers = {}) {
    headers = api.addHeaders(headers);
    const response = await api.appi.patch(url, params, {
      headers,
    });
    api.checkJwtToken(response.headers);
    return response.data;
  },
  async delete(url: string, headers = {}) {
    headers = api.addHeaders(headers);
    const response = await api.appi.delete(url, {
      headers,
    });
    api.checkJwtToken(response.headers);
    return response.data;
  },
};

export default api;
