import { IconButton } from '@mui/material';
import ItemGroupsApi from '@oneAppCore/services/apis/ItemGroups';
import { primary } from '@src/Components/Theme/themes/constants';
import { FontAwesome } from '@src/Components/common';
import type { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Id',
    keyName: 'id',
    sortBy: true,
    editable: false,
  },
  {
    title: 'Image',
    keyName: 'image',
  },
  {
    title: 'Product Name',
    keyName: 'name',
    sortBy: true,
    editable: false,
  },
  {
    title: 'Variation',
    keyName: 'variation',
    editable: true,
    // customComponent: function SelectProduct() {
    //   return (
    //     <ProductSelect
    //     // value={productName || ''}
    //     />
    //   );
    // },
  },
  {
    title: 'Manufacturer No',
    keyName: 'manufacturerNo',
    sortBy: false,
    editable: false,
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    type: 'date',
    sortBy: true,
    // customComponent: function dateClean({ row }) {
    //   return <>{/* {new Date(row.createdAt).toISOString().split('T')[0]} */}</>;
    // },
  },
  // {
  //   title: 'Price',
  //   keyName: 'price',
  //   sortBy: false,
  // },
  {
    title: 'Stock',
    keyName: 'quantity',
    sortBy: true,
  },
  {
    title: 'Edit',
    keyName: 'edit',
    sortBy: false,
    editable: false,
    align: 'center',
    editHandler: function handleEditing(
      row,
      newRow,
      isEditMode,
      setEditMode,
      index,
      editCell,
      setEditCell,
      mutate,
    ) {
      const callApi = async () => {
        const { product: name, variation } = newRow;
        const payload = {
          name,
          variation,
        };
        // if (variation === row.variation || name === row.name) {
        //   await ItemGroupsApi.put(row.groupId, payload)
        //     .them((res) => {
        //       setEditCell();
        //       setEditMode(false);
        //       mutate();
        //     })
        //     .catch((e) => console.log(e));
        // }
      };
      // if (!isEditMode) {
      return (
        <IconButton
          onClick={() => {
            setEditCell(index);
            setEditMode(true);
          }}
        >
          <FontAwesome
            name="edit"
            type="fas"
            form="fa"
            size="lg"
            style={{ cursor: 'pointer', color: primary.main }}
          />
        </IconButton>
      );
      // }
    },
  },
  // {
  //   title: 'Fitment, Listings, Suppliers',
  //   keyName: 'fls',
  //   align: 'center',
  //   sortBy: false,
  //   customComponent: function dropdown({
  //     setOpenedCell,
  //     openedCell,
  //     openedType,
  //     cellIndex,
  //   }) {
  //     const setRow = () => {
  //       if (cellIndex === openedCell) {
  //         if (openedType === 'fitment') {
  //           setOpenedCell(null);
  //         } else {
  //           setOpenedCell(cellIndex);
  //         }
  //       } else {
  //         setOpenedCell(cellIndex);
  //       }
  //     };
  //     return (
  //       <IconButton onClick={setRow}>
  //         <FontAwesome name="angle-down" type="fa" form="fa" />
  //       </IconButton>
  //     );
  //   },
  // },
];
