import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Header from './Header';
import Footer from './Footer';
import NavDrawer from './NavDrawer';
import NotificationDrawer from '../../Notifications/NotificationDrawer/NotificationDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { LayoutProps } from '../types';
import Breadcrumbs from './Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    display: 'flex',
    backgroundColor: '#EEF2F6',
    overflow: 'hidden',
    width: '100%',
  },
  displayWrapperOpen: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: useTheme().spacing(4),
    paddingRight: useTheme().spacing(4),
    [useTheme().breakpoints.up('md')]: {
      width: '85vw',
    },
    [useTheme().breakpoints.down('md')]: {
      width: '100vw',
    },
    // width: '80vw',
    // width: 'calc(100vw - 300px)',

    // margin: '0 auto',
    position: 'static',
  },
  displayWrapperClosed: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: useTheme().spacing(4),
    paddingRight: useTheme().spacing(4),
    [useTheme().breakpoints.up('md')]: {
      width: '95vw',
    },
    [useTheme().breakpoints.down('md')]: {
      width: '100vw',
    },
    // width: '95vw',
    margin: '0 auto',
    position: 'static',
  },
  childWrapper: {
    marginTop: useTheme().spacing(3),
    width: '100%',
    minHeight: '100vh',
  },
}));

function AppLayout({
  children,
  authorized = true,
  redirectUrl = '/',
}: LayoutProps) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [mini, setMini] = useState(false);
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:900px)');

  if (!authorized) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <div className={classes.contentWrapper}>
      <NavDrawer
        openDrawer={openDrawer}
        close={() => setOpenDrawer(false)}
        mini={mini}
        normalToggle={() => setMini(false)}
      />
      <div
        className={
          !mini ? classes.displayWrapperOpen : classes.displayWrapperClosed
        }
      >
        {isMobile ? (
          <>
            <Header
              toggleDrawer={() => setOpenDrawer(!openDrawer)}
              toggleMini={() => setMini(!mini)}
              togglePopper={() => setOpen(!openDrawer)}
            />
            <Breadcrumbs />
          </>
        ) : (
          <Header
            toggleDrawer={() => setOpenDrawer(!openDrawer)}
            toggleMini={() => setMini(!mini)}
            togglePopper={() => setOpen(!openDrawer)}
          />
        )}
        <div className={classes.childWrapper}>{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export default AppLayout;
