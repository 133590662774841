export const formFields: any[] = [
  {
    label: 'First Name',
    key: 'firstName',
    type: 'text',
    required: false,
  },
  {
    label: 'Last Name',
    key: 'lastName',
    type: 'text',
    required: false,
  },
  // {
  //   label: 'Username',
  //   key: 'username',
  //   type: 'text',
  //   required: true,
  // },
  {
    label: 'Email',
    key: 'email',
    type: 'text',
    required: true,
  },

  {
    label: 'Password',
    key: 'password',
    type: 'password',
    required: false,
  },
  {
    label: 'Confirm Password',
    key: 'confirmPassword',
    type: 'password',
    required: false,
  },
  {
    label: 'Company',
    key: 'companyId',
    type: 'select',
    required: true,
  },
];
