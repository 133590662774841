import { useState } from 'react';

import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material';

import type { Props } from './type';

import ManufacturerSuppliersApi from '@oneAppCore/services/apis/ManufacturerSuppliers';
import { useSnackbar } from 'notistack';

const StaticQuantityButton = ({ id, staticQuantity }: Props) => {
  const [checked, setChecked] = useState(staticQuantity);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (event) => {
    setChecked(event.target.checked);
    try {
      await ManufacturerSuppliersApi.put(parseInt(id), {
        hasStaticQuantity: event.target.checked,
      });
    } catch (err) {
      setChecked(!event.target.checked);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  return (
    <>
      <Tooltip title="Static Quantity">
        <Switch
          onChange={handleChange}
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </>
  );
};

export default StaticQuantityButton;
