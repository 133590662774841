import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';
import { Supplier } from '@oneAppCore/types/supplierTypes';

export default function useSuppliers() {
  const x = useSWR<any[]>(`/api/v1/suppliers`, Api.get);
  return x;
}

export function useSupplierById(id: number) {
  return useSWR<Supplier>(`/api/v1/suppliers/${id}`, Api.get);
}
