import React, { useRef, useEffect, Fragment } from 'react';
import { useState } from 'react';
import { useZxing } from 'react-zxing';

export const BarcodeScanner = ({ setUpc, setEnableCamera, searchOrderByUpc }) => {
  const [result, setResult] = useState('');
  const { ref } = useZxing({
    onResult(result) {        
      setResult(result.getText());
      setUpc(result.getText());
      setEnableCamera(false);
      searchOrderByUpc(result.getText());
    },
  });

  return (
    <>
      {!result && (
        <Fragment>
          <video id="video" ref={ref} />
          <p>
            <span>Last result:</span>
            <span>{result}</span>
          </p>
        </Fragment>
      )}
    </>
  );
};
export default BarcodeScanner;