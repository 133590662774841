import Cookies from 'universal-cookie';

import { JWT_TOKEN } from '@oneAppCore/constants/jwt';
import { SESSION_TIMEOUT } from '@oneAppCore/server/constants/jwt';

const cookies = new Cookies();

// const MAX_AGE = 3600 * 24; // 24 hours
// const MAX_AGE = SESSION_TIMEOUT / 2; // 7 days
const MAX_AGE = SESSION_TIMEOUT; // matches the session age limit

export function setJwtToken(token: string) {
  cookies.set(JWT_TOKEN, token, {
    path: '/',
    domain: window.location.hostname,
    maxAge: MAX_AGE,
  });
}

export function removeJwtToken() {
  cookies.remove(JWT_TOKEN, { path: '/', domain: window.location.hostname });
}

export function getJwtToken() {
  const jwt = cookies.get(JWT_TOKEN);
  return jwt;
}

export default {
  setJwtToken,
  removeJwtToken,
  getJwtToken,
};