import { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  MenuItem,
  Select,
  FormControl,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@src/Components/common';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { ChannelForm } from '../ChannelPage/ChannelPage';
import channelObjects from './constants';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import StoresApi from '@oneAppCore/services/apis/Stores';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  header: {
    marginBottom: 10,
  },
  card: {
    width: '100%',
    padding: 10,
    marginBottom: 30,
  },
  cardAction: {
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
  },
  textArea: {
    marginTop: 15,
  },
  select: {
    marginTop: 33,
  },
  formControl: {
    width: '100%',
  },
});

const filter = createFilterOptions();

export type CreateChannelProps = {
  id?: number;
  isViewMode: boolean;
  form: ChannelForm;
  updateForm: any;
};

function CreateChannel({ id, isViewMode }: CreateChannelProps) {
  const isAddMode = !id;

  const classes = useStyles();
  const [channel, setChannel] = useState('1');
  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [apiCreds, setApiCreds] = useState([]);
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    storeName: '',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getStore = async () =>
      await Api.get(`/api/v1/stores/search`).then((res) => {
        const { rows } = res;
        setStores(rows);
      });
    getStore();
  }, []);

  useEffect(() => {
    let selectedChannelCreds = channelObjects[parseInt(channel) - 1].creds;

    selectedChannelCreds.forEach((cred) => {
      setApiCreds((data) => [...data, { [cred]: '' }]);
    });
  }, [channel]);

  const handleChange = (event) => {
    setApiCreds([]);

    setChannel(event.target.value as string);
  };

  const handleClose = () => {
    setDialogValue({
      storeName: '',
    });
    toggleOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    setValue({
      storeName: dialogValue.storeName,
    });
    handleClose();
  };

  const onCredentialChange = (value, key, index) => {
    let tempArray = apiCreds;
    tempArray[index][key] = value;
    setApiCreds((data) => {
      return data.map((x) => (x[key] === key ? (x[key] = value) : x));
    });
  };

  const createNewStore = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true);
    try {
      await StoresApi.createNewStore({ name: dialogValue.storeName });
      enqueueSnackbar(`New Store Created`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const createNewChannel = async (e, isEdit: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true);
    if (value === null || channel === null) {
      enqueueSnackbar('Please Fill out all the information!', {
        variant: 'error',
      });
    } else {
      const merged = Object.assign({}, ...apiCreds);
      const storeId = value.id;
      const channelId = channel;
      const payload = {
        apiCredentials: merged,
        storeId,
        channelId,
      };
      try {
        await Api.post(`/api/v1/store-channels`, payload);
        enqueueSnackbar(`Channel created`, {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar(`${e.message || e}`, {
          variant: 'error',
        });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            className={classes.header}
            alignItems="center"
            justifyContent="space-between"
            container
            xs={12}
          >
            <Typography variant="h5" color="primary">
              <Box fontWeight="fontWeightBold">
                {isViewMode ? 'View' : isAddMode ? 'Create' : 'Edit'} Channel
              </Box>
            </Typography>
          </Grid>
          <Grid className={classes.textArea} container spacing={1}>
            <Grid className={classes.textArea} item xs={6}>
              <Autocomplete
                disabled={!isAddMode}
                value={value}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        storeName: newValue,
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      storeName: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                id="free-solo-dialog"
                options={stores}
                getOptionLabel={(option) =>
                  option.inputValue ? option.inputValue : option.name
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // renderOption={(option) => option.name}
                style={{ width: '100%' }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    size="large"
                    {...params}
                    label="Store"
                    variant="outlined"
                  />
                )}
              />
              <Dialog
                open={open}
                onClose={handleClose}
                arial-labelledby="add-store"
              >
                <form onSubmit={handleSave}>
                  <DialogTitle id="new-store">Add a new store</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please add a new store
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      value={dialogValue.storeName}
                      onChange={(event) =>
                        setDialogValue({
                          ...dialogValue,
                          storeName: event.target.value,
                        })
                      }
                      label="Store Name"
                      type="text"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={createNewStore}
                    >
                      Add
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
            <Grid className={classes.select} item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ marginLeft: 15, marginBottom: 15 }}
                  id="storeChannelInput"
                >
                  Channel
                </InputLabel>
                <Select
                  disabled={!isAddMode}
                  style={{ maxHeight: 41 }}
                  value={channel}
                  onChange={handleChange}
                  id="channel"
                  labelId="channelInput"
                  label="Channel"
                  name="channel"
                  variant="outlined"
                >
                  {channelObjects.map((channelName) => (
                    <MenuItem key={channelName.id} value={channelName.id}>
                      {channelName.name}
                    </MenuItem>
                  ))}
                  ;
                </Select>
              </FormControl>
            </Grid>
            {apiCreds.length > 0 &&
              channelObjects[parseInt(channel) - 1].creds.map(
                (channelCreds, index) => {
                  const value = apiCreds[index][channelCreds];

                  return (
                    <Grid key={index} item xs={6}>
                      <TextField
                        style={{ marginBottom: 15 }}
                        label={channelCreds}
                        value={value}
                        onChange={(e) => {
                          onCredentialChange(
                            e.target.value,
                            channelCreds,
                            index,
                          );
                        }}
                      />
                    </Grid>
                  );
                },
              )}
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              style={{ marginRight: 10 }}
              onClick={(e) => createNewChannel(e, isViewMode)}
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
            >
              {isAddMode
                ? 'Create Channel'
                : isViewMode
                ? 'Save New Channel'
                : 'Save Changes'}
            </Button>
            <Button
              className={classes.button}
              onClick={() => history.push('/store-channel/list')}
              variant="outlined"
              color="primary"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default CreateChannel;
