import bcrypt from 'bcryptjs';
import * as Crypto from 'crypto';

export function generatePassword(length: number = 14) {
  return Array(length)
    .fill(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$',
    )
    .map(
      (x) =>
        x[
        Math.floor(
          (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
          x.length,
        )
        ],
    )
    .join('');
}

export function generateUsername({ firstName, lastName, email }: any) {
  const firstInitial = firstName?.charAt(0).toLowerCase();
  const lastNameLC = lastName?.toLowerCase();
  const emailName = email?.split('@')[0].replace(/\.\_\-/g, '');
  let generatedUsername = `${firstInitial}${lastNameLC}`;
  const randomNumbers = Array(length)
    .fill(
      '01234567890123456789012345678901234567890123456789012345678901234567',
    )
    .map(
      (x) =>
        x[
        Math.floor(
          (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
          x.length,
        )
        ],
    )
    .join('');
  if (!firstInitial || !lastNameLC) {
    generatedUsername = `${emailName}${randomNumbers}`;
  }
  return generatedUsername;
}

export function hashPassword(password: string) {
  const salt = bcrypt.genSaltSync();
  return bcrypt.hashSync(password, salt);
}

export function comparePassword(password: string, hashedPassword: string) {
  return bcrypt.compareSync(password, hashedPassword);
}

export function createApiKey(seperator: '_' | ':' = ':') {
  const randomString = Crypto.randomBytes(50).toString('base64').slice(0, 50);
  const id = Crypto.randomBytes(15).toString('base64').slice(0, 15);
  return {
    keyId: id,
    apiKey: `${id}${seperator}${randomString}`,
    hashed: hashPassword(randomString),
  };
}

export default {
  generatePassword,
  hashPassword,
  comparePassword,
  createApiKey,
};
