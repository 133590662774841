import useProduct from '../hooks/useProduct';
import { useState, FormEvent } from 'react';
import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Divider,
  Paper,
  TextField,
  Button,
  IconButton,
  Popover,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import FontAwesome from '@src/Components/common/FontAwesome';

import ParentProductFields from './ParentTextFields';
import type { ParentProductProps, ProductForm } from './types';
import { primary } from '@src/Components/Theme/themes/constants';
import ProductApi from '@oneAppCore/services/apis/Products';

const useStyles = makeStyles({
  header: {
    marginBottom: 10,
  },
  card: {
    width: '100%',
    padding: 20,
    marginBottom: 40,
  },
  cardAction: {
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
  },
  textArea: {
    marginTop: 15,
  },
  shippingCard: {
    width: '20%',
    padding: 10,
    marginBotton: 30,
    marginLeft: 15,
  },
  tableContainer: {
    maxWidth: '100%',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
  fontColor: {
    color: '#fff',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  paper: {
    display: 'flex',
    border: '1px solid #c4c4c4',
    width: 'fit-content',
    marginBottom: '10px',
    marginTop: '15px',
    borderRadius: '5px',
    flexWrap: 'wrap',
    padding: 8,
  },
  divider: {
    margin: 5,
  },
});

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

function ParentProduct({
  isViewMode,
  form,
  updateForm,
  mutate,
  isGrouped,
  keywordList,
  nameError,
  descriptionError,
  storeError,
  brandError,
  togglePopper,
  aiForm,
  updateAiForm,
  
}: // nextStep,
ParentProductProps) {
  const { data: product } = useProduct();
  const [alignment, setAlignment] = useState('left');
  const [formats, setFormats] = useState(() => ['italic']);
  const [leftAlign, setLeftAlign] = useState(true);
  const [centerAlign, setCenterAlign] = useState(false);
  const [rightAlign, setRightAlign] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underlined, setUnderlined] = useState(false);
  const [open, setOpen] = useState(false);
  const [res, setRes] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const onChange = <K extends keyof ProductForm>(
    key: K,
    value: ProductForm[K],
  ) => {
    updateForm(key, value);
  };

  const enhance = async (e) => {
    setLoading(true);
    const payload = {
      brand: form?.brand,
      productName: form?.name,
    };
    try {
      const response = await ProductApi.getEnhancedProductDescription(payload);

      setRes(response);
      setOpen(true);
    } catch (error) {
      console.error('Error enhancing product:', error);
    } finally {
      setLoading(false);
    }
  };

  const CustomColorIconButton = withStyles({
    root: {
      color: 'red',
    },
  })(IconButton);

  const handleFormat = (e, newFormats: string[]) => {
    setFormats(newFormats);
  };

  const handleAlignment = (e, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  const addBulletPoint = () => {
    const newBulletPoints = form?.bulletPoints?.slice() || [];
    newBulletPoints.push('');
    onChange('bulletPoints', newBulletPoints);
  };

  const removeBulletPoint = (index: number) => {
    const newBulletPoints = form?.bulletPoints?.slice() || [];
    newBulletPoints.splice(index, 1);
    onChange('bulletPoints', newBulletPoints);
  };

  const updateBulletPoint = (index: number, value: string) => {
    const newBulletPoints = form?.bulletPoints?.slice() || [];
    newBulletPoints[index] = value;
    onChange('bulletPoints', newBulletPoints);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // nextStep(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        wrap="wrap"
        style={{ padding: 10 }}
      >
        <Card className={classes.card}>
          <CardContent>
            <Grid container xs={12}>
              <Grid item xs={12} style={{ marginBottom: 30 }}>
                <ParentProductFields
                  keywordList={keywordList}
                  isAddMode={!product}
                  isViewMode={isViewMode}
                  updateForm={updateForm}
                  mutate={mutate}
                  form={form}
                  nameError={nameError}
                  storeError={storeError}
                  brandError={brandError}
                />
              </Grid>
            </Grid>

            {isViewMode ? (
              // <Paper
              //   elevation={1}
              //   style={{
              //     padding: 15,
              //     border: '1px solid #eeeeee',
              //     borderRadius: '8px',
              //   }}
              // >
              //   <CardHeader
              //     titleTypographyProps={{
              //       variant: 'h6',
              //       style: {
              //         fontWeight: 500,
              //       },
              //     }}
              //     title="Additional Information"
              //   />
              //   <Divider style={{ width: 'fullWidth', marginBottom: 10 }} />
              <Grid className={classes.textArea} container>
                {form?.description && (
                  <Grid className={classes.textArea} item xs={12}>
                    {/* <span
                      style={{
                        fontSize: '22px',
                        color: 'grey',
                      }}
                    >
                      Description:
                    </span> */}
                    <span
                      style={{
                        display: 'flex',
                        color: 'grey',
                        fontSize: '20px',
                      }}
                    >
                      {form.description}
                    </span>
                  </Grid>
                )}
                {Boolean(form?.bulletPoints?.length) ? (
                  <Grid className={classes.textArea} item xs={12}>
                    <span style={{ fontSize: '20px', color: 'grey' }}>
                      <strong>Bullet Points:</strong>
                    </span>
                    <ul style={{ color: 'grey' }}>
                      {form.bulletPoints.length &&
                        form.bulletPoints.map((bulletPoint, i) => {
                          return (
                            <li key={i} style={{ fontSize: '20px' }}>
                              {bulletPoint}
                            </li>
                          );
                        })}
                    </ul>
                  </Grid>
                ) : (
                  <Grid className={classes.textArea} item xs={12}>
                    <p style={{ fontSize: '20px' }}>
                      No Bullet Points for this Product
                    </p>
                  </Grid>
                )}
              </Grid>
            ) : (
              // {/* </Paper> */}
              // <Paper
              //   elevation={1}
              //   style={{
              //     padding: 15,
              //     border: '1px solid #eeeeee',
              //     borderRadius: '8px',
              //     marginTop: 20,
              //   }}
              // >
              //   <CardHeader
              //     titleTypographyProps={{
              //       variant: 'h6',
              //       style: {
              //         fontWeight: 500,
              //       },
              //     }}
              //     title="Additional Information"
              //   />
              //   <Divider style={{ width: 'fullWidth', marginBottom: 10 }} />
              <Grid className={classes.textArea} container>
                <Grid className={classes.textArea} item xs={12}>
                  <Grid
                    item
                    container
                    xs={5}
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                      marginBottom: 20,
                      marginTop: 30,
                    }}
                  >
                    {/* <Paper elevation={0} className={classes.paper}>
                      <StyledToggleButtonGroup
                        size="small"
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                      >
                        <ToggleButton
                          value="left"
                          onClick={() => {
                            setLeftAlign(true);
                            setCenterAlign(false);
                            setRightAlign(false);
                          }}
                        >
                          <FormatAlignLeft />
                        </ToggleButton>
                        <ToggleButton
                          value="center"
                          onClick={() => {
                            setLeftAlign(false);
                            setCenterAlign(true);
                            setRightAlign(false);
                          }}
                        >
                          <FormatAlignCenter />
                        </ToggleButton>
                        <ToggleButton
                          value="Right"
                          onClick={() => {
                            setLeftAlign(false);
                            setCenterAlign(false);
                            setRightAlign(true);
                          }}
                        >
                          <FormatAlignRight />
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                      <Divider
                        className={classes.divider}
                        flexItem
                        orientation="vertical"
                      />
                      <StyledToggleButtonGroup
                        size="small"
                        // value={formats}
                        onChange={handleFormat}
                      >
                        <ToggleButton
                          value="bold"
                          onClick={() => {
                            bold ? setBold(false) : setBold(true);
                          }}
                        >
                          <FormatBold />
                        </ToggleButton>
                        <ToggleButton
                          value="italic"
                          onClick={() => {
                            italic ? setItalic(false) : setItalic(true);
                          }}
                        >
                          <FormatItalic />
                        </ToggleButton>
                        <ToggleButton
                          value="underlined"
                          onClick={() => {
                            underlined
                              ? setUnderlined(false)
                              : setUnderlined(true);
                          }}
                        >
                          <FormatUnderlined />
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </Paper> */}
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={(e) => {
                        enhance(e);
                        // setOpen(res === null ? false : true);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      {loading ? 'Working Magic!' : 'Enhance with AI'}
                    </Button>
                  </Grid>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    style={{ zIndex: 9999, maxWidth: 1000 }}
                  >
                    <Card>
                      <CardContent>
                        <Grid
                          style={{
                            border: '1px, solid, #e4e4e4',
                            borderRadius: 10,
                            padding: 15,
                            backgroundColor: primary.transparent.main,
                          }}
                        >
                          <Typography>{res}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <Button
                            onClick={() => {
                              setOpen(false);
                              setRes(null);
                            }}
                            style={{
                              color: 'crimson',
                            }}
                          >
                            Close
                          </Button>
                          <Button
                            onClick={(e) => enhance(e)}
                            variant="text"
                            color="primary"
                          >
                            {loading ? 'Thinking...' : 'Try Again'}
                          </Button>
                          <Button
                            onClick={(e) => {
                              updateForm('description', res);
                              setOpen(false);
                            }}
                            variant="text"
                            color="secondary"
                          >
                            Apply
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Popover>
                  <TextField
                    fullWidth
                    error={descriptionError}
                    variant="outlined"
                    label="Description"
                    name="description"
                    multiline
                    inputProps={{
                      style: {
                        textAlign: leftAlign
                          ? 'left'
                          : centerAlign
                          ? 'center'
                          : 'right',

                        fontWeight: bold ? 'bold' : 'normal',
                        fontStyle: italic ? 'italic' : 'normal',
                        textDecoration: underlined ? 'underline' : 'none',
                      },
                    }}
                    value={form?.description || ''}
                    onChange={(e) => updateForm('description', e.target.value)}
                    required
                    style={{
                      backgroundColor: primary.transparent.main,
                    }}
                  />
                </Grid>

                <Grid className={classes.textArea} container>
                  <Grid className={classes.textArea} item xs={12}>
                    <Button
                      style={{ marginBottom: 10 }}
                      variant="outlined"
                      color="secondary"
                      onClick={addBulletPoint}
                    >
                      Add Bullet Point
                    </Button>
                  </Grid>
                  {Boolean(form?.bulletPoints?.length) &&
                    form.bulletPoints.map((point, index) => (
                      <Grid
                        item
                        container
                        key={index}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={11} md={11}>
                          <TextField
                            fullWidth
                            multiline
                            style={{ marginBottom: 5 }}
                            label={`Bulletpoint #${index + 1}`}
                            value={point ?? ''}
                            onChange={(e) =>
                              updateBulletPoint(index, e.currentTarget.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <CustomColorIconButton
                            size="medium"
                            onClick={() => removeBulletPoint(index)}
                          >
                            <FontAwesome
                              name="times-circle"
                              type="fa"
                              form="far"
                            />
                          </CustomColorIconButton>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              // </Paper>
            )}
          </CardContent>
        </Card>
        {/* Removed this as it is not required as of right now. */}
        {/* <Card className={classes.shippingCard}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Product Notes"
                multiline
                rows={10}
                style={{ marginBottom: 10, minWidth: '100%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button size="small" variant="contained" color="primary">
                Save Notes
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      </Grid>
    </form>
  );
}

export default ParentProduct;
