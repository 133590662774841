import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';
import type { Role, RoleCategory } from '@oneAppCore/types/roleTypes';

export const useRoles = () => {
  return useSWR<Role[]>(`/api/v1/roles`, Api.get);
}

export const useRoleCategories = () => {
  return useSWR<RoleCategory[]>(`/api/v1/roles/categories`, Api.get);
}
