import type { Column } from '@src/Components/common/containers/SearchView/types';
import Grid from '@mui/material/Grid';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const columns: Column[] = [
  {
    title: 'Client',
    keyName: 'name',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Labels',
    keyName: 'count',
    sortBy: true,
    align: 'center',
    customComponent: function count(row) {
      return <p>{Number(row?.count).toLocaleString()}</p>;
    },
  },
  {
    title: 'Client Fees',
    keyName: 'labelCharges',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      return (
        <Grid style={{ color: 'green' }}>
          {row?.labelCharges ? formatCentsToDollarsIntl(row?.labelCharges) : 0}
        </Grid>
      );
    },
  },
  {
    title: 'Label Costs',
    keyName: 'labelCost',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      return (
        <Grid style={{ color: 'green' }}>
          {row?.labelCost > 0 ? formatCentsToDollarsIntl(row?.labelCost) : 0}
        </Grid>
      );
    },
  },
];
