import { Props } from './types';
import React, { useState } from 'react';
import { Tooltip, IconButton, Grid } from '@mui/material';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryDrawer from '../InventoryDrawer';

const AddToInternalInventoryButton: React.FC<Props> = ({ row, isInternal, addNewInternal }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <Grid item style={{ justifyContent: 'center' }}>
        <Tooltip title={'Create New Supplier'}>
          <span>
            <IconButton onClick={() => setIsDrawerOpen(true)}>
              <AssignmentReturnIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {isDrawerOpen && (
        <InventoryDrawer
          isDrawerOpen={isDrawerOpen}
          setDrawerOpen={setIsDrawerOpen}
          row={row}
          isInternal={isInternal}
          addNewInternal={addNewInternal}
        />
      )}
    </>
  );
};

export default AddToInternalInventoryButton;