import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import queryUtils from '@src/utils/queries';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { Tooltip, IconButton } from '@mui/material';
import ViewInventoryDrawer from '../ViewInventoryDrawer';

import type { Props } from './types';

const ViewItemDetails = ({ id, row, openView = false }: Props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const viewManufacturerSupplier = () => {
    const query = queryUtils.add('id', id);
    history.replace({
      search: query,
    });
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={`View Item Details`}>
        <span>
          <IconButton onClick={() => setOpen(true)}>
            <VisibilityTwoToneIcon color="primary" />
          </IconButton>
        </span>
      </Tooltip>
      {open && openView ? <ViewInventoryDrawer
        isDrawerOpen={open}
        setDrawerOpen={close}
        row={row}
      /> : null}
    </>
  );
}

export default ViewItemDetails;
