import { useState } from 'react';

import {
  Grid,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionProps } from './types';
import colors from '@oneAppCore/constants/colors';

const useStyles = makeStyles({
  button: {
    marginTop: 10,
    minWidth: '100%',
  },
  cancelButton: {
    backgroundColor: colors.BOOTSTRAP.v5.danger.color,
    color: colors.BOOTSTRAP.v5.danger.text,
    marginTop: 10,
    minWidth: '100%',
  },
});

function ActionCard({
  setIsViewMode,
  isViewMode,
  hasChanges,
  save,
  clear,
}: ActionProps) {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Card style={{ marginLeft: 20, minWidth: '100%' }}>
        <CardContent>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={() => setIsViewMode((prev) => !prev)}
              className={classes.button}
              variant="outlined"
              color="primary"
              style={{ marginBottom: 10 }}
            >
              {isViewMode ? 'Edit' : 'View'}
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={(e) => {
                save();
              }}
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={!hasChanges}
            >
              Save Changes
            </Button>
          </Grid>
          {hasChanges && (<Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={(e) => {
                clear();
              }}
              variant="contained"
              className={classes.cancelButton}
              // disabled={!hasChanges}
            >
              Clear Changes
            </Button>
          </Grid>)}
          <Grid item container justifyContent="center" xs={12}>
            <Button
              disabled
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              Send Invoice
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              disabled
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Add Payment
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ActionCard;
