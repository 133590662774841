import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Tooltip, IconButton, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

function EditButton({ row }: { row: any }) {
  const history = useHistory();

  const handleClick = () => {
    localStorage.setItem('selectedVar', row?.sku);
    history.push(`/products/view/${row?.product}`);
  };

  return (
    <>
      <Grid item style={{ justifyContent: 'center' }}>
        <Tooltip title={'Edit Listing'}>
          <span>
            <IconButton onClick={handleClick}>
              <EditTwoToneIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </>
  );
}

export default EditButton;
