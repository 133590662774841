import {
  AMAZON,
  WALMART,
  EBAY,
  WOO_COMMERCE,
  SHOPIFY,
  channelIds,
} from '@oneAppCore/constants/channels';

import { startCase } from 'lodash';

import type { ChannelName } from '@oneAppCore/types/channelTypes';

export const options = [
  {
    value: null,
    name: 'All',
  },
  {
    value: channelIds[AMAZON],
    name: startCase(AMAZON),
  },
  {
    value: channelIds[WALMART],
    name: startCase(WALMART),
  },
  {
    value: channelIds[EBAY],
    name: startCase(EBAY),
  },
  {
    value: channelIds[WOO_COMMERCE],
    name: startCase(WOO_COMMERCE),
  },
  {
    value: channelIds[SHOPIFY],
    name: startCase(SHOPIFY),
  },
];
