import axios, { AxiosInstance } from 'axios';
import { JWT_TOKEN } from '@oneAppCore/constants/jwt';
import { setJwtToken, removeJwtToken, getJwtToken } from './Jwt';
import dateUtils from '@oneAppCore/utils/dates';

class BaseApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL as string,
      timeout: 45000,
    });

    this._api.interceptors.response.use(
      (response) => response,
      this.errorResponseHandler,
    );
  }

  errorResponseHandler(error: any) {
    // check for errorHandle config
    if (
      error.config.hasOwnProperty('errorHandle') &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }

    // if has response show the error
    let errorMessage = 'There was an error';

    if (error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    if (
      errorMessage === 'jwt malformed' ||
      errorMessage === 'jwt expired' ||
      errorMessage === 'me user not found'
    ) {
      removeJwtToken();
      window.location.href = '/login';
    }
    const err = new Error(errorMessage);
    console.error(err);
    return Promise.reject(err);
  }

  checkJwtToken = (headers: any) => {
    const jwt = headers.jwt_token || headers[JWT_TOKEN];
    if (jwt) {
      setJwtToken(jwt);
    }
  };

  addHeaders = (headers: Record<string, any> = {}) => {
    const token = getJwtToken();
    headers.timezone = dateUtils.getTimezone();
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return headers;
  };

  getBase = async <T>(
    url: string,
    params: any = {},
    headers: any = {},
  ): Promise<T> => {
    headers = this.addHeaders(headers);
    const response = await this._api.get<T>(url, {
      headers,
      params,
    });
    this.checkJwtToken(response.headers);
    return response.data;
  };
  postBase = async <T>(url: string, params = {}, headers = {}): Promise<T> => {
    headers = this.addHeaders(headers);
    const response = await this._api.post<T>(url, params, {
      headers,
    });
    this.checkJwtToken(response.headers);
    return response.data;
  };
  putBase = async <T>(url: string, params = {}, headers = {}): Promise<T> => {
    headers = this.addHeaders(headers);
    const response = await this._api.put<T>(url, params, {
      headers,
    });
    this.checkJwtToken(response.headers);
    return response.data;
  };
  deleteBase = async <T>(url: string, headers = {}): Promise<T> => {
    headers = this.addHeaders(headers);
    const response = await this._api.delete<T>(url, {
      headers,
    });
    this.checkJwtToken(response.headers);
    return response.data;
  };
}

export default BaseApi;
