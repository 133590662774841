import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Modal, Button, Link, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FontAwesome from '@src/Components/common/FontAwesome';
import { useSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { APPROVED, PENDING, REJECTED } from '@oneAppCore/constants/listings';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { isObject } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    marginBottom: 2,
    marginTop: 2,
  },
  content: {
    fontSize: 15,
    textAlign: 'justify',
    marginBottom: 2,
    marginTop: 2,
  }
}));

function DescriptionModal({ row }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container justifyContent="center">
        <div>
          {row?.description?.length < 75 ? row?.description + ' ' : `${(row?.description)?.slice(0, 75)}...`}
          <Link component="button" onClick={handleOpen}>
            View Details
          </Link>
        </div>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              maxHeight: '80vh',
              overflowY: 'scroll',
              backgroundColor: 'white',
              borderRadius: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 10,
                margin: 10,
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: '-30px',
                }}
              >
                <Button onClick={() => handleClose()}>
                  <FontAwesome type="fa" form="fa" name="times" />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  Product Description
                </Typography>
              </Grid>
              {row?.description && (
                <Grid item xs={12}>
                  <Typography
                    className={classes.content}
                    dangerouslySetInnerHTML={{ __html: row?.description }}
                  ></Typography>
                </Grid>
              )}
              {!row?.description && (
                <Typography className={classes.content}>
                  No description added for this product.
                </Typography>
              )}
              <Grid item container>
                <Grid item xs={12}>
                  <Typography className={classes.title}>Bullet Points</Typography>
                </Grid>
                {row?.bulletPoints && Array.isArray(row?.bulletPoints) && row?.bulletPoints?.filter(Boolean).length > 0 && 
                <Grid item xs={12}>
                  <List>
                    {row?.bulletPoints.filter(Boolean).map((bullet, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <ArrowRightAltIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary={bullet} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>}
                {row?.bulletPoints && ((Array.isArray(row?.bulletPoints) && row?.bulletPoints?.filter(Boolean).length == 0) || Object.entries(row?.bulletPoints)?.length === 0) && 
                <Grid item xs={12}>
                  <Typography className={classes.content}>
                    No Bullet points added for this product.
                  </Typography>
                </Grid>}
                {Array.isArray(row?.bulletPoints) &&
                  row.bulletPoints.filter(Boolean).length > 0 && (
                    <Grid item xs={12}>
                      <List>
                        {row.bulletPoints
                          .filter(Boolean)
                          .map((bullet, index) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <ArrowRightAltIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={bullet} />
                            </ListItem>
                          ))}
                      </List>
                    </Grid>
                  )}
                {(!Array.isArray(row?.bulletPoints) ||
                  row.bulletPoints.filter(Boolean).length == 0) && (
                  <Grid item xs={12}>
                    <Typography className={classes.content}>
                      No Bullet points added for this product.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default DescriptionModal;
