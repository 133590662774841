import { COMPANY_CHANNELS, DASHBOARD_REPORTS, INVENTORY_SEARCH, LISTING_SEARCH, ORDER_SEARCH, PRODUCT_SEARCH, PRODUCTS, ROLES, USER_SEARCH } from "./roles";


export const INACTIVE = 'Inactive';
export const ACTIVE = 'Active';
export const AUTOMATED_USER = 1;

export const ADMIN_ROLES = [
  {
    roleId: ROLES[USER_SEARCH],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
  {
    roleId: ROLES[ORDER_SEARCH],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
  {
    roleId: ROLES[DASHBOARD_REPORTS],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
  {
    roleId: ROLES[PRODUCT_SEARCH],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
  {
    roleId: ROLES[LISTING_SEARCH],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
  {
    roleId: ROLES[COMPANY_CHANNELS],
    read: true,
    update: true,
    write: true,
    delete: false,
  },
  {
    roleId: ROLES[INVENTORY_SEARCH],
    read: true,
    update: true,
    write: true,
    delete: true,
  },
];

export const USER_ROLES = [
  {
    roleId: ROLES[ORDER_SEARCH],
    read: true,
    update: false,
    write: false,
    delete: false,
  },
  {
    roleId: ROLES[PRODUCT_SEARCH],
    read: true,
    update: false,
    write: false,
    delete: false,
  },
  {
    roleId: ROLES[LISTING_SEARCH],
    read: true,
    update: false,
    write: false,
    delete: false,
  },
  {
    roleId: ROLES[INVENTORY_SEARCH],
    read: true,
    update: false,
    write: false,
    delete: false,
  },
];
