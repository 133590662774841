import useMeStores from '@src/hooks/swr/useMeStores';
import useMagikTime from '../hooks/useMagikTime';

import AppSelect from '@src/Components/common/Selects/AppSelect';
import Grid from '@mui/material/Grid';

function StoreSelect() {
  const { data: stores = [] } = useMeStores();
  const [{ storeId }, setMagikTimeState] = useMagikTime.useState();

  const storeOptions = stores.map((store) => ({
    value: store.id,
    label: store.name,
  }));

  const updateStoreId = (storeId: number) => {
    setMagikTimeState((previous) => ({
      ...previous,
      storeId,
      lostMoneyOrders: previous.lostMoneyOrders.map((order) => ({
        ...order,
        selected: false,
      })),
    }));
  };

  return (
    <Grid item container xs={6} sm={4} lg={2}>
      <AppSelect
        options={storeOptions}
        label="Store"
        value={storeOptions.find((option) => option.value === storeId)}
        onChange={(e) => updateStoreId(e.value)}
      />
    </Grid>
  );
}

export default StoreSelect;
