import { useState, useMemo, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useCompanies from '@src/hooks/swr/useCompanies';
import { generatePassword } from '@oneAppCore/utils/authentication';

import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Select,
  FontAwesome,
  Backdrop,
} from '@src/Components/common';

import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import type { Company } from '@oneAppCore/types/companyTypes';
import type { CompanyIntegrations } from '@oneAppCore/types/companyIntegrationTypes';
import type { User } from '@oneAppCore/types/userTypes';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    padding: theme.spacing(2),
  },
  botmarg: {
    marginBottom: 10,
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

type Form = Company & User & CompanyIntegrations;

function CreateCompany({ onSubmit, close }: FormProps) {
  const classes = useStyles();
  const history = useHistory();

  const [form, setForm] = useState<Form>({});
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [residential, setResidential] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  console.log(form, 'company form');

  const update = <K extends keyof Form>(key: K, value: Form[K]) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      await Api.post(`/api/v1/companies`, form);
      form['companyName'] = form.name;
      await Api.post(`/api/v1/mailer/send`, form);
      enqueueSnackbar(`Created Company`, {
        variant: 'success',
      });
      if (onSubmit && typeof onSubmit === 'function') await onSubmit();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  // const submit = async (e: FormEvent) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setSaving(true);
  //   try {
  //     await Api.post(`/api/v1/companies`, form);
  //     form ['companyName'] = form.name
  //   }
  // }

  const handleSwitch = (e) => {
    const isResidential = e.target.checked;
    setResidential(isResidential);
    update('integrationData', {
      ...form.integrationData,
      shipFrom: {
        ...form.integrationData.shipFrom,
        residential: isResidential,
      },
    });
  };

  console.log(form, 'residential');

  return (
    <Paper elevation={1} className={classes.root}>
      <form className={classes.form} onSubmit={submit}>
        <Grid container spacing={2} direction="column">
          <Grid item container justifyContent="flex-start">
            <Typography variant="h5">Create Company</Typography>
          </Grid>
          <Grid container xs={12} justifyContent="space-around">
            <Grid item xs={12} style={{ padding: 10 }}>
              <Grid item xs={4} style={{ padding: 10 }} >
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Company/Store Name"
                  name="name"
                  value={form?.name || ''}
                  onChange={(e) => update('name', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid xs={12} style={{ padding: 10 }}>
              <Grid xs={4}>
                <Typography variant='h6'>Company Admin User</Typography>
              </Grid>
            </Grid>
            <Grid xs={12} container item style={{ padding: 10 }}>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="User's Email"
                  type="email"
                  value={form?.email || ''}
                  onChange={(e) => update('email', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  value={form?.firstName || ''}
                  onChange={(e) => update('firstName', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Last Name"
                  value={form?.lastName || ''}
                  onChange={(e) => update('lastName', e.target.value)}
                  required
                />
              </Grid>
              {/* <Grid item xs={4} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="User's Username"
                type="username"
                value={form?.username || ''}
                onChange={(e) => update('username', e.target.value)}
                required
              />
            </Grid> */}
              <Grid
                item
                container
                xs={4}
                style={{ padding: 10, alignItems: 'center' }}
              >
                <Grid item xs={9} md={11}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="User's Password"
                    type={showPassword ? 'text' : 'password'}
                    value={form?.password || ''}
                    onChange={(e) => update('password', e.target.value)}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: 'pointer' }}
                        >
                          {showPassword ? (
                            <FontAwesome name="eye-slash" type="fa" form="fa" />
                          ) : (
                            <FontAwesome name="eye" type="fa" form="fa" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} justifyContent="center">
                  <IconButton
                    onClick={() => update('password', generatePassword())}
                    color="primary"
                    style={{ marginTop: 15 }}
                    size="small"
                  >
                    <FontAwesome name="random" type="fa" form="fas" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} style={{ padding: 10 }}>
              <Grid xs={4}>
                <Typography variant='h6'>Company Address</Typography>
              </Grid>
            </Grid>
            <Grid xs={12} container item style={{ padding: 10 }}>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address Line 1"
                  name="street1"
                  value={form?.integrationData?.shipFrom?.street1 || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        street1: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address Line 2"
                  name="street2"
                  value={form?.integrationData?.shipFrom?.street2 || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        street2: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address Line 3"
                  name="street3"
                  value={form?.integrationData?.shipFrom?.street3 || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        street3: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="City"
                  name="city"
                  value={form?.integrationData?.shipFrom?.city || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        city: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="State"
                  name="state"
                  value={form?.integrationData?.shipFrom?.state || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        state: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Zip Code"
                  name="postalCode"
                  value={form?.integrationData?.shipFrom?.postalCode || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        postalCode: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Country"
                  name="country"
                  value={form?.integrationData?.shipFrom?.country || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        country: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  label="Phone"
                  name="phone"
                  value={form?.integrationData?.shipFrom?.phone || ''}
                  onChange={(e) =>
                    update('integrationData', {
                      ...form.integrationData,
                      shipFrom: {
                        ...form.integrationData?.shipFrom,
                        phone: e.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ padding: 10 }}>
                Residential
                <Switch checked={residential} onChange={handleSwitch} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item xs={1}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#B61F1F', color: 'white' }}
                fullWidth
                onClick={() => history.push('/admin/companies/search')}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Create Company
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default CreateCompany;
