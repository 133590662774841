import { useState, useEffect, FormEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import FontAwesome from '@src/Components/common/FontAwesome';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/index';

import {
  Table,
  TablePagination,
  Card,
  CircularProgress,
  Button,
  Grid,
  TextField,
  Chip,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Divider,
  Autocomplete,
  createFilterOptions,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import SearchBar from '@src/Components/common/containers/SearchView/SearchTable/SearchBar';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

import { columns } from './constants';
import { Filters } from '@src/Components/common/containers/SearchView/types';
import TableHeader from './TableHeader';
import TableRows from './TableRows';

import type {
  Form,
  Params,
  Supplier,
  VariationsForm,
  FitmentForm,
  ManufacturerSupplierForm,
} from '../types';
import { VarOptionType, OptionType } from '@oneAppCore/types/productTypes';
import { primary } from '@src/Components/Theme/themes/constants';
import { Theme } from '@mui/material/styles';
import type { Makes, Models } from '../../NewProductForm/types';
import { useVarData } from '@src/hooks/swr/Inventory/useVariationData';
import VariationBox from '../../NewProductForm/VariationsStep/variationBox'; // todo move this and its files into this page folder
import type { SaveFunction } from '../../NewProductForm/types';
// import { mutate } from 'swr';
import VariationsApi from '@oneAppCore/services/apis/Variations';
import { enqueueSnackbar } from 'notistack';
import { NONE } from '@oneAppCore/constants/products';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
    },
  },
}));

const filter = createFilterOptions<string | VarOptionType>();
const optionFilter = createFilterOptions<string | OptionType>();

const defaultVariation = {
  sku: null,
  manufacturerNo: null,
  variation: null,
  variantType: null,
  upc: '',
  quantityPerBox: 1,
  map: null,
  msrp: null,
  quantity: null,
};

function VariationsTable({
  product,
  supps,
  varList,
  brand: brandParam,
  props,
  save,
  updateProductForm,
  row,
  cellIndex,
  isViewMode,
  setIsViewMode,
  skuError,
  mpnError,
}: // mutate,
  {
    save?: SaveFunction;
    row?: any;
    varList?: any[];
    mutate?: any;
    product?: Form;
    props?: any;
    brand?: string;
    supps?: Supplier[];
    updateProductForm?: any;
    cellIndex?: number;
    isViewMode?: boolean;
    setIsViewMode?: any;
    skuError: boolean;
    mpnError: boolean;
  }) {
  const { id: paramId } = useParams<Params>();
  const classes = useStyles();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openedCell, setOpenedCell] = useState<number>();
  const [openedType, setOpenedType] = useState<string>('fitment');
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[][]>([
    [],
  ]);

  const [imageCell, setImageCell] = useState<number>();
  const [brand, setBrand] = useState<string>(brandParam);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [page, setPage] = useState(0);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [filters, setFilters] = useState<Filters[]>([]);
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [varOptsOne, setVarOptsOne] = useState<string[]>([]);
  const [varOptsTwo, setVarOptsTwo] = useState<string[]>([]);
  const [addOptsOne, setAddOptsOne] = useState(false);
  const [addOptsTwo, setAddOptsTwo] = useState(false);
  const [optionNameOne, setOptionNameOne] = useState('');
  const [hasOptNameOne, setHasOptNameOne] = useState(false);
  const [optionNameTwo, setOptionNameTwo] = useState('');
  const [hasOptNameTwo, setHasOptNameTwo] = useState(false);

  const { data: variationData, mutate: mutateVariations } = useVarData({
    id: Number(paramId),
    // searchFilter: searchOptions.searchFilter, // todo fix this?
    sku: searchOptions.filters.sku,
  });
  const [form, setForm] = useState<VariationsForm[]>(
    paramId ? variationData ?? [defaultVariation] : [defaultVariation],
  );
  const [uniqueVariations, setUniqueVariations] = useState(
    Array.from(new Set(form?.map((item) => item.variation))),
  );

  const [uniqueVariationsTwo, setUniqueVariationsTwo] = useState(
    Array.from(new Set(form?.map((item) => item.variationTwo))),
  );
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setHasOptNameOne(true);
    }
  };


  const combinations = varOptsOne?.flatMap((varItem) =>
    varOptsTwo.map((option) => ({ varItem, option })),
  );

  useEffect(() => {
    if (paramId) {
      mutateVariations();
      if (editMode === isViewMode) {
        setEditMode(!isViewMode);
      }
    }
  }, [isViewMode]);

  useEffect(() => {
    const combos = [];
    varOptsOne?.forEach((variation) => {
      varOptsTwo.forEach((variationTwo) => {
        combos.push({
          variation,
          variationTwo,
        });
      });
    });
    const updatedFormVariations =
      combos?.length > 0
        ? combos.map(({ variation, variationTwo }) => {
          const existingVariation = form?.find(
            (varObj) =>
              varObj.variation === variation &&
              (varObj.variationTwo === variationTwo || (varObj.variationTwo === null && variationTwo === null)),
          );
          return (
            existingVariation || {
              fitment: [],
              brand: '',
              sku: '',
              manufacturerNo: '',
              variation,
              variationTwo,
              suppliers: [],
              variantType: form?.[0]?.variantType ?? '',
              variantTypeTwo: form?.[0]?.variantTypeTwo ?? null,
              itemPrice: 0,
            }
          );
        })
        : varOptsOne?.map((v) => {
          const existingVariation = form?.find(
            (varObj) => (varObj.variation === v || varObj.variation === null && v === null),
          );

          return (
            existingVariation || {
              fitment: [],
              brand: '',
              sku: '',
              manufacturerNo: '',
              variation: v,
              variationTwo: null,
              suppliers: [],
              variantType: form?.[0]?.variantType ?? '',
              variantTypeTwo: null,
              itemPrice: 0,
            }
          );
        });
    /**/
    console.log('combinations', updatedFormVariations);
    if (paramId && updatedFormVariations?.length) {
      setForm((prevForm) => {
        const isSame =
          JSON.stringify(prevForm) === JSON.stringify(updatedFormVariations);
        return isSame ? prevForm : updatedFormVariations;
      });
    }
  }, [varOptsOne, varOptsTwo]);

  useEffect(() => {
    if (paramId) {
      const isSame = JSON.stringify(form) === JSON.stringify(variationData);
      if (isSame) {
        if (!isViewMode) {
          console.log('xcvb', variationData, form);
          setForm(variationData);
        }
      } else {
        setForm(variationData);
      }
    }
  }, [variationData]);

  const justVars = varOptsOne?.slice(0, variationData?.length);
  const handleKeyPress2 = (e) => {
    if (e.key === 'Enter') {
      setHasOptNameTwo(true);
    }
  };

  const unique = Array.from(new Set(varList));

  const setResetPagination = (shouldReset: boolean) => {
    if (shouldReset) {
      setPage(0);
    }
  };

  const setOpen = (cell: number, type: string = 'fitment') => {
    setOpenedType(type);
    setOpenedCell(cell);
  };


  useEffect(() => {
    setBrand(brandParam);
  }, [brandParam]);

  const addSuppliers = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice() || [];
    updatedSuppliers[variationIndex].push({ supplierId: 0 });
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
    setOpenedCell(variationIndex);
  };
  const updateAllVariationTypes = (variantType: string) => {
    const updatedVariations =
      form?.map((variation) => ({
        ...variation,
        variantType: variantType,
      })) || [];
    setForm(updatedVariations);
    updateProductForm('variations', updatedVariations);
  };

  const onChange = <K extends keyof VariationsForm>(
    index: number,
    key: K,
    value: VariationsForm[K],
  ) => {
    const updatedForm = form?.slice() || [];
    if (updatedForm[index]) {
      updatedForm[index] = {
        ...updatedForm[index],
        [key]: value,
      };
      setForm(updatedForm);
    }
    updateProductForm('variations', updatedForm);
  };

  console.log(form, 'form');

  const updateFitment = (
    index: number,
    second: number,
    key: any,
    value: FitmentForm,
  ) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index][second][key] = value;
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index].push({ years: [], make: '', model: '' });
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const createNewFitment = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.push([]);
    updatedVariations[variationIndex].fitment =
      newFitment[variationIndex] || [];
    setFitment(newFitment);
  };

  const deleteFitment = (variationIndex: number, rowIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[variationIndex].splice(rowIndex, 1);
    updatedVariations[variationIndex].fitment = newFitment[variationIndex];
    setFitment(newFitment);
  };

  const removeFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.splice(index, 1);
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const removeVariation = (index: number) => {
    const updatedVariations = form?.slice() || [];
    updatedVariations.splice(index, 1);
    removeFitment(index);
    setForm(updatedVariations);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    save({ variations: form, suppliers, fitment });
  };

  const initialFilters: Filters[] = [
    { type: 'string', name: 'SKU', keyName: 'sku' },
  ];

  const combinedFilters = initialFilters.map((filter) => {
    const updatedFilter = filters.find((f) => f.keyName === filter.keyName);
    return updatedFilter ? updatedFilter : filter;
  });

  const handleOptsOne = (newOptsOne: any[]) => {
    if (newOptsOne?.length && !newOptsOne[0]) {
      const popped = newOptsOne.filter((z) => z);
      const isSame = JSON.stringify(popped) === JSON.stringify(varOptsOne);
      if (!isSame) {
        const updatedForm = form.map((v) => {
          if (!v.variation) {
            return {
              ...v,
              variation: popped[0],
              variantType: optionNameOne,
            };
          } else {
            return v;
          }
        });
        setForm(updatedForm);
        setVarOptsOne(popped);
      }
    } else {
      if (JSON.stringify(varOptsOne) !== JSON.stringify(newOptsOne)) {
        setVarOptsOne(newOptsOne);
      }
    }
  };

  const handleOptsTwo = (newOptsTwo: any) => {
    if (newOptsTwo?.length && !newOptsTwo[0]) {
      const popped = newOptsTwo.filter((z) => z);
      const isSame = JSON.stringify(popped) === JSON.stringify(varOptsTwo);
      if (!isSame) {
        const updatedForm = form.map((v) => {
          if (!v.variationTwo) {
            return {
              ...v,
              variationTwo: popped[0],
              variantTypeTwo: optionNameTwo,
            };
          } else {
            return v;
          }
        });
        setForm(updatedForm);
        setVarOptsTwo(popped);
      }
    } else {
      if (JSON.stringify(varOptsTwo) !== JSON.stringify(newOptsTwo)) {
        setVarOptsTwo(newOptsTwo);
      }
    }
  };

  const createdCombo = combinations?.slice(0, variationData?.length);

  useEffect(() => {
    if (paramId) {
      if (variationData && variationData?.length > 0) {
        if (variationData[0]?.variantType /* && variationData[0]?.variantType !== NONE */) {
          setOptionNameOne(variationData[0]?.variantType);
          setHasOptNameOne(true);
          setVarOptsOne((prevVOO) => {
            const uniqueVariations = Array.from(
              new Set(variationData.map((item) => item.variation)),
            ) as string[];
            const isSame =
              JSON.stringify(uniqueVariations) === JSON.stringify(prevVOO);
            return isSame ? prevVOO : uniqueVariations;
          });
        }
        if (variationData[0]?.variantTypeTwo) {
          setOptionNameTwo(variationData[0]?.variantTypeTwo);
          setHasOptNameTwo(true);
          setVarOptsTwo((prevVOT) => {
            const uniqueVariations = Array.from(
              new Set(variationData.map((item) => item.variationTwo)),
            ) as string[];
            const isSame =
              JSON.stringify(uniqueVariations) === JSON.stringify(prevVOT);
            console.log('uniqueVariations', uniqueVariations);
            return isSame ? prevVOT : uniqueVariations;
          });
        }
        if (isViewMode) {
          setAddOptsOne(false);
          setAddOptsTwo(false);
        } else {
          if (variationData[0]?.variantType/*  && variationData[0]?.variantType !== NONE */) {
            setAddOptsOne(true);
          }
          if (variationData[0]?.variantTypeTwo) {
            setAddOptsTwo(true);
          }
        }
      }
    }
  }, [paramId, variationData, isViewMode]);

  const saveVariations = async () => {
    const toUpdate = [];
    const toCreate = [];

    form?.forEach((variation: any) => {
      if (variation?.id) {
        toUpdate.push(variation);
      } else {
        toCreate.push(variation);
      }
    });

    try {
      if (toUpdate.length > 0) {
        for (const variation of toUpdate) {
          const payload = {
            variation: variation.variation,
            manufacturerNo: variation.manufacturerNo,
            sku: variation.sku,
            itemPrice: variation.itemPrice,
            msrp: variation.msrp === undefined ? null : variation.msrp,
            map: variation.map === undefined ? null : variation.map,
          };
          try {
            await VariationsApi.put(variation.variationId, payload).then(() => {
              enqueueSnackbar('Saved Changes!', { variant: 'success' });
              mutateVariations();
            });
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }
      }

      if (toCreate.length > 0) {
        for (const variation of toCreate) {
          const payload = {
            variation: variation.variation,
            sku: variation.sku,
            manufacturerNo: variation.manufacturerNo,
            productId: paramId,
            brand: variation.brandId ?? 1,
            upc: variation.upc,
            quantityPerBox: variation.quantityPerBox,
            itemPrice: variation.itemPrice,
            variantType: variation.variantType,
            variantTypeTwo: variation.variantTypeTwo ?? null,
            variationTwo: variation.variationTwo ?? null,
          };
          try {
            await VariationsApi.post(payload).then(() => {
              enqueueSnackbar('Saved Changes!', { variant: 'success' });
              // mutate();
              mutateVariations();
            });
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }
      }
    } catch (e) {
      enqueueSnackbar('Error Saving Variations', { variant: 'error' });
    } finally {
      setAddOptsOne(false);
      setEditMode(false);
    }
  };

  const showEdit = !isViewMode || addOptsOne;

  useEffect(() => {
    setUniqueVariations(
      Array.from(new Set(form?.map((item) => item.variation))),
    );
    setUniqueVariationsTwo(
      Array.from(new Set(form?.map((item) => item.variationTwo))),
    );
  }, [form]);

  const existProductAddVars = (
    <Grid
      item
      container
      xs={12}
      justifyContent="flex-end"
      sx={{
        mr: 4,
        ml: 4,
        mb: 4,
      }}
    >
      <Grid sx={{ mr: 1 }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setAddOptsOne(false);
            setAddOptsTwo(false);
            setEditMode(false);
            setIsViewMode(true);
          }}
        >
          Cancel
        </Button>
      </Grid>
      <Grid sx={{ ml: 1 }}>
        <Button variant="contained" color="primary" onClick={saveVariations}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  );
  const varEditOptions = !paramId ? (
    <Grid container xs={12} alignItems="flex-end">
      {!isViewMode ? (
        <Grid container xs={12} alignItems="center" sx={{ mb: 5 }}>
          <Grid item xs={3} sx={{ mr: 5 }}>
            {addOptsOne === false ? (
              <Button variant="outlined" onClick={() => setAddOptsOne(true)}>
                Add Options
              </Button>
            ) : hasOptNameOne === false ? (
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                <InputLabel>Option</InputLabel>
                <OutlinedInput
                  type={'text'}
                  placeholder={'ex. Color'}
                  value={optionNameOne}
                  onKeyDown={(e) => handleKeyPress(e)}
                  onChange={(e) => setOptionNameOne(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={setHasOptOne(true)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Option"
                />
              </FormControl>
            ) : (
              <Autocomplete
                multiple
                freeSolo
                value={varOptsOne}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params) as VarOptionType[];

                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      label: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                options={unique}
                id="tags-standard"
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option?.inputValue) {
                    return option.inputValue;
                  }
                  return option?.label;
                }}
                onChange={(e, value) => {
                  handleOptsOne(value);
                }}
                renderInput={(params) => (
                  <TextField
                    onDoubleClick={() => setHasOptNameOne(false)}
                    {...params}
                    variant="outlined"
                    label={optionNameOne}
                    placeholder={optionNameOne}
                    style={{
                      marginRight: 10,
                      backgroundColor: primary.transparent.main,
                      width: '100%',
                    }}
                  // fullWidth
                  />
                )}
              />
            )}
          </Grid>
          {varOptsOne?.length > 0 && (
            <Grid
              item
              xs={5}
            // sx={{ margin: 20 }}
            >
              {addOptsTwo === false ? (
                <Button variant="outlined" onClick={() => setAddOptsTwo(true)}>
                  Add Options
                </Button>
              ) : hasOptNameTwo === false ? (
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel>Option</InputLabel>
                  <OutlinedInput
                    type={'text'}
                    placeholder={'ex. Color'}
                    value={optionNameTwo}
                    onKeyDown={(e) => handleKeyPress2(e)}
                    onChange={(e) => setOptionNameTwo(e.target.value)}
                  />
                </FormControl>
              ) : (
                <Autocomplete
                  multiple
                  freeSolo
                  value={varOptsTwo}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params) as OptionType[];

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  id="tags-standard"
                  options={unique}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option?.inputValue) {
                      return option.inputValue;
                    }
                    return option?.label;
                  }}
                  onChange={(event, value) => {
                    handleOptsTwo(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onDoubleClick={() => setHasOptNameTwo(false)}
                      {...params}
                      variant="outlined"
                      label={optionNameTwo}
                      placeholder={optionNameTwo}
                      style={{
                        backgroundColor: primary.transparent.main,
                        width: '50%',
                      }}
                    />
                  )}
                />
              )}
            </Grid>
          )}
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <Grid container xs={12} alignItems="center" sx={{ mb: 5 }}>
      <Grid item xs={3} sx={{ mr: 5 }}>
        {addOptsOne === false ? (
          <Button variant="outlined" onClick={() => setAddOptsOne(true)}>
            Add Options
          </Button>
        ) : hasOptNameOne === false ? (
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel>Option</InputLabel>
            <OutlinedInput
              type={'text'}
              placeholder={'ex. Color'}
              value={optionNameOne}
              onKeyDown={(e) => handleKeyPress(e)}
              onChange={(e) => setOptionNameOne(e.target.value)}
              label="Option"
            />
          </FormControl>
        ) : (
          <Autocomplete
            multiple
            freeSolo
            key={`var-opt-1`}
            value={
              varOptsOne?.length > 0
                ? varOptsOne
                : uniqueVariations
                  ? uniqueVariations
                  : []
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params) as VarOptionType[];

              if (params?.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  label: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            options={
              (varOptsOne?.length > 0
                ? varOptsOne
                : uniqueVariations
                  ? uniqueVariations
                  : []
              ).map((val) => ({
                label: val,
                inputValue: val,
              })) as VarOptionType[]
            }
            id="var-opts-one"
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option?.inputValue) {
                return option.inputValue;
              }
              return option?.label;
            }}
            onChange={(e, value) => {
              handleOptsOne(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={optionNameOne}
                placeholder={optionNameOne}
                style={{
                  marginRight: 10,
                  backgroundColor: primary.transparent.main,
                  width: '100%',
                }}
              />
            )}
          />
        )}
      </Grid>
      {varOptsOne?.length > 0 && ((
        <Grid
          item
          xs={5}
        // sx={{ margin: 20 }}
        >
          {addOptsTwo === false ? (
            <Button variant="outlined" onClick={() => setAddOptsTwo(true)}>
              Add Options
            </Button>
          ) : hasOptNameTwo === false ? (
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <InputLabel>Option</InputLabel>
              <OutlinedInput
                type={'text'}
                placeholder={'ex. Color'}
                value={optionNameTwo}
                onKeyDown={(e) => handleKeyPress2(e)}
                onChange={(e) => setOptionNameTwo(e.target.value)}
              />
            </FormControl>
          ) : (
            <Autocomplete
              multiple
              freeSolo
              key={`var-opt-2`}
              value={
                varOptsTwo.length > 0
                  ? varOptsTwo
                  : uniqueVariationsTwo
                    ? uniqueVariationsTwo
                    : []
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params) as OptionType[];

                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              options={
                (varOptsTwo.length > 0
                  ? varOptsTwo
                  : uniqueVariationsTwo
                    ? uniqueVariationsTwo
                    : []
                ).map((val) => ({
                  label: val,
                  inputValue: val,
                })) as OptionType[]
              }
              id="var-opts-two"
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option?.inputValue) {
                  return option.inputValue;
                }
                return option?.label;
              }}
              onChange={(event, value) => {
                handleOptsTwo(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={optionNameTwo}
                  placeholder={optionNameTwo}
                  style={{
                    marginRight: 10,
                    backgroundColor: primary.transparent.main,
                    width: '100%',
                  }}
                />
              )}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
  const viewVariations = (
    <>
      <SearchBar
        filters={combinedFilters}
        selectedFilterValues={selectedFilterValues}
        setSelectedFilterValues={setSelectedFilterValues}
        setResetPagination={setResetPagination}
      />
      <Table aria-label="collapsible table" style={{ maxWidth: '100%' }}>
        <TableHeader columns={columns} row={variationData} />
        <TableRows
          combinations={combinations}
          justVars={justVars}
          createdCombo={createdCombo}
          editMode={editMode}
          setEditMode={(value) => {
            setEditMode(value);
            if (!value) {
              setIsViewMode(!value);
            }
          }}
          optionNameOne={optionNameOne}
          optionNameTwo={optionNameTwo}
          hasOptionNameOne={hasOptNameOne}
          hasOptionNameTwo={hasOptNameTwo}
          columns={columns}
          rows={variationData}
          mutate={mutateVariations}
          page={page}
          rowsPerPage={rowsPerPage}
          openedCell={openedCell}
          openedType={openedType}
          setOpenedCell={setOpen}
          imageCell={imageCell}
          setImageCell={setImageCell}
          suppliers={supps}
        />
        {paramId && (
          <Grid
            item
            container
            justifyContent="flex-start"
            style={{ marginBottom: 20, marginTop: 10 }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setAddOptsOne(true);
                setEditMode(true);
              }}
              style={{ marginLeft: 10, minWidth: 'max-content' }}
            >
              Add Variation
            </Button>
          </Grid>
        )}
        <TablePagination
          count={variationData?.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          page={page}
          onPageChange={(e, page) => {
            setPage(page);
            setOpenedCell(null);
          }}
        />
      </Table>
    </>
  );
  const varsToEdit = form;
  const editVariations = (
    <>
      {varsToEdit?.map((variation, vIndex) => {
        return (
          <>
            <VariationBox
              combinations={combinations}
              vars={varOptsOne}
              index={vIndex}
              columns={columns}
              variation={variation}
              onChange={onChange}
              fitment={fitment}
              updateFitment={updateFitment}
              makes={makes}
              models={models}
              deleteRow={deleteFitment}
              removeVariation={removeVariation}
              imageCell={imageCell}
              setImageCell={setImageCell}
              row={row}
              mutate={mutateVariations}
              cellIndex={cellIndex}
              formLength={form?.length}
              varOptions={varOptsTwo}
              skuError={skuError}
              mpnError={mpnError}
              optionNameOne={optionNameOne}
              optionNameTwo={optionNameTwo}
              hasOptionNameOne={hasOptNameOne}
              hasOptionNameTwo={hasOptNameTwo}
              isViewMode={!editMode}
            />
          </>
        );
      })}
    </>
  );

  const variationsMap = showEdit ? editVariations : viewVariations;

  return (
    <>
      <Grid container xs={12} alignItems="flex-end">
        {showEdit ? varEditOptions : null}
      </Grid>
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <Grid item container xs={12} style={{ width: '100%' }}>
            {variationsMap}
            {paramId && showEdit && existProductAddVars}
          </Grid>
        </Card>
      </form>
    </>
  );
}

export default VariationsTable;
