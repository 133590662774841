import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import useBrands from '@src/hooks/swr/useBrands';

// import SupplierDrawer from './SupplierDrawer';

const weightProperties = [
  { label: 'Has Weight', value: 'weightNotNull' },
  { label: 'Missing Weight', value: 'weightNull' },
];

function VariationSearch() {
  const { data: brands } = useBrands();
  const [brandProperties, setBrandProperties] = useState<any[]>([]);

  useEffect(() => {
    if (brands?.length) {
      const brandList = brands
      .filter((brand) => brand.brand !== 'test')
      .map((brand) => ({ label: brand.brand, value: brand.id }))
      .sort((a,b) => a.label.localeCompare(b.label));
      setBrandProperties(brandList);
    }
  }, [brands]);

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/variations/search"
        columns={columns}
        // CreateFormComponent={SupplierDrawer}
        // constantFilters={{
        //   status: 'unassigned',
        // }}
        filters={[
          {
            type: 'selector',
            name: 'Brand',
            keyName: 'brandId',
            properties: brandProperties,
          },
          {
            type: 'string',
            name: 'Sku',
            keyName: 'sku',
            properties: [],
          },
          {
            type: 'string',
            name: 'MPN',
            keyName: 'manufacturerNo',
            properties: [],
          },
          {
            type: 'string',
            name: 'Variant',
            keyName: 'variation',
            properties: [],
          },
          {
            type: 'selector',
            name: 'Weight',
            keyName: 'weight',
            properties: weightProperties,
          },
        ]}
      />
    </Grid>
  );
}

export default VariationSearch;
