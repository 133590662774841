import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

interface SupplierStatus {
  data: {
    id?: number;
    status?: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: number;
    runtimeId?: number;
    supplierId?: string;
  }[];
}

export default function useSupplierStatus(runtimeId) {
  return useSWR<SupplierStatus>(
    `/api/v1/inventory/suppliers/status/${runtimeId}`,
    Api.get,
    {
      initialData: {
        data: [],
      },
      revalidateOnMount: true,
    },
  );
}
