import { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Report Name',
    keyName: 'reportName',
    align: 'left',
  },

  {
    title: 'Last Ran Date',
    keyName: 'lastRanDate',
    align: 'center',
  },
  {
    title: 'Last Ran By',
    keyName: 'lastRanBy',
    align: 'right',
  },
];
