

export const QTY_ZERO_UP = 'qty0^' as const;
export const QTY_ZERO_DN = 'qty0v' as const;
export const COST_LAW = 'cost' as const;
export const SALES_LAW = 'sales' as const;

export const PROFIT_CHECK = 'pc' as const;
export const OUT_OF_STOCK = 'oos' as const;
export const BACK_IN_STOCK = 'bis' as const;
export const PRICE_TOO_LOW = 'ctl' as const;

const priceLabel = 'Price too low';
const oosDownLabel = 'Supplier Out of Stock';
const oosUpLabel = 'Supplier Back In Stock';
const unspecLabel = 'Unspecified';

export const LAW_LIST = [
  QTY_ZERO_UP,
  QTY_ZERO_DN,
  COST_LAW,
  SALES_LAW,
];
export const LAW_MAP = {
  [QTY_ZERO_UP]: oosUpLabel,
  [QTY_ZERO_DN]: oosDownLabel,
  [COST_LAW]: priceLabel,
  none: 'none',
  // [SALES_LAW]: '',
};

export const SCENARIO_MAP = {
  [priceLabel]: PRICE_TOO_LOW,
  [oosDownLabel]: OUT_OF_STOCK,
  [oosUpLabel]: BACK_IN_STOCK,
  [unspecLabel]: PROFIT_CHECK,
};

export const SCENARIO_MAP_LABELS = {
  [PROFIT_CHECK]: unspecLabel,
  [OUT_OF_STOCK]: oosDownLabel,
  [BACK_IN_STOCK]: oosUpLabel,
  [PRICE_TOO_LOW]: priceLabel,
};
