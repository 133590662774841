import type { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Company Name',
    keyName: 'name',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Created By',
    keyName: 'createdBy',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Updated By',
    keyName: 'updatedBy',
    sortBy: true,
    align: 'center',
  },
];
