import clsx from 'clsx';
// import { options } from './constants';

import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundImage: 'linear-gradient(147deg, #4f46b0 0%, #7970dc 90%)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundImage: 'linear-gradient(147deg, #4ea3de 0%, #1a8cdb 74%)',
      color: theme.palette.common.white,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 10,
      marginTop: 10,
      minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {},
    minWidth: 'max-content',
    overflow: 'wrap',
    paddingLeft: '16px',
  },
  toggleGroup: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
}));

function TogglePages({ selectedTogglePage, togglePageOptions, setSelectedTogglePage }) {
  const classes = useStyles();

  const channelChange = (togglePage?: string) => {
    console.log({ togglePage });
    setSelectedTogglePage(togglePage);
  };

  return (
    <Grid item container alignItems="center" className={classes.responsiveUi}>
      <ToggleButtonGroup size="small" className={classes.toggleGroup}>
        {togglePageOptions.map((option) => (
          <ToggleButton
            key={option}
            style={{
              fontSize: '10px',
              fontWeight: 600,
            }}
            value={option}
            className={clsx(
              {
                [classes.selectedButton]:
                  (!selectedTogglePage && option === null) ||
                  selectedTogglePage === option,
              },
              {
                [classes.nonSelectedButton]:
                  (selectedTogglePage && option === null) ||
                  selectedTogglePage !== option,
              },
            )}
            onClick={() => channelChange(option)}
          >
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

export default TogglePages;
