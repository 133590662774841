import { useMemo } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import AppSelect from '../AppSelect';

import type { Props } from './types';
import { truncate } from 'fs';

function SupplierSelect(props: Props) {
  const { data: suppliers } = useSuppliers();

  const options = useMemo(() => {
    if (!suppliers) return [];
    return suppliers.map((channel) => ({
      value: channel.id,
      label: channel.name,
      internal: channel.internalSupplier,
      internalStock: channel.internalStock,
    }));
  }, [suppliers]);

  const firstSupp = options.find(
    (supplier) => supplier.internal === true && supplier.internalStock === true,
  );

  return (
    <AppSelect
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
      }}
      options={options}
      label={props?.label || 'Supplier'}
      {...props}
      value={
        typeof props?.value === 'object'
          ? props.value
          : options.find((option) => option.value === props?.value) || firstSupp
      }
    />
  );
}

export default SupplierSelect;
