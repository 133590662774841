function remove(key: string) {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has(key)) {
    queryParams.delete(key);
  }

  return queryParams.toString();
}

function add(key: string, value: string | number) {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(key)) {
    queryParams.delete(key);
  }
  queryParams.append(key, value.toString());
  return queryParams.toString();
}

export default {
  remove,
  add,
};
