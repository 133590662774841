import { useMemo, memo, useState, useEffect } from 'react';
import useBrands from '@src/hooks/swr/useBrands';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function BrandSelect(props: Props) {
  const { data: brands, mutate } = useBrands();
  const { addNewInternal } = props;
  // todo: turn this into a utility fumction maybe?
  const sortFunc = (a, b) => {
    const nameA = a.brand.toLocaleUpperCase(); // ignore upper and lowercase
    const nameB = b.brand.toLocaleUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
  const mapFunc = (brand) => ({
    value: brand.id,
    label: brand.brand,
  });

  useEffect(() => {
    mutate();
  }, [props.mutateVar]);

  const options = useMemo(() => {
    if (!brands) return [];
    return brands.sort(sortFunc).map(mapFunc);
  }, [brands, props.mutateVar]);

  return (
    <AppSelect
      options={options}
      label={props?.label || 'Brand'}
      {...props}
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
      }}
      value={
        typeof props?.value === 'object'
          ? props.value
          : options.find(
            (option) => option[props?.valueKey ?? 'value'] === props?.value,
          ) || {}
      }
    />
  );
}

export default memo(BrandSelect);
