import type { SelectOption } from './types';

export const options: SelectOption[] = [
  {
    value: 'LOST_MONEY_ORDERS',
    label: 'Lost Money Orders',
  },
  {
    value: 'PRICE_CHANGE_REPORT',
    label: 'Price Change Suggestions',
  },
  {
    value: 'ORDERS_COMPLETED_BY',
    label: 'Orders Completed By',
  },
  {
    value: 'ORDERS_TRACKED_BY',
    label: 'Orders Tracked By',
  },
  {
    value: 'ORDER_ITEMS',
    label: 'Order Items',
  },
  {
    value: 'SALES_TRENDS_REPORT',
    label: 'Sales Trends',
  },
  {
    value: 'INTERNAL_ORDERS_REPORT',
    label: 'Internal Orders',
  },
  {
    value: 'ORDER_PROFIT_REPORT',
    label: 'Profit By Order',
  },
  {
    value: 'FEEDS_REPORT',
    label: 'Feeds Report',
  },
  {
    value: 'INVENTORY_AGING_REPORT',
    label: 'Inventory Aging Report',
  },
  {
    value: 'BRAND_AGING_REPORT',
    label: 'Brand Aging Report',
  },
];
