import { useMemo } from 'react';
import useMeStores from '@src/hooks/swr/useMeStores';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function StoreSelect(props: Props) {
  const { data: stores } = useMeStores();

  const options = useMemo(() => {
    if (!stores) return [];
    return stores.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }));
  }, [stores]);

  return (
    <AppSelect
      options={options}
      label="Store"
      {...props}
      value={
        props?.value ? typeof props?.value === 'object'
          ? props.value
          : options.find((option) => option.value === props?.value) || {} :
          props?.selectDefault && options.length > 0 ? options[0] : {}
      }
    />
  );
}

export default StoreSelect;
