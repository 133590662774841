export function addressStringFormatter(address_string_1, address_string_2, address_string_3) {
    if (!address_string_1 && address_string_2) {
        address_string_1 = address_string_2;
        address_string_2 = "";
    } else if (!address_string_1 && !address_string_2 && address_string_3) {
        address_string_1 = address_string_3;
        address_string_3 = "";
    } else if (!address_string_1 && !address_string_2 && !address_string_3) {
        address_string_1 = "";
    }
    if (address_string_1 && address_string_1.length > 50) {
        const address1_array = addressStringLimiter(address_string_1);
        address_string_1 = address1_array[0];
        address_string_2 = address_string_2 == null ? address1_array[1] : address1_array[1] + address_string_2;
    }
    if (address_string_2 && address_string_2.length > 50) {
        const address2_array = addressStringLimiter(address_string_2);
        address_string_2 = address2_array[0];
        address_string_3 = address_string_3 == null ? address2_array[1] : address2_array[1] + address_string_3;
    }
    if (address_string_3 && address_string_3.length > 50) {
        address_string_3 = address_string_3.slice(0, 49);
    }
    // console.log("address 1:- ", address_string_1);
    // console.log("address 2:- ", address_string_2);
    // console.log("address 3:- ", address_string_3);
    return [address_string_1, address_string_2, address_string_3];
}

const addressStringLimiter = (address_string) => {
    const address_array = address_string.split(" ");
    let address_string_1 = "";
    let address_string_2 = "";
    let address_full = false;
    for (let index = 0; index < address_array.length; index++) {
        const element = address_array[index];
        if ((address_string_1 + element).length > 50) {
            address_full = true;
        }
        if (address_full == false) {
            address_string_1 = address_string_1 == "" ? element : address_string_1 + " " + element;
        } else {
            address_string_2 = address_string_2 == "" ? element : address_string_2 + " " + element;
        }
    }
    return [address_string_1, address_string_2];
}