import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { TIME_ZONES, dateFormat } from '@oneAppCore/constants/dates';

dayjs.extend(utc);
dayjs.extend(timezone);

function getFormattedDate(
  { date, format = dateFormat, timezone = TIME_ZONES.MOUNTAIN }: { date: string, format?: string, timezone?: string }
): string {
  const newDate = (dayjs(date).tz(timezone).format(format));
  return (newDate);
}

function dateCompare(a: any, b: any): number {
  if (dayjs(a.time).isBefore(b.time)) {
    return 1;
  } else if (dayjs(a.time).isAfter(b.time)) {
    return -1;
  } else {
    return 0;
  }
}

export default {
  getFormattedDate,
  dateCompare,
}