import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Tooltip,
  Grid,
  Button,
  Modal,
} from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import FontAwesome from '@src/Components/common/FontAwesome';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';
import { APPROVED, PENDING } from '@oneAppCore/constants/listings';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function ApproveButton({ selectedRows, deselectAllRows }) {
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const { mutate } = useSearch();
  const { enqueueSnackbar } = useSnackbar();

  const approveListings = async () => {
    setClicked(true);
    try {
      const data = {
        // status: APPROVED,
        status: PENDING,
      };
      let successCount = 0;
      for (const row of selectedRows) {
        const updateData = await Api.put(`/api/v1/store-listings/${row.id}`, data);
        if (updateData) {
          successCount += 1;
        }
      }
      if (successCount == selectedRows.length) {
        enqueueSnackbar(`Approved the listings`, {
          variant: 'success',
        });
        mutate();
        deselectAllRows();
      }
    } catch (error) {
      enqueueSnackbar(`Error approving listing`, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  }

  const handleOpen = async () => {
    setClicked(true);
    setOpen(true);
  };

  const handleClose = () => {
    setClicked(false);
    setOpen(false);
  };

  const handleCancelOpen = () => {
    setCancelOpen(true);
  };

  return (
    <>
      {/* <Grid item container xs={8} spacing={1} alignItems="flex-start"> */}
        <Grid item xs={12} sm={6} md={5}>
          {!clicked && (
            <Tooltip title={'Approve Listings'}>
              <span>
                <Button
                  disabled={selectedRows?.length < 1}
                  variant="contained"
                  color="primary"
                  style={{ width: 'calc(100%)' }}
                  onClick={async () => await handleOpen()}
                >
                  Approve {selectedRows.length}
                  {selectedRows.length > 1}
                </Button>
              </span>
            </Tooltip>
          )}
          {clicked && (
            <Tooltip title={'loading'}>
              <span>
                <Button
                  disabled
                  color="secondary"
                  variant="contained"
                  style={{ width: 'calc(100%)' }}
                  startIcon={
                    <FontAwesome
                      name="circle-notch"
                      type="fas"
                      form="fa"
                      spin
                    />
                  }
                >
                  Loading
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                // backgroundColor: '#7970dc',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: '-40px',
                }}
              >
                <Button onClick={() => handleClose()}>
                  <FontAwesome
                    type="fa"
                    form="fa"
                    name="times"
                    // color="#ffffff"
                  />
                </Button>
              </Grid>
              <h1>Are You Sure?</h1>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: 25,
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                This will submit selected listings directly to the sales channel.
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 20, justifyContent: 'center' }}
            >
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-start' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={6}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button variant="contained" color="primary" onClick={() => approveListings()}>
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      {/* </Grid> */}
    </>
  );
}

export default ApproveButton;
