import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import queryUtils from '@src/utils/queries';
import BrandApi from '@oneAppCore/services/apis/Brands';
import { Button, Grid, Tooltip, IconButton, Typography, LinearProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import UpoladIcon from '@mui/icons-material/Upload';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';
import type { Column } from '@src/Components/common/containers/SearchView/types';

export const columns: Column[] = [
  {
    title: 'Image',
    keyName: 'image',
    align: 'center',
    customComponent: function Image(row) {
      return (
        <img
          src={
            row?.image
              ? row.image
              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
          }
          width={65}
          height={55}
        />
      );
    },
  },
  {
    title: 'Brand Name',
    keyName: 'brand',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Aliases',
    keyName: 'brandAliases',
    sortBy: false,
    align: 'left',
    customComponent: function BrandAliases(row) {
      return (
        <>
          <p>{row.brandAliases?.map((ba) => ba).join(', ')}</p>
        </>
      );
    },
  },
  {
    title: 'MAP',
    keyName: 'isMap',
    sortBy: false,
    align: 'center',
    customComponent: function isMap(row) {
      return <>{row?.isMap === true ? <CheckIcon /> : null}</>;
    },
  },
  {
    title: 'Inventory (OOS)',
    keyName: 'inventory',
    sortBy: false,
    align: 'center',
    customComponent: function inventory(row) {
      return (
        <Grid>
          <Typography>
            {Number(row?.totalOAItems || 0).toLocaleString()}
            &nbsp;(
            {Number(row?.totalItemsWithZeroQuantity || 0).toLocaleString()})
            {/* {Number(row.brdInventory).toLocaleString()}({Number(row.brdOOS).toLocaleString()}) */}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Items (Missing)',
    keyName: 'products',
    align: 'center',
    customComponent: function Products(row) {
      const totalItems = Number(row?.totalItems || row?.totalOAItems || 0);
      const totalOAItems = Number(row?.totalOAItems || 0);
      const progress = (totalOAItems / totalItems) * 100;
      return (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>
              {totalItems}
              &nbsp;(
              {totalItems - totalOAItems < 0 ? 0 : totalItems - totalOAItems})
            </Typography>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <LinearProgress
              variant={row?.hasIncompleteFeed ? "indeterminate" : "determinate"}
              value={progress}
              sx={{
                height: 8,
                borderRadius: 4,
                // backgroundColor: '#e0e0e0'
              }}
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Listings (Inactive)',
    keyName: 'listings',
    align: 'center',
    customComponent: function Listings(row) {
      return (
        <>
          {row?.channelListings && row?.channelListings.length && <Grid container direction="column" gap={1}>
            {row?.channelListings.map((listing, lIndex) => (
              <Grid item container key={`${listing.channelId}-${lIndex}`} direction={'row'} gap={2} justifyContent={'center'}>
                <img
                  src={
                    listing?.channelIcon
                      ? listing.channelIcon
                      : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                  }
                  width={35}
                  height={30}
                />
                <Typography>
                  <b>{Number(
                    listing?.totalListings || 0,
                  )}</b>
                  &nbsp;(
                  {Number(listing?.totalListings) - Number(listing?.activeListings) < 0 ? 0 : Number(listing?.totalListings) - Number(listing?.activeListings)}
                  )
                </Typography>
              </Grid>
            ))}
          </Grid>}
          {!row?.channelListings && <Typography>
            {0}
            &nbsp;(0)
          </Typography>}
        </>
      );
    },
  },
  {
    title: 'Edit',
    keyName: 'edit',
    sortBy: false,
    align: 'center',
    customComponent: function EditBrand(row) {
      const history = useHistory();
      const { mutate } = useSearch();
      const { enqueueSnackbar } = useSnackbar();
      const EditBrand = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };

      const createCatalogFeed = async () => {
        try {
          const response: any = await BrandApi.createCatalogFeed(Number(row.id));
          if (response.message === 'Feed already exists') {
            mutate();
            enqueueSnackbar('Catalog Feed Already Exists', {
              variant: 'info',
            });
            return;
          };
          mutate();
          enqueueSnackbar('Catalog Feed Created', {
            variant: 'success',
          });
        } catch (error) {
          enqueueSnackbar('Error creating Catalog Feed', {
            variant: 'error',
          });
        };
      };
      return (
        <Grid container spacing={1} key={row.id}>
          <Grid item xs={6}>
            <Tooltip title={`Start Cataloging`}>
              <span>
                <IconButton color="primary" disabled={row?.hasIncompleteFeed} onClick={() => createCatalogFeed()}>
                  <UpoladIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title={`Edit Brand`}>
              <span>
                <IconButton onClick={() => EditBrand()}>
                  <EditTwoToneIcon color="primary" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },
  },
];
