import { useRecoilValue, useRecoilState } from 'recoil';

import { analyticsOptionsAtom } from './atoms';

import type { AnalyticsOptionsAtom } from './types';

export function useAnalyticsOptionsState() {
  return useRecoilState<AnalyticsOptionsAtom>(analyticsOptionsAtom);
}

export function useAnalyticsOptions() {
  return useRecoilValue<AnalyticsOptionsAtom>(analyticsOptionsAtom);
}
