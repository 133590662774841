type formatOptions = {
  useGrouping?: boolean;
  locale?: string;
  currency?: string;
  removeMoneySign?: boolean;
};

const optionDefault = {}

export function formatCentsToDollars(input: number | string, options: formatOptions = {}) {
  if (!input) return '0';
  const inputString = typeof input !== 'string' ? `${input}` : input;
  const { useGrouping = true, locale = 'en-US', currency = 'USD', removeMoneySign = false } = options;

  const cents = inputString.slice(-2);
  const dollarBase = inputString.slice(0, -2);
  const dollars = dollarBase;
  let ret;
  const dcs: any = `${dollars}.${cents}`;
  let formatOptions = { useGrouping, currency, style: 'currency' };
  if (removeMoneySign) delete formatOptions.style;
  const format = Intl.NumberFormat(locale, options)
  ret = format.format(dcs);
  // if (localeOptions) {
  //   ret = parseFloat(`${dollars}.${cents}`).toLocaleString(locale || undefined, localeOptions)
  // } else {
  //   ret = parseFloat(`${dollars}.${cents}`).toLocaleString(locale || undefined, { style:'currency', currency: 'USD' })
  // }

  return ret;
};

export function convertDollarsToCents(input: string | number) {
  if (!input) return 0;
  const inputString = typeof input !== 'string' ? `${input}` : input;
  const inputStringSplit = inputString.split('.');
  const centString = inputStringSplit.length > 1 ? inputStringSplit[1] : '0';
  const dollarString = inputStringSplit[0];

  const cents = parseInt(centString);
  const dollars = parseInt(dollarString) * 100;
  return dollars + (centString.length === 1 ? cents * 10 : cents)
}
