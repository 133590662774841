import { Chip, Button, Tooltip, IconButton, Grid } from '@mui/material';
import { getBootstrapColor } from '@src/utils/colors';
import { INACTIVE, ACTIVE } from '@oneAppCore/constants/users';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import { bootstrapTint } from '@oneAppCore/types/commonTypes';
import { FontAwesome } from '@src/Components/common';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

export const columns: Column[] = [
  // {
  //   title: 'Username',
  //   keyName: 'username',
  //   sortBy: true,
  //   align: 'center',
  // },
  {
    title: 'Email',
    keyName: 'email',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Company',
    keyName: 'company',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Status',
    keyName: 'userStatus',
    sortBy: true,
    align: 'center',
    customComponent: function statusView(row) {
      const { userStatus } = row;
      let tint: bootstrapTint = 'success';
      let label = ACTIVE;
      if (userStatus === 'inactive') {
        tint = 'danger';
        label = INACTIVE;
      }

      const { color: backgroundColor, text: color } = getBootstrapColor(
        tint,
        4,
      );
      return (
        <Chip
          label={label}
          style={{ backgroundColor, color, width: '60%', height: '100%' }}
        />
      );
    },
  },
  {
    title: 'Actions',
    keyName: 'actions',
    sortBy: false,
    align: 'center',
    customComponent: function ViewUser(row) {
      return (
        // <Grid container >
        // <Button
        //   key={row.id}
        //   color="primary"
        //   variant="contained"
        //   onClick={() => window.open(`/users/edit/${row.id}`, '_self')}
        // >
        //   View
        // </Button>
        <Grid container item xs={12} alignItems={'center'}>
          <Grid item xs={12} sm={6} md={4} xl={3} key={row.id}>
            <Tooltip title={`View User`}>
              <span>
                <IconButton
                  style={{ fontSize: '20px' }}
                  onClick={() =>
                    window.open(`/users/view/${row.id}`, '_self')
                  }
                >
                  <VisibilityTwoToneIcon color="primary" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3} key={row.id}>
            <Tooltip title={`Edit User`}>
              <span>
                <IconButton
                  style={{ fontSize: '20px' }}
                  onClick={() =>
                    window.open(`/users/edit/${row.id}`, '_self')
                  }
                >
                  <EditTwoToneIcon color="primary" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },
  },
];
