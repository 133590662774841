import { useEffect, useState } from 'react';

import SearchView from '@src/Components/common/containers/SearchView';
import { useSnackbar } from 'notistack';

import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { columns } from './constants';

function AppVarSearch() {
  return (
    <>
      <SearchView
        url="/api/v1/app-vars/search"
        columns={columns()}
      />
    </>
  );

};
export default AppVarSearch;