import {
  Grid, Button, Typography, LinearProgress, Table, TableBody,
  TableCell, TableHead, TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Theme,
} from '@mui/material/styles';
import {
  withStyles,
  createStyles,
} from '@mui/styles';
import { Link } from 'react-router-dom';
import { BLUES, REDS, LIGHTGREENS, LIGHTORANGES, LIGHTREDS, ORANGES, PURPLES } from '@oneAppCore/constants/colors';
import { DONE } from '@oneAppCore/constants/feeds';
import dayjs from 'dayjs';
import { ACTIVE, COMPLETED, DRAFT, INACTIVE } from '@oneAppCore/constants/listings';


const getStatusColor = (status: string): string => {
  switch (status) {
    case 'CREATED':
      return REDS.light;
    case 'UPLOADED':
      return LIGHTORANGES.light;
    case 'IN_QUEUE':
      return PURPLES.light;
    case 'IN_PROGRESS':
      return BLUES.lightest;
    case COMPLETED:
      return LIGHTGREENS.main;
    case 'DONE':
      return LIGHTGREENS.main;
  }
};

const useStyles = makeStyles((theme) => ({
  banger: ({ status }: any) => ({
    backgroundColor: getStatusColor(status),
  }),
  header: {
    marginBottom: 10,
  },
  card: {
    width: '100%',
    padding: 10,
    marginBotton: 30,
  },
  cardAction: {
    justifyContent: 'center',
  },
  textArea: {
    marginTop: 15,
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
  evenRow: {
    backgroundColor: '#EEF2F6',
  },
  cell: {
    borderRight: '1px solid #dddddd',
    paddingRight: '5px',
    paddingLeft: '5px',
    paddingTop: 2,
    paddingBottom: 1,
    width: 'auto',
    maxWidth: '100%',
    padding: '6px, 0px, 0px, !important',
    borderBottom: 'none',
    maxHeight: '10px',
  },
  column: {
    border: 0,
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f3f2f7',
    },
    body: {
      fontSize: 10,
    },
  }),
)(TableCell);

function ChannelList({ channel, channelCounts, channelHistCounts, channelFeed }) {
  const classes = useStyles({ status: channelFeed?.status });

  const correctCountIndex = channelCounts
    .map((x) => x.id)
    .indexOf(channel.scid);
  console.log('channel', channel);
  const channCount = channelCounts[correctCountIndex];
  const channHistObj = {};
  channelHistCounts.forEach((ch) => {
    if (ch.itemId === channel.scid) {
      if (!channHistObj[ch.subCategory]) channHistObj[ch.subCategory] = ch.total;
    }
  })

  const downloadFile = async (e, storeName, storeId) => {
    e.preventDefault();
    const ext = storeName === 'AMAZON' ? 'txt' : 'csv';
    const date = dayjs(channelFeed?.createdAt).format('YYYY-MM-DD-HH')
    const inventoryString = `CHANNEL_INVENTORY_${storeName}-${storeId}-${date}.${ext}`;
    // const url = await 'https://assets.pokemon.com/assets/cms2/img/pokedex/full/025.png';
    // window.open(inventoryString, '_blank');
    alert(inventoryString)
  };

  const guessEstimatedDownload = (status: string): number => {
    switch (status) {
      case 'UPLOADED':
        return 45;
      case 'IN_QUEUE':
        return 65;
      case 'IN_PROGRESS':
        return 85;
      case COMPLETED:
        return 100;
      case 'DONE':
        return 100;
      case 'CREATED':
        return 25;
      default:
        return 0;
    }
  };

  return (
    <>
      <Grid
        key={channel.id}
        id={channel.id}
        container
        justifyContent="space-between"
        alignItems="center"
        item
        xs={12}
        style={{ padding: 5 }}
      >
        <Typography style={{ fontWeight: 'bold' }}>
          {channel.name} {channel.storeName}:
        </Typography>
        <Typography
          style={{
            color: getStatusColor(channelFeed?.status),
            fontWeight: 'bold',
          }}
        >
          {channelFeed === undefined ? null : channelFeed?.status}
        </Typography>
        <Button
          disabled={channelFeed?.status !== DONE}
          color="primary"
          variant="outlined"
          key={channel.id}
          onClick={(e) => downloadFile(e, channel.name, channel.storeId)}
          type="submit"
        >
          Download
        </Button>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Table>
            <TableHead>
              <StyledTableCell
                align={'center'}
                key={`key-ins-${channel.id}`}
                className={classes.column}
                style={{
                  maxWidth: '100%',
                }}
              >
                In Stock (Previous)
              </StyledTableCell>
              <StyledTableCell
                align={'center'}
                key={`key-oos-${channel.id}`}
                className={classes.column}
                style={{
                  maxWidth: '100%',
                }}
              >
                Out of Stock (Previous)
              </StyledTableCell>
              <StyledTableCell
                align={'center'}
                key={`key-act-${channel.id}`}
                className={classes.column}
                style={{
                  maxWidth: '100%',
                }}
              >
                Active {/* (Previous) */}
              </StyledTableCell>
              <StyledTableCell
                align={'center'}
                key={`key-nac-${channel.id}`}
                className={classes.column}
                style={{
                  maxWidth: '100%',
                }}
              >
                Inactive {/* (Previous) */}
              </StyledTableCell>
              <StyledTableCell
                align={'center'}
                key={`key-drf-${channel.id}`}
                className={classes.column}
                style={{
                  maxWidth: '100%',
                }}
              >
                Draft {/* (Previous) */}
              </StyledTableCell>
            </TableHead>
            <TableBody>
              <TableRow
                key={`row-1-${channel.id}`}
                style={{ minHeight: '300px', overflow: 'auto' }}
                className={classes.oddRow}
              >
                <TableCell
                  key={`ins-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  {channCount?.instock || 0}
                </TableCell>
                <TableCell
                  key={`oos-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  {channCount?.oos || 0}
                </TableCell>
                <TableCell
                  key={`act-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  {channCount?.active || 0}
                </TableCell>
                <TableCell
                  key={`nac-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  {channCount?.inactive || 0}
                </TableCell>
                <TableCell
                  key={`drf-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  {channCount?.draft || 0}
                </TableCell>
              </TableRow>
              <TableRow
                key={`row-2-${channel.id}`}
                style={{ minHeight: '300px', overflow: 'auto' }}
                className={classes.evenRow}
              >
                <TableCell
                  key={`ins-now-${channel.id}`}
                  align={'center'}
                  style={{
                    width: 'auto',
                    maxWidth: '100%',
                    padding: '6px, 0px, 0px, !important',
                    borderBottom: 'none',
                    maxHeight: '10px',
                  }}
                  className={classes.cell}
                >
                  ({channCount?.oldstock || 0})
                </TableCell>
                <TableCell
                  key={`oos-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  ({channCount?.oldoos || 0})
                </TableCell>
                <TableCell
                  key={`act-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  ({channHistObj[ACTIVE] || 0})
                </TableCell>
                <TableCell
                  key={`nac-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  ({channHistObj[INACTIVE] || 0})
                </TableCell>
                <TableCell
                  key={`drf-now-${channel.id}`}
                  align={'center'}
                  className={classes.cell}
                >
                  ({channHistObj[DRAFT] || 0})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid id={channel.id} item xs={12} style={{ marginBottom: 10 }}>
        <LinearProgress
          classes={{ barColorPrimary: classes.banger }}
          style={{ height: 15, borderRadius: 10 }}
          variant="determinate"
          value={guessEstimatedDownload(channelFeed?.status)}
        // color="secondary"
        />
      </Grid>
    </>
  );
}

export default ChannelList;
