import recoilAnalyticsOptions from '../../AnalyticsHooks/AnalyticsOptions';

import ChannelSelect from '@src/Components/common/Selects/ChannelSelect';
import Grid from '@mui/material/Grid';

function NewerThanSelect() {
  const [, setAnalyticsOptions] = recoilAnalyticsOptions.useAnalyticsOptionsState();

  const updateAnalytics = (channelId: number | null) => {
    setAnalyticsOptions((previous) => ({
      ...previous,
      channelId,
    }));
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <ChannelSelect
        isClearable
        onChange={(option) => updateAnalytics(option?.value ?? null)}
      />
    </Grid>
  );
}

export default NewerThanSelect;
