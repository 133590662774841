import { BLUES, BOOTSTRAP, GREENS, GREYS, LIGHTBLACKS, LIGHTGREENS, PURPLES, REDS, REDS2 } from "@oneAppCore/constants/colors"
import { APPROVED, PENDING, REJECTED } from "@oneAppCore/constants/listings";
import { COMPLETED } from "@oneAppCore/constants/orders";

export const listingChangeStatusColors = (status: string) => {
  let returnColor = {bg: 'grey', text: BOOTSTRAP.v5.dark.text};
  switch(status) {
    case PENDING:{
      returnColor.bg = PURPLES.dark;
      returnColor.text = PURPLES.contrastText;
      break;
    }
    case COMPLETED:{
      returnColor.bg = GREENS.dark;
      returnColor.text = GREENS.contrastText;
      break;
    }
    case APPROVED:{
      returnColor.bg = BLUES.dark;
      returnColor.text = BLUES.contrastText;
      break;
    }
    case REJECTED:{
      returnColor.bg = REDS2.light;
      returnColor.text = REDS2.contrastText;
      break;
    }
  }
  return returnColor;
}
