import { Grid } from '@mui/material';

import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';

import CategoryViewDrawer from './CategoryViewDrawer';

function OrdersSearch() {
  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/channel-categories/search"
        columns={columns}
        CreateFormComponent={CategoryViewDrawer}
        filters={[]}
      />
    </Grid>
  );
}

export default OrdersSearch;
