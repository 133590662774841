import { Column } from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';

export const columns: Column[] = [
  {
    title: 'Category',
    keyName: 'category',
    sortBy: false,
    align: 'left',
    customComponent: function CategoryIcon(row) {
      return (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item>
            <FontAwesome 
            name='box-open'
            type='fa'
            form='fas'
            />
          </Grid> 
        </Grid>
      )
    }
  },
  {
    title: 'Notification',
    keyName: 'notification',
    sortBy: false,
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
  },
  {
    title: 'Delete',
    keyName: 'delete',
    align: 'right',
    sortBy: false,
  }
];