export const formFields = [
  'brand',
  'brandAliases',
  // 'metaData',
];

export const complexFormFields = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'internalSupplier',
    label: 'Internal',
    type: 'boolean',
  },
  {
    name: 'internalStock',
    label: 'App-Based Inventory',
    type: 'boolean',
    logic: {
      col: 'internalSupplier',
      func: (value) => value === true
    },
  },
  {
    name: 'usedSupplier',
    label: 'Used Supplier',
    type: 'boolean',
  },
  {
    name: 'metaData',
    label: 'Meta Data',
    type: 'object',
  },
];
export const disabledKeys = ['url', 'token'];
