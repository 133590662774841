import {
  ROLES,
  ADMIN,
  INVENTORY_SEARCH,
  INVENTORY_MANAGE,
  INVENTORY_STATISTICS,
  INVENTORY_RESTOCK,
  INVENTORY_FEEDS,
  PRODUCT_SEARCH,
  PRODUCT_CREATE,
  PRODUCT_CATEGORIES,
  PRODUCT_BRANDS,
  PRODUCT_SUPPLIERS,
  LISTING_SEARCH,
  STORE_MANAGER,
  ORDER_SEARCH,
  ORDER_ISSUES,
  ORDER_INTERNAL,
  COMPANY_SEARCH,
  COMPANY_CHANNELS,
  COMPANY_SUPPLIERS,
  WRITE,
  READ,
  UPDATE,
  USER_SEARCH,
} from '@oneAppCore/constants/roles';
import AuthLayout from '@src/Components/Layouts/AuthLayout';
import AppLayout from '@src/Components/Layouts/AppLayout';
import { SettingsApplicationsRounded } from '@mui/icons-material';

// Icons
import FontAwesome from '@src/Components/common/FontAwesome';

// Routes
import Login from '@src/Components/Auth/Login';
import ImportPage from '@src/Components/ImportData/ImportPage';
import Logout from '@src/Components/Auth/Logout';
import AdminUsers from '@src/Components/Users';
import UserView from '@src/Components/Users/UserView';
import AdminCompanies from '@src/Components/Companies';
// import StoreChannelSettings from '@src/Components/Store/ChannelSettings';
import StoreChannelList from '@src/Components/Store/ChannelSettings/ChannelList';
import ChannelPage from '@src/Components/Store/ChannelSettings/ChannelPage';
// import StoreSearch from '@src/Components/Stores/View/StoresView';
import InventorySearch from '@src/Components/Inventory/Search';
import InventoryManagement from '@src/Components/Inventory/Management';
import InventoryRestock from '@src/Components/Inventory/Restock';
import InboundPage from '@src/Components/Inventory/Inbound';
import OrdersSearch from '@src/Components/Orders/Search';
import EditOrderPage from '@src/Components/Orders/EditOrder';
import Issues from '@src/Components/Orders/Issues';
import Internal from '@src/Components/Orders/Internal';
import ProductsSearch from '@src/Components/Products/Search';
import FitmentSearch from '@src/Components/Fitment/Search';
import NewFitmentSearch from '@src/Components/Fitment/NewSearch';
import Reports from '@src/Components/Reports';
import ProductPage from '@src/Components/Products/ProductPage';
import ListingsReprice from '@src/Components/Listings/Reprice';
import ListingsSearch from '@src/Components/Listings/Search/Search';
import LawsAuditSearch from '@src/Components/Laws/AuditLog/Search';
import PendingListings from '@src/Components/Listings/Pending';
import InventoryStats from '@src/Components/Inventory/InventoryStats';
import InventoryFeeds from '@src/Components/Inventory/InventoryFeeds';
import AdminOperations from '@src/Components/Operations';
import MagikTime from '@src/Components/Tools/MagikTime';
import AnalyticsDashboard from '@src/Components/Dashboards/Analytics';
import Categories from '@src/Components/Products/Categories';
import Brands from '@src/Components/Products/Brands';
import Notifications from '@src/Components/Notifications/Search';
import LabelReport from '@src/Components/Tools/LabelReport';
import SupplierSearch from '@src/Components/Suppliers/Search/Search';
import VariationSearch from '@src/Components/Variations/Search/Search';
import ProfileView from '@src/Components/Users/UserView/ProfileView';
import NewListings from '@src/Components/Listings/view/NewListings';
import CCReconciliation from '@src/Components/Tools/CCReconciliation';
import CreateUser from '@src/Components/Users/UserView/UserCreation';
import CreateCompany from '@src/Components/Companies/CreateCompany';
import Aging from '@src/Components/Inventory/Aging';
import BrandAging from '@src/Components/Products/Brands/Aging';
import AppVars from '@src/Components/Tools/AppVariables/View';
import SupplierView from '@src/Components/Suppliers/Search/SupplierView';
import type { Page } from './types';
import CommissionReport from '@src/Components/Tools/CommissionReport';
import CreateFitment from '@src/Components/Fitment/Create/CreateFitment';
import Chatbot from '@src/Components/Chatbot';
import SupplierCreate from '@src/Components/Suppliers/Search/SupplierView/SupplierCreate';
// import EditModel from '@src/Components/Fitment/Edit-Model/EditModel';
import Permissions from '@src/Components/Roles/Search'

const SubRouteIcon = (
  <FontAwesome
    style={{ paddingLeft: 5 }}
    size="sm"
    name="circle"
    type="far"
    form="far"
  />
);

const mainRoutes: Page[] = [
  {
    path: '/',
    key: 'home',
    exact: true,
    component: AnalyticsDashboard,
    layout: AppLayout,
    title: 'Dashboards',
    sequence: 1,
    icon: <FontAwesome size="lg" name="chart-bar" type="fas" form="fa" />,
    // permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
    subRoutes: [
      {
        path: '/dashboards/analytics',
        key: 'dashboard-analytics',
        component: AnalyticsDashboard,
        layout: AppLayout,
        title: 'Analytics',
        icon: SubRouteIcon,
        // permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/dashboards/operations',
        key: 'dashboard-operations',
        component: AdminOperations,
        layout: AppLayout,
        title: 'Operations',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN]],
      },
      {
        path: '/dashboards/OneApp-AI',
        key: 'dashboard-chatbot',
        component: Chatbot,
        layout: AppLayout,
        title: 'OneApp AI',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN]],
      },
      {
        path: '/dashboards/notifications',
        key: 'dashboard-notifications',
        component: Notifications,
        layout: AppLayout,
        title: 'Notifications',
        icon: SubRouteIcon,
        hiddenFromNav: true,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
    ],
  },
  {
    path: '/orders',
    key: 'orders-root',
    exact: true,
    permissionRequired: [
      ROLES[ORDER_SEARCH],
      ROLES[ORDER_ISSUES],
      ROLES[ORDER_INTERNAL],
    ],
    sequence: 2,
    component: OrdersSearch,
    layout: AppLayout,
    title: 'Orders',
    icon: <FontAwesome size="lg" name="box" type="fa" form="fas" />,
    subRoutes: [
      {
        path: '/orders/list',
        key: 'orders-search',
        component: OrdersSearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ORDER_SEARCH]],
      },
      {
        path: '/orders/issues',
        key: 'orders-issues',
        component: Issues,
        layout: AppLayout,
        title: 'Issues',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ORDER_ISSUES]],
      },
      {
        path: '/orders/internal',
        key: 'orders-internal',
        component: Internal,
        layout: AppLayout,
        title: 'Internal',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ORDER_INTERNAL]],
      },
      {
        path: '/orders/view/:id',
        key: 'orders-view',
        component: EditOrderPage,
        layout: AppLayout,
        hiddenFromDrawer: true,
        title: 'Edit',
        icon: SubRouteIcon,
      },
      {
        path: '/orders/edit/:id',
        key: 'orders-edit',
        component: EditOrderPage,
        layout: AppLayout,
        hiddenFromDrawer: true,
        title: 'Edit',
        icon: SubRouteIcon,
      },
    ],
  },
  {
    path: '/inventory',
    key: 'inventory-root',
    exact: true,
    permissionRequired: [
      ROLES[INVENTORY_SEARCH],
      ROLES[INVENTORY_MANAGE],
      ROLES[INVENTORY_STATISTICS],
      ROLES[INVENTORY_RESTOCK],
      ROLES[INVENTORY_FEEDS],
    ],
    component: InventorySearch,
    layout: AppLayout,
    title: 'Inventory',
    sequence: 3,
    icon: <FontAwesome size="lg" name="dolly-flatbed" type="fa" form="fas" />,
    subRoutes: [
      {
        path: '/inventory/list',
        key: 'inventory-search',
        component: InventorySearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_SEARCH]],
      },
      {
        path: '/inventory/management',
        key: 'inventory-management',
        component: InventoryManagement,
        layout: AppLayout,
        title: 'Management',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_MANAGE]],
      },
      {
        path: '/inventory/statistics',
        key: 'inventory-statistics',
        component: InventoryStats,
        layout: AppLayout,
        title: 'Statistics',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_STATISTICS]],
      },
      {
        path: '/inventory/restock',
        key: 'inventory-restock',
        component: InventoryRestock,
        layout: AppLayout,
        title: 'Restock',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_RESTOCK]],
      },
      {
        path: '/inventory/inbound',
        key: 'inventory-inbound',
        component: InboundPage,
        layout: AppLayout,
        title: 'Inbound',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_RESTOCK]],
      },
      {
        path: '/inventory/feeds',
        key: 'inventory-feeds',
        component: InventoryFeeds,
        layout: AppLayout,
        title: 'Inventory Feeds',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[INVENTORY_FEEDS]],
      },
      {
        path: '/inventory/aging',
        key: 'inventory-aging',
        component: Aging,
        layout: AppLayout,
        title: 'Aging',
        icon: SubRouteIcon,
      },
      {
        path: '/suppliers/list',
        key: 'suppliers-list',
        component: SupplierSearch,
        layout: AppLayout,
        // hiddenFromDrawer: true,
        title: 'Suppliers',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[COMPANY_SUPPLIERS]],
      },
      {
        path: '/suppliers/view/:id',
        key: 'suppliers-view',
        component: SupplierView,
        layout: AppLayout,
        title: 'Supplier',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
      },
      {
        path: '/suppliers/create',
        key: 'suppliers-create',
        component: SupplierCreate,
        layout: AppLayout,
        title: 'Create',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
      },
    ],
  },
  {
    path: '/products',
    key: 'products-root',
    exact: true,
    permissionRequired: [
      ROLES[PRODUCT_SEARCH],
      ROLES[PRODUCT_CREATE],
      ROLES[PRODUCT_CATEGORIES],
      ROLES[PRODUCT_BRANDS],
    ],
    component: ProductsSearch,
    layout: AppLayout,
    title: 'Products',
    sequence: 4,
    icon: <FontAwesome size="lg" name="tags" type="fa" form="fas" />,
    subRoutes: [
      {
        path: '/products/list',
        key: 'products-search-search',
        component: ProductsSearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[PRODUCT_SEARCH]],
      },
      {
        path: '/products/create',
        key: 'products-create',
        component: ProductPage,
        layout: AppLayout,
        title: 'Create New Product',
        icon: SubRouteIcon,
        // permissionType: WRITE,
        // permissionRequired: [ROLES[PRODUCT_SEARCH]],
      },
      {
        path: '/products/categories',
        key: 'products-categories',
        component: Categories,
        layout: AppLayout,
        title: 'Categories',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[PRODUCT_CATEGORIES]],
      },
      {
        path: '/products/brands',
        key: 'products-brands',
        component: Brands,
        layout: AppLayout,
        title: 'Brands',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[PRODUCT_BRANDS]],
      },
      {
        path: '/products/aging',
        key: 'products-aging',
        component: BrandAging,
        layout: AppLayout,
        title: 'Aging',
        icon: SubRouteIcon,
      },
      {
        path: '/products/view/:id',
        key: 'products-edit',
        component: ProductPage,
        layout: AppLayout,
        hiddenFromDrawer: true,
        title: 'Edit',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[PRODUCT_SUPPLIERS]],
      },
      {
        path: '/suppliers/search',
        key: 'suppliers-search',
        component: SupplierSearch,
        layout: AppLayout,
        hiddenFromDrawer: true,
        title: 'Suppliers',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[PRODUCT_SEARCH]],
      },
      {
        path: '/variations/list',
        key: 'variations-list',
        component: VariationSearch,
        layout: AppLayout,
        // hiddenFromDrawer: true,
        title: 'Variations',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN], ROLES[PRODUCT_SEARCH]],
      },
    ],
  },
  {
    path: '/listings',
    key: 'listings-root',
    exact: true,
    component: ListingsSearch,
    layout: AppLayout,
    title: 'Listings',
    sequence: 5,
    icon: <FontAwesome size="lg" name="th-list" type="fa" form="fas" />,
    permissionRequired: [ROLES[LISTING_SEARCH]],
    subRoutes: [
      {
        path: '/listings/list',
        key: 'listings-search-search',
        component: ListingsSearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        sequence: 3,
        permissionRequired: [ROLES[LISTING_SEARCH]],
      },
      {
        path: '/listings/reprice',
        key: 'listings-search-reprice',
        component: ListingsReprice,
        layout: AppLayout,
        title: 'Repricing',
        icon: SubRouteIcon,
        sequence: 2,
        // permissionRequired: [ROLES[LISTING_SEARCH]],
      },
      {
        path: '/listings/draft',
        key: 'listings-pending',
        component: PendingListings,
        layout: AppLayout,
        title: 'Draft',
        icon: SubRouteIcon,
        sequence: 1,
        // permissionRequired: [ROLES[LISTING_SEARCH]],
      },
      {
        path: '/listings/create',
        key: 'create-listing',
        component: NewListings,
        layout: AppLayout,
        title: 'Pending',
        hiddenFromNav: true,
        icon: SubRouteIcon,
        sequence: 4,
      },
      {
        path: '/listings/audit',
        key: 'laws-audit-search',
        component: LawsAuditSearch,
        layout: AppLayout,
        title: 'Audit Log',
        // icon: SubRouteIcon,
        icon: <FontAwesome size="md" name="th-list" type="fa" form="fas" />,
        sequence: 5,
        // permissionRequired: [ROLES[LISTING_SEARCH]],
      },
    ],
  },
  {
    path: '/store-channels',
    key: 'store-channels-root',
    exact: true,
    component: AdminCompanies,
    permissionRequired: [ROLES[ADMIN], ROLES[COMPANY_CHANNELS]],
    // permissionType: WRITE,
    layout: AppLayout,
    title: 'Channels',
    sequence: 6,
    // webaholicsOnly: true,
    icon: <FontAwesome size="lg" name="building" type="fa" form="fa" />,
    subRoutes: [
      {
        path: '/store-channel/list',
        key: 'store-channels-list',
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        component: StoreChannelList,
        permissionType: READ,
        permissionRequired: [ROLES[COMPANY_CHANNELS]],
      },
      {
        path: '/store-channel/edit/:id',
        key: 'store-channel-edit',
        layout: AppLayout,
        hiddenFromDrawer: true,
        title: 'Update',
        icon: SubRouteIcon,
        component: ChannelPage,
        permissionType: UPDATE,
        permissionRequired: [ROLES[COMPANY_CHANNELS]],
      },
      {
        path: '/store-channel/create',
        key: 'store-channel-create',
        layout: AppLayout,
        title: 'Create Store Channel',
        icon: SubRouteIcon,
        component: ChannelPage,
        hiddenFromDrawer: true,
        // permissionType: WRITE,
        // permissionRequired: [ROLES[COMPANY_CHANNELS]],
      },
    ],
  },
  {
    key: 'fitment-root',
    exact: true,
    component: FitmentSearch,
    layout: AppLayout,
    title: 'Fitment',
    sequence: 7,
    icon: <FontAwesome size="lg" name="wrench" type="fa" form="fas" />,
    subRoutes: [
      {
        path: '/fitment/list',
        key: 'fitment-search-search',
        component: FitmentSearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
      },
      {
        path: '/fitment/new-list',
        key: 'fitment-search-search2',
        component: NewFitmentSearch,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
      },
      {
        path: '/fitment/create',
        key: 'fitment-craate-create',
        component: CreateFitment,
        layout: AppLayout,
        title: 'Create',
        icon: SubRouteIcon,
      },
      {
        path: '/fitment/view/:id/:name',
        key: 'product-fitment-edit',
        component: CreateFitment,
        layout: AppLayout,
        title: 'Edit',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
      },
      // {
      //   path: '/fitment/edit-model',
      //   key: 'model-edit',
      //   component: EditModel,
      //   layout: AppLayout,
      //   title: 'Edit-Model',
      //   icon: SubRouteIcon,
      //   hiddenFromDrawer: false,
      // }
    ],
  },
  {
    path: '/users',
    key: 'users-main',
    exact: true,
    component: AdminUsers,
    layout: AppLayout,
    title: 'Users',
    sequence: 8,
    icon: <FontAwesome size="lg" name="users" type="fa" form="fa" />,
    subRoutes: [
      {
        path: '/users/list',
        key: 'users-search',
        component: AdminUsers,
        permissionRequired: [ROLES[USER_SEARCH]],
        permissionType: READ,
        layout: AppLayout,
        title: 'List',
        icon: SubRouteIcon,
      },
      {
        path: '/user/profile',
        key: 'user-profile',
        component: ProfileView,
        layout: AppLayout,
        title: 'Profile',
        icon: <FontAwesome size="md" name="user-circle" type="fa" form="fa" />,
      },
      {
        path: '/users/edit/:id',
        key: 'users-edit-admin',
        component: UserView,
        // permissionRequired: [ROLES[ADMIN]],
        layout: AppLayout,
        title: 'Edit',
        permissionRequired: [ROLES[USER_SEARCH]],
        permissionType: UPDATE,
        icon: SubRouteIcon,
        hiddenFromNav: true,
      },
      {
        path: '/users/view/:id',
        key: 'users-edit',
        component: UserView,
        // permissionRequired: [ROLES[ADMIN]],
        layout: AppLayout,
        title: 'Edit',
        permissionRequired: [ROLES[USER_SEARCH]],
        permissionType: READ,
        icon: SubRouteIcon,
        hiddenFromNav: true,
      },
      {
        path: '/admin/users/create',
        key: 'users-create',
        component: CreateUser,
        layout: AppLayout,
        title: 'Create User',
        permissionRequired: [ROLES[USER_SEARCH]],
        permissionType: WRITE,
        icon: SubRouteIcon,
      },
    ],
  },
  {
    path: '/tools',
    key: 'tools',
    exact: true,
    component: MagikTime,
    layout: AppLayout,
    title: 'Tools',
    sequence: 97,
    webaholicsOnly: true,
    icon: <SettingsApplicationsRounded />,
    // icon: <FontAwesome size="lg" name="tools" type="fas" form="fa" />,
    subRoutes: [
      {
        path: '/tools/tutorial',
        key: 'tools-tutorial',
        component: MagikTime,
        layout: AppLayout,
        title: 'Magik Time',
        icon: SubRouteIcon,
        hiddenFromNav: true,
      },
      {
        path: '/tools/magik-time',
        key: 'tools-magik-time',
        component: MagikTime,
        layout: AppLayout,
        title: 'Magik Time',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/tools/shipstation-report',
        key: 'label-report',
        component: LabelReport,
        layout: AppLayout,
        title: 'ShipStation Report',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        key: 'tools-import',
        path: '/tools/import',
        component: ImportPage,
        layout: AppLayout,
        title: 'Import',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN]],
      },
      {
        path: '/tools/reports',
        key: 'tools-reports',
        component: Reports,
        layout: AppLayout,
        title: 'Reports',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN]],
      },
      {
        path: '/tools/commission-report',
        key: 'commission-report',
        component: CommissionReport,
        layout: AppLayout,
        title: 'Commission Report',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/tools/CCReconciliation',
        key: 'ccReconciliation',
        component: CCReconciliation,
        layout: AppLayout,
        title: 'Credit Card Reconciliation',
        icon: SubRouteIcon,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/tools/app-vars',
        key: 'app-variables-read',
        component: AppVars,
        layout: AppLayout,
        title: 'Application Variables',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/tools/app-vars/create',
        key: 'app-variables-create',
        component: AppVars,
        layout: AppLayout,
        title: 'create app vars',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/tools/app-vars/edit',
        key: 'app-variables-edit',
        component: AppVars,
        layout: AppLayout,
        title: 'edit app vars',
        icon: SubRouteIcon,
        hiddenFromDrawer: true,
        permissionRequired: [ROLES[ADMIN], ROLES[STORE_MANAGER]],
      },
      {
        path: '/admin/companies/search',
        key: 'companies-search',
        component: AdminCompanies,
        layout: AppLayout,
        title: 'List Companies',
        icon: SubRouteIcon,
        webaholicsOnly: true,
        permissionRequired: [ROLES[COMPANY_SEARCH]],
        permissionType: READ,
      },
      {
        path: '/admin/companies/create',
        key: 'companies-create',
        component: CreateCompany,
        layout: AppLayout,
        title: 'Create Company',
        icon: SubRouteIcon,
        webaholicsOnly: true,
        permissionRequired: [ROLES[COMPANY_SEARCH]],
        permissionType: WRITE,
      },
      {
        path: '/admin/companies/edit/:id',
        key: 'companies-edit',
        component: AdminCompanies,
        layout: AppLayout,
        title: 'Edit Company',
        icon: SubRouteIcon,
        hiddenFromNav: true,
        permissionRequired: [ROLES[COMPANY_SEARCH]],
        permissionType: UPDATE,
      },
      {
        path: '/admin/permissions/search',
        key: 'perms-search',
        component: Permissions,
        layout: AppLayout,
        title: 'List Permissions',
        icon: SubRouteIcon,
        webaholicsOnly: true,
        permissionRequired: [ROLES[COMPANY_SEARCH]],
        permissionType: READ,
      },
    ],
  },
  {
    key: 'login',
    path: '/login',
    component: Login,
    loggoutRequired: true,
    hideWhenLoggedIn: true,
    layout: AuthLayout,
    title: 'Login',
    sequence: 98,
    icon: <FontAwesome size="lg" name="sign-in-alt" type="fa" form="fa" />,
  },
  {
    key: 'logout',
    exact: true,
    path: '/logout',
    component: Logout,
    layout: AuthLayout,
    title: 'Logout',
    sequence: 100,
    icon: <FontAwesome name="sign-out-alt" type="fa" form="fa" />,
  },
];

export default mainRoutes;
