import recoilReportOptions from '../../hooks/useReportOptions';

import { options } from './constants';

import AppSelect from '@src/Components/common/Selects/AppSelect';
import Grid from '@mui/material/Grid';

function NewerThanSelect() {
  const [
    reportOptions,
    setReportOptions,
  ] = recoilReportOptions.useReportOptionsState();

  const updateReport = (date: string | null) => {
    setReportOptions((previous) => ({
      ...previous,
      newerThan: date,
    }));
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <AppSelect
        options={options}
        value={options.find(
          (option) => option.value === reportOptions.newerThan,
        )}
        onChange={(option) => updateReport(option?.value || null)}
        label="Newer Than"
        isClearable
      />
    </Grid>
  );
}

export default NewerThanSelect;
