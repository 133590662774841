import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function getUtcTimestamp(date?: Date | string | number) {
  return dayjs.utc(date);
}

// sets sime to a specific timezone and returns the UTC from that date
// defaults to Pacific, set as LA time
function getUtcTimestampForTZ(date?: string, tzone: string = 'America/Los_Angeles') {
  const newDate = (dayjs(date).tz(tzone))['$d'];
  return (newDate);
}

function toDayjs(date: Date | string | number) {
  return dayjs(date);
}

function within7Days(date: Date | string | number) {
  const sevenDaysAgo = dayjs().subtract(7, 'days');
  const dayjsDate = dayjs(date);
  const difference = dayjsDate.diff(sevenDaysAgo, 'days');
  return difference <= 7;
}

function getDay(date: Date | string | number) {
  return dayjs(date).day();
}

function createDate() {
  return dayjs();
}

function getTimezone() {
  return dayjs.tz.guess();
}

type DaysBackOptions = {
  unitsBack?: number;
  unitType?: string;
};

function getDaysBack({ unitsBack = 14, unitType = '' }: DaysBackOptions, isPrevious?: boolean) {

  let days = 14;
  let daysPrev = days * 2;
  let unit = 'days';
  if (unitsBack) {
    days = unitsBack;
    daysPrev = unitsBack * 2;
  } else if (typeof unitsBack === 'number' && unitsBack === 0) {
    days = unitsBack;
    daysPrev = 1;
  }
  if (unitType) unit = unitType;
  const newerThan = dayjs()
    .endOf('day')
    .subtract(days, unit as ManipulateType)
    .toISOString();
  const newerThan2 = dayjs()
    .endOf('day')
    .subtract(daysPrev, unit as ManipulateType)
    .toISOString();

  return { newerThan, newerThan2, daysPrev };
}

export default {
  getUtcTimestamp,
  getUtcTimestampForTZ,
  toDayjs,
  within7Days,
  getDay,
  createDate,
  getTimezone,
  getDaysBack,
};
