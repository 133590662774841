import useInventoryHistory from '../hooks/useInventoryHistory';

import dayjs from 'dayjs';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Grid } from '@mui/material';
import type { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: 600,
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
  evenRow: {
    backgroundColor: '#EEF2F6',
  },
}));

function InventoryHistory({ page, setPage, limit }: Props) {
  const classes = useStyles();

  const {
    data: { rows = [], count = 0 },
  } = useInventoryHistory(page, limit);
  return (
    <TableContainer component={Paper} className={classes.root}>
      {rows ? (
        <>
          <Table aria-label="simple table" stickyHeader={true}>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#f3f2f7',
                  whiteSpace: 'nowrap',
                }}
              >
                <TableCell align="center">Sku</TableCell>
                <TableCell align="center">Bin</TableCell>
                <TableCell align="center">Manufacturer Number</TableCell>
                <TableCell align="center">PO #</TableCell>
                <TableCell align="center">Brand</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Inbound</TableCell>
                <TableCell align="center">Override Quantity</TableCell>
                <TableCell align="center">Location Updated</TableCell>
                <TableCell align="center">Scanned At</TableCell>
                <TableCell align="center">Scanned By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <>
                  <TableRow
                    key={`${row.id}-${index}`}
                    className={`${index % 2 === 0 ? classes.evenRow : classes.oddRow
                      }`}
                  >
                    <TableCell align="center">{row.sku}</TableCell>
                    <TableCell align="center">{row.bin}</TableCell>
                    <TableCell align="center">
                      {row.manufacturerNumber}
                    </TableCell>
                    <TableCell align="center" style={{ maxWidth: 100 }}>
                      {row.purchaseOrderNo} <br />
                      ({row.itemsReceived} / {row.itemsPurchased})
                    </TableCell>
                    <TableCell align="center">{row.brand}</TableCell>
                    <TableCell align="center">
                      {row.quantity}
                      {row.newTotalQuantity
                        ? `/${row.newTotalQuantity}`
                        : ''}{' '}
                    </TableCell>
                    <TableCell align="center">{row.vq ? row.vq : 0}</TableCell>
                    <TableCell align="center">
                      {row.overrideQuantity?.toString()}
                    </TableCell>
                    <TableCell align="center">
                      {row.locationUpdated?.toString()}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(row.scannedAt)
                        .tz(dayjs.tz.guess())
                        .format('MM/DD/YYYY hh:mma z')}
                    </TableCell>
                    <TableCell align="center">{row.scannedBy}</TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
          <Grid style={{ marginTop: 15, padding: '6px 0px', position: 'sticky', bottom: 0, backgroundColor: '#fdfdfddd' }}>
            <Pagination
              count={Math.max(Math.ceil(count / limit), 1)}
              color="primary"
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
    </TableContainer>
  );
}

export default InventoryHistory;
