import { useState, useMemo } from 'react';
import useChannelCategories from '@src/hooks/swr/useChannelCategories';

import MenuItem from '@mui/material/MenuItem';
import {NestedMenuItem} from 'mui-nested-menu';

import type { Props } from './types';

function NestedCategory({ onChange, selectedId, categoryId, label }: Props) {
  const { data: channels } = useChannelCategories();
  const [open, setOpen] = useState(false);

  const categoryOptions = useMemo(() => {
    if (!channels || !categoryId) return [];
    return channels
      .filter((channel) => channel.parentCategoryId === categoryId)
      .map((parentChannel) => {
        const hasChildren = Boolean(
          channels.find(
            (channel) => channel.parentCategoryId === parentChannel.id,
          ),
        );
        return {
          value: parentChannel.id,
          label: parentChannel.categoryName,
          parentId: parentChannel.parentCategoryId,
          hasChildren,
        };
      });
  }, [channels, categoryId]);

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <NestedMenuItem
      onMouseEnter={() => setOpen(true)}
      parentMenuOpen={open}
      onKeyDown={(e) => e.stopPropagation()}
      onClickCapture={stopImmediatePropagation}
      key={categoryId}
      label={label}
      style={{
        fontSize: 15,
        padding: 0,
        paddingLeft: 5,
      }}
    >
      {categoryOptions.map((category) => {
        if (category.hasChildren) {
          return (
            <NestedCategory
            key={`nested-category-${category.label}`}
              categoryId={category.value}
              selectedId={selectedId}
              onChange={onChange}
              label={category.label}
            />
          );
        } else {
          return (
            <MenuItem
            key={`nested-category-${category.label}`}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={() => onChange(category.value)}
              selected={selectedId === category.value}
              style={{
                fontSize: 15,
                padding: 0,
                paddingLeft: 5,
              }}
            >
              {category.label}
            </MenuItem>
          );
        }
      })}
    </NestedMenuItem>
  );
}

export default NestedCategory;
