import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Paper, Typography, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import numberUtils from '@oneAppCore/utils/numbers';
import { EllipseLoader } from '@src/Components/common/Loaders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      fontWeight: 'bold',
    },
  }),
);

function Breakdown(props: any) {
  const classes = useStyles();
  // const [revenue, setRevenue] = useState(0);
  // const [profit, setProfit] = useState(0);

  // useEffect(() => {
  //   const getBreakdown = async () => {
  //     const count = await Api.get(`/api/v1/store-listings/search`).then(
  //       (res) => {
  //         const { userRevenue, userProfit } = res;
  //         setRevenue(userRevenue);
  //         setProfit(userProfit);
  //       },
  //     );
  //   };
  //   getBreakdown();
  // }, []);

  return (
    <SearchViewMiniReport>
      <Tooltip
        title="Breakdown of commission earned from listings created by current user"
        placement="top"
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2">Commission Breakdown</Typography>
          </Grid>
          {true ? (
            <>
              <Grid item container spacing={1}>
                <Grid item>
                  <Typography variant="body1">Total Revenue: </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.number}>
                    $ {numberUtils.formatNumber(props?.userRevenue / 100, '0.00')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <Typography variant="body1">Total Profit: </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.number}>
                    $ {numberUtils.formatNumber(props?.userProfit / 100, '0.00')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <Typography variant="body1">Total Commission: </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.number}>
                    $ {numberUtils.formatNumber((props?.userProfit * 0.08) / 100, '0.00')}{' '}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <EllipseLoader dark={true} style={{ display: 'flex' }} />
          )}
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default Breakdown;
