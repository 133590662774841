export const OUT_OF_STOCK = 'Out Of Stock';
export const BAD_ADDRESS = 'Bad Address';
export const OTHER = 'Other';
export const LOW_PROFIT = 'Low Profit';

export const issueIds = {
  [OUT_OF_STOCK]: 1,
  [BAD_ADDRESS]: 2,
  [OTHER]: 3,
  [LOW_PROFIT]: 4,
};

export const colorTheme = {
  palette: {
    [OUT_OF_STOCK]: { main: '#F291A0' },
    [BAD_ADDRESS]: { main: '#5F37B1' },
    [OTHER]: { main: '#2188E5' },
    [LOW_PROFIT]: { main: '#B4EDD2' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    green: {
      main: '#0f0',
      light: '#0f0',
      dark: '#0f0',
      lightest: '#0f0',
      darkest: '#0f0',
      contrastText: '#fff',
    },
    orange: {
      main: '#FFA500',
      light: '#FFA500',
      dark: '#FFA500',
      lightest: '#FFA500',
      darkest: '#FFA500',
      contrastText: '#fff',
    },
    red: {
      main: '#f00',
      light: '#f00',
      dark: '#f00',
      lightest: '#f00',
      darkest: '#f00',
      contrastText: '#fff',
    },
    purple: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      lightest: '#800080',
      darkest: '#800080',
      contrastText: '#fff',
    },
    blue: {
      main: '#00f',
      light: '#00f',
      dark: '#00f',
      lightest: '#00f',
      darkest: '#00f',
      contrastText: '#fff'
    },
    greys: {
      main: '#999',
      light: '#999',
      dark: '#999',
      lightest: '#999',
      darkest: '#999',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      lightest: '#000',
      darkest: '#000',
      contrastText: '#fff',
    },
    light: '',
  },
}