import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip } from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
    number: {
      color: theme.palette.purple.main,
      fontWeight: 700,
    },
  }),
);

function Unshipped(props: any) {
  const classes = useStyles();

  return (
    <SearchViewMiniReport>
      <Tooltip
        placement="top"
        title="Orders from the last 30 days that are unshipped"
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2">Unshipped</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.number}>
              {Number(props?.unshippedCount || 0).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default Unshipped;
