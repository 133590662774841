import Grid from '@mui/material/Grid';
import MatchedCount from './MatchedCount';
import UnmatchedCount from './UnmatchedCount';
import AmountBreakdown from './AmountBreakdown';

function MiniReports(props: any) {
  return (
    <Grid item container spacing={1}>
      <MatchedCount {...props} />
      <UnmatchedCount {...props} />
      <AmountBreakdown {...props} />
    </Grid>
  );
}

export default MiniReports;
