import { ReactElement } from 'react';
import { ISSUE, TRACKED, PLACED, COMPLETED } from '@oneAppCore/constants/orders';
import { startCase, lowerCase } from 'lodash';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { getColor } from '@src/utils/colors';
import { orderProfitColors, orderStatusColors } from '@src/utils/orders';
import dayjs from 'dayjs';

const formatNumber = (inputCost: string | number | null) => {
  let returnedValue = 'N/A';
  if (typeof inputCost === 'number') {
    returnedValue = inputCost.toFixed(2);
  } else if (typeof inputCost === 'string') {
    returnedValue = parseFloat(inputCost).toFixed(2);
  }
  return returnedValue;
};

export const firstColumns = [
  {
    id: 1,
    title: 'Order #',
    key: 'channelOrderId', //TODO make this clickable later?
  },
  {
    id: 2,
    title: 'Bought At',
    key: 'purchaseDate',
    customComponent: function purchaseDate({ item }) {
      const time = dayjs(item.purchaseDate).format('MM-DD-YY hh:mm A');
      return <p>
        {time}
      </p>;
    },
  },
  {
    id: 3,
    title: 'Ship By',
    key: 'expectedShipDate',
    customComponent: function shipDate({ item }) {
      const time = dayjs(item.expectedShipDate).format('MM-DD-YY hh:mm A');
      return <p>
        {time}
      </p>;
    },
  },
];

export const secondColumns = [
  {
    id: 1,
    title: 'Status',
    key: 'orderStatus',
    customComponent: function statusChip({ item, itemIndex }) {
      const backgroundColor = orderStatusColors(item.orderStatus);

      return (
        // <Grid key={item.id} container spacing={1} justifyContent="space-between">
        // <Grid item container xs={8} alignItems="center" justifyContent="flex-start">
        <>
          {/* <Grid item container xs={8}>
              <Typography>
                <strong>Status :</strong>
              </Typography>
            </Grid> */}

          <Grid
            style={{
              backgroundColor,
              padding: 10,
              borderRadius: 10,
              width: 100,
              height: 10,
              marginTop: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            {startCase(lowerCase(item.orderStatus))}
          </Grid>
          {item.issueType && (
            <Grid
              style={{
                backgroundColor: getColor('red', 'main'),
                padding: 10,
                borderRadius: 10,
                minWidth: 100,
                height: 10,
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {startCase(lowerCase(item.issueType))}
            </Grid>
          )}
        </>
        // </Grid>
        // </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'Cost',
    key: 'itemPrice',
    customComponent: function CostOfItem({ item, itemIndex }): ReactElement {
      const cost = item.orderItems?.reduce((acc, val) => {
        if (!val) return acc;
        const itemCost =
          (val.supplierTax || 0) / 100 +
          (val.supplierSubTotal || 0) / 100 +
          (val.supplierShippingCost || 0) / 100;
        return (acc += itemCost);
      }, 0);

      return (
        <>
          {/* <Grid container spacing={1} justifyContent="space-between"> */}
          {/* <Grid
              item
              container
              xs={8}
              alignItems="center"
              justifyContent="flex-start"
            > */}
          {/* <Grid item container xs={8}>
                <Typography>
                  <strong>Cost :</strong>
                </Typography>
              </Grid> */}

          <Grid item container xs={4}>
            <Typography>${formatNumber(cost)}</Typography>
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </>
      );
    },
  },
  {
    id: 3,
    title: 'Profit',
    key: 'profit',
    customComponent: function Profit({ item, itemIndex }) {
      const cost = item.orderItems?.reduce((acc, val) => {
        if (!val) return acc;

        const itemCost =
          (val.supplierTax || 0) / 100 +
          (val.supplierSubTotal || 0) / 100 +
          (val.supplierShippingCost || 0) / 100;
        return (acc += itemCost);
      }, 0);

      const price = item.orderItems?.reduce((acc, val) => {
        if (!val) return acc;
        const price = (val.itemPrice || 0) / 100;
        return (acc += price);
      }, 0);
      const profit = !cost ? 0 : price - price * 12 * 0.01 - cost;
      const profitMargin = (profit / price) * 100;
      const backgroundColor = orderProfitColors(profitMargin);
      console.log('backgroundColor', backgroundColor);
      return (
        <>
          {/* <Grid container spacing={1} justifyContent="space-between"> */}
          {/* <Grid
              item
              container
              xs={8}
              alignItems="center"
              justifyContent="flex-start"
            > */}
          {/* <Grid item container xs={8}>
                <Typography>
                  <strong>Profit :</strong>
                </Typography>
              </Grid> */}

          <Grid item container xs={4}>
            <Typography>${formatNumber(profit)} </Typography>
            <Grid item xs={12}>
              <Chip style={{ backgroundColor: backgroundColor }} label={`${formatNumber(profitMargin)}%`} />
            </Grid>
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </>
      );
    },
  },
];

export const textColumns = [
  {
    id: 1,
    require: true,
    title: 'Address Line 1',
    key: 'addressLine1',
    list: 'left',
    editable: true,
  },
  {
    id: 2,
    title: 'Address Line 2',
    key: 'addressLine2',
    list: 'left',
    editable: true,
  },
  {
    id: 3,
    require: true,
    title: 'City',
    key: 'city',
    list: 'left',
    editable: true,
  },
  {
    id: 4,
    require: true,
    title: 'State',
    key: 'state',
    list: 'right',
    editable: true,
  },
  {
    id: 5,
    require: true,
    title: 'Zip Code',
    key: 'zipCode',
    list: 'right',
    editable: true,
  },
  {
    id: 6,
    require: true,
    title: 'Country',
    key: 'country',
    list: 'right',
    editable: true,
  },
];

export const buyerInfoColumns = [
  {
    id: 1,
    title: 'Buyer Name:',
    key: 'shippingName',
  },
];

export const orderItemsColumns = [
  {
    id: 1,
    title: 'Product',
    // title: 'Product Name',
    key: 'channelData',
    customComponent: function ProductName(row) {
      console.log('item', row)
      return (
        // <Grid container spacing={1} justifyContent="flex-start">
        // <Grid
        //   item
        //   container
        //   key={item?.id}
        //   xs={6}
        //   spacing={1}
        //   alignItems="center"
        //   justifyContent="flex-start"
        // >
        //   <Grid item container xs={5}>
        //     <Typography>
        //       <strong>Product Name :</strong>
        //     </Typography>
        //   </Grid>
        <Grid item container xs={6}>
          <Grid item>{row?.item?.channeldata?.[0].title}</Grid>
        </Grid>
        // </Grid>
        // </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'Listing Sku',
    key: 'listingSku',
  },
  {
    id: 3,
    title: 'Tracking',
    key: 'supplierTrackingNo',
    customComponent: function Tracking({ item, order }) {
      let content: any = 'Not Yet Placed';
      if (order?.orderStatus === PLACED) {
        content = 'Not yet Tracked'
      } else if (order?.orderStatus === TRACKED) {
        content = (<>{item?.supplierTrackingNo} ({item?.supplierTrackingCarrier})</>)
      }
      return <>
        <p>{content}</p>
      </>
    },
  },
  {
    id: 4,
    title: 'Supplier No',
    key: 'supplierOrderNo',
    editable: true,
    customComponent: function SuppNo({ item, order }) {
      let content: any = 'Not Yet Placed';
      if ([PLACED, TRACKED, COMPLETED].includes(order?.orderStatus)) {
        content = (<>{item?.supplierOrderNo} ({item?.name})</>)
      }
      return <>
        <p>{content}</p>
      </>
    },
  },
];
