export default (theme): any => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '15px',
    width: '100%',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    // [theme.breakpoints.down('xs')]: {
    //   gridTemplateColumns: '1fr',
    // },
  },
  headerCard: {
    position: 'relative',
    borderRadius: '7px',
    padding: '18px',
    boxShadow: 'none',
    userSelect: 'none',
    '&.mini': {
      backgroundColor: '#ffffff',
      color: '#616161',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundImage: 'none',
      borderRadius: '8px',
      border: 'none rgba(144, 202, 249, 0.46)',
      overflow: 'hidden',
      padding: '10px 0 0 18px',
    },
  },
  bottomCard: {
    color: '#616161',
    backgroundColor: '#ffffff',
  },
  onlyScrollY: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  onlyScrollX: {
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  rowHalfHgt: {
    height: '80px',
  },
  rowOneHgt: {
    height: '175px',
  },
  rowTwoHgt: {
    height: 'calc(175px * 2 + (15px * 1))',
  },
  rowThreeHgt: {
    height: 'calc(180px * 2 + (15px * 2))',
  },
  rowFourHgt: {
    height: 'calc(300px * 2 + (15px * 2))',
  },
  jstSpcBtw: {
    justifyContent: 'space-between',
  },
  marginBottom: {
    marginBottom: '15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
    },
  },
  marginTop: {
    marginTop: '15px',
  },
  mobileMarginTop: {
    [theme.breakpoints.down('md')]: {
      marginTop: '15px',
    },
  },
  totalSalesCard: {
    backgroundColor: '#5e35b1',
    color: 'white',
    '&::before': {
      content: "''",
      position: 'absolute',
      // zIndex: '1',
      width: '210px',
      height: '210px',
      backgroundColor: '#4527a0',
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: '0.5',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: '#4527a0',
      borderRadius: '50%',
      // zIndex: '1',
      top: '-85px',
      right: '-95px',
    },
  },
  headerIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: '1',
    overflow: 'hidden',
    userSelect: 'none',
    color: 'white',
    fontSize: '16px',
    background: '#4527a0',
    cursor: 'pointer',
    borderRadius: '8px',
    width: '44px',
    height: '44px',
    marginTop: '8px',
    marginBottom: '8px',
    '&.gold': {
      backgroundColor: '#f3ac74',
      color: '#ffffff',
    },
    '&.green': {
      backgroundColor: '#b4edd2',
    },
  },
  totalCostCard: {
    backgroundColor: '#1e88e5',
    color: 'white',
    '&::before': {
      content: "''",
      position: 'absolute',
      zIndex: '1',
      width: '210px',
      height: '210px',
      background: '#1565c0',
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: '0.5',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      width: '210px',
      height: '210px',
      background: '#1565c0',
      borderRadius: '50%',
      zIndex: '1',
      top: '-85px',
      right: '-95px',
    },
  },
  cardFont: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '25px',
    fontWeight: 500,
    margin: '0',
    '& > span': {
      fontSize: '18px',
      color: '#b39ddb',
    },
    '&.mini': {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontWeight: '500',
      margin: '0',
      '& > span': {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '12px',
        color: '#c9bae3',
      },
    },
    '&.profit': {
      color: 'grey',
      fontSize: '14px',
      textAlign: 'right',
    },
  },
  profitCard: {
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      borderRadius: '50%',
    },
    '&::before': {
      background:
        'linear-gradient(140.9deg,#73eeb4 -14.02%,rgba(144, 202, 249, 0) 77.58%)',
      top: '-160px',
      right: '-130px',
    },
    '&::after': {
      background:
        'linear-gradient(210.04deg,#73eeb4 -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '-30px',
      right: '-180px',
    },
  },
  profitCardInnerDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  orderCard: {
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      borderRadius: '50%',
    },
    '&::before': {
      background:
        'linear-gradient(140.9deg,#f3ac74 -14.02%,rgba(144, 202, 249, 0) 70.5%)',
      top: '-160px',
      right: '-130px',
    },
    '&::after': {
      background:
        'linear-gradient(210.04deg,#f3ac74 -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '-30px',
      right: '-180px',
    },
  },
  categoryCard: {
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
  inventoryCard: {
    color: '#616161',
    backgroundColor: '#ffffff',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      borderRadius: '50%',
    },
    '&::before': {
      background:
        'linear-gradient(210.04deg,#4527a0 -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '100px',
      right: '-15px',
      opacity: '0.5',
    },
    '&::after': {
      background:
        'linear-gradient(210.04deg,#1565c0 -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '65px',
      right: '-95px',
    },
  },
  labelCard: {
    color: 'rgb(97, 97, 97)',
    backgroundcolor: 'rgb(255, 255, 255)',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      borderRadius: '50%',
    },
    '&::before': {
      background:
        'linear-gradient(210.04deg,rgb(69, 39, 160) -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '100px',
      right: '-15px',
      opacity: '0.5',
    },
    '&::after': {
      background:
        'linear-gradient(210.04deg,rgb(21, 101, 192) -50.94%,rgba(144, 202, 249, 0) 83.49%)',
      top: '65px',
      right: '-95px',
    },
  },
  rateCard: {},
  productCard: {
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
    '&::before, &::after': {
      content: ' ',
      position: 'absolute',
      width: '210px',
      height: '210px',
      borderRadius: '50%',
    },
    '::before': {
      top: '100px',
      right: '-15px',
      opacity: '0.5',
    },
    '::after': {
      top: '65px',
      right: '-95px',
    },
  },
  profitCards: {
    // ProfitCardsInnerDiv
    '& > div': {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        minWidth: '100%',
        flexDirection: 'column',
        marginTop: '15px',
        gap: '15px',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& > div': {
        [theme.breakpoints.down('md')]: {
          flex: '1',
          minWidth: '100%',
        },
      },
    },
  },
});
