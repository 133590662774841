import { Typography } from '@mui/material';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

type FitmentTable = {
  title: string;
  keyName: string;
  align?: string;
  customComponent?: (row: any) => JSX.Element;
}[];

export const fitmentTable: Column[] = [
  // {
  //   title: 'Image',
  //   keyName: 'imageUrl',

  // },
  // {
  //   title: 'Title',
  //   keyName: 'variation',
  //   customComponent: function nameCol(row) {
  //     console.log(row, 'caroline row');
  //     return <Typography>{row.variation}</Typography>;
  //   },
  //   // customComponent: function variation(row) {
  //   //   if (row) {
  //   //     return row.variation ? (
  //   //       <Typography>{row.variation}</Typography>
  //   //     ) : (
  //   //       <Typography>{row.name || '-'}</Typography>
  //   //     );
  //   //   }
  //   //   return <Typography>-</Typography>;
  //   // },
  // },
  // {
  //   title: 'SKU',
  //   keyName: 'sku',
  // },
  // {
  //   title: 'MPN',
  //   keyName: 'manufacturerNo',
  // },
  {
    title: 'Year',
    keyName: 'year',
    customComponent: function yearGen(row: number[]) {
      if (row?.length > 1) {
        const sortedRow = row.sort();
        return (
          <div>
            {sortedRow?.[0]}-{sortedRow[sortedRow.length - 1]}
          </div>
        );
      } else return <div>{row?.[0]}</div>;
    },
  },
  {
    title: 'Make',
    keyName: 'make',
  },
  {
    title: 'Model',
    keyName: 'model',
  },
  {
    title: 'Edit',
    keyName: 'edit',
    align: 'right',
  },
];
