import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

interface RuntimeStatus {
  id: number;
  status: string;
  createdAt: Date | null;
  createdBy: number;
  updatedAt: Date | null;
}

export default function useRuntimeStatus() {
  return useSWR<RuntimeStatus>('/api/v1/inventory/run', Api.get, {
    initialData: {
      id: 0,
      status: '',
      createdAt: null,
      createdBy: 0,
      updatedAt: null,
    },
    revalidateOnMount: true,
  });
}
