export const dateFormat = 'MMM DD, YYYY';


const PACIFIC = 'America/Los_Angeles';
const MOUNTAIN = 'America/Denver';
const CENTRAL = 'America/Nebraska';
const EASTERN = 'America/New_York_City';
const ARIZONA = 'America/Phoenix';


export const TIME_ZONES = {
  MOUNTAIN,
  PACIFIC,
  CENTRAL,
  EASTERN,
  ARIZONA,
};