import colors from '@oneAppCore/constants/colors';
import { createTheme } from '@mui/material/styles';
import { primary } from './constants';
import baseOptions from './baseTheme';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: primary,
    // secondary: colors.DARKPURPLES,
    secondary: {
      main: '#7970dc',
      light: '#909ba2',
      dark: '#000000',
      contrastText: '#FFF',
    },
    orange: colors.ORANGES,
    green: colors.GREENS,
    red: colors.REDS,
    purple: colors.PURPLES,
    blue: colors.BLUES,
    darkPurple: colors.DARKPURPLES,
    darkBlue: colors.DARKBLUES,
    greys: colors.GREYS,
    black: colors.BLACKS,
    // error: colors.REDS,
    // warning: colors.GOLDS,
    // info: colors.BLUES,
    // success: colors.LIGHTGREENS,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.BLACKS.main,
          color: colors.BLACKS.contrastText,
        },
        arrow: {
          color: colors.BLACKS.main,
        },
      },
    },
  },
}, baseOptions);

export default theme;
