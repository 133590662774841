import BaseApi from './BaseApi';

import type { User, ViewPageUser } from '@oneAppCore/types/userTypes';
import type { Search } from '@oneAppCore/types/searchTypes';

class UsersApi extends BaseApi {
  constructor() {
    super();
  }

  async get(id: number) {
    return await super.getBase<User>(`/api/v1/users/${id}`);
  }
  async getLoggedIn(id: number) {
    return await super.getBase<ViewPageUser>(`/api/v1/users/${id}`);
  }
  async put(id: number, payload: User) {
    return super.putBase<User>(`/api/v1/users/${id}`, payload);
  }
  async delete(id: number) {
    return super.deleteBase<User>(`/api/v1/users/${id}`);
  }
  async search(id: number, search: Search) {
    return super.getBase<User>(`/api/v1/users`, search);
  }
  async getLocations(id: number) {
    return super.getBase<any>(`/api/v1/user-location/user/${id}`);
  }
}

export default new UsersApi();
