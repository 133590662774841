import type { Tint } from '@oneAppCore/types/commonTypes';

type ColorTints = Record<Tint, string>;

export const THEME_PRIMARY = {
  main: '#389ADE',
  light: '#CEE6F7',
  dark: '#2C7BB1',
  contrastText: '#FFF',
  transparent: {
    main: '#389ADE15',
  },
};

export const THEME_GREYS = {
  main: '#72717a',
  light: '#f3f2f7',
  border: '#e0e0e1',
};

export const REDS: ColorTints = {
  darkest: '#ba4c4d',
  dark: '#c15e60',
  main: '#cf8384',
  light: '#cf8384',
  lightest: '#faeaea',
  contrastText: '#000',
};
export const REDS2: ColorTints = {
  darkest: '#B61F1F',
  dark: '#B61F1F',
  main: '#B61F1F',
  light: '#B61F1F',
  lightest: '#B61F1F',
  contrastText: '#fff',
};
export const LIGHTREDS: ColorTints = {
  darkest: '#fad9d9',
  dark: '#fae0e0',
  main: '#faeaea',
  light: '#faefef',
  lightest: '#faf6f6',
  contrastText: '#000',
};
export const DARKREDS: ColorTints = {
  darkest: '#8a0c18',
  dark: '#db1629',
  main: '#DC3545',
  light: '#db4d5a',
  lightest: '#e07b85',
  contrastText: '#FFF',
  // DC3545
};
export const LIGHTORANGES: ColorTints = {
  darkest: '#fff',
  dark: '#fff',
  main: '#ffa500',
  light: '#f3ac74',
  lightest: '#fff',
  contrastText: '#FFF',
};
export const ORANGES: ColorTints = {
  darkest: '#C6803F',
  dark: '#DF9047',
  main: '#f8a14f',
  light: '#F8AA60',
  lightest: '#fee6c8',
  contrastText: '#FFF',
};
export const GOLDS: ColorTints = {
  darkest: '#7a5b17',
  dark: '#CC9623',
  main: '#FFBC2B',
  light: '#E6BE67',
  lightest: '#ffe1a1',
  contrastText: '#FFF',
};
export const BLUES: ColorTints = {
  darkest: '#05A3B5',
  dark: '#06B7CC',
  main: '#07cce3',
  light: '#1FD1E5',
  lightest: '#38D6E8',
  contrastText: '#FFF',
};
export const DARKBLUES: ColorTints = {
  darkest: '#063b63',
  dark: '#0d578f',
  main: '#1677C1',
  light: '#4098db',
  lightest: '#85c3f2',
  contrastText: '#FFF',
};
export const LIGHTGREENS: ColorTints = {
  darkest: '#fff',
  dark: '#fff',
  main: '#b4edd2',
  light: '#fff',
  lightest: '#fff',
  contrastText: '#000',
};
export const GREENS: ColorTints = {
  darkest: '#2c6345',
  dark: '#47a06f',
  main: '#61b989',
  light: '#73c196',
  lightest: '#e4f8ed',
  contrastText: '#FFF',
};
export const DARKGREENS: ColorTints = {
  darkest: '#294721',
  dark: '#38692b',
  main: '#4d9c38',
  light: '#6cc953',
  lightest: '#88c977',
  contrastText: '#FFF',
};
export const PURPLES: ColorTints = {
  darkest: '#6059B0',
  dark: '#6C64C6',
  main: '#7970dc',
  light: '#867EDF',
  lightest: '#938CE3',
  contrastText: '#FFF',
};
export const DARKPURPLES: ColorTints = {
  darkest: '#6059B0',
  dark: '#6C64C6',
  main: '#7970dc',//5e35b1
  light: '#867EDF',
  lightest: '#938CE3',
  contrastText: '#FFF',
};
export const TRANSPARENTPURPLES: ColorTints = {
  darkest: '#302C582A',
  dark: '#4A45882A',
  main: '#5B55A42A',//5e35b1
  light: '#7970dc2a',
  lightest: '#897FF72A',
  contrastText: '#FFF',
};
export const GREYS: ColorTints = {
  darkest: '#707376',
  dark: '#7E8185',
  main: '#8d9094',
  light: '#989B9E',
  lightest: '#A3A6A9',
  contrastText: '#FFF',
};
export const BLACKS: ColorTints = {
  darkest: '#3C3B3B',
  dark: '#434242',
  main: '#4b4a4a',
  light: '#5D5C5C',
  lightest: '#6F6E6E',
  contrastText: '#FFF',
};
export const LIGHTBLACKS: ColorTints = {
  darkest: '#6e6e6e',
  dark: '#7f7f7f',
  main: '#909090',
  light: '#a1a1a1',
  lightest: '#b2b2b2',
  contrastText: '#FFF',
};

export const BOOTSTRAP = {
  v0: {
    warning: { color: '#FF9F43', text: '#212529' },
    success: { color: '#28C76F', text: '#212529' },
    danger: { color: '#EA5455', text: '#212529' },
    light: { color: '#82868B', text: '#212529' },
    dark: { color: '#696969', text: '#fff' },
  },
  v4: {
    primary: { color: '#007bff', text: '#fff' },
    secondary: { color: '#6c757d', text: '#fff' },
    success: { color: '#28a745', text: '#fff' },
    danger: { color: '#dc3545', text: '#fff' },
    warning: { color: '#ffc107', text: '#212529' },
    info: { color: '#17a2b8', text: '#fff' },
    light: { color: '#f8f9fa', text: '#212529' },
    dark: { color: '#343a40', text: '#fff' },
  },
  v5: {
    primary: { color: '#0d6efd', text: '#fff' },
    secondary: { color: '#6c757d', text: '#fff' },
    success: { color: '#198754', text: '#fff' },
    danger: { color: '#dc3545', text: '#fff' },
    warning: { color: '#ffc107', text: '#212529' },
    info: { color: '#0dcaf0', text: '#212529' },
    light: { color: '#f8f9fa', text: '#212529' },
    dark: { color: '#212529', text: '#fff' },
  },
};

export default {
  LIGHTGREENS,
  GREENS,
  DARKGREENS,
  LIGHTORANGES,
  ORANGES,
  REDS,
  DARKREDS,
  GOLDS,
  PURPLES,
  DARKPURPLES,
  BLUES,
  DARKBLUES,
  GREYS,
  BLACKS,
  LIGHTBLACKS,
  LIGHTREDS,
  BOOTSTRAP,
  TRANSPARENTPURPLES,
};
