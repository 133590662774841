import { useEffect, useState } from 'react';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import useQuery from '@src/hooks/util/useQuery';
import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { SwipeableDrawer } from '@mui/material';
import Button from '@mui/material/Button';

import type { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: 400,
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
  },
}));

function CreationDrawer({ Form, customSubmit }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const { mutate } = useSearch(false);
  const history = useHistory();

  const toggleOpen = () => setOpen(true);

  const close = () => {
    const query = queryUtils.remove('id');
    history.replace({
      search: query,
    });
    setOpen(false);
  };

  const onSubmit = async (props) => {
    if (customSubmit) {
      await customSubmit(props);
    }
    await mutate();
    close();
  };

  const { id } = useQuery();

  useEffect(() => {
    if (id) {
      setOpen(true);
    }
  }, [id]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={toggleOpen}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        New
      </Button>
      <SwipeableDrawer
        open={open}
        onClose={close}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <Form onSubmit={onSubmit} close={close} />
      </SwipeableDrawer>
    </>
  );
}

export default CreationDrawer;
