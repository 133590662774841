import BaseApi from '../BaseApi';

import type { OrderStats } from '@oneAppCore/types/reportTypes';
import type {
  OneAppOrder,
  OneAppOrderItem,
} from '@oneAppCore/types/orderTypes';
import type { Search } from '@oneAppCore/types/searchTypes';

interface SearchOrdersResponse extends OneAppOrder {
  orderItems: OneAppOrderItem[];
  price: number;
  profit: string;
  profitInDollars: string;
  channel: string;
  store: string;
}
class OrdersApi extends BaseApi {
  constructor() {
    super();
  }

  async getStats() {
    return this.getBase<OrderStats>(`/api/v1/orders/stats`);
  };

  async postToAutoDist(order: OneAppOrder) {
    return this.postBase(`/api/v1/orders/auto-dist`, order);
  };

  async postToTurn14(order: OneAppOrder) {
    return this.postBase(`/api/v1/orders/turn-14`, order);
  };

  async search(search: Search) {
    return await this.getBase<SearchOrdersResponse>(
      `/api/v1/orders/search`,
      search,
    );
  }

  async update(orderId, update) {
    return await this.putBase(
      `/api/v1/orders/${orderId}`,
      update,
    );
  }

  async getOrder(orderId) {
    return await this.getBase(`/api/v1/orders/${orderId}`);
  }

  async cancel(orderId) {
    console.log(orderId)
  }

  async postToWps(order: OneAppOrder) {
    return this.postBase(`/api/v1/orders/wps`, order);
  };

  async revertInternal(orderItems: OneAppOrderItem[], dropship: string = '') {
    return this.putBase(`/api/v1/orders/internal/negative`, { orderItems, dropship });
  };
}

export default new OrdersApi();
