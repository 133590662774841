import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { ANALYTIC_INS, ANALYTICS_TYPE_LABELS, colorTheme } from '@oneAppCore/constants/listings';

import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BarChart from '@src/Components/common/charts/BarChart';

import ReportApi from '@oneAppCore/services/apis/Reports';

import { Props, ChannelProps } from '@src/Components/Dashboards/Analytics/types';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function ListingStatusChart({ unitsBack, unitType, chartType = ANALYTIC_INS }: Props & ChannelProps) {
  const [data, setData] = useState<any[]>([]);
  const [labels, setLabels] = useState<any[]>([]);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);
      const response = await ReportApi.get('LISTING_STOCK_REPORT', {
        newerThan,
        page: 1,
        limit: 1000000,
      });
      const obj = {};
      const rows = response.rows.reduce((acc, row) => {
        const keyName = dayjs(
          row['createdAt'],
          // 'MM-DD-YYYY HH:mm:ss',
        ).format('MM/DD/YYYY');
        // const subKeyName = row.storeChannel;
        const addTotal = row.subCategory === chartType ? row.total : 0;
        return {
          ...acc,
          [keyName]: acc[keyName] ? acc[keyName] + addTotal : addTotal,
        };
      }, {});
      const labelRows = Object.keys(rows)
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: rows[key],
          }),
          {},
        );
      const orderedRows = [];
      response.rows.forEach((row) => {
        if (!obj[row['storeChannel']]) obj[row['storeChannel']] = [];
        obj[row['storeChannel']].push(row);
      });
      const colors = theme.palette['indexColors'];
      Object.keys(obj).forEach((objKey, objIndex) => {
        const rows = obj[objKey].reduce((acc, row) => {
          const keyName = dayjs(
            row['createdAt'],
            // 'MM-DD-YYYY HH:mm:ss',
          ).format('MM/DD/YYYY');
          const add = row.subCategory === chartType ? row.total : 0;
          const base = !acc[keyName] ? 0 : acc[keyName];
          return {
            ...acc,
            [keyName]: base + add,
          };
        }, {});
        let arrayOfOrderedRows = [];
        Object.keys(labelRows)
          .sort()
          .forEach((labelName) => {
            if (!arrayOfOrderedRows[labelName])
              arrayOfOrderedRows[labelName] = 0;
          });
        const reducedRows = Object.keys(labelRows)
          .sort()
          .reduce((acc, key) => {
            let returningReducedObject = {
              ...acc,
              [key]: rows[key],
            };
            if (!rows[key])
              returningReducedObject = {
                ...acc,
                [key]: 0,
              };
            return returningReducedObject;
          }, {});
        arrayOfOrderedRows = Object.keys(reducedRows).map(
          (reducedRow, index) => {
            return reducedRows[reducedRow];
          },
        );
        const ret = {
          data: arrayOfOrderedRows,
          label: objKey,
          backgroundColor: theme.palette['indexColors'][objIndex % colors.length],
          hoverOffset: 5,
        };
        orderedRows.push(ret);
      });
      setData(orderedRows);
      setLabels(Object.keys(labelRows).sort());
    })();
  }, [unitsBack, chartType]);

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">{`${ANALYTICS_TYPE_LABELS[chartType]} Listings`}</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <BarChart
          labels={labels}
          data={data}
          // stacked
          grouped
          title={`${ANALYTICS_TYPE_LABELS[chartType]} Listings`}
          scales={{
            x: { stacked: true },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          }}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ListingStatusChart;
