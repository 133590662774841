import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
    number: {
      color: theme.palette.purple.main,
      fontWeight: 700,
    },
  }),
);

function TotalCost(props: any) {
  const classes = useStyles();
  const [sumCost, setCost] = useState<number>(0);

  useEffect(()=>{
    if (props?.sumCost) {
      const additive = `${props?.sumCost}`.split('.')[0];
      setCost(parseInt(additive));
    }
  }, [props])

  return (
    <SearchViewMiniReport>
      <Tooltip
        placement="top"
        title="Total cost for inventory"
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body1">Total Cost</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.number}>
              {formatCentsToDollarsIntl(sumCost)}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default TotalCost;
