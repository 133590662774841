import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import MiniReports from './MiniReports';
import { reposState as reposAtom } from '../../common/containers/SearchView/SearchTable/atoms';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMeStores from '@src/hooks/swr/useMeStores';
import { Filters } from '@src/Components/common/containers/SearchView/types';
import useUsers from '@src/hooks/swr/useUsers';
import { ACTIVE, INACTIVE } from '@oneAppCore/constants/listings';

const useStyles = makeStyles((theme: Theme) => ({
  tablePaper: {
    width: '425px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

const ListingsSearch = () => {
  const classes = useStyles();
  const { data } = useMeStores();
  const [stores, setStores] = useState([{ label: 'None', value: null }]);
  const [tableRowsState, setTableRowsState] = useRecoilState(reposAtom);
  const [filters, setFilters] = useState<Filters[]>([]);
  const { data: users = [] } = useUsers();
  const sortedUsers = users.length ? users?.sort((a, b) => {
    const nameA = a.firstName.toUpperCase();
    const nameB = b.firstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }) : [];
  const userOptions = sortedUsers?.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));
  useEffect(() => {
    if (data) {
      setStores([
        ...data.map((store) => ({
          label: store.name,
          value: store.id,
        })),
      ]);
    }
  }, [data]);

  useEffect(() => {
    const listingFromStorage = localStorage.getItem('listingSku') || '';
    if (listingFromStorage) {
      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.keyName === 'listingSku') {
            return {
              ...filter,
              defaultValue: listingFromStorage,
            };
          }
          return filter;
        });
        return updatedFilters;
      });
    }
  }, []);

  useEffect(() => {
    const mpnFromLocalStorage = localStorage.getItem('selectedVarMpn') || '';
    if (mpnFromLocalStorage) {
      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.keyName === 'manufacturerNo') {
            return {
              ...filter,
              defaultValue: mpnFromLocalStorage,
            };
          }
          return filter;
        });
        return updatedFilters;
      });
    }
  }, []);

  const changePriceFunc = (props: string): void => {};
  
  const initialFilters: Filters[] = [
    { type: 'string', name: 'Listing SKU', keyName: 'listingSku' },
    { type: 'string', name: 'Channel Product ID', keyName: 'channelProductId' },
        {
      type: 'selector',
      name: 'Status',
      keyName: 'status',
      options: {hideDefaultNoneOption: true},
      properties: [
        { label: 'ACTIVE', value: ACTIVE },
        { label: 'INACTIVE', value: INACTIVE },
      ],
    },
    {
      type: 'selector',
      name: 'Store',
      keyName: 'store',
      properties: stores,
      options: {hideDefaultNoneOption: true}
    },
    { type: 'string', name: 'Brand', keyName: 'brand' },
    {
      type: 'equality',
      name: 'Sales Count',
      keyName: 'salesCount',
    },
    {
      type: 'equality',
      name: 'Profit %',
      keyName: 'profitPercentage',
    },
    {
      type: 'selector',
      name: 'Created By',
      keyName: 'createdBy',
      properties: userOptions,
      options: {hideDefaultNoneOption: true}
    },
    { type: 'date range', name: 'Created At', keyName: 'createdAt' },
  ];

  const combinedFilters = initialFilters.map((filter) => {
    const updatedFilter = filters.find((f) => f.keyName === filter.keyName);
    return updatedFilter ? updatedFilter : filter;
  });

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/store-listings/list"
        showChannelOptions
        swrOptions={{
          revalidateOnFocus: false,
          focusThrottleInterval: 72000000,
        }}
        supportsDownload
        select
        columns={columns(changePriceFunc, tableRowsState, classes)}
        filters={combinedFilters}
        MiniReportComponent={MiniReports}
      />
    </Grid>
  );
};

export default ListingsSearch;
