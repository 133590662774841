import type { RoleNames, RolePermissions } from 'types/roleTypes';

export const ADMIN: RoleNames = 'ADMIN';
export const STORE_MANAGER: RoleNames = 'STORE MANAGER';
export const CUSTOMERS: RoleNames = 'CUSTOMERS';
export const PRODUCTS: RoleNames = 'PRODUCTS';
export const ORDERS: RoleNames = 'ORDERS';
export const DASHBOARD_REPORTS: RoleNames = 'DASHBOARD_REPORTS';
export const DASHBOARD_OPERATIONS: RoleNames = 'DASHBOARD_OPERATIONS';
export const USER_SEARCH: RoleNames = 'USER_SEARCH';
export const COMPANY_SEARCH: RoleNames = 'COMPANY_SEARCH';
export const COMPANY_STORES: RoleNames = 'COMPANY_STORES';
export const COMPANY_CHANNELS: RoleNames = 'COMPANY_CHANNELS';
export const COMPANY_SUPPLIERS: RoleNames = 'COMPANY_SUPPLIERS';
export const ORDER_SEARCH: RoleNames = 'ORDER_SEARCH';
export const INVENTORY_SEARCH: RoleNames = 'INVENTORY_SEARCH';
export const INVENTORY_MANAGE: RoleNames = 'INVENTORY_MANAGE';
export const INVENTORY_RESTOCK: RoleNames = 'INVENTORY_RESTOCK';
export const INVENTORY_FEEDS: RoleNames = 'INVENTORY_FEEDS';
export const INVENTORY_STATISTICS: RoleNames = 'INVENTORY_STATISTICS';
export const PRODUCT_SEARCH: RoleNames = 'PRODUCT_SEARCH';
export const PRODUCT_LISTINGS: RoleNames = 'PRODUCT_LISTINGS';
export const PRODUCT_CATEGORIES: RoleNames = 'PRODUCT_CATEGORIES';
export const PRODUCT_BRANDS: RoleNames = 'PRODUCT_BRANDS';
export const PRODUCT_SUPPLIERS: RoleNames = 'PRODUCT_SUPPLIERS';
export const ORDER_ISSUES: RoleNames = 'ORDER_ISSUES';
export const ORDER_INTERNAL: RoleNames = 'ORDER_INTERNAL';
export const PRODUCT_CREATE: RoleNames = 'PRODUCT_CREATE';
export const LISTING_SEARCH: RoleNames = 'LISTING_SEARCH';

export const READ: RolePermissions = 'READ';
export const WRITE: RolePermissions = 'WRITE';
export const UPDATE: RolePermissions = 'UPDATE';
export const DELETE: RolePermissions = 'DELETE';

export const ROLES: Record<RoleNames, number> = {
  [ADMIN]: 1,
  [STORE_MANAGER]: 2,
  [CUSTOMERS]: 3,
  [PRODUCTS]: 4,
  [ORDERS]: 5,
  [DASHBOARD_REPORTS]: 6,
  [DASHBOARD_OPERATIONS]: 7,
  [USER_SEARCH]: 8,
  [COMPANY_SEARCH]: 9,
  [COMPANY_STORES]: 10,
  [COMPANY_CHANNELS]: 11,
  [COMPANY_SUPPLIERS]: 12,
  [ORDER_SEARCH]: 13,
  [INVENTORY_SEARCH]: 14,
  [INVENTORY_MANAGE]: 15,
  [INVENTORY_RESTOCK]: 16,
  [INVENTORY_STATISTICS]: 17,
  [PRODUCT_SEARCH]: 18,
  [PRODUCT_LISTINGS]: 19,
  [PRODUCT_CATEGORIES]: 20,
  [PRODUCT_BRANDS]: 21,
  [PRODUCT_SUPPLIERS]: 22,
  [ORDER_ISSUES]: 23,
  [ORDER_INTERNAL]: 24,
  [PRODUCT_CREATE]: 25,
  [LISTING_SEARCH]: 26,
  [INVENTORY_FEEDS]: 27,
};

export const ROLE_IDS: Record<string, number> = {
  [ADMIN]: 1,
  [STORE_MANAGER]: 2,
  [CUSTOMERS]: 3,
  [PRODUCTS]: 4,
  [ORDERS]: 5,
  [DASHBOARD_REPORTS]: 6,
  [DASHBOARD_OPERATIONS]: 7,
  [USER_SEARCH]: 8,
  [COMPANY_SEARCH]: 9,
  [COMPANY_STORES]: 10,
  [COMPANY_CHANNELS]: 11,
  [COMPANY_SUPPLIERS]: 12,
  [ORDER_SEARCH]: 13,
  [INVENTORY_SEARCH]: 14,
  [INVENTORY_MANAGE]: 15,
  [INVENTORY_RESTOCK]: 16,
  [INVENTORY_STATISTICS]: 17,
  [PRODUCT_SEARCH]: 18,
  [PRODUCT_LISTINGS]: 19,
  [PRODUCT_CATEGORIES]: 20,
  [PRODUCT_BRANDS]: 21,
  [PRODUCT_SUPPLIERS]: 22,
  [ORDER_ISSUES]: 23,
  [ORDER_INTERNAL]: 24,
  [PRODUCT_CREATE]: 25,
  [LISTING_SEARCH]: 26,
  [INVENTORY_FEEDS]: 27,
  'USERS': 0,
  'DASHBOARD': 0,
  'OPERATIONS': 0,
  'AI': 0,
  'ORDERSA': 0,
  'INTERNAL': 0,
  'USERSA': 0,
  'USERSB': 0,
  'USERSC': 0,
  'USERSD': 0,
};

export const ROLE_NAMES: Record<number, RoleNames> = {
  [ROLES[ADMIN]]: ADMIN,
  [ROLES[STORE_MANAGER]]: STORE_MANAGER,
  [ROLES[CUSTOMERS]]: CUSTOMERS,
  [ROLES[PRODUCTS]]: PRODUCTS,
  [ROLES[ORDERS]]: ORDERS,
  [ROLES[DASHBOARD_REPORTS]]: DASHBOARD_REPORTS,
  [ROLES[DASHBOARD_OPERATIONS]]: DASHBOARD_OPERATIONS,
  [ROLES[USER_SEARCH]]: USER_SEARCH,
  [ROLES[COMPANY_SEARCH]]: COMPANY_SEARCH,
  [ROLES[COMPANY_STORES]]: COMPANY_STORES,
  [ROLES[COMPANY_CHANNELS]]: COMPANY_CHANNELS,
  [ROLES[COMPANY_SUPPLIERS]]: COMPANY_SUPPLIERS,
  [ROLES[ORDER_SEARCH]]: ORDER_SEARCH,
  [ROLES[INVENTORY_SEARCH]]: INVENTORY_SEARCH,
  [ROLES[INVENTORY_MANAGE]]: INVENTORY_MANAGE,
  [ROLES[INVENTORY_RESTOCK]]: INVENTORY_RESTOCK,
  [ROLES[INVENTORY_STATISTICS]]: INVENTORY_STATISTICS,
  [ROLES[INVENTORY_FEEDS]]: INVENTORY_FEEDS,
  [ROLES[PRODUCT_SEARCH]]: PRODUCT_SEARCH,
  [ROLES[PRODUCT_LISTINGS]]: PRODUCT_LISTINGS,
  [ROLES[PRODUCT_CATEGORIES]]: PRODUCT_CATEGORIES,
  [ROLES[PRODUCT_BRANDS]]: PRODUCT_BRANDS,
  [ROLES[PRODUCT_SUPPLIERS]]: PRODUCT_SUPPLIERS,
  [ROLES[ORDER_ISSUES]]: ORDER_ISSUES,
  [ROLES[ORDER_INTERNAL]]: ORDER_INTERNAL,
  [ROLES[PRODUCT_CREATE]]: PRODUCT_CREATE,
  [ROLES[LISTING_SEARCH]]: LISTING_SEARCH,
};

export const rolePermissions: Record<RolePermissions, RolePermissions> = {
  READ,
  WRITE,
  UPDATE,
  DELETE,
};
