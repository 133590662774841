import BaseApi from '../BaseApi';

interface SearchResponse {
  rows: any[];
  count: number;
}

class ShippingPackageApi extends BaseApi {
  constructor() {
    super();
  }

  post(payload) {
    return this.postBase(`/api/v1/shipping-packages/create`, payload);
  }

  get() {
    return this.getBase(`/api/v1/shipping-packages/readAll`);
  }

  updatePackageId(payload) {
    return this.postBase(`/api/v1/shipping-packages/updatePackageId`, payload);
  }
}

export default ShippingPackageApi;
