import { FormEvent, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import recoilSearch from '../../../hooks/useSearchOptions';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import qs from 'query-string';
import { omit } from 'lodash';
import ListingModal from '@src/Components/Products/ProductPage/VariationsTable/ListingModal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import StoreSelect from '@src/Components/common/Selects/StoreSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, FormControlLabel } from '@mui/material';
import type { Props } from './types';
import type { ExportOption } from '../../../types';
import { channelEnums } from '../../../types';
import ToggleButtonList from '@src/Components/common/buttons/ToggleButtonList';
import Checkbox from '@mui/material/Checkbox';
import useMeStores from '@src/hooks/swr/useMeStores';

function ExportButton({
  exportOptions: exportOptionsProps,
  selectedRows,
  deselectAllRows,
  selectRow,
}: Props) {
  const { data: stores } = useMeStores();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ExportOption>(
    exportOptionsProps[0],
  );
  const [channels, setChannels] = useState([]);
  const [storeChannels, setStoreChannels] = useState([]);
  const [storeId, setStoreId] = useState<number>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [exportOptionsState] = useState<ExportOption[]>(exportOptionsProps);
  const [moreData, setMoreData] = useState({});
  // const [listing, setListing] = useState<{ price: number }>();
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [createdListings, setCreatedListings] = useState([]);
  let onlyNewListings = false;
  const searchUrl = recoilSearch.useUrl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const updateExportType = (option: ExportOption) => {
    setChannels([]);
    setStoreChannels([]);
    setStoreId(null);
    setSelectedOption(option);
  };

  const toggleOpen = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const close = () => {
    setLoading(false);
    setOpen(false);
    // setChannels([]);
    // setStoreId(null);
    setSelectedOption(exportOptionsProps[0]);
    // setStoreChannels([]);
  };

  const setUserStoreChannels = async (storeId) => {
    try {
      const queryParams = qs.parse(searchUrl.split('?')[1]);
      const query = {
        ...omit(queryParams, ['storeId']),
        storeId,
      };
      const { userStoreChannels } = await Api.get(
        `${process.env.PUBLIC_URL}/api/v1/me/filter-store-channels`,
        query,
      );

      let exportChannelsArray: string[] = userStoreChannels.map(
        (channel) => channelEnums[channel.name],
      );

      setStoreChannels(Array.from(new Set<string>(exportChannelsArray)));
      setChannels([exportChannelsArray[0]]);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (selectedRows?.length > 0) {
      setProductData(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    storeId && setUserStoreChannels(storeId);
  }, [storeId]);

  useEffect(() => {
    if (stores?.length > 0) {
      setStoreId(stores[0].id);
      setUserStoreChannels(stores[0].id);
    }
  }, [stores]);

  function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem('listings', JSON.stringify([]));

    const prepSnackBarKey = enqueueSnackbar(
      downloadCsv
        ? `Prepping files for download...`
        : `Initialized ${capitalizeFirstLetter(channels[0])} Listing Creation `,
      {
        variant: 'info',
        autoHideDuration: null,
      },
    );
    try {
      const body = {
        productIds: !selectedRows
          ? [selectedOption?.query?.productId]
          : selectedRows.map((pRow) => pRow.id),
        storeId,
        channels,
        downloadCsv,
      };
      const {
        exportUrls,
        newListings,
      }: { exportUrls: string[]; newListings: any[] } = await Api.post(
        selectedOption.url,
        body,
      );
      setCreatedListings(newListings);
      onlyNewListings = true;
      localStorage.setItem('listings', JSON.stringify(newListings));
      setMoreData(body);
      enqueueSnackbar(
        `${newListings.length} Listing${newListings.length === 1 ? '' : 's'
        } Created!`,
        { variant: 'success' },
      );

      /* exportUrls.forEach((url) => {
        window.open(url, '_blank');
      }); */
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      if (selectedRows) deselectAllRows();
      setLoading(false);
      setOpen(false);
      closeSnackbar(prepSnackBarKey);
      setModalOpen(true);
    }
  };

  return (
    <Grid item>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={toggleOpen}
        style={{ marginRight: 10, whiteSpace: 'nowrap' }}
        disabled={selectedRows?.length < 1}
      >
        {loading ? (
          <CircularProgress size={24} style={{ color: '#ffffff' }} />
        ) : selectedRows?.length > 0 ? (
          `Export ${selectedRows?.length}`
        ) : (
          'Export'
        )}
      </Button>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { overflowY: 'visible' } }}
      >
        <DialogTitle id="alert-dialog-title">Product Export</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent style={{ overflowY: 'visible', paddingTop: '0px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AppSelect
                  value={
                    exportOptionsState?.find(
                      (option) => option.value === selectedOption?.value,
                    ) || {}
                  }
                  required
                  label="Export Type"
                  options={exportOptionsState}
                  onChange={(option) => updateExportType(option)}
                />
              </Grid>
              <Grid item xs={12}>
                <StoreSelect
                  value={storeId}
                  onChange={(option) => setStoreId(option.value)}
                  required
                  selectDefault
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonList
                  values={storeChannels?.length > 0 ? storeChannels : []}
                  updateSelectedValues={setChannels}
                />
              </Grid>
              {storeChannels?.length > 0 && (
                <Grid xs={12} item>
                  <FormControlLabel
                    label={'Download CSV?'}
                    labelPlacement="start"
                    control={
                      <Checkbox
                        checked={downloadCsv}
                        color="secondary"
                        onChange={(e) => {
                          setDownloadCsv(e.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={close}
              color="primary"
              style={{ backgroundColor: 'red', color: 'white' }}
            >
              Cancel
            </Button>
            {storeChannels.length > 0 && (
              <>
                <Button disabled={loading} variant="contained" type="submit" color="secondary">
                  {loading && <CircularProgress color='info' />}
                  Create Listing
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {modalOpen && <ListingModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onClose={closeModal}
        selectedRows={selectedRows}
        channels={moreData}
        productData={productData}
        setProductData={setProductData}
        setLoading={setLoading}
        onlyNewListings={onlyNewListings}
        createdListings={createdListings}
        setCreatedListings={setCreatedListings}
      />}
    </Grid>
  );
}

export default ExportButton;
