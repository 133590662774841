import { Fragment, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Typography,
  Divider,
  Popper,
  Card,
  CardContent,
} from '@mui/material';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import { columns } from './constants';
import React from 'react';
import FontAwesome from '@src/Components/common/FontAwesome';
import { primary } from '@src/Components/Theme/themes/constants';


const useStyles = makeStyles(() =>
  ({
    card:{
      backgroundImage: `linear-gradient(147deg, ${primary.main} 0%, #7970dc 74%)`
    },
    icon: {
      cursor: 'pointer',
    },
    heading: {
      paddingRight: 50, 
      paddingLeft: 50, 
      paddingTop: 15,
      paddingBottom: 15,
    }
  }),
);

export default function NotificationDrawer() {
  const classes = useStyles();
  const [item, setItem] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div>
      <div
        style={{
          color: 'rgb(94, 53, 177)',
          background: 'rgb(237, 231, 246',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          width: '34px',
          height: '34px',
          fontSize: '1.2rem',
          transition: 'all 0.2s ease-in-out 0s',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <NotificationsNoneTwoToneIcon />
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        <Card>
          <CardContent>
            <Grid alignItems="center" justifyContent="center" container>
              <Card style={{ marginBottom: 10 }} className={classes.card}>
                <Typography
                  className={classes.heading}
                  variant="h6"
                  color="primary"
                >
                  <span style={{ color: 'white' }}>Coming Soon!</span>
                </Typography>
              </Card>
            </Grid>
            {/* <Grid item xs={12}>
              <Divider style={{marginBottom: 15}} />
            </Grid>
            {columns.map((name, nameIndex) => {
              const retComp = name.customComponent ? (<name.customComponent itemIndex={item} />) : <></>
                return(
                  <Fragment key={`notif-drawer-custom-comp-${nameIndex}`}>
                    {retComp}
                  </Fragment>
                )
            })}
            <Grid item xs={12}>
              <Divider style={{marginBottom: 10}} />
            </Grid>
            <Grid container>
              <Grid item container xs={12} justify="center">
                <Button variant="outlined" color="primary" style={{marginTop: 10, borderRadius: 50}}>
                  View All
                  <FontAwesome
                  name='arrow-right'
                  type='fa'
                  form='fas'
                  size='small'
                  />
                </Button>
              </Grid>
            </Grid> */}
          </CardContent>
        </Card>
      </Popper>
    </div>
  );
}
