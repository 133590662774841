export const formFields: any[] = [
  {
    label: 'Supplier Name',
    key: 'name',
    type: 'text',
    required: true,
  },
  {
    label: 'Internal Supplier',
    key: 'internalSupplier',
    type: 'boolean',
    create: true,
  },
  {
    label: 'Self Managed Stock',
    key: 'internalStock',
    type: 'boolean',
    create: true,
  },
  {
    label: 'Used Supplier',
    key: 'usedSupplier',
    type: 'boolean',
    create: true,
  },
  {
    label: 'Address Line 1',
    key: 'addressLineOne',
    type: 'string',
    create: true,
  },
  {
    label: 'Address Line 2',
    key: 'addressLine2',
    type: 'string',
    create: true,
  },
  {
    label: 'City',
    key: 'city',
    type: 'string',
  },
  {
    label: 'State',
    key: 'state',
    type: 'string',
  },
  {
    label: 'Country',
    key: 'coutry',
    type: 'string',
  },
  {
    label: 'Zip',
    key: 'zipCpde',
    type: 'string',
  },
];
