import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Modal, Button } from '@mui/material';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function DropshipButton({ row }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const setOrderInbox = async () => {
  //   try {
  //     const issuePayload = {
  //       orderId: row.id,
  //       issueType: 'OTHER',
  //       previousOrderStatus: row.orderStatus,
  //     };
  //     const orderUpdatePayload = { orderStatus: 'ISSUE' };
  //     await OrderIssueApi.post(issuePayload);
  //     await OrdersApi.update(row.id, orderUpdatePayload);
  //     enqueueSnackbar('Marked out of Stock!', {
  //       variant: 'success',
  //     });
  //   } catch (e) {
  //     enqueueSnackbar('Error Marking Out of Stock', {
  //       variant: 'error',
  //     });
  //   } finally {
  //     {
  //       handleClose();
  //     }
  //   }
  // };

  return (
    <>
      <Grid item container style={{ justifyContent: 'flex-start' }}>
        <Tooltip title={'Out of Stock (Disabled)'}>
          <span>
            <IconButton
              style={{ fontSize: '14px' }}
              disabled
              onClick={handleOpen}
            >
              <WarningTwoToneIcon
                style={{ color: '#f8a14f' }}
                fontSize="medium"
              />
              {/* <FontAwesome
                name="exclamation-triangle"
                type="fas"
                form="fa"
                onClick={handleOpen}
              /> */}
            </IconButton>
          </span>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              padding: 10,
            }}
          >
            <Grid item container xs={12} style={{ justifyContent: 'center' }}>
              <h1>Mark Out of Stock?</h1>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'flex-end' }}
            >
              <Button
                variant="contained"
                color="primary"
                // onClick={() => {
                //   setOrderInbox();
                // }}
              >
                Move
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default DropshipButton;
