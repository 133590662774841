import { useHistory } from 'react-router-dom';

import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import { Tooltip, IconButton, Grid } from '@mui/material';

function ListingButton({ row }: { row: any }) {
  const history = useHistory();

  const handleClick = () => {
    localStorage.setItem('selectedVarMpn', row?.manufacturerNo);
    history.push('/listings/list');
  };

  return (
    <Grid item style={{ justifyContent: 'center' }}>
      <Tooltip title={'View Item Listings'}>
        <span>
          <IconButton onClick={handleClick}>
            <MonetizationOnTwoToneIcon color="secondary" />
          </IconButton>
        </span>
      </Tooltip>
    </Grid>
  );
}

export default ListingButton;
