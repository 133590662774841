import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';

function CommissionReport() {
  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/store-listings/commission-report"
        columns={columns}
        filters={[
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
        ]}
      />
    </Grid>
  );
}

export default CommissionReport;
