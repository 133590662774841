import { useMemo } from 'react';
import useChannels from '@src/hooks/swr/useChannels';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function ChannelSelect(props: Props) {
  const { data: channels } = useChannels();

  const options = useMemo(() => {
    if (!channels) return [];
    return channels.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }));
  }, [channels]);

  return <AppSelect options={options} label="Channel" {...props} />;
}

export default ChannelSelect;
