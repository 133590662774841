import {
  channelIds,
  AMAZON,
  EBAY,
  WALMART,
  WOO_COMMERCE,
} from '@oneAppCore/constants/channels';
import _ from 'lodash';

export const channelOptions = [
  {
    label: _.startCase(AMAZON.toLowerCase()),
    value: channelIds[AMAZON],
  },
  {
    label: _.startCase(EBAY.toLowerCase()),
    value: channelIds[EBAY],
  },
  {
    label: _.startCase(WALMART.toLowerCase()),
    value: channelIds[WALMART],
  },
  {
    label: _.startCase(WOO_COMMERCE.toLowerCase()),
    value: channelIds[WOO_COMMERCE],
  },
];
