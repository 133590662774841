import UnitsSoldChart from '../../UnitsSoldChart';
import GrossRevenueByDay from '../../GrossRevenueByDayChart';
import { Grid } from '@mui/material';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import { options } from './constants';
import { Props } from '../../types';
import SalesChartOptions from '../../SalesChartHooks/SalesChartOptions';
function SalesChart({ unitsBack, unitType }: Props) {
  const [
    reportOptions,
    setChartOptions,
  ] = SalesChartOptions.useChartOptionsState();

  const updateChart = (input: string) => {
    setChartOptions((previous) => ({
      ...previous,
      chart: input,
    }));
  };

  return (
    <>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AppSelect
            label="Chart Select"
            options={options}
            value={options.find(
              (option) => option.value === reportOptions.chart,
            )}
            onChange={(option) => {
              updateChart(option?.value || 'units-sold');
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SalesChart;
