import { useState, useMemo, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useCompanies from '@src/hooks/swr/useCompanies';
import { generatePassword } from '@oneAppCore/utils/authentication';

import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Paper,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Select,
  FontAwesome,
  Backdrop,
} from '@src/Components/common';

import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import type { User } from '@oneAppCore/types/userTypes';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    padding: theme.spacing(2),
  },
  botmarg: {
    marginBottom: 10,
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

function CreateUser() {
  const classes = useStyles();
  const history = useHistory();

  const [form, setForm] = useState<User>({});
  const [showPassword, setShowPassword] = useState(false);
  const [saving, setSaving] = useState(false);

  const { data: companies = [] } = useCompanies();
  const { enqueueSnackbar } = useSnackbar();

  const update = <K extends keyof User>(key: K, value: User[K]) => {
    setForm({
      ...form,
      [key]: value,
    });
  };
  const onSubmit = (id: number | string) => {
    setForm({});
    history.push(`/users/edit/${id}`);
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      const { id } = await Api.post(`/api/v1/users`, form);
      enqueueSnackbar(`Registered account`, {
        variant: 'success',
      });
      const companyName = companyOptions.find(
        (option) => option.value === form.companyId,
      );
      form['companyName'] = companyName.label;
      await Api.post(`/api/v1/mailer/send`, form);
      await onSubmit(id);
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const companyOptions = useMemo(() => {
    const companyList = companies.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    if (companyList.length === 1) {
      update('companyId', companyList[0].value);
    }
    return companyList;
  }, [companies]);

  return (
    <Paper elevation={1} className={classes.root}>
      <form className={classes.form} onSubmit={submit}>
        <Grid container spacing={2} direction="column">
          <Grid item container xs={12} justifyContent="flex-start">
            <>
              {companyOptions.length > 1 && (
                <Grid item xs={6} style={{ padding: 10 }}>
                  <Select
                    options={companyOptions}
                    label="Company"
                    value={companyOptions.find(
                      (option) => option.value === form.companyId,
                    )}
                    onChange={(e) => update('companyId', e.value)}
                    required
                  />
                </Grid>
              )}
              {companyOptions.length === 1 && (
                <Grid item xs={6} style={{ padding: 10 }}>
                  <TextField
                    fullWidth
                    disabled
                    variant="outlined"
                    label="Company"
                    value={companyOptions[0].label}
                  />
                </Grid>
              )}
            </>
          </Grid>
          <Grid
            container
            xs={12}
            justifyContent={'space-around'}
            style={{ padding: '24px' }}
          >
            <Grid item xs={6} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="First Name"
                value={form?.firstName || ''}
                onChange={(e) => update('firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Last Name"
                value={form?.lastName || ''}
                onChange={(e) => update('lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                value={form?.email || ''}
                onChange={(e) => update('email', e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={6} style={{ padding: 10 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Username"
                type="username"
                value={form?.username || ''}
                onChange={(e) => update('username', e.target.value)}
                required
              />
            </Grid> */}
            <Grid
              item
              container
              xs={6}
              style={{ padding: 10, alignItems: 'center' }}
            >
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={form?.password || ''}
                  onChange={(e) => update('password', e.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: 'pointer' }}
                      >
                        {showPassword ? (
                          <FontAwesome name="eye-slash" type="fa" form="fa" />
                        ) : (
                          <FontAwesome name="eye" type="fa" form="fa" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} justifyContent="center">
                <IconButton
                  onClick={() => update('password', generatePassword())}
                  color="primary"
                  style={{ marginTop: 15 }}
                  size="small"
                >
                  <FontAwesome name="random" type="fa" form="fas" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item xs={1} style={{ padding: 10 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#B61F1F', color: 'white' }}
                fullWidth
                onClick={() => history.push('/users/list')}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3} md={1} style={{ padding: 10 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default CreateUser;
