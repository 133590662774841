import { atom } from 'recoil';

import type { ReportOptionsAtom } from './types';

export const reportOptionsAtom = atom<ReportOptionsAtom>({
  key: 'report-options',
  default: {
    selectedReport: null,
    page: 1,
    limit: 50,
    csv: null,
    newerThan: null,
    channelId: null,
  },
});
