import { useState } from 'react';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { useSnackbar } from 'notistack';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';

import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FontAwesome } from '@src/Components/common';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    minWidth: 120,
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

const defaultAlias = [
  'ebayAlias',
  'amazonAlias',
  'wooCommerceAlias',
  'walmartAlias',
];

function MakeDrawer(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSearch();
  const { close } = props;
  const classes = useStyles();
  const [form, setForm] = useState({
    make: '',
    type: '',
    alias: [],
  });
  const [aliasOptions, setAliasOptions] = useState(defaultAlias);

  const addAlias = () => {
    setForm({ ...form, alias: [...form.alias, { aliasType: '', aliasValue: '' }] });
  }

  const removeAlias = (index) => {
    const updatedAlias = form.alias.filter((_, i) => i !== index);
    setForm({ ...form, alias: updatedAlias });
  }

  const update = (type, value, index = -1) => {
    if (index != -1) {
      const updatedAlias = form.alias.map((al, i) => {
        if (i == index) {
          return {
            ...al,
            [type]: value
          }
        } else {
          return al;
        }
      });
      setForm({ ...form, alias: updatedAlias });
      return;
    }
    setForm({ ...form, [type]: value });
  }

  const checkAliasDisable = (option) => {
    let disable = false;
    for (let index = 0; index < form.alias.length; index++) {
      const element = form.alias[index];
      if (element.aliasType == option) {
        disable = true;
        break;
      }
    }
    return disable;
  }

  const handleSubmit = async () => {
    let formData = {
      make: form.make,
      type: form.type,
    }
    for (let index = 0; index < form.alias.length; index++) {
      const element = form.alias[index];
      if(element.aliasType && element.aliasValue) {
        formData = {...formData, [element.aliasType]: element.aliasValue}
      }
    }

    try {
      const response = await Api.post(`/api/v1/fitment/create-make`, {form: formData});
      if (response.status == 'success') {
        enqueueSnackbar(`Created Make`, {
          variant: 'success',
        });
      }
      mutate();
      close();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    }
  }

  return (
    <>
      <Grid container spacing={2} direction="column" className={classes.form}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">New Make</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span" className={classes.label}>Make*</Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="make"
            value={form?.make}
            onChange={(e) => update('make', e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span" className={classes.label}>Type</Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="type"
            value={form?.type}
            onChange={(e) => update('type', e.target.value)}
          />
        </Grid>
        {form.alias.length > 0 && form.alias.map((al, aIndex) => <Grid key={aIndex} container item spacing={1}>
          <Grid item xs={5}>
            <Typography variant="caption" component="span" className={classes.label}>Alias Type</Typography>
            <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.alias[aIndex].aliasType}
                onChange={(e) => update('aliasType', e.target.value, aIndex)}
              >
                {aliasOptions.map((option, oIndex) => (
                  <MenuItem disabled={checkAliasDisable(option)} key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" component="span" className={classes.formControl}>Value</Typography>
            <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                name="aliasValue"
                disabled={!form.alias[aIndex].aliasType}
                value={form.alias[aIndex].aliasValue}
                onChange={(e) => update('aliasValue', e.target.value, aIndex)}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={1}>
            <IconButton
              color="primary"
              onClick={() => {
                removeAlias(aIndex);
              }}
              style={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <FontAwesome
                size="xs"
                name="times"
                type="fa"
                form="fas"
              />
            </IconButton>
          </Grid>
        </Grid>)}
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              addAlias();
            }}
            style={{ margin: 5 }}
          >
            Add Alias
          </Button>
        </Grid>
        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={close}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={form.make ? false : true}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MakeDrawer;