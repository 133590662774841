import { useEffect } from 'react';
import recoilSearch from '../../../hooks/useSearchOptions';
import useSearch from '../../../hooks/useSearch';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

function DownloadButton({
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
}) {
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const { data } = useSearch(
    swrOptions.revalidateOnFocus,
    false,
    swrOptions.focusThrottleInterval,
  );

  const download = () => {
    setSearchOptions((previous) => ({
      ...previous,
      csv: true,
    }));
  };

  // Get the url, download it, then set the csv to false to refresh the search and remove the csv url.
  useEffect(() => {
    if (data?.csvUrl) {
      window.open(data.csvUrl, '_blank');
      setSearchOptions((previous) => ({
        ...previous,
        csv: false,
      }));
    }
  }, [data]);

  return (
    <Grid item>
      <Button
        variant="outlined"
        color="primary"
        onClick={download}
        disabled={searchOptions.csv}
        style={{ whiteSpace: 'nowrap' }}
      >
        Download CSV
      </Button>
    </Grid>
  );
}

export default DownloadButton;
