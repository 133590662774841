import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { SwipeableDrawer } from '@mui/material';
import type { DrawerProps } from './types';
import CategoryViewDrawer from '@src/Components/Products/Categories/CategoryViewDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: 425,
    padding: theme.spacing(2),
  },
}));

export default function NewCategoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  submit,
  customSubmit,
}: DrawerProps) {
  const classes = useStyles();
  const { mutate } = useSearch();

  const onSubmit = async (props) => {
    await mutate();
    setDrawerOpen();
    await customSubmit(props)
  };

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <CategoryViewDrawer close={setDrawerOpen} onSubmit={onSubmit} />
      </SwipeableDrawer>
    </>
  );
}
