import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  button: {
    marginTop: 10,
  },
  card: {
    marginLeft: 20,
    minWidth: '100%',
  },
});

function NoteCard() {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Card className={classes.card}>
        <CardContent>
          <Grid container xs={12} justifyContent="center">
            <Typography style={{ marginBottom: 10 }}>
              <strong>Order Notes</strong>
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <TextField
              disabled
              style={{ marginBottom: 10, width: '100%' }}
              multiline
              variant="outlined"
            />
            <Button disabled variant="contained" color="primary" size="small">
              Save Notes
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default NoteCard;
