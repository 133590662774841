import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

type Params = {
  id: number;
  searchFilter?: string;
  filters?: any;
  sku?: string;
  onlyNewListings?: boolean;
};

export default function useVariationData({
  id,
  searchFilter,
  filters,
  sku,
  onlyNewListings = false,
}: Params) {
  let andCount = 0;
  if (sku) {
    andCount++;
  }
  if (onlyNewListings) {
    andCount++;
  }
  if (searchFilter) {
    andCount++;
  }
  const skuSearch = sku ? `sku=${sku}` : '';
  const newListSearch = onlyNewListings ? `onlyNewListings=${onlyNewListings}` : '';
  const searchSearch = searchFilter ? `searchFilter=${searchFilter}` : '';
  const andString = andCount > 1 ? '&' : '';
  return useSWR(
    id
      ? `/api/v1/products/${id}/variations${andString ? '?' : ''}${andString}${searchSearch}${andString}${skuSearch}${andString}${newListSearch}`
      : null,
    Api.get,
  );
}

export function useVarData({ id }: Params) {
  return useSWR(id ? `/api/v1/products/${id}/variations` : null, Api.get, { revalidateOnFocus: false });
}
