import { useState, useEffect } from 'react';
import {
  Grid,
  Modal,
  IconButton,
  Card,
  CardContent,
  Divider,
  CardHeader,
  CardActions,
  Button,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@src/Components/common';
import { enqueueSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import StoresApi from '@oneAppCore/services/apis/Stores';
// import {ChannelForm} from '../../'

const filter = createFilterOptions();

export type ChannelForm = {
  channelName?: string;
  storeName?: string;
  apiUrl?: string;
  consumerKey?: string;
  consumerSecret?: string;
  status?: string;
  apiCredentials?: string;
  sellerId?: string;
  secretKey?: string;
  spClientId?: string;
  MWSAuthToken?: string;
  refreshToken?: string;
  MarketplaceId?: string;
  AWSAccessKeyId?: string;
  spClientSecret?: string;
};

export type CreateChannelProps = {
  id?: number;
  isViewMode: boolean;
  form: ChannelForm;
  updateForm: any;
};

export default function SimpleModal({ open, onClose }) {
  const [form, setForm] = useState<ChannelForm>({});
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(null);
  const [apiCreds, setApiCreds] = useState([]);
  const channel = 'Amazon';
  const [stores, setStores] = useState([]);

  const handleInputChange = (field: keyof ChannelForm) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (field === 'storeName' || field === 'channelName') {
      setForm((prevForm) => ({
        ...prevForm,
        [field]: event.target.value,
      }));
    } else {
      setApiCreds((prevCreds) => ({
        ...prevCreds,
        [field]: event.target.value,
      }));
    }
  };

  useEffect(() => {
    const getStore = async () =>
      await Api.get(`/api/v1/stores/search`).then((res) => {
        const { rows } = res;
        setStores(rows);
      });
    getStore();
  }, []);

  const onCredentialChange = (value, key, index) => {
    let tempArray = apiCreds;
    tempArray[index][key] = value;
    setApiCreds((data) => {
      return data.map((x) => (x[key] === key ? (x[key] = value) : x));
    });
  };

  const createChannel = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true);
    if (value === null || channel === null) {
      enqueueSnackbar('Please fill out all information!', { variant: 'error' });
    } else {
      const channelId = channel;
      const payload = {
        apiCredentials: apiCreds,
        storeId: value.id,
        channelId: 1,
      };
      try {
        await Api.post(`/api/v1/store-channels`, payload);
        enqueueSnackbar('Channel created!', { variant: 'success' });
                onClose();

      } catch (e) {
        enqueueSnackbar(`${e.message || e}`, { variant: 'error' });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container justifyContent="center">
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: 280, lg: 600 },
            p: 2,
          }}
        >
          <CardHeader
            title="Amazon Credentials"
            action={
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          />

          <Divider />

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {' '}
                <Autocomplete
                  value={value}
                  options={stores}
                  getOptionLabel={(option) =>
                    option.inputValue ? option.inputValue : option.name
                  }
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="large"
                      {...params}
                      label="Store"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Seller Id"
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange('sellerId')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Secret Key"
                  variant="outlined"
                  fullWidth
                  // value={form.secretKey || ''}
                  onChange={handleInputChange('secretKey')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Client Id"
                  variant="outlined"
                  fullWidth
                  // value={form.spClientId || ''}
                  onChange={handleInputChange('spClientId')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="MWS Auth Token"
                  variant="outlined"
                  fullWidth
                  // value={form.MWSAuthToken || ''}
                  onChange={handleInputChange('MWSAuthToken')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Refresh Token"
                  variant="outlined"
                  fullWidth
                  // value={form.refreshToken || ''}
                  onChange={handleInputChange('refreshToken')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Marketplace Id"
                  variant="outlined"
                  fullWidth
                  // value={form.MarketplaceId || ''}
                  onChange={handleInputChange('MarketplaceId')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="AWS Access Key Id"
                  variant="outlined"
                  fullWidth
                  // value={form.AWSAccessKeyId || ''}
                  onChange={handleInputChange('AWSAccessKeyId')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Client Secret"
                  variant="outlined"
                  fullWidth
                  // value={form.spClientSecret || ''}
                  onChange={handleInputChange('spClientSecret')}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />

          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => createChannel(e)}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Modal>
  );
}
