import { Typography, Grid, Stack, Chip, CircularProgress } from '@mui/material';
import { CustomComponentValues } from './types';
import { FontAwesome } from '@src/Components/common';
import dayjs from 'dayjs';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';

export const columns = [
  {
    title: 'Name',
    keyName: 'name',
    customComponent: function name({ form, variations }) {
      console.log(form, 'product form');
      const createdAtDate = new Date(form.createdAt);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - createdAtDate.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      const isRecent = daysDifference <= 15;

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '1.25rem',
                        fontWeight: 600,
                        lineHeight: 1.167,
                        color: '#000',
                      }}
                    >
                      {form.name}
                    </Typography>
                    {isRecent && ( // Conditionally render the Chip
                      <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        label="New"
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Brand',
    keyName: 'brandName',
    customComponent: function brand({ form, variations }) {
      const itemPrices =
        variations?.map((variation) => variation.itemPrice) || [];
      let price = '';
      if (itemPrices.length) {
        const minPrice = Math.min(...itemPrices);
        const maxPrice = Math.max(...itemPrices);
        if (itemPrices.length > 1) {
          price = minPrice !== maxPrice ? `$${formatCentsToDollars(minPrice)} – ${formatCentsToDollars(maxPrice)}` : `${formatCentsToDollars(minPrice)}`;
        } else {
          price = `${formatCentsToDollars(minPrice)}`;
        }
      }
      return (
        <>
          {form.brandName ? (
            <Grid item xs={12}>
              <Typography variant="body2">{form.brandName}</Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {price
              ? <Typography color="primary" variant="h5" fontWeight="bold">
                {price}
              </Typography>
              : <CircularProgress color='primary' />
            }
          </Grid>
        </>
      );
    },
  },
  {
    title: 'Store',
    keyName: 'storeId',
  },
  {
    title: 'Category',
    keyName: 'category',
    customComponent: function renderCategories({ form }) {
      return (
        <>
          <Grid
            item
            container
            xs={12}
            style={{
              minHeight: 'min-content',
              border: 'lightGrey 1px solid',
              borderRadius: 10,
              padding: 10,
              marginBottom: 10,
            }}
          >
            <Grid xs={5}>
              <Typography style={{ fontSize: '20px' }}>Categories:</Typography>
            </Grid>
            <Grid item container xs={7} alignItems="flex-start">
              {form.grandparentCategory && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '20px' }}>
                    {form.grandparentCategory}{' '}
                    <FontAwesome
                      name="arrow-right"
                      type="fal"
                      form="fa"
                      size="xs"
                    />
                  </Typography>
                </Grid>
              )}
              {form.parentCategory && (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: '20px' }}>
                    {form.parentCategory}{' '}
                    <FontAwesome
                      name="arrow-right"
                      type="fal"
                      form="fa"
                      size="xs"
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item xs={form.category ? 12 : 7}>
                <Typography style={{ fontSize: '20px' }}>
                  {form.category ? form.category : 'No Category Associated'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    title: 'Keywords',
    keyName: 'keywords',
    customComponent: function renderKeywords({
      keyValue,
    }: CustomComponentValues) {
      return (
        <>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: 10, maxHeight: 'min-content' }}
          >
            <Grid xs={5}>
              <Typography style={{ fontSize: '20px' }}>Keywords:</Typography>
            </Grid>
            <Grid xs={7}>
              {keyValue?.split(',').map((value, index) => {
                return (
                  <Grid key={`product-keyword-${index + 1}`}>
                    <Typography style={{ fontSize: '20px' }}>
                      <li>{value}</li>
                    </Typography>
                  </Grid>
                );
              })}
              {!keyValue && (
                <Typography style={{ fontSize: '20px' }}>
                  No Keywords
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    customComponent: function renderDate({ keyValue }: CustomComponentValues) {
      return (
        <Grid
          item
          container
          xs={12}
          style={{ maxHeight: '45px', marginBottom: 10 }}
        >
          <Grid xs={5}>
            <Typography style={{ fontSize: '20px' }}>Created At: </Typography>
          </Grid>
          <Grid xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {dayjs(keyValue).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Created By',
    keyName: 'createdBy',
    customComponent: function renderCreatedBy({
      keyValue,
      form,
    }: CustomComponentValues) {
      return (
        <Grid
          item
          container
          xs={12}
          style={{ maxHeight: '45px', marginBottom: 10 }}
        >
          <Grid item container xs={5}>
            <Typography style={{ fontSize: '20px' }}>Created By:</Typography>
          </Grid>
          <Grid item container xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {keyValue} ({form.createdByAlias ? form.createdByAlias : null})
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Updated By',
    keyName: 'updatedBy',
    customComponent: function createdByRender({
      keyValue,
      form,
    }: CustomComponentValues) {
      return (
        <Grid item container xs={12} style={{ marginBottom: 10 }}>
          <Grid xs={5}>
            <Typography style={{ fontSize: '20px' }}>Updated By:</Typography>
          </Grid>
          <Grid xs={7}>
            <Typography style={{ fontSize: '20px' }}>
              {keyValue} ({form.updatedByAlias ? form.updatedByAlias : null})
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
];
