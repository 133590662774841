import { useState } from 'react';
import { IconButton, Tooltip, Grid } from '@mui/material';
import type { Props } from './types';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import ListingDrawer from '@src/Components/common/Drawers/ListingDrawer';

function ViewDrawerButton({ row }: Props) {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container style={{ justifyContent: 'flex-end' }}>
        <Tooltip title={`View`}>
          <span>
            <IconButton onClick={() => setOpen(true)}>
              <VisibilityTwoToneIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {open && <ListingDrawer isDrawerOpen={open} setDrawerOpen={close} row={row} />}
    </>
  );
}

export default ViewDrawerButton;
