import { atom } from 'recoil';

import { magikTimeStateDefault } from './constants';

import type { MagikTimeState } from './types';

export const magikTimeState = atom<MagikTimeState>({
  key: 'magik-time-state',
  default: magikTimeStateDefault,
});
