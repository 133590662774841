import { Box, Button, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { FontAwesome } from '@src/Components/common';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import type {
  Column,
  ColumnAlign,
} from '@src/Components/common/containers/SearchView/types';
import { primary } from '@src/Components/Theme/themes/constants';

export const columns = (data): Column[] => {
  let { defaultValues, setDefaultValues, editClicked, setEditClicked, editData, setEditData, mutate } = data;

  const filterYears = (models, modelId) => {
    const filteredYears = models.filter((e) => modelId == e.id);
    if (filteredYears.length > 0) {
      return filteredYears[0].years;
    }
    return [];
  }

  const filterModels = (models, make) => {
    return models.filter((e) => make === e.make);
  };

  const update = (pageIndex, value, type) => {
    let fitments = defaultValues.fitments.map((fitment, fIndex) => {
      if (fIndex == pageIndex) {
        if (type == 'years') {
          return {
            ...fitment,
            [type]: value,
          };
        } else if (type == 'makeId') {
          return {
            ...fitment,
            [type]: value,
            modelId: null,
            years: [],
          };
        }
        return {
          ...fitment,
          [type]: value,
          years: [],
        };
      } else {
        return fitment;
      }
    });
    setEditData([fitments[pageIndex]]);
    setDefaultValues({ ...defaultValues, fitments: fitments });
  }

  return [
    {
      title: 'Make',
      keyName: 'make',
      sortBy: false,
      align: 'center' as ColumnAlign,
      customComponent: function fitmentYear(row, index) {
        const pageIndex = index;
        const editCheck = editClicked.indexOf(pageIndex);
        return (
          <div>
            {editCheck == -1 && row.make}
            {editCheck !== -1 && <div>
              <FormControl size='small' variant="outlined" style={{ minWidth: '120px', maxWidth: '300px' }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={defaultValues.fitments[pageIndex].makeId}
                  onChange={(e) => update(pageIndex, e.target.value, 'makeId')}
                >
                  {defaultValues.make.map(({ make, id }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {make}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>}
          </div>
        );
      },
    },
    {
      title: 'Model',
      keyName: 'model',
      sortBy: false,
      align: 'center' as ColumnAlign,
      customComponent: function fitmentYear(row, index) {
        const pageIndex = index;
        const editCheck = editClicked.indexOf(pageIndex);
        return (
          <div>
            {editCheck == -1 && row.model}
            {editCheck !== -1 && <div>
              <FormControl size='small' variant="outlined" style={{ minWidth: '120px', maxWidth: '300px' }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={defaultValues.fitments[pageIndex].modelId}
                  onChange={(e) => update(pageIndex, e.target.value, 'modelId')}
                >
                  {filterModels(defaultValues.model, defaultValues.fitments[pageIndex].makeId).map(({ model, id }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {model}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>}
          </div>
        );
      },
    },
    {
      title: 'Years',
      keyName: 'year',
      sortBy: false,
      align: 'center' as ColumnAlign,
      customComponent: function FitmentYear(row, index) {
        const pageIndex = index;
        const editCheck = editClicked.indexOf(pageIndex);
        return (
          <div>
            {editCheck == -1 && row?.years?.toString()}
            {editCheck !== -1 && <div>
              <FormControl size='small' variant="outlined" style={{ minWidth: '120px', maxWidth: '300px' }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={defaultValues.fitments[pageIndex].years}
                  multiple
                  onChange={(e) => update(pageIndex, e.target.value, 'years')}
                >
                  {filterYears(defaultValues.model, defaultValues.fitments[pageIndex].modelId).map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>}
          </div>
        );
      },
    },
    {
      title: 'Edit',
      keyName: 'edit',
      sortBy: false,
      align: 'center' as ColumnAlign,
      customComponent: function editFitment(row, index) {
        const pageIndex = index;
        const handleClick = () => {
          setEditData([]);
          if (editClicked.indexOf(pageIndex) == -1) {
            setEditClicked([pageIndex]);
          } else {
            setEditClicked([]);
          }
        }

        const handleSave = async () => {
          if (editData.length == 0) {
            return;
          }
          const data = {
            id: editData[0].id,
            year: editData[0].years,
            make: editData[0].makeId,
            model: editData[0].modelId,
          }
          const fitmentData = await Api.put(`/api/v1/fitment`, data);
          if(fitmentData && fitmentData.status == 'success') {
            handleClick()
            mutate();
          }
        }

        const checkDisable = () => {
          if (editData.length == 0 || editData?.[0]?.years.length == 0 || !editData?.[0]?.makeId || !editData?.[0]?.modelId) {
            return true;
          }
          return false;
        }
        return (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            {editClicked.indexOf(pageIndex) == -1 && <IconButton disabled={defaultValues.fitments.length == 0} onClick={() => { handleClick() }}>
              <FontAwesome
                name="edit"
                type="fas"
                form="fa"
                size="xs"
                style={{ cursor: 'pointer', color: primary.main }}
              />
            </IconButton>}
            {editClicked.indexOf(pageIndex) !== -1 && <Button
              variant='outlined'
              size='small'
              color='secondary'
              style={{ margin: '5px' }}
              onClick={() => { handleClick() }}
            >
              Cancel
            </Button>}
            {editClicked.indexOf(pageIndex) !== -1 && <Button
              size='small'
              variant='contained'
              color='primary'
              style={{ margin: '5px' }}
              disabled={checkDisable()}
              onClick={() => { handleSave() }}
            >
              Save
            </Button>}
          </Box>
        )
      }
    }
  ]
};
