// import recoilAnalyticsOptions from '@src/Components/Dashboards/Analytics/AnalyticsHooks/AnalyticsOptions';
import recoilChartOptions from '@src/Components/Dashboards/Analytics/ListingHooks';

import Grid from '@mui/material/Grid';

import ListingStatusChart from './Chart';
import ListingChartSelect from './ListingChartSelect';
import { Props } from '@src/Components/Dashboards/Analytics/types';

function ListingStatusReport({ unitsBack }: Props) {
  const chartOptions = recoilChartOptions.useChartOptions();
  // const analyticsOptions = recoilAnalyticsOptions.useAnalyticsOptions();
  if (!chartOptions.chart) {
    return <></>;
  }

  return (
    <>
      <ListingChartSelect />
      <Grid container item spacing={1}>
        <ListingStatusChart
          unitsBack={unitsBack}
          chartType={chartOptions.chart}
        />
      </Grid>
    </>
  );
}

export default ListingStatusReport;
