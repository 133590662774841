import { useMemo } from 'react';
import useSelectedReport from '../hooks/useSelectedReport';
import recoilReportOptions from '../hooks/useReportOptions';

import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

export default function ReportPaginator() {
  const [
    reportOptions,
    setReportOptions,
  ] = recoilReportOptions.useReportOptionsState();
  const { data: report } = useSelectedReport();

  const updatePage = (page: number) => {
    setReportOptions((previous) => ({
      ...previous,
      page,
    }));
  };

  const count = useMemo(() => {
    if (typeof report === 'string') return 1;
    return Math.ceil(report?.count / reportOptions?.limit) || 1;
  }, [report, reportOptions]);

  if (!reportOptions.selectedReport || typeof report === 'string') {
    return <></>;
  }

  return (
    <Grid item container justifyContent="flex-end">
      <Grid item>
        <Pagination
          color="primary"
          count={count}
          page={reportOptions?.page || 1}
          onChange={(_, page) => updatePage(page)}
        />
      </Grid>
    </Grid>
  );
}
