import { columns } from './constants';
import { Grid, Button, Select } from '@mui/material';
import { useHistory } from 'react-router-dom';
import SearchView from '@src/Components/common/containers/SearchView';

function ChannelList() {
  const history = useHistory();

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid
        item
        container
        justifyContent="flex-start"
        style={{ marginBottom: 15 }}
      >
        <Grid item container justifyContent="flex-end">
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => history.push('/store-channel/create')}
          >
            Create
          </Button> */}
        </Grid>
      </Grid>
      <SearchView
        url="/api/v1/store-channels/search"
        columns={columns}
        showChannelIcons
        filters={[
          {
            type: 'string',
            name: 'Store',
            keyName: 'store',
          },
          {
            type: 'string',
            name: 'Channel',
            keyName: 'channel',
          },
        ]}
      />
    </Grid>
  );
}

export default ChannelList;
