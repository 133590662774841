import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import useUsers from '@src/hooks/swr/useUsers';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import UsersApi from '@oneAppCore/services/apis/UsersApi';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { headers } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  subTitle: {
    color: theme.palette.grey[500],
    fontWeight: 500,
  },
  tableMargin: {
    marginBottom: '10px',
  },
  tableMarginUnset: {
    marginBottom: 'unset',
  },
  rowCategoryHeader: {
    width: '30%',
  },
}));

const StyledTableRow = makeStyles((theme: Theme) => ({
  rows: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function UserExistance({
  user: paramUser,
  edit,
}: {
  user: any;
  edit: Boolean;
}) {
  const classes = useStyles();
  const { data: users = [] } = useUsers();
  const { data: suppliers = [] } = useSuppliers();
  const [userLocationIds, setUserLocationIds] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const getLoc = async () => {
    return await UsersApi.getLocations(paramUser?.id || 42);
  };

  useEffect(() => {
    (async () => {
      try {
        const suppliers = await UsersApi.getLocations(paramUser.id);
        const supplierIds = suppliers.map((supp) => supp.id);
        setUserLocationIds(supplierIds);
      } catch (err) {
        throw err;
      }
    })();
  }, []);

  const update = (supplierId: number, checked: boolean) => {
    const newIdList = userLocationIds.slice();
    const isIdInList = newIdList.find((id) => id === supplierId);
    const index = newIdList.indexOf(supplierId);
    if (isIdInList) {
      newIdList.splice(index, 1);
    } else {
      newIdList.push(supplierId);
    }
    setUserLocationIds(newIdList);
  };

  const submit = async () => {
    try {
      await Api.post(`/api/v1/user-location/user/${paramUser.id}`, {
        supplierIds: userLocationIds,
      });
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const locationsTable = (
    <Table className={classes.tableMarginUnset}>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              align={index > 0 ? 'center' : 'left'}
              key={`${header}-${index}`}
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {suppliers
          .sort((a, b) => a.id - b.id)
          .map((supplier) => {
            const isEnabled = userLocationIds.find((id) => id === supplier.id);
            return (
              <TableRow key={supplier.id}>
                <TableCell align="left">{supplier.name}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    disabled={!edit}
                    color="primary"
                    checked={Boolean(isEnabled)}
                    onChange={(e) => update(supplier.id, e.target.checked)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );

  const noLocations = (
    <Grid item container justifyContent="center" xs={12}>
      <Typography variant="h4">No locations available</Typography>
    </Grid>
  );

  return (
    // <Paper elevation={1} className={classes.root}>
    <Grid item container alignItems="center" spacing={1}>
      <Grid item container style={{ justifyContent: 'center' }} xs={12}>
        <Typography variant="h5">Locations</Typography>
      </Grid>
      {locationsTable}
      {edit && (
        <Grid item container style={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={submit}>
            Save
          </Button>
        </Grid>
      )}
    </Grid>
    // </Paper>
  );
}

export default UserExistance;
