import React, { memo } from 'react';

import {
  Grid,
  Hidden,
  Paper,
  Tooltip,
  IconButton,
  Icon,
  Typography,
  Link,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import Brightness2TwoToneIcon from '@mui/icons-material/Brightness2TwoTone';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import NotificationDrawer from '@src/Components/Notifications/NotificationDrawer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FontAwesome from '@src/Components/common/FontAwesome';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#FFF',
    transition: '1s ease-in-out',
    width: '100%',
    // margin: '0 auto',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  button: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1rem',
  },
  buttonText: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressRoot: {
    height: 15,
    width: '100%',
  },
  progressBar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    cursor: 'pointer',
  },
}));

interface Props {
  toggleDrawer: () => void;
  toggleMini: () => void;
  togglePopper: () => void;
}

function AuthLayoutHeader({ toggleDrawer, toggleMini, togglePopper }: Props) {
  const classes = useStyles();
  const path = window.location.pathname;
  const pathSegments = path.split('/').filter((segment) => segment);
  const lastSegment = pathSegments[pathSegments.length - 1];
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <>
      <Paper className={classes.root} elevation={1}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item xs={1} container alignItems="center" spacing={2}>
            <Grid item>
              <Hidden mdDown>
                <Tooltip title={'Toggle Navigation'}>
                  <span>
                    <IconButton
                      color="primary"
                      style={{ fontSize: '14px' }}
                      onClick={toggleMini}
                    >
                      <FontAwesome
                        className={classes.icon}
                        size="lg"
                        name="bars"
                        type="fa"
                        form="fas"
                        color="grey"
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Hidden>
              <Hidden mdUp>
                <FontAwesome
                  className={classes.icon}
                  size="lg"
                  name="bars"
                  type="fa"
                  form="fas"
                  color="grey"
                  onClick={toggleDrawer}
                />
              </Hidden>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={12}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  {pathSegments.length < 1 ? (
                    <Grid item container alignItems="center">
                      <Typography style={{ textTransform: 'capitalize' }}>
                        Dashboards
                      </Typography>
                      <NavigateNextIcon fontSize="small" />
                      <Typography
                        style={{ textTransform: 'capitalize' }}
                        color="secondary"
                      >
                        Analytics
                      </Typography>
                    </Grid>
                  ) : (
                    pathSegments.map((segment, i) => {
                      const lastSegment = i === pathSegments.length - 1;
                      const segmentLink = `/${pathSegments
                        .slice(0, i + 1)
                        .join('>')}`;
                      return lastSegment ? (
                        <Typography
                          style={{ textTransform: 'capitalize' }}
                          color="secondary"
                          key={segment}
                        >
                          {segment}
                        </Typography>
                      ) : (
                        <Typography
                          style={{ textTransform: 'capitalize' }}
                          key={segment}
                        >
                          {segment}
                        </Typography>
                      );
                    })
                  )}
                </Breadcrumbs>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={8}
            md={5}
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <div
                style={{
                  background: 'rgb(227, 242, 253) ',
                  color: 'rgb(33, 150, 243)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  width: '34px',
                  height: '34px',
                  fontSize: '1.2rem',
                  transition: 'all 0.2s ease-in-out 0s',
                  cursor: 'pointer',
                }}
              >
                <Brightness2TwoToneIcon />
              </div>
            </Grid>
            <Grid item>
              <NotificationDrawer />
            </Grid>
            <Grid item>
              <div
                style={{
                  background: 'rgb(227, 242, 253) ',
                  color: 'rgb(33, 150, 243)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  width: '34px',
                  height: '34px',
                  fontSize: '1.2rem',
                  transition: 'all 0.2s ease-in-out 0s',
                  cursor: 'pointer',
                }}
              >
                <PersonOutlineTwoToneIcon />
              </div>
            </Grid>

            {/* <Grid item>
            <FontAwesome
              className={classes.icon}
              size="lg"
              name="moon"
              type="fa"
              form="far"
              color="grey"
            />
          </Grid> */}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default memo(AuthLayoutHeader);
