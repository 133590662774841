import { ROLES, ADMIN } from '@oneAppCore/constants/roles';

export const adminCheck = (userRoles: any[]) => {
  let isAdmin = false;
  for (let index = 0; index < userRoles.length; index++) {
    const element = userRoles[index];
    if (
      element.roleId == ROLES[ADMIN] &&
      (element.read || element.write || element.update || element.delete)
    ) {
      isAdmin = true;
      break;
    }
  }
  return isAdmin;
}