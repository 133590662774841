import { useState, useEffect, useMemo } from 'react';
import recoilSearch from '../../hooks/useSearchOptions';
import { useDebouncedCallback } from 'use-debounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Badge,
  Select,
  SwipeableDrawer,
  InputLabel,
  Grid,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NestedMenuItem } from 'mui-nested-menu';
import DateSelect from '@src/Components/common/Selects/DateSelect';
import type { Props } from './types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: '300px',
    overflowY: 'scroll',
  },
}));

function SearchFilters({
  filters,
  constantFilters = {},
  setResetPagination,
  selectedFilterValues,
  setSelectedFilterValues,
}: Props) {
  const [searchFilters, setSearchFilters] = useState({ ...constantFilters });
  const [defaultSearchFilters, setDefaultSearchFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [searchValue, setSearchValue] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [constantFilterCount, setConstantFilterCount] = useState(
    Object.keys(constantFilters).length,
  );
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    const selectedMPN = localStorage.getItem('selectedMPN');
    const selectedListing = localStorage.getItem('listingSku');
    const selectedVarMPN = localStorage.getItem('selectedVarMpn');
    const selectedVar = localStorage.getItem('selectedVar');
    const selectedSku = localStorage.getItem('selectedSku');

    setSelectedFilterValues((prevValues) => ({
      ...prevValues,
      ...(selectedMPN && { mpn: selectedMPN }),
      ...(selectedListing && { listingSku: selectedListing }),
      ...(selectedVarMPN && { manufacturerNo: selectedVarMPN }),
      ...(selectedVar && { sku: selectedVar }),
      ...(selectedSku && { sku: selectedSku }),
    }));
  }, []);

  const updateSearchFilter = (filter: string) => {
    setSearchOptions((previous) => ({
      ...previous,
      searchFilter: filter,
    }));
  };
  const updateFilterValues = (key, value) => {
    setSelectedFilterValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };


  const searchButton = () => {
    const value = searchValue.trim().replaceAll('&', encodeURIComponent('&'));
    updateSearchFilter(value);
    setResetPagination(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const inputValue = e.target.value;
      if (inputValue !== undefined && inputValue !== null) {
        const value = inputValue
          .trim()
          .replaceAll('&', encodeURIComponent('&'));
        setSearchOptions((previous) => ({
          ...previous,
          searchFilter: value,
        }));
      }
      setResetPagination(true);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = (key: string[], value: any[]) => {
    const updatedFilters = { ...searchFilters };

    if (value.length === 0) {
      delete updatedFilters[key[0]];
    } else {
      let secondOpts: any = {};
      key.forEach((k, i) => {
        secondOpts[k] = value[i];
      });
      Object.assign(updatedFilters, secondOpts);
    }

    setSearchFilters(updatedFilters);
    updateStore(updatedFilters);
  };

  useEffect(() => {
    setSearchFilters(searchOptions.filters);
    setDefaultSearchFilters(Object.keys(constantFilters).map((key) => key));
  }, [searchOptions.filters]);

  const updateStore = useDebouncedCallback(
    (filterOptions: Record<string, any>) => {
      setSearchOptions((previous) => ({
        ...previous,
        filters: filterOptions,
      }));
    },
    1500,
  );

  // ! was causing filter counts to not change on "Clear".
  // useEffect(() => {
  //   const count = Object.values(constantFilters).filter(
  //     (value) => value !== null && value !== undefined,
  //   ).length;
  //   setConstantFilterCount(count);
  // }, [constantFilters]);

  const handleClear = () => {
    localStorage?.removeItem('selectedMPN');
    localStorage?.removeItem('selectedVar');
    localStorage?.removeItem('selectedVarMpn');
    localStorage?.removeItem('listingSku');
    const clearedFilters = Object.keys(searchFilters).reduce((acc, key) => {
      acc[key] = undefined;
      return acc;
    }, {});

    setSearchFilters({ ...constantFilters });
    setSelectedFilterValues({ ...constantFilters });
    updateStore({ ...constantFilters });
    setConstantFilterCount(0);
  };

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  interface FilterOption {
    defaultValue?: string | number | (string | number)[];
    // Add other properties if necessary
  }

  const applyFilters = () => {
    const updatedFilters: Record<string, string | number | (string | number)[]> = {};
    Object.keys(searchFilters).forEach((key) => {
      const storedValue = localStorage.getItem(key);
      const filterOption = searchFilters[key] as FilterOption;
      if (storedValue) {
        updatedFilters[key] = storedValue;
      } else if (filterOption?.defaultValue) {
        updatedFilters[key] = filterOption.defaultValue;
      } else {
        updatedFilters[key] = searchFilters[key];
      }
    });
    Object.entries(selectedFilterValues).forEach(([key, value]) => {
      const storedValue = localStorage.getItem(key);

      if (value && (!storedValue || storedValue === value.toString())) {
        updatedFilters[key] = typeof value === 'string' ? value.trim() : value;

      } else if (storedValue) {
        updatedFilters[key] = storedValue;
      } else {
        delete updatedFilters[key];
      }
    });
    setSearchFilters(updatedFilters);

    if (!selectedFilterValues['sku']) {
      setResetPagination(true);
    }

    updateStore(updatedFilters);
    handleClose();
  };

  useEffect(() => {
    if (selectedFilterValues['mpn']) {
      applyFilters();
      localStorage?.removeItem('selectedMPN');
    } else if (selectedFilterValues['sku']) {
      applyFilters();
      localStorage?.removeItem('selectedVar');
    } else if (selectedFilterValues['manufacturerNo']) {
      applyFilters();
      localStorage?.removeItem('selectedVarMpn');
    } else if (selectedFilterValues['listingSku']) {
      applyFilters();
      localStorage.removeItem('listingSku');
    }
  }, [selectedFilterValues]);

  const activeFilters = useMemo(
    () => {
      const availableFilters = filters.map((f) => f.keyName);
      const keys = [];
      const cons = Object.entries(constantFilters).filter(
        ([_, value]) => value !== null && value !== undefined && value !== '--',
      ).filter(([key]) => availableFilters.includes(key));
      const entries = Object.entries(searchOptions.filters)
        .filter(([key]) => !defaultSearchFilters.includes(key));
      entries.forEach(([key]) => {
        const keySplit = key.split('.')[0];
        if (!keys.includes(keySplit)) keys.push(keySplit);
      });
      cons.forEach(([key]) => {
        const keySplit = key.split('.')[0];
        if (!keys.includes(keySplit)) keys.push(keySplit);
      });
      return keys.length;
      // Object.entries(searchOptions.filters)
      // .filter(([key]) => !defaultSearchFilters.includes(key))
      // .filter(([key, val]) => val).length;
    },
    [searchOptions, constantFilters],
  );

  function parseToDayjs(value: any): Dayjs | null {
    if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      return dayjs(value);
    }
    return null;
  }

  const searchFieldWrapper = (buttonChild) => (
    <>
      {Boolean(filters.length) && (
        <Grid item xs={1}>
          <Badge
            badgeContent={activeFilters}
            color="secondary"
            style={{ float: 'right' }}
          >
            {buttonChild}
          </Badge>
        </Grid>)
      }
      <Grid item xs={6} md={8}>
        <TextField
          id="searchField"
          value={searchValue}
          onKeyPress={(e) => handleKeyPress(e)}
          placeholder={'Search...'}
          variant="outlined"
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ marginLeft: 15, width: '100%' }}
        />
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        style={{ marginRight: 15 }}
        onClick={searchButton}
      >
        <SearchIcon />
      </Button>
    </>
  );

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="flex-end"
      style={{ marginBottom: 10 }}
    >
      {isMobile ? (
        <>
          {searchFieldWrapper((
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDrawerOpen(true)}
            >
              <FilterIcon />
            </Button>))}
          <SwipeableDrawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => null}
            anchor="right"
            style={{ width: '300px' }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {filters.map((filter) => (
              <div key={filter.keyName} style={{ justifyContent: 'center' }}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{filter.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Divider style={{ marginTop: 10, marginBottom: 10 }} /> */}
                    {filter.type === 'string' ? (
                      <Grid container style={{ justifyContent: 'center' }}>
                        <TextField
                          value={selectedFilterValues[filter.keyName] || ''}
                          onChange={(e) =>
                            updateFilterValues(
                              filter.keyName,
                              e.target.value,
                            )
                          }
                          disabled={filter.disabled || false}
                          label={filter.name}
                          style={{ width: '85%' }}
                          variant="outlined"
                        />
                      </Grid>
                    ) : filter.type === 'number' ? (
                      <Grid container style={{ justifyContent: 'center' }}>
                        <TextField
                          value={selectedFilterValues[filter.keyName] || ''}
                          onChange={(e) =>
                            updateFilterValues(
                              filter.keyName,
                              e.target.value,
                            )
                          }
                          disabled={filter.disabled || false}
                          label={filter.name}
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                    ) : filter.type === 'date range' ? (
                      <Grid
                        container
                        spacing={1}
                        style={{ justifyContent: 'center' }}
                      >
                        <Grid item>
                          <DateSelect
                            label="Start Date"
                            value={
                              selectedFilterValues[
                              `${filter.keyName}.startDate`
                              ] || null
                            }
                            onChange={(val) =>
                              updateFilterValues(
                                `${filter.keyName}.startDate`,
                                val,
                              )
                            }
                            animateYearScrolling
                            fullWidth
                            startOfDay
                            disabled={filter.disabled || false}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                        <Grid item>
                          <DateSelect
                            label="End Date"
                            value={
                              selectedFilterValues[
                              `${filter.keyName}.endDate`
                              ] || null
                            }
                            onChange={(val) =>
                              updateFilterValues(`${filter.keyName}.endDate`, val)
                            }
                            animateYearScrolling
                            fullWidth
                            endOfDay
                            disabled={filter.disabled || false}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                      </Grid>
                    ) : filter.type === 'date range select' ? (
                      <Grid container spacing={1}>
                        <Grid item>
                          <InputLabel htmlFor={`date-select-${filter.keyName}`}>
                            Range Preset
                          </InputLabel>
                          <Select
                            disabled={filter.disabled || false}
                            labelId="selectorProperty"
                            id={`date-select-${filter.keyName}`}
                            variant="outlined"
                            value={selectedFilterValues[filter.keyName] || null}
                            onChange={(e) =>
                              updateFilterValues(filter.keyName, e.target.value)
                            }
                          >
                            <MenuItem value="--">Select Range</MenuItem>
                            {filter.properties.map((property, i) => {
                              return (
                                <MenuItem
                                  disabled={property.disabled}
                                  value={`${property.value}`}
                                  key={i}
                                >
                                  {property.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item>
                          <DateSelect
                            label="Start Date"
                            value={selectedFilterValues[`${filter.keyName}.startDate`] || null}
                            onChange={(val) => {
                              updateFilterValues(
                                `${filter.keyName}.startDate`,
                                val,
                              );
                            }}
                            animateYearScrolling
                            fullWidth
                            startOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                        <Grid item>
                          <DateSelect
                            label="End Date"
                            value={
                              selectedFilterValues[
                              `${filter.keyName}.endDate`
                              ] || null
                            }
                            onChange={(val) =>
                              updateFilterValues(`${filter.keyName}.endDate`, val)
                            }
                            animateYearScrolling
                            fullWidth
                            endOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                      </Grid>
                    ) : filter.type == 'date' ? (


                      <DateSelect
                        label="Date"
                        value={parseToDayjs(searchFilters?.[filter.keyName])}
                        onChange={(val) => update([filter.keyName], [val])}
                        animateYearScrolling
                        fullWidth
                      />
                    ) : filter.type == 'selector' ? (
                      <Grid
                        container
                        spacing={1}
                        style={{ justifyContent: 'center' }}
                      >
                        <Select
                          disabled={filter.disabled || false}
                          labelId="selectorProperty"
                          value={selectedFilterValues[filter.keyName] || ''}
                          variant="outlined"
                          style={{ width: '80%', height: '60%' }}
                          onChange={(e) =>
                            updateFilterValues(filter.keyName, e.target.value)
                          }
                        >
                          {!filter.options?.hideDefaultNoneOption && (
                            <MenuItem value="">None</MenuItem>
                          )}
                          {filter.properties.map((property, i) => {
                            return (
                              <MenuItem value={property.value} key={i}>
                                {property.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    ) : filter.type == 'multiSelector' ? (
                      <Grid container spacing={1}>
                        <Select
                          disabled={filter.disabled || false}
                          labelId="multiSelectorProperty"
                          value={
                            Array.isArray(selectedFilterValues[filter.keyName])
                              ? selectedFilterValues[filter.keyName]
                              : selectedFilterValues[filter.keyName]
                                ? [selectedFilterValues[filter.keyName]]
                                : []
                          }
                          multiple
                          onChange={(e) =>
                            updateFilterValues(filter.keyName, e.target.value)
                          }
                        >
                          {!filter.options?.hideDefaultNoneOption && (
                            <MenuItem value="">None</MenuItem>
                          )}
                          {filter.properties.map((property, i) => (
                            <MenuItem value={property.value} key={i}>
                              {property.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : filter.type == 'array' ? (
                      <Grid container spacing={1}>
                        <Select
                          disabled={filter.disabled || false}
                          labelId="arrayProperty"
                          value={
                            selectedFilterValues[filter.keyName]?.[1] || []
                          }
                          onChange={(e) =>
                            updateFilterValues(
                              filter.keyName,
                              e.target.value
                                ? [filter.operator, e.target.value]
                                : null,
                            )
                          }
                        >
                          <MenuItem value="">None</MenuItem>
                          {filter.properties.map((property, i) => (
                            <MenuItem value={property.value} key={i}>
                              {property.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
            <Grid
              item
              container
              style={{
                padding: 10,
                justifyContent: 'space-between',
                minWidth: 200,
              }}
            >
              <Grid item xs={5} style={{}}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    width: '100%',
                    backgroundColor: 'crimson',
                    color: 'white',
                  }}
                  onClick={() => {
                    handleClear();
                    setIsDrawerOpen(false);
                  }}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ width: '100%' }}
                  onClick={() => {
                    applyFilters();
                    setIsDrawerOpen(false);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </>
      ) : (
        <>
          {searchFieldWrapper((
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              <FilterIcon />
            </Button>))}
          <Menu
            id="filters-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            disableAutoFocusItem
          // getContentAnchorEl={() => { return null }}
          >
            {filters.map((filter) => {
              const filterName = filter.keyName.includes('.') ? filter.keyName.split('.')[0] : filter.keyName;
              let filterStart = '';
              let filterEnd = '';
              if (filter.type === 'date range select') {
                if (selectedFilterValues[filterName]) {
                  if (selectedFilterValues[`${filterName}.startDate`]) {
                    filterStart = (selectedFilterValues[`${filterName}.startDate`]);
                  }
                  if (selectedFilterValues[`${filterName}.endDate`]) {
                    filterEnd = (selectedFilterValues[`${filterName}.endDate`]);
                  }
                }
              }
              return (
                <NestedMenuItem
                  key={filter.keyName}
                  label={filter.name}
                  parentMenuOpen={Boolean(anchorEl)}
                  selected={
                    (Boolean(searchFilters?.[filter.keyName]) && searchFilters?.[filter.keyName] !== '--') ||
                    Boolean(searchFilters?.[`${filter.keyName}.startDate`]) ||
                    Boolean(searchFilters?.[`${filter.keyName}.endDate`])
                  }
                  onKeyDown={(e) => e.stopPropagation()}
                  onClickCapture={stopImmediatePropagation}
                  value={''}
                >
                  <MenuItem onKeyDown={(e) => e.stopPropagation()} style={{ maxWidth: isMobile ? '100vw' : '480px' }}>
                    {filter.type === 'string' || filter.type === 'text' ? (
                      <TextField
                        value={selectedFilterValues[filter.keyName] || ''}
                        onChange={(e) =>
                          updateFilterValues(filter.keyName, e.target.value)
                        }
                        disabled={filter.disabled || false}
                        label={filter.name}
                        variant="outlined"
                      />
                    ) : filter.type === 'number' ? (
                      <TextField
                        value={selectedFilterValues[filter.keyName] || ''}
                        onChange={(e) =>
                          updateFilterValues(filter.keyName, e.target.value)
                        }
                        disabled={filter.disabled || false}
                        label={filter.name}
                        variant="outlined"
                        type="number"
                      />
                    ) : filter.type === 'date range' ? (
                      <Grid container spacing={1}>
                        <Grid item>
                          <DateSelect
                            label="Start Date"
                            value={
                              selectedFilterValues[
                              `${filter.keyName}.startDate`
                              ] || null
                            }
                            onChange={(val) =>
                              updateFilterValues(`${filter.keyName}.startDate`, val)
                            }
                            animateYearScrolling
                            fullWidth
                            startOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                        <Grid item>
                          <DateSelect
                            label="End Date"
                            value={
                              selectedFilterValues[`${filter.keyName}.endDate`] ||
                              null
                            }
                            onChange={(val) =>
                              updateFilterValues(`${filter.keyName}.endDate`, val)
                            }
                            animateYearScrolling
                            fullWidth
                            endOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                      </Grid>
                    ) : filter.type === 'fitment' ? (
                      <Grid container spacing={1}>
                      </Grid>
                    ) : filter.type === 'date range select' ? (
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <InputLabel htmlFor={`date-select-${filter.keyName}`}>
                            Ranges
                          </InputLabel>
                          <Select
                            disabled={filter.disabled || false}
                            labelId="selectorProperty"
                            id={`date-select-${filter.keyName}`}
                            variant="outlined"
                            value={
                              selectedFilterValues[filterName] || `${filterStart || ''}--${filterEnd || ''}`
                            }
                            onChange={(e) => {
                              updateFilterValues(`${filterName}.startDate`, '');
                              updateFilterValues(`${filterName}.endDate`, '');
                              updateFilterValues(filter.keyName, e.target.value)
                            }}
                            fullWidth
                          >
                            <MenuItem value="--">None</MenuItem>
                            {filter.properties.map((property, i) => {
                              return (
                                <MenuItem
                                  disabled={property.disabled}
                                  value={property.value}
                                  key={i}
                                >
                                  {property.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={4}>
                          <DateSelect
                            label="Start Date"
                            value={
                              filterStart || null
                            }
                            onChange={(val) => {
                              updateFilterValues(filter.keyName, '--');
                              updateFilterValues(`${filterName}.startDate`, (val));
                            }}
                            animateYearScrolling
                            fullWidth
                            startOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DateSelect
                            label="End Date"
                            value={
                              filterEnd ||
                              null
                            }
                            onChange={(val) => {
                              updateFilterValues(filterName, '--');
                              updateFilterValues(`${filterName}.endDate`, (val))
                            }}
                            animateYearScrolling
                            fullWidth
                            endOfDay
                            disabled={filter.disabled || false}
                          />
                        </Grid>
                      </Grid>
                    ) : filter.type == 'date' ? (
                      <DateSelect
                        label="Date"
                        value={parseToDayjs(searchFilters?.[filter.keyName])}
                        onChange={(val) => update([filter.keyName], [val])}
                        animateYearScrolling
                        fullWidth
                        disabled={filter.disabled || false}
                      />
                    ) : filter.type === 'equality' ? (
                      <Grid
                        container
                        spacing={1}
                        style={{ justifyContent: 'center' }}
                      >
                        <Grid item>
                          <Select
                            disabled={filter.disabled || false}
                            labelId="selectorProperty"
                            id={`equality-direction-${filter.keyName}`}
                            variant="outlined"
                            value={
                              selectedFilterValues[
                              `${filter.keyName}.direction`
                              ] || null
                            }
                            onChange={(e) =>
                              updateFilterValues(
                                `${filter.keyName}.direction`,
                                e.target.value,
                              )
                            }
                          >
                            <MenuItem value="<=">{'< '}</MenuItem>
                            <MenuItem value=">=">{'> '}</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item>
                          <TextField
                            value={
                              selectedFilterValues[`${filter.keyName}.value`] ||
                              ''
                            }
                            onChange={(e) =>
                              updateFilterValues(
                                `${filter.keyName}.value`,
                                e.target.value,
                              )
                            }
                            disabled={filter.disabled || false}
                            label={filter.name}
                            variant="outlined"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    ) : filter.type == 'selector' ? (
                      <Grid container spacing={1}>
                        <Select
                          disabled={filter.disabled || false}
                          labelId="selectorProperty"
                          value={selectedFilterValues[filter.keyName] || ''}
                          onChange={(e) =>
                            updateFilterValues(filter.keyName, e.target.value)
                          }
                        >
                          {!filter.options?.hideDefaultNoneOption && (
                            <MenuItem value="">None</MenuItem>
                          )}
                          {filter.properties.map((property, i) => {
                            return (
                              <MenuItem value={property.value} key={i}>
                                {property.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    ) : filter.type == 'multiSelector' ? (
                      <Grid container spacing={1}>
                        <Select
                          disabled={filter.disabled || false}
                          labelId="multiSelectorProperty"
                          value={
                            Array.isArray(selectedFilterValues[filter.keyName])
                              ? selectedFilterValues[filter.keyName]
                              : selectedFilterValues[filter.keyName]
                                ? [selectedFilterValues[filter.keyName]]
                                : []
                          }
                          multiple
                          onChange={(e) =>
                            updateFilterValues(filter.keyName, e.target.value)
                          }
                        >
                          {!filter.options?.hideDefaultNoneOption && (
                            <MenuItem value="">None</MenuItem>
                          )}
                          {filter.properties.map((property, i) => (
                            <MenuItem value={property.value} key={i}>
                              {property.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : filter.type == 'array' ? (
                      <Grid container spacing={1}>
                        <Select
                          disabled={filter.disabled || false}
                          labelId="arrayProperty"
                          value={selectedFilterValues[filter.keyName]?.[1] || []}
                          onChange={(e) =>
                            updateFilterValues(
                              filter.keyName,
                              e.target.value
                                ? [filter.operator, e.target.value]
                                : null,
                            )
                          }
                        >
                          <MenuItem value="">None</MenuItem>
                          {filter.properties.map((property, i) => (
                            <MenuItem value={property.value} key={i}>
                              {property.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                </NestedMenuItem>
              )
            })}
            <Grid
              item
              container
              style={{
                padding: 10,
                justifyContent: 'space-between',
                minWidth: 200,
              }}
            >
              <Grid item xs={5}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={applyFilters}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Menu>
        </>
      )}
    </Grid>
  );
}

export default SearchFilters;
