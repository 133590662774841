import { COST_LAW, PRICE_TOO_LOW, SCENARIO_MAP_LABELS, LAW_MAP } from '@oneAppCore/constants/laws';
import ApproveButton from './ApproveButton';
import {
  Grid,
  Link,
  Chip,
} from '@mui/material';
import type {
  Column,
  ChildColumn,
} from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import {
  APPROVED,
  CREATED,
  EDIT,
  PENDING,
  REJECTED,
} from '@oneAppCore/constants/listings';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import { formatCentsToDollars } from '@src/utils/currency';
import { capitalize, getStartLetters } from '@oneAppCore/utils/formatters';
import { COMPLETED } from '@oneAppCore/constants/orders';
import { lowerCase, startCase } from 'lodash';
import { listingChangeStatusColors } from '@src/utils/listings';
import TooltipTable from '@src/Components/common/TooltipTable';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
// import {} from '@oneAppCore/constants/laws';listingChangeStatusColors

export const columns = (
  suppliers,
  functions,
): Column[] => [
    {
      keyName: 'id',
      title: 'Id',
      sortBy: true,
      // hidden: true,
    },
    {
      keyName: 'name-IMAGE',
      title: 'Product',
      // sortBy: true,
      customComponent: function law(row) {
        const { name, prodImages, varImages } = row;
        const placeholderImage = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
        const imageUrl = prodImages?.length ? prodImages[0] : varImages?.length ? varImages?.[0].imageUrl : placeholderImage;
        return <>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'left',
              gap: 5,
            }}
          >
            <img
              width={60}
              height={60}
              // style={{ borderRadius: 10 }}
              src={imageUrl}
              alt={imageUrl === placeholderImage ? `placeholder image for product ${name}` : `image for product with name of ${name}`}
            />
            &nbsp;
            {name}
          </Grid>
        </>;
      },
    },
    {
      keyName: 'sku',
      title: 'Sku',
      align: 'center',
      // sortBy: true,
    },
    {
      keyName: 'scenario',
      title: 'Scenario',
      // sortBy: true,
      customComponent: function law(row) {
        const x = row?.scenario || 'none'
        if (row.id === 169624) console.log('row', row);
        return <>
          <Grid container item xs={12} style={{ minWidth: '140px' }} justifyContent='center'>
            {LAW_MAP[row?.scenario || 'none']}
          </Grid>
        </>;
      },
    },
    {
      keyName: 'profit',
      title: 'Profit (Prev)',
      customComponent: function listing(row) {
        const listings = row?.listings || [];
        const newChanges = listings?.[0]?.changes;

        const newProfPercCalc = newChanges?.newValues?.revenue ? ((newChanges?.newValues?.profit / newChanges?.newValues?.revenue) * 100).toFixed(3) : -100;
        const newProf = newChanges?.newValues?.profit ? newChanges?.newValues?.profit : 0;
        const newProfPerc = newChanges?.newValues?.profPercent ? newChanges?.newValues?.profPercent : newProfPercCalc;

        const prevProf = {
          min: 0, max: 0,
          minP: '0', maxP: '0',
        };

        listings.forEach((lst) => {
          const prfPrcLstClc = lst?.changes?.oldValues?.revenue ? ((lst?.changes?.oldValues?.profit / lst?.changes?.oldValues?.revenue) * 100).toFixed(3) : -100;
          const prfLst = lst?.changes?.oldValues?.profit ? lst?.changes?.oldValues?.profit : 0;
          const prdPrcLst = lst?.changes?.oldValues?.profPercent ? lst?.changes?.oldValues?.profPercent : prfPrcLstClc;

          if (prevProf.min === 0) {
            prevProf.min = prfLst;
            prevProf.minP = prdPrcLst;
          } else if (prevProf.min > prfLst) {
            prevProf.min = prfLst;
            prevProf.minP = prdPrcLst;
          }
          if (prevProf.max === 0) {
            prevProf.max = prfLst;
            prevProf.maxP = prdPrcLst;
          } else if (prevProf.max < prfLst) {
            prevProf.max = prfLst;
            prevProf.maxP = prdPrcLst;
          }
        });
        const prevMaxprf = prevProf.min !== prevProf.max ? (<>&nbsp;to&nbsp;{formatCentsToDollars(prevProf.max)}</>) : null;
        const prevMaxprc = prevProf.min !== prevProf.max ? (<>&nbsp;to&nbsp;{prevProf.maxP}</>) : null;

        return <>
          <Grid container xs={12} /* style={{ maxWidth: '210px' }} */>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={6}>Profit</Grid>
              <Grid item xs={6}>Percent</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={6}>{formatCentsToDollars(newProf)}</Grid>
              <Grid item xs={6}>{newProfPerc}%</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              {/* <Grid item xs={4} sm={3}>Percent</Grid> */}
              <Grid item xs={6}>({formatCentsToDollars(prevProf.min)}{prevMaxprf})</Grid>
              <Grid item xs={6}>({prevProf.minP}{prevMaxprc}%)</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.suppliers',
      title: 'Costs (Prev)',
      customComponent: function (row) {
        let returnElement = null;
        if (row) {
          const rw = row ?? { listings: [] } as any
          const { listings, suppliers: rowSupps } = rw;
          const listing = listings?.length ? listings[0] : null;
          if (listing) {
            const { changes: { newValues, oldValues } } = listing;

            const newSuppId = newValues?.supplierId ? newValues?.supplierId : 0;
            const oldSuppId = oldValues?.supplierId ? oldValues?.supplierId : 0;

            const newSuppPick = rowSupps?.find((s) => s.supplierId === newSuppId);
            const oldSuppPick = rowSupps?.find((s) => s.supplierId === oldSuppId);
            const newSupp = suppliers?.find((s) => s.id === newSuppId);
            const oldSupp = suppliers?.find((s) => s.id === oldSuppId);
            const newSuppName = newSupp?.metaData?.shortName || getStartLetters(newSupp?.name, { caps: true }) || newSuppId;
            const oldSuppName = oldSupp?.metaData?.shortName || getStartLetters(oldSupp?.name, { caps: true }) || oldSuppId;
            const tooltipColumns: Column[] = [
              {
                title: 'Supplier',
                type: 'string',
                keyName: 'supplier',
              },
              {
                // title: 'Total',
                title: 'Total (Est.)',
                type: 'string',
                keyName: 'totalCost',
                customComponent: function (row) {
                  const {
                    // oldCost,
                    cost,
                    estimatedShipping,
                    shippingCost
                  } = row || { estimatedShipping: 0, shippingCost: 0, cost: 0 };
                  const total = cost + shippingCost;
                  const estTotal = cost + estimatedShipping;
                  return <Grid container item>
                    <Grid item xs={12}>{formatCentsToDollarsIntl(total)}</Grid>
                    <Grid item xs={12}>({formatCentsToDollarsIntl(estTotal)})</Grid>
                  </Grid>;
                },
              },
              {
                title: 'Cost (Prev)',
                type: 'money',
                keyName: 'cost',
                customComponent: function (row) {
                  const { oldCost, cost } = row || { oldCost: 0, cost: 0 };
                  return <Grid container item>
                    <Grid item xs={12}>{formatCentsToDollarsIntl(cost)}</Grid>
                    <Grid item xs={12}>({formatCentsToDollarsIntl(oldCost)})</Grid>
                  </Grid>;
                },
              },
              {
                title: 'Ship (Est.)',
                type: 'money',
                keyName: 'shippingCost',
                customComponent: function (row) {
                  const { estimatedShipping, shippingCost } = row || { estimatedShipping: 0, shippingCost: 0 };
                  return <Grid container item>
                    <Grid item xs={12}>{formatCentsToDollarsIntl(shippingCost)}</Grid>
                    <Grid item xs={12}>({formatCentsToDollarsIntl(estimatedShipping)})</Grid>
                  </Grid>;
                },
              },
              /* {
                title: 'Act. Ship',
                type: 'money',
                keyName: 'shippingCost',
              },
              {
                title: 'Est. Ship',
                type: 'money',
                keyName: 'estimatedShipping',
              }, */
            ];
            const tooltipRows: any = rowSupps?.map((supplier) => {
              const { supplierId: suppId, cost, shippingCost, estimatedShipping, oldCost } = supplier;
              const supp = suppliers.find((s) => s.id === suppId);
              return ({
                supplier: supp?.metaData?.shortName || getStartLetters(supp?.name) || suppId,
                cost, shippingCost, estimatedShipping, oldCost
              });
            });
            // const pickCost = (newValues?.scenario && newValues?.scenario === PRICE_TOO_LOW ? oldSuppPick?.oldCost : oldSuppPick?.cost);
            const pickCost = oldSuppPick?.cost;
            returnElement = (<TooltipTable
              columns={tooltipColumns}
              rows={tooltipRows}
            >
              <Grid container xs={12} /* style={{ maxWidth: '200px' }} */>
                <Grid container item xs={12} justifyContent='flex-start'>
                  <Grid item xs={6} /* sm={3} */>Supplier</Grid>
                  <Grid item xs={6} /* sm={3} */>Cost</Grid>
                </Grid>
                <Grid container item xs={12} justifyContent='flex-start'>
                  <Grid item xs={6} /* sm={3} */>{(newSuppName)}</Grid>
                  <Grid item xs={6} /* sm={3} */>{formatCentsToDollars((newSuppPick?.cost) + newSuppPick?.estimatedShipping)}</Grid>
                </Grid>
                <Grid container item xs={12} justifyContent='flex-start'>
                  <Grid item xs={6} /* sm={3} */>({(oldSuppName)})</Grid>
                  <Grid item xs={6} /* sm={3} */>({formatCentsToDollars(pickCost + oldSuppPick?.estimatedShipping)})</Grid>
                </Grid>
              </Grid>
            </TooltipTable>);
          }
        }
        return returnElement;
      }
    },
    {
      keyName: 'actions',
      title: 'Actions',
      customComponent: function ActionButtons(row) {
        const listings = row?.listings || [];
        let createdLength = 0;
        let approvedLength = 0;
        let pendingLength = 0;
        let completedLength = 0;
        let nonLength = 0;

        listings.forEach((lst) => {
          if (lst.changes.status) {
            if (lst.changes.status === CREATED) {
              createdLength++;
            } else if (lst.changes.status === APPROVED) {
              approvedLength++;
            } else if (lst.changes.status === COMPLETED) {
              completedLength++;
            } else if (lst.changes.status === PENDING) {
              pendingLength++;
            } else {
              nonLength++;
            }
          }
        })
        return (
          <Grid container xs={12}>
            {createdLength > 0 && <Grid item xs={4}>
              {<ApproveButton hoverText={`Approve ${createdLength} Created`} row={row} dataType={'variation'} />}
            </Grid>}
            {createdLength > 0 && <Grid item xs={4}>
              {<ApproveButton hoverText={`Reject ${createdLength} Created`} row={row} modalType={REJECTED} dataType={'variation'} />}
            </Grid>}
            {approvedLength > 0 && <Grid item xs={4}>
              {<ApproveButton hoverText={`Undo ${approvedLength} Approved`} row={row} modalType={CREATED} dataType={'variation'} />}
            </Grid>}
            {/* commented out for possible future use */}
            {/* <Grid item xs={4}>
              {edit}
            </Grid> */}
          </Grid >
        );
      },
    },
    {
      keyName: 'listings',
      type: 'parent',
      title: '',
    },
  ];

export const childColumns = (
  suppliers,
  functions,
): ChildColumn[] => [
    {
      keyName: 'id',
      parentKeyName: 'listings',
      title: 'Listing Id',
      sortBy: true,
    },
    {
      keyName: 'status',
      parentKeyName: 'listings',
      title: 'Status',
      sortBy: true,
      customComponent: function law(row) {
        const colors = listingChangeStatusColors(row.changes?.status)
        return <>
          <Grid container item xs={12} style={{ minWidth: '140px' }} justifyContent='center'>
            <Chip
              style={{
                backgroundColor: colors.bg,
                color: colors.text,
                width: '75%',
                height: '100%',
              }}
              label={startCase(lowerCase(row.changes?.status))}
            />
          </Grid>
        </>;
      },
    },
    // {
    //   keyName: 'scenario',
    //   parentKeyName: 'listings',
    //   title: 'Scenario',
    //   sortBy: true,
    //   customComponent: function law(row) {
    //     const scenario = row?.changes.type || 'bin'
    //     return <>
    //       <Grid container item xs={12} style={{ minWidth: '140px' }} justifyContent='center'>
    //         {scenario}
    //       </Grid>
    //     </>;
    //   },
    // },
    {
      keyName: 'listing',
      parentKeyName: 'listings',
      title: 'Listing',
      customComponent: function listing(row) {
        let href = '';
        if (row?.channelProductId) {
          if (row?.channelId === channelIds[AMAZON]) {
            href = AMAZON_ASIN_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[WALMART]) {
            href = WALMART_PRODUCT_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[EBAY]) {
            href = EBAY_PRODUCT_LINK(row?.channelProductId);
          }
        }
        const text = row?.listingSku || 'No SKU';
        const link = (
          <Link href={href} target="_blank">
            {text}
          </Link>
        );
        return <>
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
            {href !== '' ? link : text}
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.prices',
      parentKeyName: '',
      title: 'Prices (Prev)',
      customComponent: function price(row) {
        const newPrice = row?.changes?.newValues?.price ? row?.changes?.newValues?.price : 0;
        const oldPrice = row?.changes?.oldValues?.price ? row?.changes?.oldValues?.price : 0;
        const newMin = row?.changes?.newValues?.minPrice ? row?.changes?.newValues?.minPrice : 0;
        const oldMin = row?.changes?.oldValues?.minPrice ? row?.changes?.oldValues?.minPrice : 0;
        const newMax = row?.changes?.newValues?.maxPrice ? row?.changes?.newValues?.maxPrice : 0;
        const oldMax = row?.changes?.oldValues?.maxPrice ? row?.changes?.oldValues?.maxPrice : 0;
        return <>
          <Grid container xs={12} style={{ maxWidth: '220px' }}>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4}>Price</Grid>
              <Grid item xs={4}>Min</Grid>
              <Grid item xs={4}>Max</Grid>
            </Grid>
            {/* make min and max a hover? */}
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4}>{formatCentsToDollars(newPrice)}</Grid>
              <Grid item xs={4}>{formatCentsToDollars(newMin)}</Grid>
              <Grid item xs={4}>{formatCentsToDollars(newMax)}</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4}>({formatCentsToDollars(oldPrice)})</Grid>
              <Grid item xs={4}>({oldMin === 0 ? 'Not Set' : formatCentsToDollars(oldMin)})</Grid>
              <Grid item xs={4}>({oldMax === 1000000000 ? 'Not Set' : formatCentsToDollars(oldMax)})</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    {
      keyName: 'values.profits',
      parentKeyName: '',
      title: 'Profit (Prev)',
      customComponent: function profit(row) {
        const { changes } = row;
        // const oldSuppId = oldValues?.supplierId ? oldValues?.supplierId : 0;
        // const oldSuppPick = rowSupps?.find((s) => s.supplierId === oldSuppId);
        const newProfPercCalc = changes?.newValues?.revenue ? ((changes?.newValues?.profit / changes?.newValues?.revenue) * 100).toFixed(3) : -100;
        const oldProfPercCalc = changes?.oldValues?.revenue ? ((changes?.oldValues?.profit / changes?.oldValues?.revenue) * 100).toFixed(3) : -100;
        const newProf = changes?.newValues?.profit ? changes?.newValues?.profit : 0;
        const oldProf = changes?.oldValues?.profit ? changes?.oldValues?.profit : 0;
        const newProfPerc = changes?.newValues?.profPercent ? changes?.newValues?.profPercent : newProfPercCalc;
        const oldProfPerc = changes?.oldValues?.profPercent ? changes?.oldValues?.profPercent : oldProfPercCalc;
        return <>
          <Grid container xs={12} style={{ maxWidth: '210px' }}>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>Profit</Grid>
              <Grid item xs={4} sm={3}>Percent</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>{formatCentsToDollars(newProf)}</Grid>
              <Grid item xs={4} sm={3}>{newProfPerc}%</Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start'>
              <Grid item xs={4} sm={3}>({formatCentsToDollars(oldProf)})</Grid>
              <Grid item xs={4} sm={3}>({oldProfPerc}%)</Grid>
            </Grid>
          </Grid>
        </>;
      }
    },
    // {
    //   keyName: 'values.suppliers',
    //   parentKeyName: '',
    //   title: 'Costs (Prev)',
    //   customComponent: function supplier(row) {
    //     const { changes, suppliers: rowSupps } = row;
    //     const newSuppId = changes?.newValues?.supplierId ? changes?.newValues?.supplierId : 0;
    //     const oldSuppId = changes?.oldValues?.supplierId ? changes?.oldValues?.supplierId : 0;
    //     const newSuppPick = rowSupps?.find((s) => s.supplierId === newSuppId)
    //     const oldSuppPick = rowSupps?.find((s) => s.supplierId === oldSuppId)
    //     const newSupp = suppliers?.find((s) => s.id === newSuppId);
    //     const oldSupp = suppliers?.find((s) => s.id === oldSuppId);
    //     const newSuppName = newSupp?.metaData?.shortName || getStartLetters(newSupp?.name, { caps: true }) || newSuppId;
    //     const oldSuppName = oldSupp?.metaData?.shortName || getStartLetters(oldSupp?.name, { caps: true }) || oldSuppId;
    //     return <>
    //       <Grid container xs={12} style={{ maxWidth: '200px' }}>
    //         <Grid container item xs={12} justifyContent='flex-start'>
    //           <Grid item xs={4}>Supplier</Grid>
    //           <Grid item xs={4}>Cost</Grid>
    //         </Grid>
    //         <Grid container item xs={12} justifyContent='flex-start'>
    //           <Grid item xs={4}>{(newSuppName)}</Grid>
    //           <Grid item xs={4}>{formatCentsToDollars((newSuppPick?.cost) + newSuppPick?.estimatedShipping)}</Grid>
    //         </Grid>
    //         <Grid container item xs={12} justifyContent='flex-start'>
    //           <Grid item xs={4}>({(oldSuppName)})</Grid>
    //           <Grid item xs={4}>({formatCentsToDollars((changes?.newValues?.scenario && changes?.newValues?.scenario === PRICE_TOO_LOW ? oldSuppPick?.oldCost : oldSuppPick?.cost) + oldSuppPick?.estimatedShipping)})</Grid>
    //         </Grid>
    //       </Grid>
    //     </>;
    //   }
    // },
    {
      keyName: 'actions',
      parentKeyName: '',
      title: 'Actions',
      customComponent: function ActionButtons(row) {

        let aprv = (<ApproveButton row={row} modalType={CREATED} />);
        let deny = (null);
        let edit = (null);
        switch (row.changes?.status) {
          case COMPLETED: {
            aprv = <ApproveButton row={row} disabled />;
            deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
            edit = <ApproveButton row={row} modalType={EDIT} disabled />;
            break;
          }
          case PENDING: {
            aprv = <ApproveButton row={row} disabled />;
            deny = <ApproveButton row={row} modalType={REJECTED} disabled />;
            edit = <ApproveButton row={row} modalType={EDIT} disabled />;
            break;
          }
          case CREATED: {
            aprv = (<ApproveButton row={row} />)
            deny = (<ApproveButton row={row} modalType={REJECTED} />);
            edit = <ApproveButton row={row} modalType={EDIT} />;
          }
        }
        return (
          <Grid container xs={12}>
            <Grid item xs={4}>
              {aprv}
            </Grid>
            <Grid item xs={4}>
              {deny}
            </Grid>
            <Grid item xs={4}>
              {edit}
            </Grid>
          </Grid >
        );
      },
    },
  ];