import { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Modal, Button } from '@mui/material';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import OrdersApi from '@oneAppCore/services/apis/Orders';
import { lowerCase } from 'lodash';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function InternalModalButton({ row }: Props) {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (lowerCase(row.orderStatus) !== 'unassigned') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [row.orderStatus]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setOrderInternal = async () => {
    try {
      const orderItems = row.orderItems.map((orderItem) => {
        return { id: orderItem.id, supplierId: 7 };
      });
      const orderUpdateInternal = { orderItems };
      await OrdersApi.update(row.id, orderUpdateInternal);
      enqueueSnackbar('Moved Order to Internal', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error Moving Order', {
        variant: 'error',
      });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Grid item container style={{ justifyContent: 'center' }}>
        <Tooltip title={'Internal Order'}>
          <IconButton
            onClick={handleOpen}
            style={{ fontSize: '10px' }}
            disabled={isDisabled}
          >
            {isDisabled ? (
              <HomeTwoToneIcon />
            ) : (
              <HomeTwoToneIcon color="secondary" />
            )}
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h2>Are you sure?</h2>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: 15,
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                This will move the order to the internal order page to be
                fulfilled in the warehouse.
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'flex-end' }}
            >
              <Grid item container xs={2} style={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  style={{ border: '1px solid red', color: 'red' }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={2}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={setOrderInternal}
                >
                  Move
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default InternalModalButton;
