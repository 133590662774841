export const FEDEX = 'FedEx';
export const USPS = 'USPS';
export const UPS = 'UPS';
export const USPS_CODE = 'stamps_com';
export const FEDEX_CODE = 'fedex';
export const UPS_CODE = 'ups_walleted';
export const FLEET_STREET = 'Fleet Street';
export const GSO = 'GSO';
export const SPEEDY_METRO = 'Speedy Metro';
export const TRUCK = 'Truck';
export const CUSTOMER_PICKUP = 'Customer Pickup';
export const AIR_DELIVERY = 'Air Delivery';
export const ONTRAC = 'OnTrac';
export const GROUND_DELIVERY = 'Ground Delivery';

export const FEDEX_HOME = 'FedEx Home Delivery';
export const FEDEX_GROUND = 'FedEx Ground';

export const FEDEX_METHODS = {
  [FEDEX_HOME]: FEDEX_HOME,
  [FEDEX_GROUND]: FEDEX_HOME,
};

export const UPS_GROUND = 'UPS Ground';
export const UPS_METHODS = {
  [UPS_GROUND]: UPS_GROUND,
};

export const ONTRAC_GROUND = 'OnTrac Ground';
export const ONTRAC_METHODS = {
  [ONTRAC_GROUND]: ONTRAC_GROUND,
};

export const USPS_FIRST_CLASS = 'USPS First Class';
export const USPS_METHODS = {
  [USPS_FIRST_CLASS]: USPS_FIRST_CLASS,
};

export const US_TERRITORIES = ['AS', 'GU', 'MH', 'FM', 'MP', 'PW', 'PR', 'VI'];

export const NONE = 'none';
export const DELIVERY = 'delivery';
export const SIGNATURE = 'signature';
export const ADULT_SIGNATURE = 'adult_signature';
export const DIRECT_SIGNATURE = 'direct_signature';

export const PRIORITY_MAIL = 'Priority Mail';

export const SHIPSTATION_CONFIRMATION_TYPES = {
  [NONE]: [],
  [DELIVERY]: [],
  [SIGNATURE]: [],
  [ADULT_SIGNATURE]: [],
  [DIRECT_SIGNATURE]: [FEDEX],
}
