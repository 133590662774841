import Grid from '@mui/material/Grid';

import Created from './Created';
import Sold from './Sold';
import Breakdown from './Breakdown';

function MiniReports(props: any) {
  return (
    <Grid item container spacing={1}>
      <Created {...props} />
      <Sold {...props} />
      <Breakdown {...props} />
    </Grid>
  );
}

export default MiniReports;
