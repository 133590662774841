import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Tooltip, Fade, TooltipProps } from '@mui/material';


const useStyles = makeStyles((theme: Theme) => ({
  tablePaper: {
    // width: '425px',
    borderRadius: '10px',
    padding: 0,
    // padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  arrow: {
    color: 'rgb(128, 128, 128) !important',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

function StyledTooltip(props: TooltipProps) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={classes || { arrow: classes.arrow }}
      arrow
      TransitionComponent={Fade}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
}

export default StyledTooltip;
