import { ANALYTIC_INS, ANALYTIC_INSPREV, ANALYTIC_OOS, ANALYTIC_OOSPREV, ANALYTICS_TYPE_LABELS } from "@oneAppCore/constants/listings";

export const options = [
  {
    label: ANALYTICS_TYPE_LABELS[ANALYTIC_INS],
    value: ANALYTIC_INS,
  },
  {
    label: ANALYTICS_TYPE_LABELS[ANALYTIC_OOS],
    value: ANALYTIC_OOS,
  },
  // {
  //   label: ANALYTICS_TYPE_LABELS[ANALYTIC_INSPREV],
  //   value: ANALYTIC_INSPREV,
  // },
  // {
  //   label: ANALYTICS_TYPE_LABELS[ANALYTIC_OOSPREV],
  //   value: ANALYTIC_OOSPREV,
  // },
  // {
  //   label: 'Automatic Distributors',
  //   value: 'autoDist',
  // },
  // {
  //   label: 'Western Power Sports',
  //   value: 'wps',
  // },
  // {
  //   label: 'Tucker Powersports',
  //   value: 'tucker',
  // },
  // {
  //   label: 'Monarch Products Inc',
  //   value: 'monarch',
  // },
  // {
  //   label: 'Arclite Optics',
  //   value: 'arclite',
  // },
];
