import { memo } from 'react';

import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Select from 'react-select/async';

import type { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

function AsyncSelect(props: Props) {
  const classes = useStyles();

  const {
    containerClass,
    disabled,
    label,
    labelClass,
    loadOptions,
    id,
    menuPortalTarget,
    ...rest
  } = props;

  const emptyTarget = !menuPortalTarget;
  const noTarget = menuPortalTarget && menuPortalTarget === 'none';

  let portalTargetOpts = {};
  if (emptyTarget) {
    portalTargetOpts = { menuPortalTarget: document.body };
  } else if (noTarget) {
    portalTargetOpts = {};
  } else {
    portalTargetOpts = { menuPortalTarget };
  }
  return (
    <div className={containerClass || classes.selectContainer} id={`${id}-div`} style={{maxHeight: '400px'}}>
      {label && (
        <Typography
          variant="caption"
          component="span"
          className={labelClass || classes.label}
        >
          {`${label}${props.required ? '*' : ''}`}
        </Typography>
      )}
      <Select
        {...rest}
        {...portalTargetOpts}
        isDisabled={disabled}
        loadOptions={loadOptions}
        inputId={id}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(-180deg)',
            transitionDuration: '0.3s',
            transitionProperty: 'transform',
          }),
          menu: (provided) => ({
            ...provided,
            maxHeight: '300px',
            overflowY: 'auto',
            zIndex: 10000,
          }),
        }}
      />
    </div>
  );
}

export default memo(AsyncSelect);
