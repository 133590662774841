import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';

import { searchOptionsAtom } from './atoms';
import { searchOptionsUrlSelector } from './selectors';

export function useState() {
  return useRecoilState(searchOptionsAtom);
}

export function useValues() {
  return useRecoilValue(searchOptionsAtom);
}

export function resetState() {
  return useResetRecoilState(searchOptionsAtom);
}

export function useUrl() {
  return useRecoilValue(searchOptionsUrlSelector);
}
