import { useMemo } from 'react';
import { Grid, Paper, Typography, Button, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

import useUsers from '@src/hooks/swr/useUsers';
import UsersApi from '@oneAppCore/services/apis/UsersApi';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  subTitle: {
    color: theme.palette.grey[500],
    fontWeight: 500,
  },
  tableMargin: {
    marginBottom: '10px',
  },
  tableMarginUnset: {
    marginBottom: 'unset',
  },
  rowCategoryHeader: {
    width: '30%',
  },
}));

function UserExistance({ user: paramUser, resetUser }) {
  const classes = useStyles();
  const { data: users = [] } = useUsers();

  const { enqueueSnackbar } = useSnackbar();


  const userList = useMemo(
    () =>
      users.map((usr) => ({ id: usr.id, name: `${usr.firstName} ${usr.lastName}` })),
    [users],
  );

  const deleteUser = async () => {
    try {
      const { id } = await UsersApi.delete(paramUser.id);
      enqueueSnackbar(`${(userList.find((usr) => usr.id === id))?.name} deleted`, { variant: 'success' });
      resetUser();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }

  const returnUser = async () => {
    try {
      const { id } = await UsersApi.put(paramUser.id, { deletedAt: null, deletedBy: null });

      enqueueSnackbar(`${(userList.find((usr) => usr.id === id))?.name} returned`, { variant: 'success' });
      resetUser();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }

  const dataTable = paramUser.deletedBy ? (
    <>
      <Table
        className={classes.tableMarginUnset}
      >
        <TableHead>
          <TableRow>
            <TableCell align={"left"}>
              Deleted By
            </TableCell>
            <TableCell align={"center"}>
              Deleted At
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='left'>
              {users.length ? (userList.find((usr) => usr.id === paramUser.deletedBy))?.name : `User with id ${paramUser.deletedBy}`}
            </TableCell>
            <TableCell align='center'>
              {dayjs(paramUser.deletedAt).format('MM-DD-YY hh:mm a')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  ) : (<></>);

  const controls = paramUser.deletedBy ? (
    <>
      <Button
        style={{ width: '100%' }}
        color='primary'
        variant='contained'
        onClick={returnUser}
      >Restore User</Button>
    </>
  ) : (
    <>
      <Button
        style={{ width: '100%' }}
        color='secondary'
        variant='contained'
        onClick={deleteUser}
      >Delete User</Button>
    </>

  );

  return (
    <Paper elevation={1} className={classes.root}>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item container justifyContent="center" xs={12}>
          <Typography variant="h5">User Controls</Typography>
        </Grid>
        <Grid item container xs={12}>
          {dataTable}
        </Grid>
        <Grid item container xs={12} justifyContent='center'>
          <Grid item xs={12}/*  sm={5} md={4} lg={2} xl={1} spacing={2} */ justifyContent='center' direction="row">
            {controls}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default UserExistance;
