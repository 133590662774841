export const borderRadius = 10;

export const boxShadowConfig = `0px 4px 24px 0px`;

export const primary = {
  main: '#389ADE',
  light: '#CEE6F7',
  dark: '#2C7BB1',
  contrastText: '#FFF',
  transparent: {
    main: '#389ADE15',
  },
};

export const greys = {
  main: '#72717a',
  light: '#f3f2f7',
  border: '#e0e0e1',
};

export default {
  borderRadius,
  boxShadowConfig,
  primary,
  greys,
};
