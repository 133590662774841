import React from 'react';
import {
  Chip,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  supplierIds,
  WPS,
  AD,
  RMATV,
  MOTO,
  DZONE,
  T14,
} from '@oneAppCore/constants/suppliers';
import SupplierOrderButton from '@src/Components/Orders/SupplierOrderButton';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { makeStyles } from '@mui/styles';
import { getColor } from '@src/utils/colors';
import { getStartLetters } from '@oneAppCore/utils/formatters';
import { getItemImageUrl } from '../utils';

const useStyles = makeStyles(() => ({
  image: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
  leadColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unavailable: {
    color: getColor('red', 'main'),
  },
  available: {
    color: getColor('green', 'main'),
  },
  notLowestPrice: {
    color: getColor('red', 'main'),
  },
  lowestPrice: {
    color: getColor('green', 'main'),
  },
  empty: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
}));


function OrderItemsRows({
  row,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  totals,
  calculatedResponse,
}) {
  const { orderItems } = row;
  const classes = useStyles();
  let available = true;
  if (!orderItems[0].allSuppliersArray.length) {

    return (
      <TableRow key={orderItems[0].id}>
        <TableCell className={classes.leadColumn}>
          <img
            className={classes.image}
            src={getItemImageUrl(orderItems[0])}
          />
          <Grid></Grid>
        </TableCell>
        <TableCell>
          <Grid>
            <b>Supplier: </b>
            NO SUPPLIER
          </Grid>
          <Grid>
            <b>SKU: </b>
            NO SUPPLIER
          </Grid>
          <Grid>
            <b>Qty: </b>
            NO SUPPLIER
          </Grid>
        </TableCell>
        <TableCell>
          <Grid>
            <b>Cost: </b>
            {orderItems[0].supplierSubTotal > 0
              ? formatCentsToDollars(orderItems[0].supplierSubTotal)
              : 'NO SUPPLIER'}
          </Grid>
          <Grid>
            <b>Ship: </b>
            {row.supplierShippingCost > 0
              ? formatCentsToDollars(row.supplierShippingCost)
              : orderItems[0].supplierShippingCost > 0
                ? formatCentsToDollars(orderItems[0].supplierShippingCost)
                : 'NO SUPPLIER'}
          </Grid>
          <Grid>
            <b>Total: </b>
            {row.supplierShippingCost > 0 && orderItems[0].supplierSubTotal > 0
              ? formatCentsToDollars(
                row.supplierShippingCost + orderItems[0].supplierSubTotal,
              )
              : orderItems[0].supplierShippingCost > 0 &&
                orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(
                  orderItems[0].supplierShippingCost +
                  orderItems[0].supplierSubTotal,
                )
                : 'NO SUPPLIER'}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  if (
    (row.store === 'dzone' || row.store === 'ads') &&
    (row.orderStatus === 'UNASSIGNED' || row.orderStatus === 'ISSUE')
  ) {
    if (orderItems.length === 1) {

      const { allSuppliersArray } = orderItems[0];
      const totalsArray = totals
        .filter((e) => e?.total !== null && e?.total !== 0)
        .map((e) => e.total);
      return allSuppliersArray[0].supplierId.map((id, index) => {
        const supplierTotalObj = totals.find(
          (e) => e.supplier === allSuppliersArray[0].name[index],
        );
        available = supplierTotalObj?.available;

        const supplierTotal = supplierTotalObj?.total;

        return (
          <TableRow key={id}>
            <TableCell className={classes.leadColumn}>
              <SupplierOrderButton
                row={row}
                supplier={allSuppliersArray[0].name[index]}
                isLoading={isLoading}
                supplierRadioSelection={supplierRadioSelection}
                onRadioChange={onRadioChange}
                available={available}
              />
              {allSuppliersArray[0].name[index] === AD || allSuppliersArray[0].name[index] === T14 ? (
                <Grid>
                  <Chip
                    style={{
                      color: supplierTotalObj?.calculatedResponse
                        ? getColor('purple', 'main')
                        : '#ffffff',
                      width: 'fit-content',
                      height: '100%',
                    }}
                    label={supplierTotalObj?.calculatedResponse ? 'Calculated' : 'Awaiting'}
                  />
                </Grid>
              ) : null}
            </TableCell>
            <TableCell>
              <Grid>
                <b>Supplier: </b>
                {getStartLetters(allSuppliersArray[0].name[index], { caps: true })}
              </Grid>
              <Grid>
                <b>SKU: </b>
                {orderItems[0].sku}
              </Grid>
              <Grid
                className={
                  calculatedResponse && (allSuppliersArray[0].name[index] === AD || allSuppliersArray[0].name[index] === T14) && supplierTotalObj?.available
                    ? classes.available
                    : calculatedResponse && (allSuppliersArray[0].name[index] === AD || allSuppliersArray[0].name[index] === T14) && !supplierTotalObj?.available
                      ? classes.unavailable
                      : orderItems[0].supplierName === allSuppliersArray[0].name[index]
                        ? classes.available
                        : orderItems[0].quantity > allSuppliersArray[0].available[index]
                          ? classes.unavailable
                          : classes.available}
              >
                <b>Qty: </b>
                {orderItems[0].quantity}
                {supplierTotalObj?.calculatedResponse && (allSuppliersArray[0].name[index] === AD || allSuppliersArray[0].name[index] === T14) ? null : (
                  <>
                    <b> Avail: </b>
                    {orderItems[0].supplierName === allSuppliersArray[0].name[index]
                      ? allSuppliersArray[0].available[index] +
                      orderItems[0].quantity
                      : allSuppliersArray[0].available[index]}
                  </>
                )}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid>
                {calculatedResponse && allSuppliersArray[0].name[index] === AD ? null : (
                  <>
                    <b>Cost: </b>
                    {formatCentsToDollars(supplierTotalObj?.totalItemCost)}
                  </>
                )}
              </Grid>
              <Grid>
                {calculatedResponse && allSuppliersArray[0].name[index] === AD ? null : (
                  <>
                    <b>Ship: </b>
                    {formatCentsToDollars(supplierTotalObj?.shippingCost)}
                  </>
                )}
              </Grid>
              <Grid
                className={
                  !supplierTotal
                    ? classes.notLowestPrice
                    : supplierTotal <= Math.min(...totalsArray)
                      ? classes.lowestPrice
                      : classes.notLowestPrice
                }
              >
                <b>Total: </b>
                {formatCentsToDollars(supplierTotal)}
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    } else {
      const totalWeight = orderItems.reduce(
        (total, acc) => acc.quantity * acc.allSuppliersArray[0].weight + total,
        0,
      );

      const totalsArray = totals.map((e) =>
        e.calculatedResponse
          ? e.total
          : totalWeight > 5
            ? orderItems.length * e.shippingCost + e.totalItemCost
            : e.total,
      );
      return orderItems[0].allSuppliersArray[0].name.map(
        (supplier, supplierIndex) => {
          const itemsForSupplier = orderItems.filter(
            (item) =>
              item.allSuppliersArray[0].name[supplierIndex] === supplier,
          );
          const supplierTotalObj = totals.find((e) => e.supplier === supplier);
          const totalCost = supplierTotalObj?.calculatedResponse
            ? supplierTotalObj?.total
            : totalWeight > 5
              ? orderItems.length * supplierTotalObj?.shippingCost +
              supplierTotalObj?.totalItemCost
              : supplierTotalObj?.total;
          const totalShippingCost = totalWeight > 5 ? orderItems.length * supplierTotalObj?.shippingCost : supplierTotalObj?.shippingCost;
          return (
            <React.Fragment key={supplierIndex}>
              {itemsForSupplier.map((item, itemIndex) => (
                <TableRow key={itemIndex}>
                  {
                    (available =
                      item.supplierName ===
                        item.allSuppliersArray[0].name[supplierIndex]
                        ? available
                        : item.quantity >
                          item.allSuppliersArray[0].available[supplierIndex]
                          ? false
                          : available)
                  }
                  <TableCell className={classes.leadColumn}>
                    <Grid
                      onClick={() => {
                        (supplier !== AD && supplier !== WPS && supplier !== T14) ||
                          !available ||
                          isLoading
                          ? null
                          : onRadioChange(supplier);
                      }}
                    >
                      <img
                        className={classes.image}
                        src={
                          getItemImageUrl(orderItems[itemIndex])
                        }
                      />
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <b>Supplier: </b>
                    {getStartLetters(supplier, { caps: true })}
                    <Grid>
                      <b>SKU: </b>
                      {item.sku}
                    </Grid>
                    <Grid
                      className={
                        supplierTotalObj?.calculatedResponse && (item.allSuppliersArray[0].name[supplierIndex] === AD || item.allSuppliersArray[0].name[supplierIndex] === T14) && supplierTotalObj?.available
                          ? classes.available
                          : supplierTotalObj?.calculatedResponse && (item.allSuppliersArray[0].name[supplierIndex] === AD || item.allSuppliersArray[0].name[supplierIndex] === T14) && !supplierTotalObj?.available
                            ? classes.unavailable
                            : item.supplierName === item.allSuppliersArray[0].name[supplierIndex]
                              ? classes.available
                              : item.quantity > item.allSuppliersArray[0].available[supplierIndex]
                                ? classes.unavailable
                                : classes.available
                      }
                    >
                      <b>Qty: </b>
                      {item.quantity}
                      {supplierTotalObj?.calculatedResponse && (item.allSuppliersArray[0].name[supplierIndex] === AD || item.allSuppliersArray[0].name[supplierIndex] === T14) ? null : (
                        <>
                          <b> Avail: </b>
                          {item.supplierName ===
                            item.allSuppliersArray[0].name[supplierIndex]
                            ? item.allSuppliersArray[0].available[supplierIndex] +
                            item.quantity
                            : item.allSuppliersArray[0].available[supplierIndex]}
                        </>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid>
                      {calculatedResponse && supplier === AD ? null : (
                        <>
                          <b>Cost: </b>
                          : {supplier === T14 && supplierTotalObj?.calculatedResponse && supplierTotalObj?.available
                            ? `$${supplierTotalObj?.lineItems?.find((i => i.item_id === item.allSuppliersArray[0].supplierItemNo[supplierIndex]))?.line_total}` :
                            formatCentsToDollars(item.allSuppliersArray[0].cost[supplierIndex])}
                        </>
                      )}

                    </Grid>

                    <Grid>

                    </Grid>
                    <Grid>

                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell className={classes.leadColumn}>
                  <SupplierOrderButton
                    row={row}
                    supplier={supplier}
                    isLoading={isLoading}
                    supplierRadioSelection={supplierRadioSelection}
                    onRadioChange={onRadioChange}
                    available={
                      (supplierTotalObj?.supplier === AD || supplierTotalObj?.supplier === T14 || supplierTotalObj.supplier === WPS) ?
                        supplierTotalObj?.available
                        : available
                    }
                  />
                  {supplier === AD || supplier === T14 ? (
                    <Grid>
                      <Chip
                        style={{
                          color: supplierTotalObj?.calculatedResponse
                            ? getColor('purple', 'main')
                            : '#ffffff',
                          width: 'fit-content',
                          height: '100%',
                        }}
                        label={supplierTotalObj?.calculatedResponse ? 'Calculated' : 'Awaiting'}
                      />
                    </Grid>
                  ) : null}
                </TableCell>
                <TableCell>
                  <Grid
                    className={
                      totalCost <= Math.min(...totalsArray)
                        ? classes.lowestPrice
                        : classes.notLowestPrice
                    }
                  >
                    <Grid>
                      {getStartLetters(supplier, { caps: true })}
                    </Grid>
                    <Grid>
                      {calculatedResponse && supplier === AD ? null : (
                        <>
                          <b>Shipping: </b>
                          {formatCentsToDollars(totalShippingCost)}
                        </>
                      )}
                    </Grid>
                    <Grid>
                      <b>Total: </b>
                      {formatCentsToDollars(totalCost)}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid className={classes.empty}></Grid>
                </TableCell>
              </TableRow>
              <TableRow></TableRow>
            </React.Fragment>
          );
        },
      );
    }
  } else {
    // orders that are placed or tracked.
    if (orderItems.length === 1) {
      const { allSuppliersArray } = orderItems[0];

      return (
        <TableRow key={orderItems[0].id}>
          <TableCell className={classes.leadColumn}>

            <img
              className={classes.image}
              src={getItemImageUrl(orderItems[0])}
            />

            <Grid></Grid>
          </TableCell>
          <TableCell>
            <Grid>
              <b>Supplier: </b>
              {getStartLetters(orderItems[0].supplierName, { caps: true })}
            </Grid>
            <Grid>
              <b>SKU: </b>
              {orderItems[0].sku}
            </Grid>
            <Grid>
              <b>Qty: </b>
              {orderItems[0].quantity}
            </Grid>
          </TableCell>
          <TableCell>
            <Grid>
              <b>Cost: </b>
              {orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(orderItems[0].supplierSubTotal)
                : allSuppliersArray[0].name.map((e, i) => {
                  if (e === orderItems[0].supplierName) {
                    return formatCentsToDollars(allSuppliersArray[0].cost[i]);
                  }
                })}
            </Grid>
            <Grid>
              <b>Ship: </b>
              {row.supplierShippingCost > 0
                ? formatCentsToDollars(row.supplierShippingCost)
                : orderItems[0].supplierShippingCost > 0
                  ? formatCentsToDollars(orderItems[0].supplierShippingCost)
                  : allSuppliersArray[0].name.map((e, i) => {
                    if (e === orderItems[0].supplierName) {
                      if (allSuppliersArray[0].estimatedShipping[i] > 0) {
                        return formatCentsToDollars(
                          allSuppliersArray[0].estimatedShipping[i],
                        );
                      } else {
                        return formatCentsToDollars(
                          allSuppliersArray[0].shippingCost[i],
                        );
                      }
                    }
                  })}
            </Grid>
            <Grid>
              <b>Total: </b>
              {row.supplierShippingCost > 0 &&
                orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(
                  row.supplierShippingCost + orderItems[0].supplierSubTotal,
                )
                : orderItems[0].supplierShippingCost > 0 &&
                  orderItems[0].supplierSubTotal > 0
                  ? formatCentsToDollars(
                    orderItems[0].supplierShippingCost +
                    orderItems[0].supplierSubTotal,
                  )
                  : orderItems[0].allSuppliersArray[0].name.map((e, i) => {
                    if (e === orderItems[0].supplierName) {
                      if (
                        orderItems[0].allSuppliersArray[0].estimatedShipping[
                        i
                        ] > 0 &&
                        orderItems[0].allSuppliersArray[0].cost[i] > 0
                      ) {
                        return formatCentsToDollars(
                          orderItems[0].allSuppliersArray[0].estimatedShipping[
                          i
                          ] + orderItems[0].allSuppliersArray[0].cost[i],
                        );
                      } else if (
                        orderItems[0].allSuppliersArray[0].shippingCost[i] >
                        0 &&
                        orderItems[0].allSuppliersArray[0].cost[i] > 0
                      ) {
                        return formatCentsToDollars(
                          orderItems[0].allSuppliersArray[0].shippingCost[i] +
                          orderItems[0].allSuppliersArray[0].cost[i],
                        );
                      }
                    };
                  })}
            </Grid>
          </TableCell>
        </TableRow>
      );
    } else {
      const itemsCost = orderItems.every((item) => item.supplierSubTotal > 0)
        ? orderItems.reduce((total, item) => total + item.supplierSubTotal, 0)
        : orderItems.reduce((total, item) => {
          const supplierCost = item.allSuppliersArray[0].name.reduce(
            (sum, e, i) => {
              return e === orderItems[0].supplierName
                ? sum + item.allSuppliersArray[0].cost[i] * item.quantity
                : sum;
            },
            0,
          );
          return total + supplierCost;
        }, 0);
      const shippingCost =
        row.supplierShippingCost > 0
          ? row.supplierShippingCost
          : orderItems.every((item) => item.supplierShippingCost > 0)
            ? orderItems.reduce(
              (total, item) => total + item.supplierShippingCost,
              0,
            )
            : orderItems.every(
              (item, index) =>
                item.allSuppliersArray[0].estimatedShipping[index] > 0,
            )
              ? orderItems.reduce((total, item) => {
                const estimatedShippingCost =
                  item.allSuppliersArray[0].name.reduce((sum, name, i) => {
                    return name === orderItems[0].supplierName
                      ? sum + item.allSuppliersArray[0].estimatedShipping[i]
                      : sum;
                  }, 0);
                return total + estimatedShippingCost;
              }, 0)
              : orderItems.every(
                (item, index) =>
                  item.allSuppliersArray[0].shippingCost[index] > 0,
              )
                ? orderItems.reduce((total, item) => {
                  const shippingCost = item.allSuppliersArray[0].name.reduce(
                    (sum, name, i) => {
                      return name === orderItems[0].supplierName
                        ? sum + item.allSuppliersArray[0].shippingCost[i]
                        : sum;
                    },
                    0,
                  );
                  return total + shippingCost;
                }, 0)
                : 0;
      const totalCost = itemsCost + shippingCost;
      return (
        <React.Fragment key={row.id}>
          {orderItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.leadColumn}>
                <Grid>
                  <img
                    className={classes.image}
                    src={getItemImageUrl(item)}
                  />
                </Grid>
                <Grid></Grid>
              </TableCell>
              <TableCell>
                <b>Supplier: </b>
                {getStartLetters(item.supplierName, { caps: true })}
                <Grid>
                  <b>SKU: </b>
                  {item.sku}
                </Grid>
                <Grid>
                  <b>Qty: </b>
                  {item.quantity}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid>
                  <b>Cost: </b>
                  {item.supplierSubTotal > 0
                    ? formatCentsToDollars(item.supplierSubTotal)
                    : item.allSuppliersArray[0].name.map((e, i) => {
                      if (e === item.supplierName) {
                        return formatCentsToDollars(
                          item.allSuppliersArray[0].cost[i],
                        );
                      }
                    })}
                </Grid>
                <Grid>
                  {/* <b>Ship: </b>
                  {item.supplierShippingCost > 0
                    ? formatCentsToDollars(item.supplierShippingCost)
                    : item.allSuppliersArray[0].name.map((e, i) => {
                      if (e === item.supplierName) {
                        if (
                          item.allSuppliersArray[0].estimatedShipping[i] > 0
                        ) {
                          return formatCentsToDollars(
                            item.allSuppliersArray[0].estimatedShipping[i],
                          );
                        } else if (
                          item.allSuppliersArray[0].shippingCost[i] > 0
                        )
                          return formatCentsToDollars(
                            item.allSuppliersArray[0].shippingCost[i],
                          );
                      }
                    })} */}
                </Grid>
                <Grid>

                </Grid>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell className={classes.leadColumn}>
              <Grid className={classes.empty}></Grid>
            </TableCell>
            <TableCell>
              <Grid>
                <Grid>
                  <b>Shipping: </b>
                  {itemsCost > 0 && shippingCost > 0
                    ? formatCentsToDollars(shippingCost)
                    : 'N/A'}
                </Grid>
                <Grid>
                  <b>Total: </b>
                  {itemsCost > 0 && shippingCost > 0
                    ? formatCentsToDollars(totalCost)
                    : 'N/A'}
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid></Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
  }
}
export default function OrderDrawerItemsTable({
  row,
  signatureChecked,
  handleSignatureRequiredCheckboxChange,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  totals,
  calculatedResponse,
}) {
  return (
    <>
      {(row.store === 'dzone' || row.store === 'ads') &&
        (row.orderStatus === 'UNASSIGNED' || row.orderStatus === 'ISSUE') &&
        row.orderItems &&
        row.orderItems[0]?.allSuppliersArray.length >= 1 ? (
        <FormControlLabel
          control={
            <Checkbox
              title="Signature Required"
              checked={signatureChecked}
              onChange={handleSignatureRequiredCheckboxChange}
            />
          }
          label="Signature Required"
        />
      ) : null}
      <TableContainer style={{ marginBottom: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Supplier Cost</TableCell>
            </TableRow>
          </TableHead>
          <OrderItemsRows
            row={row}
            isLoading={isLoading}
            onRadioChange={onRadioChange}
            supplierRadioSelection={supplierRadioSelection}
            totals={totals}
            calculatedResponse={calculatedResponse}
          />
        </Table>
      </TableContainer>
    </>
  );
}
