import { useRecoilValue, useRecoilState } from 'recoil';

import { reportOptionsAtom } from './atoms';

import type { ReportOptionsAtom } from './types';

export function useReportOptionsState() {
  return useRecoilState<ReportOptionsAtom>(reportOptionsAtom);
}

export function useReportOptions() {
  return useRecoilValue<ReportOptionsAtom>(reportOptionsAtom);
}
