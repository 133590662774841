import clsx from 'clsx';
import { options } from './constants';

import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import recoilSearch from '../hooks/useSearchOptions';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundColor: `${theme.palette.purple.main} !important`,
    color: `${theme.palette.purple.contrastText} !important`,
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundColor: `${theme.palette.darkBlue.light} !important`,
      color: `${theme.palette.darkBlue.contrastText} !important`,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 10,
      marginTop: 10,
      minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {},
    minWidth: 'max-content',
    overflow: 'wrap',
    paddingLeft: '16px',
  },
  toggleGroup: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
}));

function ChannelOptions() {
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const classes = useStyles();

  const channelChange = (channelId?: number) => {
    setSearchOptions((previous) => ({
      ...previous,
      channel: channelId ? channelId : null,
    }));
  };

  return (
    <Grid item container alignItems="center" className={classes.responsiveUi}>
      <ToggleButtonGroup size="small" className={classes.toggleGroup}>
        {options.map((option) => {
          const isSelected = (!searchOptions.channel && option.value === null) || searchOptions.channel === option.value;
          return (
            <ToggleButton
              key={option.name}
              style={{
                fontSize: '10px',
                fontWeight: 600,
              }}
              selected={isSelected}
              value={option.value}
              // color={isSelected ? 'darkPurple' : 'red'}
              classes={{ selected: classes.selectedButton }}
              className={classes.nonSelectedButton}
              // className={clsx(
              //   {
              //     [classes.selectedButton]:
              //       (!searchOptions.channel && option.value === null) ||
              //       searchOptions.channel === option.value,
              //   },
              //   {
              //     [classes.nonSelectedButton]:
              //       (searchOptions.channel && option.value === null) ||
              //       searchOptions.channel !== option.value,
              //   },
              // )}
              onClick={() => channelChange(option.value)}
            >
              {option.name}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </Grid>
  );
}

export default ChannelOptions;
