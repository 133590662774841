import { FormEvent } from 'react';

import TextField from '@src/Components/common/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import type { Props } from './types';

function AddLocationDialog({
  open,
  close,
  location,
  setLocation,
  submit,
}: Props) {
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    submit();
  };

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Location</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            id="location"
            label="Location"
            fullWidth
            required
            value={location ?? ''}
            onChange={(e) => setLocation(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddLocationDialog;
