import EditButton from './EditButton';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import { Typography } from '@mui/material';
import _ from 'lodash';

const getSpecificClientObjectKeys = (apiCredentials) => {
  return Object.keys(apiCredentials);
};

const renderKeyMapper = (keys, creds) => {
  return (
    <>
      {keys?.map((key, i) => {
        return (
          <div key={i}>
            {key}: {creds[key].substring(0, 50).replace(/.(?=.{4,}$)/g, '*')}
          </div>
        );
      })}
    </>
  );
};

export const columns: Column[] = [
  {
    keyName: 'storeName',
    title: 'Store',
    sortBy: true,
    align: 'center',
    customComponent: function storeName(row): any {
      return <Typography>{_.startCase(row?.storeName)}</Typography>;
    },
  },
  {
    keyName: 'channelName',
    title: 'Channel',
    sortBy: true,
    align: 'center',
    customComponent: function channelName(row): any {
      return (
        <img
          src={row?.image}
          height="35px"
          width="35px"
          style={{ borderRadius: 5 }}
        />
      );
    },
  },
  {
    keyName: 'apiCredentials',
    title: 'Credentials',
    sortBy: false,
    align: 'left',
    customComponent: function clientInfo(row): any {
      switch (row?.channelId) {
        case 1:
          const amazonKeys = getSpecificClientObjectKeys(row.apiCredentials);
          return renderKeyMapper(amazonKeys, row.apiCredentials);
        case 2:
          const secondKeys = getSpecificClientObjectKeys(row.apiCredentials);
          return renderKeyMapper(secondKeys, row.apiCredentials);
        case 3:
          const theDZoneKeys = getSpecificClientObjectKeys(row.apiCredentials);
          return renderKeyMapper(theDZoneKeys, row.apiCredentials);
        case 4:
          const otherDZoneKeys = getSpecificClientObjectKeys(
            row.apiCredentials,
          );
          return renderKeyMapper(otherDZoneKeys, row.apiCredentials);
        default:
          return 'null';
      }
    },
  },
  {
    keyName: 'edit',
    title: 'Edit',
    sortBy: false,
    align: 'center',
    customComponent: function CustomEdit(row) {
      return <EditButton id={row?.id} key={row?.id} />;
    },
  },
];
