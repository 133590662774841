import BaseApi from '../BaseApi';

import type { OrderStats } from 'types/reportTypes';
import type { ManufacturerSupplier, Supplier, SupplierType } from '@oneAppCore/types/supplierTypes';

import type { InventoryByUPCPayload } from './types';

class SupplierApi extends BaseApi {
  constructor() {
    super();
  }

  getManufacturerSupplier(id: string) {
    return this.getBase<OrderStats>(`/api/v1/manufacturer-suppliers/${id}`);
  }

  updateManufacturerSupplier(id: string, payload: ManufacturerSupplier) {
    return this.putBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers/${id}`,
      payload,
    );
  }

  createManufacturerSupplier(payload: ManufacturerSupplier) {
    return this.postBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers`,
      payload,
    );
  }

  updateInventoryByBarcode(payload: InventoryByUPCPayload) {
    return this.putBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers/barcode`,
      payload,
    );
  }

  createSupplier(payload: Supplier) {
    return this.postBase<Supplier>(
      '/api/v1/suppliers',
      payload
    );
  }

  getSupplierById(id: number) {
    return this.getBase<Supplier>(`/api/v1/suppliers/${id}`);
  }

  editSupplier(id: number, payload: Supplier) {
    return this.putBase<Supplier>(`/api/v1/suppliers/${id}`, payload);
  }

  getSuppliers() {
    return this.getBase<Partial<SupplierType>[]>('/api/v1/suppliers');
  }

  getWpsItemInventory(sku) {
    return this.getBase(`/api/v1/suppliers/wps/${sku}`);
  }
  calculateAutoDistOrder(payload) {
    return this.postBase(`/api/v1/suppliers/auto-dist/calculate`, payload);
  }
  calculateTurn14Order(payload) {
    return this.postBase(`/api/v1/suppliers/turn-14/calculate`, payload);
  }
}

export default SupplierApi;
