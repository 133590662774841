import { useState } from 'react';
import {
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import StoreListingApi from '@oneAppCore/services/apis/StoreListings';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import StyledTooltip from '@src/Components/common/TooltipTable/StyledTooltip';

const useStyles = makeStyles(() => ({
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  }
}));

function RefreshButton({ update }) {

  const [clicked, setClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const handleRefresh = async (e) => {
    e.preventDefault();
    setClicked(true);
    try {
      const refreshListings = await StoreListingApi.refreshChannelListings();
      if (refreshListings) {
        enqueueSnackbar(refreshListings, {
          variant: 'success',
        });
        setClicked(false);
      }
    } catch (error) {
      enqueueSnackbar(`Error Trying To Refresh Listings`, {
        variant: 'error',
      });
      setClicked(false);
    } finally {
      update(e.limit);
    }
  };
  return <>
    <Box marginLeft='10px' width='fit-content' justifyContent='center' alignContent='center'>
      {!clicked && (
        <StyledTooltip
          title={'Refresh Listings On Channels'}>
          <PublishedWithChangesIcon
            color="primary"
            sx={{ fontSize: 'xx-large' }}
            onClick={async (e) => await handleRefresh(e)}
            style={{ cursor: 'pointer' }}
          />
        </StyledTooltip>
      )}
      {clicked && (
        <StyledTooltip title={'Please Wait'} className={classes.rotateIcon} >
          <PublishedWithChangesIcon
            color="secondary"
            sx={{ fontSize: 'xx-large' }}
            style={{ cursor: 'pointer' }}
          />
        </StyledTooltip>
      )}
    </Box>
  </>
};
export default RefreshButton;