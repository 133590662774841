import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

interface InventoryHistory {
  count: number;
  rows: {
    id: number;
    bin: string;
    sku: string;
    manufacturerNumber: string;
    brand: string;
    quantity: number;
    internalInventory: number;
    purchaseOrderNo: number;
    newTotalQuantity: number | null;
    overrideQuantity: boolean;
    locationUpdated: boolean;
    scannedAt: Date;
    scannedBy: string;
    vq?: number;
    itemsPurchased?: number;
    itemsReceived?: number;
  }[];
}

export default function useInventoryHistory(page?: number, limit?: number) {
  return useSWR<InventoryHistory>(
    `/api/v1/manufacturer-supplier-records/search?page=${page}&limit=${limit}`,
    Api.get,
    {
      initialData: {
        count: 0,
        rows: [],
      },
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );
}
