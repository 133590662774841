import useOrderProfitOperations from './hooks/useOrderProfitOperations';

import dayjs from 'dayjs';

import { makeStyles } from '@mui/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';

import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import { orderProfitColors } from '@src/utils/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

function OrderProfitOperations() {
  const classes = useStyles();
  const { data } = useOrderProfitOperations();
  const { rows, channels, suppliers } = data || {};
  const orderCountRows = rows?.filter((row) => row && row?.orderCount);
  const newRows = rows
    ?.filter((row) => row && !row?.orderCount)
    .map((row) => {
      const x = orderCountRows
        .filter((i) => i)
        .find((bog) => {
          const checkQ =
            bog.quantity === row.quantity ||
            (bog.quantity > 1 && row.quantity > 1);
          const checkC =
            bog.itemCount ===
            (typeof row.itemCount === 'string'
              ? parseInt(row.itemCount)
              : row.itemCount) ||
            (bog.itemCount > 1 &&
              (typeof row.itemCount === 'string'
                ? parseInt(row.itemCount)
                : row.itemCount) > 1);
          return (
            bog.supplier === row.supplier &&
            bog.channel === row.channel &&
            checkQ &&
            checkC
          );
        });
      return {
        ...row,
        orderCount: x?.orderCount,
      };
    });
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell>Order #</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Channel</TableCell>
            <TableCell>Order-Item Configuration</TableCell>
            <TableCell>Supplier SubTotal</TableCell>
            <TableCell>Supplier Shipping</TableCell>
            <TableCell>Supplier Tax</TableCell>
            <TableCell>Channel Fees</TableCell>
            <TableCell>Order Total</TableCell>
            <TableCell>Order Count</TableCell>
            <TableCell>Profit</TableCell>
            <TableCell>Percent Profit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newRows
            ?.sort((a, b) => a.channel - b.channel || a.supplier - b.supplier)
            .map((row, index) => {
              const synced = dayjs(row.orderNo).isAfter(
                dayjs().subtract(21, 'minutes'),
              );
              const colorsAndTints: any[] = [
                { tint: 'main', color: 'dark_red' },
                { tint: 'main', color: 'red' },
                { tint: 'main', color: 'orange' },
                { tint: 'main', color: 'green' },
                { tint: 'main', color: 'dark_green' },
              ];
              const colorsAndTintsRows: any[] = [
                { tint: 'lightest', color: 'red' },
                { tint: 'lightest', color: 'red' },
                { tint: 'lightest', color: 'orange' },
                { tint: 'lightest', color: 'green' },
                { tint: 'lightest', color: 'green' },
              ];
              const channelFee =
                row.orderChannelFees > 0
                  ? row.orderChannelFees
                  : row.channelFee > 0
                    ? row.channelFee
                    : 0.12 * row.orderTotal;
              const orderProfit =
                row.orderShippingCost > 0
                  ? row.orderTotal -
                  row.supplierSubTotal -
                  row.orderShippingCost -
                  row.supplierTax -
                  channelFee
                  : row.orderTotal -
                  row.supplierSubTotal -
                  row.supplierShippingCost -
                  row.supplierTax -
                  channelFee;
              const backgroundColor = orderProfitColors(
                (orderProfit / row.orderTotal) * 100,
                [0, 2, 10, 20],
                colorsAndTints,
              );

              const rowBackground = orderProfitColors(
                (orderProfit / row.orderTotal) * 100,
                [0, 2, 10, 20],
                colorsAndTintsRows,
              );
              return (
                <TableRow
                  key={row.orderNo}
                  style={{
                    backgroundColor: `${rowBackground}`,
                  }}
                >
                  <TableCell style={{ paddingTop: 50 }}>{index + 1}</TableCell>
                  <TableCell>{row.orderNo}</TableCell>
                  <TableCell>
                    {suppliers.find((supplier) => supplier.id == row.supplier)
                      ?.name || row.supplier}
                  </TableCell>
                  <TableCell>
                    {channels.find((channel) => channel.id == row.channel)
                      ?.name || row.channel}
                  </TableCell>
                  <TableCell>
                    {row.quantity > 1 ? 'Multiple Qty' : 'Single Qty'},{' '}
                    {row.itemCount > 1 ? 'Multiple Item' : 'Single Item'}
                  </TableCell>
                  <TableCell>
                    {formatCentsToDollars(Math.floor(row.supplierSubTotal))}
                  </TableCell>
                  <TableCell>
                    {row.orderShippingCost > 0
                      ? formatCentsToDollars(row.orderShippingCost)
                      : formatCentsToDollars(
                        Math.floor(row.supplierShippingCost),
                      )}
                  </TableCell>
                  <TableCell>
                    {formatCentsToDollars(Math.floor(row.supplierTax))}
                  </TableCell>
                  <TableCell>
                    {row.orderChannelFees > 0
                      ? formatCentsToDollars(row.orderChannelFees)
                      : formatCentsToDollars(Math.floor(row.channelFee))}
                  </TableCell>
                  <TableCell>
                    {formatCentsToDollars(Math.floor(row.orderTotal))}
                  </TableCell>
                  <TableCell>{row.orderCount}</TableCell>
                  {/* <TableCell>${formatCentsToDollars(Math.floor(row.profit))}</TableCell> */}
                  <TableCell>
                    {formatCentsToDollars(Math.floor(orderProfit))}
                  </TableCell>
                  <TableCell>
                    {/* {Math.floor((row.profit / row.orderTotal) * 100)}%
                    <br /> */}
                    <Grid
                      item
                      xs={5}
                      style={{
                        backgroundColor,
                        padding: 10,
                        borderRadius: 10,
                        width: 100,
                        height: 10,
                        marginTop: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {Math.floor((orderProfit / row.orderTotal) * 100)}%
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OrderProfitOperations;
