import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';

import SupplierDrawer from './SupplierDrawer';

const locatorProperties = [
  { label: 'Warehouse', value: 'internalSupplier' },
  { label: 'Dropship', value: 'dropshipSupplier' },
  // { label: 'Warehouse - 3ps', value: 'internalStock' },
  // { label: 'Static Quantity', value: 'hasStaticQuantity' },
  // { label: 'Out of Stock', value: 'outOfStock' },
];

const dateRangeOptions = [
  {
    label: 'Today',
    value: [
      dayjs().format('YYYY-MM-DD'),
      dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ],
  },
  {
    label: 'Yesterday',
    value: [
      dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
      dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ],
  },
  {
    label: '7 Days',
    value: [
      dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ],
  },
  {
    label: '30 Days',
    value: [
      dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ],
  },
  {
    label: '60 Days',
    value: [
      dayjs().subtract(60, 'days').format('YYYY-MM-DD'),
      dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ],
  },
];

const defaultDateFilter = `${dayjs().startOf('day').subtract(30, 'days').format('YYYY-MM-DD')}--${dayjs().add(1, 'days').format('YYYY-MM-DD')}`

function SupplierSearch() {
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="/api/v1/suppliers/search"
        columns={columns}
        selectTypes={['createSupplier']}
        CreateFormComponent={SupplierDrawer}
        constantFilters={{
          purchasedAt: defaultDateFilter,
        }}
        filters={[
          {
            type: 'date range select',
            name: 'Purchased At',
            keyName: 'purchasedAt',
            properties: dateRangeOptions.map(({ label, value }) => ({ label, value: value.filter((a) => a).join('--') })),
          },
          {
            type: 'selector',
            name: 'Internal Supplier',
            keyName: 'searchFilter',
            properties: locatorProperties,
          },
        ]}
      />
    </Grid>
  );
}

export default SupplierSearch;
