import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Props } from './types';


const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

export default function BackdropDisplay({ open }: Props) {
  const classes = useStyles();
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
