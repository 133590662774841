import { Grid, Typography, TextField } from '@mui/material';

export const formFields = [
  'brand',
  'brandAliases',
  'discounts',
  'isMap',
  'images',
];

export const complexFormFields = [
  {
    name: 'brand',
    label: 'Brand',
    type: 'text',
  },
  {
    name: 'brandAliases',
    label: 'Aliases',
    type: 'list',
  },
];
