import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';

import { magikTimeState } from './atoms';

export function useState() {
  return useRecoilState(magikTimeState);
}

export function useValues() {
  return useRecoilValue(magikTimeState);
}
