import recoilReportOptions from '../../hooks/useReportOptions';

import ChannelSelect from '@src/Components/common/Selects/ChannelSelect';
import Grid from '@mui/material/Grid';

function NewerThanSelect() {
  const [, setReportOptions] = recoilReportOptions.useReportOptionsState();

  const updateReport = (channelId: number | null) => {
    setReportOptions((previous) => ({
      ...previous,
      channelId,
    }));
  };

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <ChannelSelect
        isClearable
        onChange={(option) => updateReport(option?.value ?? null)}
      />
    </Grid>
  );
}

export default NewerThanSelect;
