import {
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from '@mui/material';
import FontAwesome from '@src/Components/common/FontAwesome';
import { useState } from 'react';
import FitmentApi from '@oneAppCore/services/apis/Fitment';
import { useSnackbar } from 'notistack';
import { YearMakeModel } from './types';
import AsyncSelect from '@src/Components/common/Selects/AsyncSelect';
import ImportData from '@src/Components/ImportData';
import { FITMENT_IMPORT } from '@oneAppCore/constants/import';

type HandleCSVUpload = (data: any) => any;

const AddFitmentRow = ({
  variationId,
  makes,
  models,
  mutate,
}: {
  variationId: number;
  makes: any[];
  models: any[];
  mutate: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentYear = new Date().getFullYear();
  const [test, setTest] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [manual, setManual] = useState(true);
  const [disabledMake, setDisabledMake] = useState(true);
  const [newymm, setNewymm] = useState<YearMakeModel>({
    year: [],
    make: '',
    model: '',
  });

  const filterMakes = (makes) => {
    return makes.filter(
      (e) => e.year.filter((el) => newymm.year.includes(String(el))).length,
    );
  };

  const filterModels = async (searchFilter: string) => {
    if (!Array.isArray(models)) {
      return [];
    }

    if (searchFilter.length < 2) {
      return [];
    }

    const filteredModels = models.filter(
      (e) =>
        newymm.make === e.make &&
        e.model.toLowerCase().includes(searchFilter.toLowerCase()),
    );

    setOptions(filteredModels);
    return filteredModels;
  };

  const addNewFitment = async (newymm) => {
    try {
      if (newymm.year === '' || newymm.make === '' || newymm.model === '') {
        return;
      } else {
        const createFitment = await FitmentApi.createFitment({
          fitment: newymm,
          variationId,
        });
        if (createFitment?.status === 'success') {
          enqueueSnackbar('Success!', { variant: 'success' });
          setNewymm({ year: [], make: '', model: '' });
          setOptions([]);
          mutate();
        }
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setNewymm({ year: [], make: '', model: '' });
      setOptions([]);
    }
  };

  const handleChangeModel = (selectedOption) => {
    if (selectedOption) {
      const selectedModelId = selectedOption.id;
      setNewymm((ymm) => ({
        ...ymm,
        model: selectedModelId,
      }));
    } else {
      setNewymm((ymm) => ({
        ...ymm,
        model: '',
      }));
    }
  };

  const years = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 100))
      .fill('')
      .map((v, idx) => now - idx) as Array<number>;
  };

  const importOpts = {
    customValues: { variationId },
    hideTable: true,
    tableName: FITMENT_IMPORT,
  };

  const isYearSelected = (year) => newymm.year.includes(year.toString());

  return (
    <TableRow style={{ justifyContent: 'space-between' }}>
      {manual === false ? (
        <>
          <TableCell colSpan={2}>
            {
              // ! value={variationId} was added for the Fitment_Search_import custom import tool feature
            }
            <ImportData title="Import" options={importOpts} />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => setManual(true)}
            >
              Enter Fitment Manually
            </Button>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell style={{ width: '25%' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  lineHeight: 'normal',
                  padding: '0 8px',
                }}
                id="demo-select-small-label"
              >
                Year(s)
              </InputLabel>
              <Select
                style={{ width: '100%' }}
                value={newymm.year}
                multiple
                onChange={(e: any) =>
                  setNewymm((ymm) => ({
                    ...ymm,
                    year: e.target.value,
                  }))
                }
              >
                {years().map((year) => {
                  return (
                    <MenuItem
                      key={year}
                      value={year.toString()}
                      style={{
                        backgroundColor: isYearSelected(year)
                          ? 'lightblue'
                          : 'inherit',
                        // fontWeight: isYearSelected(year) ? 'bold' : 'normal',
                      }}
                    >
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ width: '25%' }}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  lineHeight: 'normal',
                  padding: '0 8px',
                }}
                id="demo-select-small-label"
              >
                Make
              </InputLabel>
              <Select
                style={{ width: '100%' }}
                disabled={newymm?.year?.length < 1}
                value={newymm.make}
                onChange={(e: any) => {
                  setNewymm((ymm) => ({
                    ...ymm,
                    make: e.target.value,
                  }));
                }}
              >
                {filterMakes(makes).map(({ make, id }) => {
                  return (
                    <MenuItem key={id} value={id}>
                      {make}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ width: '15%' }}>
            <AsyncSelect
              placeholder="Model"
              disabled={newymm?.make === ''}
              id="model"
              loadOptions={filterModels}
              value={options.find((option) => option.value === newymm.model)}
              onChange={handleChangeModel}
              getOptionLabel={(option) => option.model}
            />
          </TableCell>
          <TableCell style={{ width: '15%' }}>
            <FontAwesome
              name="check"
              type="fas"
              form="fa"
              onClick={() => {
                addNewFitment(newymm);
              }}
              style={{ cursor: 'pointer', color: '#50C878' }}
            />
            <Button
              style={{ marginLeft: 10 }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => setManual(false)}
            >
              Import
            </Button>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default AddFitmentRow;
