import type { CreateUser } from '@oneAppCore/types/userTypes';

export function validate(data: CreateUser) {
  const errors: any = {};
  if (
    (data.password || data.confirmPassword) &&
    data.password !== data.confirmPassword
  ) {
    errors.password = true;
  }
  return errors;
}
