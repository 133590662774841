export const x = {};
export const requiredAddressFields = [
  'name',
  'street1',
  'city',
  'state',
  'city',
  'postalCode',
  'country',
];

export const simpleFields = [
  {key: 'carrierCode', label: 'Carrier', value: 'stamps_com'},
  {key: 'serviceCode', label: 'Service', value: 'usps_first_class_mail'},
  {key: 'packageCode', label: 'Package', value: 'package'},
];
export const objectFields = [
  { key: 'shipFrom', label: 'Ship From', value: 'z'},
  { key: 'shipTo', label: 'Ship To', value: 'x'},
];