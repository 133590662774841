import {
  TableRow,
  TableCell,
  Typography,
  Button,
  IconButton,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import useVariationData from '@src/hooks/swr/Inventory/useVariationData';
import AsyncSelect from '@src/Components/common/Selects/AsyncSelect';
import { Column } from '@src/Components/common/containers/SearchView/types';
import { FontAwesome } from '@src/Components/common';
import { useEffect, useState, FormEvent } from 'react';
import { CreateGroup, Props } from './types';
import { Form, GroupForm } from '../../types';
import { useSnackbar } from 'notistack';
import ProductApi from '@oneAppCore/services/apis/Products';
import ItemGroupsApi from '@oneAppCore/services/apis/ItemGroups';
import useProducts from '@src/hooks/swr/useProducts';

type ProductData = {
  id: number;
  name: string;
};

const AddGroupedProduct = ({
  columns,
  index,
  productIndex,
  removeProduct,
  deleteRow,
  onChange,
  group,
  mutate,
  mutateGroup,
  groups,
}: {
  columns: Column[];
  onChange?: any;
  group?: any;
  groups?: any[];
  index?: any;
  product?: Form;
  row: any;
  mutate: any;
  mutateGroup: any;
  deleteRow?: any;
  productData?: ProductData[];
  productIndex?: number;
  removeProduct?: (type: number) => void;
}) => {
  const [options, setOptions] = useState([]);

  const [form, setForm] = useState<GroupForm>(group);
  const [value, setValue] = useState(null);
  const [vars, setVars] = useState([]);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    productName: '',
  });
  const [newProduct, setNewProduct] = useState<CreateGroup>({
    variation: '',
    quantity: 0,
  });

  const id = form.id;
  const { data: variations, mutate: mutateVar } = useVariationData({
    id: Number(id),
  });

  const varId = form.variationId;

  const { data: product } = useProducts.useProductData();

  const varName = variations?.map((v) => {
    return v.variationId;
  });

  const { enqueueSnackbar } = useSnackbar();

  const loadProducts = async (searchFilter: string) => {
    if (searchFilter?.length < 5) return [];
    const { rows: products } = await ProductApi.getNames({
      searchFilter,
    });

    const newOptions = products.slice(0, 10).map((product) => ({
      value: product.id,
      label: product.name,
    }));
    setOptions(newOptions);
    return newOptions;
  };

  const handleClose = () => {
    setDialogValue({
      productName: '',
    });
    toggleOpen(false);
  };

  const handleAddProduct = async () => {
    try {
      if (!form.id) {
        throw new Error('Please select a product');
      } else {
        if (variations.length === 1) {
          const addedProduct = await ItemGroupsApi.addItemToGroup(
            groups[0].groupId,
            {
              variationId: varName[0],
              quantity: 1,
            },
          );
          enqueueSnackbar('Added Product!', { variant: 'success' });
        } else {
          const addedProduct = await ItemGroupsApi.addItemToGroup(
            groups[0].groupId,
            {
              variationId: varId,
              quantity: 1,
            },
          );
          enqueueSnackbar('Added Product!', { variant: 'success' });
          mutate();
          mutateGroup();
        }
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    setValue({
      productName: dialogValue.productName,
    });
    handleClose();
  };

  const update = <K extends keyof GroupForm>(key: K, value: GroupForm[K]) => {
    setForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  return (
    <>
      <TableRow>
        {columns.map((column) => {
          if (column.keyName === 'id') {
            return (
              <TableCell key={column.keyName}>
                <Typography>Select Product:</Typography>
              </TableCell>
            );
          } else if (column.keyName === 'edit') {
            return (
              <TableCell key={column.keyName}>
                <Button
                  size="small"
                  style={{ backgroundColor: '#B61F1F' }}
                  onClick={() => removeProduct(productIndex)}
                >
                  <FontAwesome
                    name="times"
                    type="fa"
                    form="fas"
                    style={{ color: '#FFFFFF' }}
                  />
                </Button>
              </TableCell>
            );
          } else if (column.keyName === 'quantity') {
            return (
              <TableCell key={column.keyName}>
                <Button size="small" style={{ backgroundColor: '#50C878' }}>
                  <FontAwesome
                    name="check"
                    type="fas"
                    form="fa"
                    style={{ cursor: 'pointer', color: '#FFFFFF' }}
                    onClick={handleAddProduct}
                  />
                </Button>
              </TableCell>
            );
          } else if (column.keyName === 'name') {
            return (
              <TableCell key={column.keyName}>
                <Grid item xs={9}>
                  <AsyncSelect
                    style={{ overflow: 'visible' }}
                    id="product"
                    loadOptions={loadProducts}
                    value={options.find((option) => option.value === form.id)}
                    onChange={(e) => {
                      update('id', e.value);
                      onChange(index, 'id', e.value);
                    }}
                  />
                </Grid>
              </TableCell>
            );
          } else if (column.editable) {
            return (
              <TableCell key={column.keyName}>
                {variations?.length > 1 && (
                  <Grid item xs={9} key={index}>
                    <Select
                      variant="outlined"
                      label="Variation"
                      disabled={!id}
                      value={form.variationId || 0}
                      style={{
                        width: '100%',
                        maxHeight: 40,
                      }}
                      onChange={(e) => {
                        update('variationId', e.target.value as number);
                        onChange(index, 'variationId', e.target.value);
                      }}
                    >
                      <MenuItem value={0}>--</MenuItem>
                      {variations?.map((v) => (
                        <MenuItem key={v.variationId} value={v.variationId}>
                          {v.variation}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                {variations?.length === 1 && <></>}
              </TableCell>
            );
          } else {
            // ? This is to maintain the spacing consistancy for each column.
            return <TableCell key={column.keyName} />;
          }
        })}
      </TableRow>
    </>
  );
};

export default AddGroupedProduct;
