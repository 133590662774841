import Grid from '@mui/material/Grid';
import TotalCost from './TotalCost';
import TotalQuantity from './TotalQuantity';

function MiniReports(props: any) {
  return (
    <Grid item container spacing={1}>
      <TotalCost {...props} />
      <TotalQuantity {...props} />
    </Grid>
  )
}

export default MiniReports;
