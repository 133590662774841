import { Grid, Button, Typography, LinearProgress } from '@mui/material';
import { SupplierListProp } from './types';
import { LIGHTORANGES, PURPLES } from '@oneAppCore/constants/colors';
import { COMPLETED } from '@oneAppCore/constants/listings';

function SupplierList({
  supplier,
  supplierStatus,
  supplierCounts,
}: SupplierListProp) {
  const correctSupplierIndex = supplierStatus
    .map((x) => x.supplierId)
    .indexOf(supplier.id);
  const correctCountIndex = supplierCounts
    .map((x) => x.supplierId)
    .indexOf(supplier.id);
  const correctSupplier = supplierStatus[correctSupplierIndex];
  const suppCount = supplierCounts[correctCountIndex];
  const guessEstimatedDownload = (status: string): number => {
    switch (status) {
      case 'DOWNLOADING':
        return 50;
      case COMPLETED:
        return 100;
      default:
        return 0;
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'DOWNLOADING':
        return LIGHTORANGES.main;
      case COMPLETED:
        return PURPLES.main;
    }
  };

  return (
    <>
      <Grid
        id={supplier.id}
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 5 }}
      >
        <Typography style={{ fontWeight: 'bold' }}>
          {supplier.name}
        </Typography>
        <Typography>
          In Stock: {Number(suppCount?.inStock).toLocaleString()}
        </Typography>
        <Typography>
          Static Quantity: {Number(suppCount?.staticQuantity).toLocaleString()}
        </Typography>
        <Typography>
          Temp Out of Stock:{' '}
          {Number(suppCount?.tempOutOfStock).toLocaleString()}
        </Typography>
        <Typography>
          Out of Stock: {Number(suppCount?.outOfStock).toLocaleString()}
        </Typography>
        <Typography
          style={{
            color: getStatusColor(correctSupplier?.status),
            fontWeight: 'bold',
          }}
        >
          {correctSupplier === undefined ? null : correctSupplier?.status}
        </Typography>

        {correctSupplier?.status === COMPLETED ? (
          <a
            href={correctSupplier?.url}
            download
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" variant="outlined" key={supplier.id}>
              Download
            </Button>
          </a>
        ) : null}
      </Grid>
      <Grid id={supplier.id} item xs={12} style={{ marginBottom: 15 }}>
        <LinearProgress
          style={{ height: 15, borderRadius: 10 }}
          variant="determinate"
          value={guessEstimatedDownload(correctSupplier?.status)}
          color="secondary"
        />
      </Grid>
    </>
  );
}

export default SupplierList;
