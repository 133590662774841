import { useMemo } from 'react';
import useMagikTime from '../hooks/useMagikTime';

import ToolsApi from '@oneAppCore/services/apis/Tools';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

function DownloadButton() {
  const [
    { lostMoneyOrders, channelId, storeId },
    setState,
  ] = useMagikTime.useState();

  const downloadCSV = async () => {
    setState((previous) => ({
      ...previous,
      loading: true,
      csv: true,
    }));

    const selectedOrders = lostMoneyOrders.filter((row) => row.selected);

    const url = await ToolsApi.getMagikTimeCSV(
      selectedOrders,
      channelId,
      storeId,
    );
    window.open(url, '_blank');

    setState((previous) => ({
      ...previous,
      loading: false,
      csv: false,
    }));
  };

  const hasSelectedOrders = useMemo(
    () => lostMoneyOrders.filter((order) => order.selected).length,
    [lostMoneyOrders],
  );

  return (
    <Grid container item>
      <Button
        variant="outlined"
        color="primary"
        onClick={downloadCSV}
        disabled={!hasSelectedOrders}
      >
        Download
      </Button>
    </Grid>
  );
}

export default DownloadButton;
