import { channelIds, AMAZON } from '@oneAppCore/constants/channels';

import type { MagikTimeState } from './types';

export const magikTimeStateDefault: MagikTimeState = {
  lostMoneyOrders: [],
  channelId: channelIds[AMAZON],
  storeId: null,
  loading: false,
  csv: false,
};
