import type { SearchOptionsAtom } from './types';

export const searchOptionsDefault: SearchOptionsAtom = {
  filters: {},
  page: 1,
  limit: 20,
  channel: null,
  url: '',
  orderBy: ['id', 'desc'],
  orderByColumn:['id'],
  orderByDirection:['desc'],
  searchFilter: '',
  csv: false,
};
