export const RMATV = 'Rocky Mountain ATVMC';
export const WPS = 'Western Power Sports';
export const WPSIR = 'Western Power Sports Internal Restock';
export const AD = 'Automatic Distributors';
export const T14 = 'Turn 14';
export const MONARCH = 'Monarch Products Inc';
export const TUCKER = 'Tucker Powersports';
export const ARCLITE = 'Arclite Optics';
export const DZONE = 'dzone';
export const MOTO = 'Moto United';
export const MX = 'Moto X';
export const DEMO = 'Demoex Parts';

//FTP string names
export const WPS_FTP_NAME = 'Western-Power-Sports';
export const AD_FTP_NAME = 'Automatic-Distributors';
export const RMATV_FTP_NAME = 'Rocky-Mountain-ATVMC';

// WPS statuses
export const SHIPPED = 'Shipped';
export const PICKING = 'Picking';
export const INVOICED = 'Invoiced';
export const CHECKING = 'Checking';
export const BACKORDER = 'B/O';
export const INOE = 'In O/E';
export const UNKNOWN = 'Unknown';

export const SUPP_UN = 'SUPPLIER UNKNOWN';

export const skuSupplierLookup = {
  [RMATV]: '*-',
  [AD]: '#-',
  [TUCKER]: '/-',
  [MONARCH]: '&-',
  [WPS]: '\\-',
  [MOTO]: '-MU',
};

export const supplierIds = {
  [RMATV]: 1,
  [WPS]: 2,
  [AD]: 3,
  [MONARCH]: 4,
  [TUCKER]: 5,
  [ARCLITE]: 6,
  [DZONE]: 7,
  [MOTO]: 8,
  [WPSIR]: 20,
  [T14]: 19,
};

// supplier names
export const suppliers = {
  [1]: RMATV,
  [2]: WPS,
  [3]: AD,
  [4]: MONARCH,
  [7]: DZONE,
  [8]: MOTO,
};

export const internalSuppliers = [7, 8];

export const dropShippingIds = Object.values(supplierIds);

export const colorTheme = {
  palette: {
    [RMATV]: { main: '#F3AC74', dark: '#990000', light: '#D54040' },
    [WPS]: { main: '#9F82D9', dark: '#B09225', light: '#E8CF76' },
    [AD]: { main: '#91C5F2', dark: '#294694', light: '#829BE0' },
    [MONARCH]: { main: '#B09225', dark: '#7D681A', light: '#FCE281' },

    [TUCKER]: { main: '#6BDBA7', dark: '#A61F16', light: '#DF6860' },
    [ARCLITE]: { main: '#1E73BE', dark: '#16558C', light: '#5D97CA' },
    [DZONE]: { main: '#4444DA', dark: '#377FA3', light: '#8DC2DD' },
    [MOTO]: { main: '#Da494b', dark: '#8c2f30', light: '#D47475' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    green: {
      main: '#0f0',
      light: '#0f0',
      dark: '#0f0',
      lightest: '#0f0',
      darkest: '#0f0',
      contrastText: '#fff',
    },
    orange: {
      main: '#FFA500',
      light: '#FFA500',
      dark: '#FFA500',
      lightest: '#FFA500',
      darkest: '#FFA500',
      contrastText: '#fff',
    },
    red: {
      main: '#f00',
      light: '#f00',
      dark: '#f00',
      lightest: '#f00',
      darkest: '#f00',
      contrastText: '#fff',
    },
    purple: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      lightest: '#800080',
      darkest: '#800080',
      contrastText: '#fff',
    },
    blue: {
      main: '#00f',
      light: '#00f',
      dark: '#00f',
      lightest: '#00f',
      darkest: '#00f',
      contrastText: '#fff',
    },
    greys: {
      main: '#999',
      light: '#999',
      dark: '#999',
      lightest: '#999',
      darkest: '#999',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      lightest: '#000',
      darkest: '#000',
      contrastText: '#fff',
    },
    light: '',
  },
};
