import { useEffect, useState } from 'react';

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T | null, (val: T) => void] {
  // State to store our value
  const [storedValue, setStoredValue] = useState<T | null>(null);

  // Get the value when the window is loaded
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const item = window.localStorage.getItem(key);
      const value = item ? (JSON.parse(item) as T) : initialValue;
      setStoredValue(value);
    }
  }, [key, initialValue]);

  // Function to set state and local storage
  const setValue = (value: T) => {
    setStoredValue(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
}
