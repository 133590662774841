import type { Column } from '@src/Components/common/containers/SearchView/types';
import {
  Grid,
} from '@mui/material';
import dayjs from 'dayjs';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';


export const columns = (): Column[] => [
  {
    keyName: 'name',
    title: 'Name',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'value',
    title: 'Value',
    sortBy: true,
    align: 'center',
  },
  {
    keyName: 'created',
    title: 'Created',
    sortBy: true,
    customComponent: function (row) {
      return (
        <Grid container item xs={12} >
          <Grid item xs={8}>{dayjs(row.createdAt).format('MM-DD-YYYY HH:mm A')}</Grid>
          <Grid item xs={4}>({row.createdByUser})</Grid>
        </Grid>
      );
    },
  },
  {
    keyName: 'updated',
    title: 'Updated',
    sortBy: true,
    customComponent: function (row) {
      return (
        <Grid container item xs={12} >
          <Grid item xs={8}>{dayjs(row.updatedAt).format('MM-DD-YYYY HH:mm A')}</Grid>
          <Grid item xs={4}>({row.updatedByUser})</Grid>
        </Grid>
      );
    },
  },
  {
    keyName: 'actions',
    title: 'Actions',
    customComponent: function () {
      return (
        <Grid></Grid>
      );
    },
  },
];