import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions';

import FontAwesome from '@src/Components/common/FontAwesome';

import type { DirectionIconProps } from './types';

export const AscIcon = ({ keyName }: DirectionIconProps) => {
  const [searchOptions, setSearchOptions] = recoilSearch.useState();

  const updateOrderBy = () => {
    setSearchOptions((previous) => {
      let columnElement = [];
      let columnDirection = [];
      previous.orderByColumn.forEach((element) => {
        columnElement.push(element);
      });
      previous.orderByDirection.forEach((element) => {
        columnDirection.push(element);
      });

      if(columnElement.includes(keyName)) {
        const elementIndex = columnElement.indexOf(keyName);
        if (elementIndex > -1) {
          if(columnDirection[elementIndex] == 'asc') { //removing the column from sorting filter
            columnElement.splice(elementIndex, 1);
            columnDirection.splice(elementIndex, 1);
          } else { //change the column from desc to asc
            columnElement.splice(elementIndex, 1);
            columnDirection.splice(elementIndex, 1);
            if(keyName == 'id' || !columnElement.includes('id')){ //if column is 'id' or filter does not have 'id' add to last
              columnElement.push(keyName);
              columnDirection.push('asc');
            } else{ //if there is 'id' in the filter, add to second last
              columnElement.splice(columnElement.length - 1, 0, keyName);
              columnDirection.splice(columnDirection.length - 1, 0, 'asc');
            }
          }
        }
      } else { //adding column to filter
        if(keyName == 'id' || !columnElement.includes('id')){
          columnElement.push(keyName);
          columnDirection.push('asc');
        } else{
          columnElement.splice(columnElement.length - 1, 0, keyName);
          columnDirection.splice(columnDirection.length - 1, 0, 'asc');
        }
      }
      return({
        ...previous,
        orderBy: [keyName, 'asc'],
        orderByColumn: columnElement,
        orderByDirection: columnDirection,
      })
    });
  };

  const elementIndex = searchOptions?.orderByColumn?.indexOf(keyName);
  const selected = elementIndex > -1 && searchOptions.orderByDirection[elementIndex] === 'asc';

  return (
    <FontAwesome
      size="xs"
      name="chevron-up"
      type="fas"
      form="fa"
      color={selected ? 'black' : 'grey'}
      onClick={updateOrderBy}
      style={{ cursor: 'pointer' }}
      key={keyName}
    />
  );
};

export default AscIcon;
