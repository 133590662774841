import { Chip } from '@mui/material';

import type {
  ExportOption,
  Column,
} from '@src/Components/common/containers/SearchView/types';

export const exportOptions = (productId): ExportOption[] => [
  {
    query: { productId },
    label: 'New Listings',
    value: 'newListings',
    supportedChannels: ['amazon', 'ebay', 'walmart', 'wooCommerce'],
    url: `/api/v1/products/export/newListings/single`,
  },
];

export const columns = (product): Column[] => [
  {
    title: 'Product Score',
    keyName: 'productScore',
    customComponent: function ProductScore() {
      let productScore = 0;
      if (product.name) {
        productScore += product.name.length;
      }
      if (product.manufacturerNo) {
        productScore += 2;
      }
      if (product.sku) {
        productScore += 1;
      }
      if (product.image) {
        productScore += 10;
      }
      // if(product.categories) {
      //   productScore += 10;
      // }
      // if(product.brand) {
      //   productScore += 10;
      // }
      // if(product.fitment) {
      //   productScore += 10;
      // }
      // if(product.description) {
      //   productScore += 10;
      // }
      // if(product.bulletPoints) {
      //   productScore += 10;
      // }
      // if(product.fitment) {
      //   productScore += 10;
      // }
      const color =
        productScore < 30
          ? 'secondary'
          : productScore >= 30 && productScore <= 60
          ? 'primary'
          : 'default';

      return <Chip color={color} size="medium" label={productScore} />;
    },
  },
];

export const updatableFields = [
  'name',
  'description',
  'storeId',
  'keywords',
  'bulletPoints',
  'channelCategoryId',
];
