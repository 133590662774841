import recoilReportOptions from '../hooks/useReportOptions';

import Grid from '@mui/material/Grid';

import NewerThanSelect from './NewerThanSelect';
import ChannelSelect from './ChannelSelect';

function ReportOptions() {
  const reportOptions = recoilReportOptions.useReportOptions();

  if (!reportOptions.selectedReport) {
    return <></>;
  }

  return (
    <Grid container item spacing={1}>
      <NewerThanSelect />
      <ChannelSelect />
    </Grid>
  );
}

export default ReportOptions;
