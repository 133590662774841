import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';
import { removeJwtToken } from '@oneAppCore/services/Jwt';

import type { LoggedInUser } from '@oneAppCore/types/userTypes';

export default function useMe() {
  return useSWR<LoggedInUser>(`/api/v1/me`, Api.get, {
    onError: () => {
      removeJwtToken();
      if (window.location.pathname !== '/login')
        window.location.pathname = '/login';
    },
  });
}
