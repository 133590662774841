import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

type Props = {
  orderItem: any
};
function itemImage ({
  orderItem
}: Props) {
  return (orderItem.imageUrl?.[0] ? (
    <img
      src={orderItem.imageUrl}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
      }}
      style={{
        width: 100,
        height: 100,
        marginRight: 10,
      }}
    />
  ) : (
    <img
      height={100}
      width={100}
      src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
      alt="Product Placeholder"
    />
  ))
}
export default itemImage;