import BaseApi from '../BaseApi';

import type { ReportName } from '@oneApp/Components/Reports/types';
import type { ReportResponse } from './types';

class ReportApi extends BaseApi {
  constructor() {
    super();
  }

  get(reportName: ReportName, query: Record<string, any> = {}) {
    return this.getBase<ReportResponse>(`/api/v1/reports/${reportName}`, query);
  }
}

export default ReportApi;
