import { atom } from 'recoil';

import type { ChartOptionsAtom } from './types';

export const chartOptionsAtom = atom<ChartOptionsAtom>({
  key: 'chart1-options',
  default: {
    chart: 'units-sold',
  },
});
