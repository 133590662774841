import recoilReportOptions from '../hooks/useReportOptions';
import ReportOptions from '../ReportOptions';
import ReportDownload from '../ReportDownload';
import ReportTable from '../ReportTable';
import ReportPaginator from '../ReportPaginator';

import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { columns } from './constants';
import { options } from '../ReportSelect/constants';
import { Typography, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    // maxWidth: 'calc(100vw - 400px)',
    // minWidth: 'calc(100vw - 400px)',
    width: '100%',
    marginTop: 30,
    // overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      minWidth: '90vw',
      maxWidth: '90vw',
    },
  },
  cardRoot: {
    maxWidth: '33%',
    transition: 'transform 0.15s ease-in-out',
  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  card: {
    width: '33%',
    marginBottom: '15px',
    padding: 10,
  },
}));

export default function RunHistoryTable() {
  const classes = useStyles();

  const [
    reportOptions,
    setReportOptions,
  ] = recoilReportOptions.useReportOptionsState();

  return (
    <>
      <Grid container xs={12} style={{ justifyContent: 'space-between' }}>
        <Grid container spacing={3} direction="column">
          <Grid item container>
            <ReportOptions />
          </Grid>
          <Grid item container>
            <ReportDownload />
          </Grid>
          <Grid item container>
            <ReportPaginator />
          </Grid>
          <Grid item container>
            <ReportTable />
          </Grid>
          <Grid item container>
            <ReportPaginator />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper} classes={{ root: classes.root }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((columnName) => (
                <TableCell key={columnName.title}>{columnName.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((optionName) => (
              <TableRow key={optionName.value}>
                <TableCell>{optionName.label}</TableCell>
                <TableCell>
                  <Typography>Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Name</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
