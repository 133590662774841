import colors from '@oneAppCore/constants/colors';
import { createTheme } from '@mui/material/styles';
import { primary } from '../constants';
import baseOptions from './base';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary,
    secondary: {
      main: '#272C2F',
      light: '#909ba2',
      dark: '#000000',
      contrastText: '#FFF',
    },
    orange: colors.ORANGES,
    green: colors.GREENS,
    red: colors.REDS,
    purple: colors.PURPLES,
    blue: colors.BLUES,
    greys: colors.GREYS,
    black: colors.BLACKS,
    // error: colors.REDS,
    // warning: colors.GOLDS,
    // info: colors.BLUES,
    // success: colors.LIGHTGREENS,
  },
}, baseOptions);

export default theme;
