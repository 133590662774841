import BaseApi from '../BaseApi';

import type { User } from '@oneAppCore/types/userTypes';
import type { LoggedInUser } from '@oneAppCore/types/userTypes';

class UsersApi extends BaseApi {
  constructor() {
    super();
  }

  async logIn(auth: Partial<User>) {
    return await super.postBase<LoggedInUser>('/api/v1/login', auth);
  }

  async logOut() {
    return await super.postBase<LoggedInUser>('/api/v1/logout');
  }

}

export default new UsersApi();
