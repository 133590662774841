

const channelObject = [{
    id:1, 
    name:'Amazon', 
    creds:[
        'sellerId',
        'secretKey',
        'spClientId',
        'MWSAuthToken',
        'refreshToken',
        'MarketplaceId',
        'AWSAccessKeyId',
        'spClientSecret',
    ]
},
{
    id:2,
    name:'Walmart',
    creds:[
        'id',
        'clientId',
        'privateKey',
        'clientSecret',
    ]
},
{
    id:3,
    name: 'Ebay',
    creds:[
        'clientId',
        'clientSecret',
        'refreshToken',
    ]
},
{
    id:4,
    name:'Woocommerce',
    creds:[
        'url',
        'version',
        'consumerKey',
        'consumerSecret'
    ]
},
]

export default channelObject;

