import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SupplierOrderButtonProps } from './types';
import Radio from '@mui/material/Radio';
import { getItemImageUrl } from '../utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',

    width: '100%',
  },
  image: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
  empty: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
});

const SupplierOrderButton: React.FC<SupplierOrderButtonProps> = ({
  row,
  supplier,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  available,
}) => {
  const classes = useStyles();
  return (
    <>
      {row.orderItems.length === 1 ? (
        <Grid
          onClick={() => {
            !available || isLoading
              ? null
              : onRadioChange(supplier);
          }}
        >
          <img
            className={classes.image}
            src={getItemImageUrl(row.orderItems[0])}
          />
        </Grid>
      ) : null}
      <Grid className={classes.container}>
        <Radio
          title={`Select ${supplier}`}
          checked={supplierRadioSelection === supplier}
          size="small"
          key={row.id}
          disabled={!available || isLoading}
          onChange={() => {
            onRadioChange(supplier);
          }}
          value={supplier}
          name={`${supplier} select radio button`}
          inputProps={{ 'aria-label': `${supplier} select radio button` }}
        />
        {row.orderItems.length > 1 ? (
          <Grid className={classes.empty}></Grid>


        ) : row.orderItems.length > 1 ? (
          <Grid className={classes.empty}></Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default SupplierOrderButton;
