import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import { Grid } from '@mui/material';
import dateUtil from '@src/utils/dates';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import ViewDrawerButton from '../ViewDrawerButton'

export const columns: Column[] = [
  {
    title: 'Sku',
    keyName: 'sku',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'MPN',
    keyName: 'manufacturerNo',
    sortBy: true,
    align: 'left',
  },
  /* {
    title: 'Internal Supplier',
    keyName: 'internalSupplier',
    sortBy: false,
    customComponent: function InternalSupplier(row) {
      return (
        <>
          <p>{row.internalSupplier ? 'Internal' : 'Dropship'}</p>
        </>
      );
    },
  }, */
  // {
  //   title: 'Brand',
  //   keyName: 'brandName',
  //   sortBy: true,
  // },
  {
    title: 'Brand',
    keyName: 'brandName',
    sortBy: true,
    customComponent: function brandName(row) {
      return (
        <>
          <p>
            {row.brandName} ({row.brand})
          </p>
        </>
      );
    },
  },
  {
    title: 'Product Id',
    keyName: 'productId',
    sortBy: true,
  },
  // {
  //   title: 'Variant',
  //   keyName: 'variation',
  //   sortBy: true,
  // },
  {
    title: 'Name',
    keyName: 'variation',
    sortBy: false,
    customComponent: function prodName(row) {
      return (
        <>
          <p>
            {row.name}
            {row.variation ? ` - ${row.variation}` : ''}
          </p>
        </>
      );
    },
  },
  {
    title: 'Weight',
    keyName: 'weight',
    sortBy: true,
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    align: 'center',
    customComponent: function createdAt(row) {
      return (
        <>
          <span>{dateUtil.getFormattedDate({ date: row.createdAt })}</span>
        </>
      );
    },
  },
  {
    title: 'Updated At',
    keyName: 'updatedAt',
    sortBy: true,
    align: 'center',
    customComponent: function updatedAt(row) {
      return (
        <>
          <span>{dateUtil.getFormattedDate({ date: row.updatedAt })}</span>
        </>
      );
    },
  },
  // {
  //   title: 'Supplier Meta Data',
  //   keyName: 'metaData',
  //   sortBy: false,
  //   customComponent: function SupplierMetaData(row) {
  //     const metaData = row.metaData ? row.metaData : '';
  //     return (
  //       <>
  //         <p>{typeof metaData/* {metadata.map(ba => ba).join(', ')} */}</p>
  //       </>
  //     );
  //   },
  // },
  {
    title: 'View',
    keyName: 'view',
    sortBy: false,
    customComponent: function ViewSupplier(row) {
      const history = useHistory();

      const ViewSupplier = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };
      return (
        <>
          <Grid
            container
            xs={12}
            direction="column"
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item spacing={1} direction="row">
              <ViewDrawerButton id={row.id} row={row} />
            </Grid>
            {/* <Grid item spacing={1} direction="row">
              <InternalButton row={row} />
            </Grid>
            <Grid item spacing={1} direction="row">
              <Tooltip title="Edit Order">
                <IconButton
                  style={{ fontSize: '14px' }}
                  disabled={lowerCase(row.orderStatus) !== 'unassigned'}
                  onClick={() => history.push(`/orders/view/${id}`)}
                >
                  <EditTwoToneIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </>
      );
    },
  },
];
