///statuses
export const CREATED = 'CREATED';
export const UPLOADED = 'UPLOADED';
export const UPLOADING = 'UPLOADING';
export const DONE = 'DONE';
export const ERROR = 'ERROR';
export const IN_QUEUE = 'IN_QUEUE';
export const SENT = 'SENT';
export const IN_PROGRESS = 'IN_PROGRESS';

///feed types
export const REPORTS ='REPORTS';
export const FEEDS ='FEEDS';
export const INVENTORY ='INVENTORY';
export const PRODUCTS ='PRODUCTS';
export const LISTINGS ='LISTINGS';
