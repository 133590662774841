import BaseApi from '../../BaseApi';
import type { UploadBody } from './types'

class ImportApi extends BaseApi {
  constructor() {
    super();
  }

  upload(tableName, uploadType, payload: Partial<UploadBody>) {
    return this.postBase(`/api/v1/import/${uploadType}/${tableName}`, payload);
  }
  getTableColumns<T>(tableName: string) {
    return this.getBase<T>(
      `/api/v1/import/getTableColumns/${tableName}`,
    );
  }
  getTables() {
    return this.getBase<any[]>(
      `/api/v1/import/getAllTables`,
    );
  }
}

export default ImportApi;
