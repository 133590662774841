import { useState } from 'react';

import LoginApi from '@oneAppCore/services/apis/Login'
import useMe from '@src/hooks/swr/useMe';

import { useSnackbar } from 'notistack';
import { Backdrop } from '@src/Components/common/Loaders';

import TextField from '@src/Components/common/TextField';
import {
  Grid,
  Button,
  Divider,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import FontAwesome from '@src/Components/common/FontAwesome';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import type { User } from '@oneAppCore/types/userTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `10px`,
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },
    justifyContent: 'center',
  },
  logo: {
    height: 'auto',
    width: 120,
    // width: 150,
  },
}));

function Login() {
  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [auth, setAuth] = useState<Partial<User>>({});

  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useMe();

  const update = (key: string, value: string) => {
    const updatedAuth = { ...auth, [key]: value };

    setAuth(updatedAuth);
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      const loggedInUser = await LoginApi.logIn(auth);
      // const loggedInUser = await Api.post(`/api/v1/login`, auth);
      enqueueSnackbar(`Welcome ${loggedInUser.firstName}!`, {
        variant: 'success',
      });
      mutate();
    } catch (e) {
      enqueueSnackbar(`Error: ${e?.message || e}`, { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      spacing={1}
      className={classes.root}
    >
      <Grid item container style={{ justifyContent: 'center' }}>
        <img
          src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG`}
          // src="/public/logo.png"
          alt="logo"
          className={classes.logo}
          onClick={() => { }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/public/logo.png';
            // currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/oa-logo.png`;
          }}
        />
      </Grid>
      <Grid item container style={{ justifyContent: 'center' }}>
        <Typography style={{ fontSize: 'small' }}>
          Enter your credentials to continue
        </Typography>
      </Grid>
      <Grid
        item
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
      >
        <TextField
          variant="outlined"
          label="Email"
          fullWidth
          value={auth?.username ?? ''}
          onChange={(e) => update('username', e.currentTarget.value)}
        />
      </Grid>
      <Grid
        item
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
      >
        <TextField
          variant="outlined"
          label="Password"
          fullWidth
          value={auth?.password ?? ''}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => update('password', e.currentTarget.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
              >
                {showPassword ? (
                  <FontAwesome name="eye-slash" type="fa" form="fa" />
                ) : (
                  <FontAwesome name="eye" type="fa" form="fa" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item container justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={submitting}
          style={{ minWidth: '100%', marginTop: 10 }}
        >
          Sign In
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      </Grid>
      <Grid item container justifyContent="center">
        <Typography
          style={{
            fontSize: '0.75 rem',
            fontWeight: 500,
            // color: 'rgb(18, 25, 38)'
            lineHeight: 1.75,
            textDecoration: 'none',
          }}
        >
          <a
            style={{
              fontSize: '0.75 rem',
              fontWeight: 500,
              color: 'rgb(18, 25, 38)',
              lineHeight: 1.75,
              textDecoration: 'none',
            }}
            href="https://oneapp.today/"
          >
            Dont have an account?
          </a>
        </Typography>
      </Grid>
      <Backdrop open={submitting} />
    </Grid>
  );
}

export default Login;
