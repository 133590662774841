import COLORS from '@oneAppCore/constants/colors';

import type { bootstrapTint, Color, Tint } from '@oneAppCore/types/commonTypes';

export function getColor(color: Color, tint: Tint) {
  switch (color) {
    case 'black': {
      return COLORS.BLACKS[tint];
    }
    case 'light_black': {
      return COLORS.LIGHTBLACKS[tint];
    }
    case 'blue': {
      return COLORS.BLUES[tint];
    }
    case 'green': {
      return COLORS.GREENS[tint];
    }
    case 'grey': {
      return COLORS.GREYS[tint];
    }
    case 'orange': {
      return COLORS.ORANGES[tint];
    }
    case 'purple': {
      return COLORS.PURPLES[tint];
    }
    case 'red': {
      return COLORS.REDS[tint];
    }
    case 'light_red': {
      return COLORS.LIGHTREDS[tint];
    }
    case 'gold': {
      return COLORS.GOLDS[tint];
    }
    case 'dark_green': {
      return COLORS.DARKGREENS[tint];
    }
    case 'dark_red': {
      return COLORS.DARKREDS[tint];
    }
    case 'dark_blue': {
      return COLORS.DARKBLUES[tint];
    }
    case 'dark_purple': {
      return COLORS.DARKPURPLES[tint];
    }
    case 'light_green': {
      return COLORS.LIGHTGREENS[tint];
    }
    case 'light_orange': {
      return COLORS.LIGHTORANGES[tint];
    }
    default: {
      return '';
    }
  }
}

export function getHexColorDupe(hexNum: string) {
  return `#${hexNum}${hexNum}${hexNum}`;
}

export function getBootstrapColor(tint: bootstrapTint, version: number = 5) {
  return COLORS.BOOTSTRAP[`v${version}`][tint];
}
