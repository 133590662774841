import TabbedPage from '@oneAppCore/one-app/src/Components/CustomPages/TabbedPage';
import ImportData from './ImportData';
import { Options } from './types';


const customImportOpts: Options = {
  tableList: [
    'Fitment_Search_import',
    'Product_Variation_Mansupp'
  ],
};

const ImportPage = () => {
  const tabs: any[] = [
    {title: 'Import', child: <ImportData title={'Import'} options={customImportOpts} key={`import-1`} />},
    {title: 'Advanced', child: <ImportData title={'Import'} key={`import-0`} />},
  ];
  return (
    <TabbedPage tabs={tabs} />
  );
};

export default ImportPage;
