

import { columns } from './constants';
import {
  Grid,
} from '@mui/material';
import SearchView from '@src/Components/common/containers/SearchView';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginBottom: theme.spacing(2),
//     overflowX: 'initial',
//   },
//   actions: {
//     margin: 5,
//   },
//   card: {
//     width: 200,
//     border: 'solid grey 1px',
//   },
// }));

// const StyledTableCell = withStyles((theme: Theme) =>
//   createStyles({
//     head: {
//       backgroundColor: '#f3f2f7',
//     },
//     body: {
//       fontSize: 14,
//     },
//   }),
// )(TableCell);

function InventoryFeed() {
  // const classes = useStyles();
  // const { data } = useInventoryFeed();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'transition-popper' : undefined;

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/channel-feeds/search"
        // OrderViewDrawerComponent={OrderViewDrawer}
        filters={
          [
            // {
            //   type: 'string',
            //   name: 'Store',
            //   keyName: 'storeName',
            // },
            // {
            //   type: 'string',
            //   name: 'Channel',
            //   keyName: 'channelName',
            // },
          ]
        }
        columns={columns}
      />
    </Grid>
  );

  // return (
  //   <>
  //     <SearchPaginator />
  //     <TableContainer component={Paper} className={classes.root}>
  //       <Table stickyHeader>
  //         <TableHead>
  //           <TableRow>
  //             <StyledTableCell>Store</StyledTableCell>
  //             <StyledTableCell>Channel</StyledTableCell>
  //             <StyledTableCell>Feed Type</StyledTableCell>
  //             <StyledTableCell>Success</StyledTableCell>
  //             <StyledTableCell>Failed</StyledTableCell>
  //             <StyledTableCell>Errors</StyledTableCell>
  //             <StyledTableCell>Status</StyledTableCell>
  //             <StyledTableCell>Completed</StyledTableCell>
  //             <StyledTableCell>Last Scanned At</StyledTableCell>
  //             <StyledTableCell>Actions</StyledTableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {(data || []).map((row) => {
  //             const completed = row.completed === true;
  //             return (
  //               <TableRow key={row.storeName + row.channelName}>
  //                 <TableCell>{row.storeName}</TableCell>
  //                 <TableCell>{row.channelName}</TableCell>

  //                 <TableCell>{row.feedType}</TableCell>

  //                 <TableCell>
  //                   {numberUtils.formatNumber(row.successfulCount, '0,0')}
  //                 </TableCell>
  //                 <TableCell>
  //                   {numberUtils.formatNumber(row.failedCount, '0,0')}
  //                 </TableCell>
  //                 <TableCell>{JSON.stringify(row.errors)}</TableCell>
  //                 <TableCell>{row.status}</TableCell>
  //                 <TableCell>
  //                   {completed ? (
  //                     <FontAwesome
  //                       name="check-circle"
  //                       type="fa"
  //                       form="fa"
  //                       color="green"
  //                     />
  //                   ) : (
  //                     <FontAwesome
  //                       name="times-circle"
  //                       type="fa"
  //                       form="far"
  //                       color="red"
  //                     />
  //                   )}
  //                 </TableCell>
  //                 {/* <TableCell>{row.completed}</TableCell> */}
  //                 <TableCell>
  //                   {dayjs(row.lastScannedAt).format('MM/DD/YYYY hh:mma')}
  //                 </TableCell>
  //                 <TableCell>
  //                   <ToolTip title={'Actions'}>
  //                     <IconButton style={{ fontSize: '14px' }}>
  //                       <FontAwesome
  //                         name="ellipsis-v"
  //                         type="fa"
  //                         form="fa"
  //                         onClick={handleClick}
  //                       />
  //                     </IconButton>
  //                   </ToolTip>
  //                   <Popper id={id} open={open} anchorEl={anchorEl} transition>
  //                     <Card className={classes.card} elevation={0}>
  //                       <CardContent>
  //                         <Grid
  //                           container
  //                           xs={12}
  //                           alignItems="center"
  //                           justify="center"
  //                         >
  //                           <Grid item xs={12}>
  //                             <Typography
  //                               className={classes.actions}
  //                               style={{ cursor: 'pointer' }}
  //                               onClick={() => {}}
  //                             >
  //                               Try Failed Again
  //                             </Typography>
  //                           </Grid>
  //                           <Grid item xs={12}>
  //                             <Divider />
  //                           </Grid>
  //                           <Grid item xs={12}>
  //                             <Typography
  //                               className={classes.actions}
  //                               style={{ cursor: 'pointer' }}
  //                               onClick={() => {}}
  //                             >
  //                               Try All Again
  //                             </Typography>
  //                           </Grid>
  //                           <Grid item xs={12}>
  //                             <Divider />
  //                           </Grid>
  //                           <Grid
  //                             alignItems="center"
  //                             justify="center"
  //                             item
  //                             xs={12}
  //                           >
  //                             <Typography
  //                               className={classes.actions}
  //                               style={{ cursor: 'pointer' }}
  //                               onClick={() => {}}
  //                             >
  //                               See More Info
  //                             </Typography>
  //                           </Grid>
  //                         </Grid>
  //                       </CardContent>
  //                     </Card>
  //                   </Popper>
  //                 </TableCell>
  //               </TableRow>
  //             );
  //           })}
  //         </TableBody>
  //       </Table>
  //       {/* <Paginator /> */}
  //     </TableContainer>
  //     <SearchPaginator />
  //   </>
  // );
}

export default InventoryFeed;
