
type PackTable = {
  title: string;
  keyName: string;
  customComponent?: (row: any) => JSX.Element;
}[];

export const packTable: PackTable = [
  {
    title: 'ID',
    keyName: 'id',
  },
  {
    title: 'Image',
    keyName: 'image',
  },
  // {
  //   title: 'Title (Variation)',
  //   keyName: 'title',
  // },

  // {
  //   title: 'SKU',
  //   keyName: 'sku',
  // },
  {
    title: 'Pack Name',
    keyName: 'name',
  },
  // {
  //   title: 'SKU',
  //   keyName: 'sku',
  // },
  // {
  //   title: 'Price',
  //   keyName: 'price',
  // },
  {
    title: 'Pack Count',
    keyName: 'quantity',
  },
];
