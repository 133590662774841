import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas);
library.add(far);
library.add(fab);

function FontAwesome({ className = '', form = '', name = '', size = 'lg', fixedWidth = true, ...rest }) {
  return (
    <FontAwesomeIcon
      {...rest}
      className={className}
      icon={[form, name]}
      size={size}
      fixedWidth={fixedWidth}
    />
  );
}

export default FontAwesome;
