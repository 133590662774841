import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  Select,
  MenuItem,
  TablePagination,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { fitmentTable } from './constants';
import type {
  Fitment,
  FitmentProps,
  FitmentConstant,
  YearMakeModel,
  Makes,
} from './types';
import FontAwesome from '@src/Components/common/FontAwesome';
import FitmentApi from '@oneAppCore/services/apis/Fitment';
import AddFitmentRow from './addFitmentRow';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    maxWidth: '100%',
    overflowX: 'hidden',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

type EditYmm = {
  id: number;
  year: number[];
  make: string;
  model: string;
};

const FitmentTable = ({ fitmentData, variationId, mutate }: FitmentProps) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState(0);
  const [editableColumn, setEditableColumn] = useState<{
    fitIndex: number;
  }>(null);
  const [editYmm, setEditYmm] = useState<EditYmm>({
    id: null,
    year: [],
    make: '',
    model: '',
  });
  const [makes, setMakes] = useState<Makes>([]);
  const [models, setModels] = useState<YearMakeModel[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    const grabMakesAndModels = async () => {
      const makes = await FitmentApi.getMakes();
      const models = await FitmentApi.getModels();
      setMakes(makes.rows);
      setModels(models.rows);
    };
    grabMakesAndModels();
  }, []);

  const enableEditing = (fitment: YearMakeModel, fitIndex: number) => {
    const { id, year, make, model } = fitment;
    setEditYmm({
      id,
      year,
      make: `${make}`,
      model: `${model}`,
    });
    setEnableEdit(true);
    setEditableColumn({ fitIndex });
  };

  const years = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 100))
      .fill('')
      .map((_, idx) => now - idx) as Array<number>;
  };

  const invokeFitmentUpdate = async (id: number) => {
    const { year, make, model } = editYmm;
    if (year?.length === 0 || make === '' || model === '') return;
    try {
      await FitmentApi.updateFitment({
        id,
        year,
        make,
        model,
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      mutate();
      setEditYmm({ id: null, year: [], make: '', model: '' });
      setEnableEdit(false);
      setEditableColumn(null);
      enqueueSnackbar('Successfully Updated!', { variant: 'success' });
    }
  };

  const handleChange = (key, e) => {
    setEditYmm((ymm) => ({
      ...ymm,
      [key.keyName]:
        key.keyName === 'year' ? e.target.value : String(e.target.value),
    }));
  };

  const filterMakes = (makes) => {
    return makes.filter(
      (e) => e.year.filter((el) => editYmm.year.includes(el)).length,
    );
  };

  const filterModels = (models) => {
    return models.filter((e) => editYmm.make === e.make.toString());
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" aria-label="fitment">
        <TableHead>
          <TableRow className={classes.headerColor}>
            {fitmentTable.map((fitment: FitmentConstant, i) => (
              <TableCell
                className={classes.fontColor}
                sx={{
                  backgroundColor: '#72717a',
                  color: '#fff',
                }}
                key={'fitment_' + fitment.keyName + i}
              >
                {fitment.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {fitmentData &&
          fitmentData.map((fitment: Fitment, fitIndex) => {
            return (
              <TableRow key={fitment.id}>
                {fitmentTable.map((key) => {
                  if (key.keyName === 'edit') {
                    const isEditing =
                      enableEdit && editableColumn?.fitIndex === fitIndex;
                    return (
                      <TableCell key={'fitment_' + key.keyName + fitment.id}>
                        <FontAwesome
                          name={isEditing ? 'check' : 'edit'}
                          type="fas"
                          form="fa"
                          onClick={() => {
                            isEditing
                              ? invokeFitmentUpdate(fitment.id)
                              : enableEditing(fitment, fitIndex);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    );
                  } else if (
                    enableEdit &&
                    editableColumn?.fitIndex === fitIndex
                  ) {
                    return (
                      <TableCell key={`edit-${fitIndex}-${fitment.id}`}>
                        <Select
                          multiple={key.keyName === 'year'}
                          value={editYmm[key.keyName]}
                          onChange={(e) => handleChange(key, e)}
                        >
                          {key.keyName === 'year' &&
                            years().map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          {key.keyName === 'make' &&
                            filterMakes(makes).map(({ make, id }) => (
                              <MenuItem key={id} value={id}>
                                {make}
                              </MenuItem>
                            ))}
                          {key.keyName === 'model' &&
                            filterModels(models).map(({ model, id }) => (
                              <MenuItem
                                key={id}
                                value={id}
                                sx={{ zIndex: 9999, overflow: 'visible' }}
                              >
                                {model}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    );
                  } else if (key.customComponent && fitment[key.keyName]) {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'fitment_' + key.keyName + fitment.id}
                      >
                        {key.customComponent(fitment[key.keyName])}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'fitment_' + key.keyName + fitment.id}
                      >
                        {fitment[key.keyName + '_name'] || '-'}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        <AddFitmentRow
          variationId={variationId}
          makes={makes}
          models={models}
          mutate={mutate}
        />

        <TablePagination
          count={fitmentData?.length || 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Table>
    </TableContainer>
  );
};

export default FitmentTable;
