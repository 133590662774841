import { startCase, lowerCase } from 'lodash';
import { Grid, Typography } from '@mui/material';
import ViewOrderDrawerButton from '../ViewOrderDrawerButton/ViewOrderDrawerButton';
import ResolveButton from '../ActionsGroup/ResolveButton';
import CancelButton from '../ActionsGroup/CancelButton';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import { dateFormat } from '@oneAppCore/constants/dates';
import dayjs from 'dayjs';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import Chip from '@mui/material/Chip';

export const columns = (): Column[] => [
  {
    title: 'Placed At',
    keyName: 'purchaseDate',
    sortBy: true,
    type: 'date',
    align: 'center',
    customComponent: function purchaseDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.purchaseDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    keyName: 'expectedShipDate',
    title: 'Ship By',
    sortBy: true,
    type: 'date',
    align: 'center',
    customComponent: function purchaseDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.purchaseDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    title: 'Order Number',
    keyName: 'orderNumber',
    sortBy: true,
    align: 'center',
    customComponent: function OrderNumberDisplay(row) {
      return (
        <div key={row.id} style={{ minWidth: '160px' }}>
          # {row.orderNumber}
          {row.issues?.length && (
            <div>
              <Chip
                style={{
                  backgroundColor: 'crimson',
                  color: 'white',
                  width: '75%',
                  height: '100%',
                }}
                label={startCase(lowerCase(row.issues[0].issueType))}
              />
            </div>
          )}
        </div>
      );
    },
  },

  {
    keyName: 'channel',
    title: 'Store & Channel',
    sortBy: true,
    align: 'center',
    customComponent: function StoreChannels(row) {
      return (
        <Grid container xs={12} justifyContent="space-around">
          <Grid item xs={4}>
            <Typography style={{ textTransform: 'uppercase' }}>
              {row.store}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    keyName: 'quantity',
    title: 'QTY',
    sortBy: true,
    customComponent: function Quantity(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          xs={10}
          spacing={1}
          direction="column"
          style={{
            maxWidth: '600px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => {
            return (
              <Grid item container key={item.id} alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <div
                      style={{
                        border: '1px solid black',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 30,
                        width: 30,
                      }}
                    >
                      <p style={{ fontSize: '18px' }}>{item?.quantity}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    keyName: 'items',
    title: 'Items',
    customComponent: function OrderItemsDisplay(row) {
      if (!row?.orderItems?.[0]) return;

      return (
        <Grid
          container
          xs={10}
          spacing={1}
          direction="column"
          style={{
            maxWidth: '600px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => (
            <Grid item container key={item.id} alignItems="center">
              <Grid
                item
                container
                xs={4}
                direction="column"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  {item.variationImages?.[0] ? (
                    <img
                      height={55}
                      width={55}
                      src={
                        item.variationImages?.[0]?.imageUrl != null
                          ? item.variationImages?.[0]?.imageUrl
                          : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
                      }}
                      alt="Product"
                    />
                  ) : (
                    <>
                      <img
                        height={75}
                        width={75}
                        src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                        alt="placeholder image for product image"
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="column" xs={8}>
                <Grid item xs={12}>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '300px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item?.channelData?.title}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <span>SKU: </span>
                  <span style={{ fontWeight: 'bold' }}>{item.listingSku}</span>
                  {' | '}
                  <span>MPN: </span>
                  <span style={{ fontWeight: 'bold' }}>
                    {item.manufacturerNo}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  Location:{' '}
                  {item.location ? (
                    <span style={{ fontWeight: 'bold' }}>{item.location}</span>
                  ) : (
                    <span style={{ fontWeight: 'bold' }}> N/A </span>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    title: 'Order Total',
    keyName: 'itmPrice',
    sortBy: true,
    align: 'center',
    customComponent: function orderTotal(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          xs={12}
          spacing={1}
          direction="row"
          style={{
            justifyContent: 'flex-end',
            alignItems: 'space-around',
          }}
        >
          {row.orderItems?.map((item) => (
            <Grid item container key={item.id} alignItems="center">
              <Grid item container spacing={1} direction="column" xs={12}>
                <Grid item style={{ justifyContent: 'center' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {formatCentsToDollarsIntl(item.itemPrice || 0)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    },
  },
  {
    title: 'Actions',
    keyName: 'actions',
    customComponent: function ActionButtons(row) {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ minWidth: '100px' }}
        >
          <Grid item xs={3}>
            <ViewOrderDrawerButton id={row.id} row={row} orderType={'issue'} />
          </Grid>
          <Grid item xs={3}>
            <CancelButton row={row} />
          </Grid>
          <Grid item xs={3}>
            <ResolveButton row={row} />
          </Grid>
        </Grid>
      );
    },
  },
];
