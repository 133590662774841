import { useEffect, useMemo, useState } from 'react';

import { commonColumns, weekColumns, supplierColumns } from './constants';

import Grid from '@mui/material/Grid';

import SearchView from '@src/Components/common/containers/SearchView';

function Aging() {
  const [dynamicColumns, setDynamicColumns] = useState([...commonColumns, ...weekColumns]);
  const [selectedTogglePage, setSelectedTogglePage] = useState('Week');

  useEffect(() => {
    if (selectedTogglePage === 'Week') {
      setDynamicColumns([...commonColumns, ...weekColumns]);
    } else {
      setDynamicColumns([...commonColumns, ...supplierColumns]);
    }
  }, [selectedTogglePage]);

  return (
    <Grid item container justifyContent="center" alignItems="center">
      <SearchView
        url="api/v1/manufacturer-suppliers/agingSearch"
        togglePageOptions={['Week', 'Supplier']}
        selectedTogglePage={selectedTogglePage}
        setSelectedTogglePage={setSelectedTogglePage}
        columns={dynamicColumns}
        filters={[
          { type: 'string', name: 'SKU', keyName: 'listingSku' },
          { type: 'string', name: 'Item Name', keyName: 'name' },
          { type: 'string', name: 'Brand', keyName: 'brand' },
        ]}
      />
    </Grid>
  );
}

export default Aging;
