import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  SwipeableDrawer,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import type { DrawerProps } from './types';
import { columns, issueColumns } from './constants';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

const drawerWidth = 400;
const mobileWidth = 260;

interface StyleProps {
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    // width: 400,
    [theme.breakpoints.up('md')]: {
      // width: ({ mobile }: StyleProps) => (mobile ? mobileWidth : drawerWidth),
      flexShrink: 0,
      width: '475px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },
  // typography: {
  //   marginLeft: 10,
  //   marginTop: 5,
  //   fontSize: 14,
  // },
  // button: {
  //   margin: 5,
  // },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  // spacing: {
  //   marginTop: 5,
  // },
}));

export default function VariationDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: DrawerProps) {
  const classes = useStyles({ mobile });

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <Grid container>
          <Grid alignItems="center" justifyContent="center" container>
            <Typography
              variant="h4"
              color="primary"
              style={{ marginBottom: 15, fontWeight: 600 }}
            >
              Variation Details
            </Typography>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
          </Grid>
          {columns
            .filter((col) => !issueColumns.includes(col.key))
            .map((name) => {
              if (name.customComponent) {
                return (
                  <>
                    <name.customComponent row={row} />
                    {name.key === 'addressLine1' ? (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    ) : null}
                    {name.key === 'cost' ? (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    ) : null}
                    {name.key === 'purchaseDate' ? (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    ) : null}
                    {name.key === 'price' ? (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    ) : null}
                  </>
                );
              }
              return (
                <Grid key={name.key} container style={{ marginTop: 5 }}>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                  // className={classes.spacing}
                  >
                    <Typography
                      style={{ fontWeight: 600, fontSize: '1.15rem' }}
                      key={name.id}
                    >
                      {name.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                  // className={classes.spacing}
                  >
                    <Typography style={{ fontSize: '1.15rem' }}>
                      {row[name.key]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'sku' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'manufacturerNo' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'id' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'orderNumber' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'supplierTrackingNo' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'shippingName' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'channel' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'orderStatus' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {name.key === 'placedByUsername' ? (
                      <Divider className={classes.divider} />
                    ) : null}
                  </Grid>
                </Grid>
              );
            })}

          {/* <Grid item container xs={12} justify="center">
            <Typography variant="h6" style={{ marginTop: 10 }}>
              <strong>Notes</strong>
            </Typography>
          </Grid>
          <Grid item container xs={12} justify="center">
            <TextField
              disabled
              style={{ marginBottom: 10, width: '80%' }}
              multiline
              variant="outlined"
            />
            <Button disabled variant="contained" color="primary">
              Save Notes
            </Button>
          </Grid> */}
        </Grid>
      </SwipeableDrawer>
    </>
  );
}
