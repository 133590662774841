import { useState } from 'react';

import { ALL_TABLES, ORDERS, INVENTORY, PRODUCTS, ORDERPROFIT } from './constants';

import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import OperationTabs from './OperationTabs';
import OrderOperations from './OrderOperations';
import InventoryOperations from './InventoryOperations';
import ProductOperations from './ProductOperations';
import OrderProfitOperations from './OrderProfitOperations/OrderProfitOperations';

import type { OperationTab } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  scrollable: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
}));

function Operations() {
  const [selectedTab, setSelectedTab] = useState<OperationTab>(ALL_TABLES);

  const classes = useStyles();

  return (
    <Grid container spacing={3} direction="column">
      <Grid item container style={{ overflow: 'scroll' }}>
        <OperationTabs selectedTab={selectedTab} changeTab={setSelectedTab} />
      </Grid>
      <Grid item container xs={12}>
        {selectedTab === ALL_TABLES && (
          <Grid
            item
            container
            direction="column"
            spacing={1}
            xs={12}
            className={classes.scrollable} /* style={{ overflowX: 'scroll' }} */
          >
            <Grid item>
              <Typography variant="h6">Orders</Typography>
            </Grid>
            <Grid item>
              <OrderOperations />
            </Grid>
            <Grid item>
              <Typography variant="h6">Inventory</Typography>
            </Grid>
            <Grid item>
              <InventoryOperations />
            </Grid>
            <Grid item>
              <Typography variant="h6">Product</Typography>
            </Grid>
            <Grid item>
              <ProductOperations />
            </Grid>
            <Grid item>
              <Typography variant="h6">Order Profit</Typography>
            </Grid>
            <Grid item>
              <OrderProfitOperations />
            </Grid>
          </Grid>
        )}
        {selectedTab === ORDERS && <OrderOperations />}
        {selectedTab === INVENTORY && <InventoryOperations />}
        {selectedTab === PRODUCTS && <ProductOperations />}
        {selectedTab === ORDERPROFIT && <OrderProfitOperations />}
      </Grid>
    </Grid>
  );
}

export default Operations;
