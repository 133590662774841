import {
  Grid,
  Link,
  Chip,
} from '@mui/material';
import type {
  Column,
} from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import {
  APPROVED,
  CREATED,
  EDIT,
  PENDING,
  REJECTED,
} from '@oneAppCore/constants/listings';
import { lowerCase, startCase } from 'lodash';
import TooltipTable from '@src/Components/common/TooltipTable';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
// import {} from '@oneAppCore/constants/laws';listingChangeStatusColors

export const columns = (
  functions,
): Column[] => [
    {
      keyName: 'id',
      title: 'Id',
      sortBy: true,
      // hidden: true,
    },
    {
      keyName: 'name',
      title: 'Name',
      sortBy: true,
      // hidden: true,
    },
    {
      keyName: 'displayName',
      title: 'displayName',
      sortBy: true,
      // hidden: true,
    },
    {
      keyName: 'roleCategoryId',
      title: 'category id',
      sortBy: true,
      hidden: true,
    },
    {
      keyName: 'categoryName',
      title: 'Category',
      sortBy: true,
      // hidden: true,
    },
    {
      keyName: 'createdAt',
      title: 'created',
      sortBy: true,
    },
    {
      keyName: 'updatedAt',
      title: 'updated',
      sortBy: true,
    },
    {
      keyName: 'deletedAt',
      title: 'deleted',
      sortBy: true,
    },
  ];


  