import useInventoryOperations from './hooks/useInventoryOperations';

import dayjs from 'dayjs';
import numberUtils from '@oneAppCore/utils/numbers';

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FontAwesome from '@src/Components/common/FontAwesome';
import { CircularProgress, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  'bg-synced': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-nah': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
}));

function InventoryOperations() {
  const classes = useStyles();
  const { data } = useInventoryOperations();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Supplier</TableCell>
            <TableCell>Synced</TableCell>
            <TableCell>Yesterdays Count</TableCell>
            <TableCell>Count</TableCell>
            <TableCell>Variance</TableCell>
            <TableCell>Scanned At</TableCell>
          </TableRow>
        </TableHead>
        {data ? (
          <TableBody>
            {(data || []).map((row) => {
              const synced = row.variance > -40 && row.variance < 40;
              return (
                <TableRow key={row.name}  className={synced ? classes[`bg-synced`] : classes[`bg-nah`]}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {synced ? (
                      <FontAwesome
                        name="check-circle"
                        type="fa"
                        form="fa"
                        color="green"
                      />
                    ) : (
                      <FontAwesome
                        name="times-circle"
                        type="fa"
                        form="far"
                        color="red"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.oldQuantity, '0,0')}
                  </TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.quantity, '0,0')}
                  </TableCell>
                  <TableCell style={{ color: !synced ? 'red' : 'black' }}>
                    {(row.variance || 0) + '%'}
                  </TableCell>
                  <TableCell>
                    {dayjs(row.scannedAt).format('MM/DD/YYYY hh:mma')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <Grid
            container
            xs={12}
            style={{
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Table>
    </TableContainer>
  );
}

export default InventoryOperations;
