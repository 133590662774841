import { atom } from 'recoil';

import type { ChartOptionsAtom } from './types';

export const chartOptionsAtom = atom<ChartOptionsAtom>({
  key: 'chart2-options',
  default: {
    chart: 'All Suppliers',
    chartId: -1,
  },
});
