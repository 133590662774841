import UnitsSoldChart from '../../UnitsSoldChart';
import GrossRevenueByDay from '../../GrossRevenueByDayChart/GrossRevenueByDayChart';

export const options = [
  {
    label: 'Units Sold',
    value: 'units-sold',
  },
  {
    label: 'Orders By Channel',
    value: 'orders-by-channel-bar',
  },
  {
    label: 'Gross Revenue',
    value: 'gross-revenue',
  },
];
