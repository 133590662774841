import { memo, useMemo } from 'react';
import { ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useTheme } from '@mui/styles';
import { Chart } from 'chart.js/auto';

import type { Props } from '../types';
Chart.register(ArcElement);
function ReusablePieChart({ data, labels, title, plugins: pluginProp }: Props) {
  const theme = useTheme();

  const colors = useMemo(
    () => [
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.warning.dark,
      theme.palette.error.dark,
      theme.palette.info.dark,
      theme.palette.success.dark,
      theme.palette.primary.main,
      theme.palette.secondary.light,
      theme.palette.warning.main,
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.success.main,
    ],
    [theme],
  );

  const backgroundColor = useMemo(
    () => data.slice().map((_, index) => colors[index % 12]),
    [data, colors],
  );

  // const isTablet = useMediaQuery('(max-width:1250px)');
  // const isMobile = useMediaQuery('(max-width:900px)');

  const plugins = Object.assign(
    {
      legend: {
        display: false,
      },
    },
    pluginProp,
  );
  const dataObject = {
    labels: labels ? labels.map((l) => l) : [],
    datasets: [
      {
        label: title,
        data: data || [],
        borderWidth: 0,
        hoverOffset: 3,
        backgroundColor: backgroundColor || [],
      },
    ],
  };

  return (
    <Pie
      options={{
        responsive: true,
        // maintainAspectRatio: true,
        // aspectRatio: 2,
        plugins,
      }}
      data={dataObject}
    />
  );
}

export default memo(ReusablePieChart);
