import { useState, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { pick, omit } from 'lodash';
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  IconButton,
  OutlinedInput,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Divider,
  Box,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Theme } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import useQuery from '@src/hooks/util/useQuery';
import { TextField, Backdrop } from '@src/Components/common';
import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import queryUtils from '@src/utils/queries';
import BrandApi from '@oneAppCore/services/apis/Brands';
import type { BrandForEdit } from '@oneAppCore/types/brandTypes';
import { SupplierForm } from './types';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import { formFields, complexFormFields } from './constants';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import { useMediaQuery } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  selectStyle: {
    height: '43px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
  },
}));

function BrandDrawer({ onSubmit, close }: FormProps) {
  const classes = useStyles();

  const [form, setForm] = useState<BrandForEdit>({ isMap: false });
  const [brandArray, setBrandArray] = useState([]);
  const [discountArray, setDiscountArray] = useState([]);
  const [suppliers, setSuppliers] = useState<SupplierForm[]>([]);
  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState('');
  const [modal, setModal] = useState<boolean>(false);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  console.log(image, 'brand image');

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useQuery();
  const history = useHistory();

  const handleSelectValue = (value, index) => {
    let updatedDiscountArray = [...discountArray];
    updatedDiscountArray[index]['discountPercent'] =
      value == '%' ? true : false;
    updatedDiscountArray[index]['discountFlat'] = value == '$' ? true : false;
    setDiscountArray(updatedDiscountArray.filter((element) => element));
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await BrandApi.getById(Number(id));
        console.log(response, 'response');
        const newForm = pick(response, formFields);
        setForm(newForm);
        setImage(response.image);
        setBrandArray(newForm.brandAliases);
        setDiscountArray(newForm.discounts);
      })();
    } else {
      setForm({});
    }
  }, [id]);

  console.log(form, 'brand form');

  const update = <K extends keyof BrandForEdit>(
    key: K,
    value: BrandForEdit[K],
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateImage = async (url: string) => {
    if (!form.brand && (!form.brandAliases || form.brandAliases.length === 0)) {
      enqueueSnackbar('Either brand name or brand aliases must be provided!', {
        variant: 'error',
      });
      return;
    }

    const updatedForm = omit(
      {
        ...form,
        image: url,
        brand: form.brand,
      },
      ['brandAliases', 'discounts', 'isMap'],
    );

    try {
      await BrandApi.put(Number(id), updatedForm);
      setModal(false);
      enqueueSnackbar('Updated Brand Logo!', { variant: 'success' });
      setImage(url);
      setForm((prevForm) => ({
        ...prevForm,
        image: url,
      }));
    } catch (e) {
      setModal(false);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const updateItem = (index: number, value: any, key: string) => {
    const newList = form[key];
    newList[index] = value;
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const updateDiscount = (index: number, key: any, value: any) => {
    const updatedDisc = discountArray.slice();
    updatedDisc[index] = {
      ...updatedDisc[index],
      [key]: typeof value === 'string' ? parseInt(value, 10) : value,
    };
    update('discounts', updatedDisc);
    setDiscountArray(updatedDisc);
  };

  const removeDiscount = (index: number) => {
    const updatedDisc = discountArray.slice();
    updatedDisc.splice(index, 1);
    update('discounts', updatedDisc);
    setDiscountArray(updatedDisc);
  };

  const addItem = (key: string) => {
    const newList = form[key] ? form[key] : [];
    newList.push('');
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const addDiscount = () => {
    const updatedDiscounts = discountArray.slice();
    updatedDiscounts.push({
      supplierId: 0,
      discountPercent: false,
      discountFlat: true,
    });
    update('discounts', updatedDiscounts);
    setDiscountArray(updatedDiscounts);
  };

  const updateDiscountType = (index: number, type: string) => {
    const updatedDiscounts = discountArray.slice();
    const discountPercent = type === 'perc';
    const discountFlat = type === 'flat';
    updatedDiscounts[index] = {
      ...updatedDiscounts[index],
      discountFlat,
      discountPercent,
    };
    update('discounts', updatedDiscounts);
    setDiscountArray(updatedDiscounts);
  };

  const updateSuppliers = <K extends keyof SupplierForm>(
    index: number,
    key: K,
    value: SupplierForm[K],
  ) => {
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[index] = {
      ...updatedSuppliers[index],
      [key]: value,
    };
    setSuppliers(updatedSuppliers);
  };

  const removeItem = (key: string, index: number) => {
    const newList = form[key];
    newList.splice(index, 1);
    setForm({
      ...form,
      [key]: newList,
    });
  };

  // const submit = async (e: FormEvent) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   setSaving(true);
  //   try {
  //     const newForm = form;
  //     let operation = 'Created';
  //     complexFormFields
  //       .filter((field) => field.type === 'list')
  //       .forEach((field) => {
  //         if (newForm[field.name]) {
  //           newForm[field.name] = newForm[field.name].filter((value) => value);
  //         }
  //       });
  //     if (id) {
  //       operation = 'Updated';
  //       await BrandApi.put(Number(id), newForm);
  //       const newParams = queryUtils.remove('id');
  //       history.replace({
  //         search: newParams,
  //       });
  //     } else {
  //       await BrandApi.post(newForm);
  //     }
  //     enqueueSnackbar(`Brand ${operation}`, {
  //       variant: 'success',
  //     });
  //     await onSubmit();
  //   } catch (e) {
  //     enqueueSnackbar(`${e.message || e}`, {
  //       variant: 'error',
  //     });
  //   } finally {
  //     setSaving(false);
  //   }
  // };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!form.brand && (!form.brandAliases || form.brandAliases.length === 0)) {
      enqueueSnackbar('Either brand name or brand aliases must be provided!', {
        variant: 'error',
      });
      return;
    }

    setSaving(true);
    let brandResult;
    try {
      const newForm = form;
      let operation = 'Created';
      complexFormFields
        .filter((field) => field.type === 'list')
        .forEach((field) => {
          if (newForm[field.name]) {
            newForm[field.name] = newForm[field.name].filter((value) => value);
          }
        });
      if (id) {
        operation = 'Updated';
        brandResult = await BrandApi.put(Number(id), newForm);
        const newParams = queryUtils.remove('id');
        history.replace({
          search: newParams,
        });
      } else {
        brandResult = await BrandApi.post(newForm);
      }
      enqueueSnackbar(`Brand ${operation}`, {
        variant: 'success',
      });
      await onSubmit(brandResult);
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const typeLogicCheck = (percent = false, flat = false) => {
    let retVal = 'perc';
    if ((!percent && !flat) || (percent && flat)) {
      retVal = 'perc';
    } else if (flat) {
      retVal = 'flat';
    } else {
      retVal = 'perc';
    }
    return typeList.find((item) => item.value === retVal) || retVal;
  };

  const typeList = [
    { label: 'Flat', value: 'flat' },
    { label: 'Percent', value: 'perc' },
  ];

  const renderTextInput = (
    propertyName: any,
    propertyLabel: string,
    variant: 'outlined' | 'filled' | 'standard' = 'outlined',
  ) => {
    return (
      <TextField
        fullWidth
        variant={variant}
        label={propertyLabel}
        name={propertyName}
        value={form?.[propertyName] || ''}
        onChange={(e) => update(propertyName, e.target.value)}
        required
      />
    );
  };

  const renderListInput = (
    propertyName: any,
    propertyLabel: string,
    variant: 'outlined' | 'filled' | 'standard' = 'outlined',
  ) => {
    const listThing = form?.[propertyName] ? form?.[propertyName] : brandArray;
    return (
      <>
        {/* <Grid item container>
          <Typography variant="h5">Test</Typography>
        </Grid> */}
        <Grid container direction="column">
          {listThing?.map((listItem, index) => {
            return (
              <FormControl key={`brand-${propertyName}-${index + 1}`}>
                <OutlinedInput
                  style={{ margin: 5 }}
                  fullWidth
                  id={`brand-${propertyName}-${index + 1}`}
                  type="text"
                  name={`${propertyName}-${index}`}
                  value={listItem || ''}
                  onChange={(e) =>
                    updateItem(index, e.target.value, propertyName)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label={`remove item from ${propertyLabel} list`}
                        onClick={() => {
                          removeItem(propertyName, index);
                        }}
                      >
                        <FontAwesome
                          size="small"
                          name="times"
                          type="fa"
                          form="fas"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            );
          })}
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              addItem(propertyName);
            }}
            style={{ margin: 5 }}
            size="small"
          >
            Add Alias
          </Button>
        </Grid>
      </>
    );
  };

  const inputTypes = {
    text: renderTextInput,
    list: renderListInput,
  };

  return (
    <form className={classes.form} onSubmit={submit}>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 2.5, pt: 1.5 }}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <IconButton
                onClick={close}
                sx={{ mr: '10px', padding: matchesXs ? '0px' : '' }}
              >
                {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
              <h3>Brand Details</h3>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ px: 2.5 }}>
            <Grid container spacing={2.5}>
              <Grid item container xs={12} sx={{ justifyContent: 'center' }}>
                <img
                  src={
                    image
                      ? image
                      : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                  }
                  width={'fit-content'}
                  height={75}
                  style={{ marginBottom: '16px' }} // Add some spacing between the image and upload button
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2.5}
              xs={12}
              sx={{ justifyContent: 'center' }}
            >
              <Grid item>
                <FileUpload
                  id="brandUpload"
                  onUpload={(urls: string[]) => {
                    if (urls.length > 0) {
                      updateImage(urls[0]);
                    }
                  }}
                  buttonText="Upload Logo"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ px: 2.5 }}>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: 'left',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  Brand Name and Aliases
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {complexFormFields.map((field, index) => (
                  <Grid item key={`edit-brand-${index}`}>
                    {inputTypes[field.type](field.name, field.label)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ px: 2.5 }}>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: 'left',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  Additional Info
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container xs={12} justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        title="Signature Required"
                        checked={form.isMap}
                        onChange={(e) => {
                          update('isMap', e.target.checked);
                        }}
                      />
                    }
                    label="Brand uses MAP"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                justifyContent="space-between"
                sx={{ paddingLeft: 2 }}
              >
                <Grid item container xs={6} justifyContent="flex-start">
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}
                  >
                    Supplier
                  </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-start">
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid item container xs={2} justifyContent="flex-start">
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}
                  >
                    Type
                  </Typography>
                </Grid>
                <Grid item container xs={1} justifyContent="flex-start"></Grid>
                {discountArray
                  ?.filter((item) => item)
                  .map(
                    (
                      {
                        discountAmount,
                        supplierId,
                        discountPercent,
                        discountFlat,
                      },
                      index,
                    ) => {
                      return (
                        <Grid
                          container
                          xs={12}
                          justifyContent="space-between"
                          key={index + 1}
                        >
                          <Grid
                            item
                            container
                            xs={6}
                            justifyContent="flex-start"
                            style={{ marginTop: 5 }}
                          >
                            <SupplierSelect
                              label={''}
                              value={supplierId}
                              onChange={(e) =>
                                updateDiscount(index, 'supplierId', e.value)
                              }
                            />
                          </Grid>
                          <Grid item container xs={3}>
                            <OutlinedInput
                              id={`discount ${index + 1}`}
                              name={`discount ${index}`}
                              style={{
                                margin: '5px',
                                width: '95%',
                                paddingRight: '10px',
                              }}
                              value={discountAmount}
                              onChange={(e) =>
                                updateDiscount(
                                  index,
                                  'discountAmount',
                                  e.target.value,
                                )
                              }
                              type="number"
                            />
                          </Grid>
                          <Grid item container xs={2} style={{ marginTop: 3 }}>
                            <Select
                              labelId={`discField ${index + 1}-label`}
                              id={`discField ${index + 1}`}
                              value={discountPercent == true ? '%' : '$'}
                              variant={'outlined'}
                              onChange={(e: any) =>
                                handleSelectValue(e?.target?.value, index)
                              }
                              className={classes.selectStyle}
                            >
                              <MenuItem key={'$'} value={'$'}>
                                $
                              </MenuItem>
                              <MenuItem key={'%'} value={'%'}>
                                %
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item container xs={1}>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                removeDiscount(index);
                              }}
                              style={{ paddingLeft: '0', paddingRight: '0' }}
                            >
                              <FontAwesome
                                size="small"
                                name="times"
                                type="fa"
                                form="fas"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    },
                  )}
                <Grid item container xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 5 }}
                    onClick={(e) => {
                      addDiscount();
                    }}
                    size="small"
                  >
                    Add Supplier
                  </Button>
                </Grid>
                {suppliers.map((suppliers: SupplierForm, index: number) => (
                  <>
                    <Grid
                      item
                      container
                      xs={8}
                      key={`supplier-${index + 1}`}
                      style={{ marginTop: '5px' }}
                    >
                      <SupplierSelect
                        label={''}
                        value={suppliers.name ?? ''}
                        style={{ width: '95%' }}
                      />
                    </Grid>
                    <Grid item container xs={4}>
                      <FormControl>
                        <OutlinedInput
                          style={{
                            width: '95%',
                            margin: '5px',
                          }}
                          onChange={(e) =>
                            updateDiscount(
                              index,
                              'discountAmount',
                              e.currentTarget.value,
                            )
                          }
                          type="text"
                          value={''}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                aria-label={`remove item from discount list`}
                                onClick={() => {
                                  removeDiscount(index);
                                }}
                              >
                                <FontAwesome
                                  size="small"
                                  name="times"
                                  type="fa"
                                  form="fas"
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container justifyContent="flex-end">
          {/* <Grid item> */}
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          {/* </Grid> */}
        </Grid>
      </Grid>
      <Backdrop open={saving} />
    </form>
  );
}

export default BrandDrawer;
