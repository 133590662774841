import type { Column } from '@src/Components/common/containers/SearchView/types';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Grid, Typography } from '@mui/material';
const placeholderImageUrl = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;

export const commonColumns: Column[] = [
  {
    keyName: 'image',
    title: 'Image',
    align: 'center',
    customComponent: function InventoryImage(row) {
      const imageUrlArray = [];
      if (row.images?.[0]) {
        imageUrlArray.push(row.images[0]);
      };
      if (row.productImages?.[0]) {
        if (Array.isArray(row.productImages[0])) {
          imageUrlArray.push(...row.productImages[0].flat());
        }
      };
      imageUrlArray.push(placeholderImageUrl);
      if (imageUrlArray[0] === placeholderImageUrl) {
        const firstElement = imageUrlArray.shift();
        imageUrlArray.push(firstElement);
      };
      const imageUrl = imageUrlArray[0];
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
              alt={`product image for ${row.manufacturerNo}`}
              width={45}
              height={45}
              style={{ borderRadius: 5 }}
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Item Name',
    keyName: 'name',
    customComponent: function Name(row) {
      return <Typography>
        {row.name} - {row.sku}
      </Typography>
    },
  },
  {
    title: 'MPN',
    keyName: 'manufacturerNo',
    align: 'center',
  },
  {
    title: 'Brand',
    keyName: 'brand',
    align: 'center',
  },
];

export const weekColumns: Column[] = [
  {
    title: 'Last Week',
    keyName: 'lastweekcount',
    customComponent: function LastWeek(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.lastweekdollaramount > row.twoweeksagodollaramount
                  ? 'green'
                  : row.lastweekdollaramount < row.twoweeksagodollaramount
                    ? 'red'
                    : 'inherit',
            }}
          >
            {row.lastweekcount} (${row.lastweekdollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '2 Weeks Ago',
    keyName: 'twoweeksagodata',
    customComponent: function TwoWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.twoweeksagodollaramount > row.threeweeksagodollaramount
                  ? 'green'
                  : row.twoweeksagodollaramount < row.threeweeksagodollaramount
                    ? 'red'
                    : 'inherit',
            }}
          >
            {row.twoweeksagocount} (${row.twoweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '3 Weeks Ago',
    keyName: 'threeweeksagodata',
    customComponent: function ThreeWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.threeweeksagodollaramount > row.fourweeksagodollaramount
                  ? 'green'
                  : row.threeweeksagodollaramount < row.fourweeksagodollaramount
                    ? 'red'
                    : 'inherit',
            }}
          >
            {row.threeweeksagocount} (${row.threeweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '4 Weeks Ago',
    keyName: 'fourweeksagodata',
    customComponent: function FourWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fourweeksagodollaramount > row.fiveweeksagodollaramount
                  ? 'green'
                  : row.fourweeksagodollaramount < row.fiveweeksagodollaramount
                    ? 'red'
                    : 'inherit',
            }}
          >
            {row.fourweeksagocount} (${row.fourweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '5 Weeks Ago',
    keyName: 'fiveweeksagodata',
    customComponent: function FiveWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography
            style={{
              color:
                row.fiveweeksagodollaramount > row.sixweeksagoamount
                  ? 'green'
                  : row.fiveweeksagodollaramount < row.sixweeksagoamount
                    ? 'red'
                    : 'inherit',
            }}
          >
            {row.fiveweeksagocount} (${row.fiveweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: '6 Weeks Ago',
    keyName: 'sixweeksagodata',
    customComponent: function SixWeeks(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography>
            {row.sixweeksagocount} (${row.sixweeksagodollaramount})
          </Typography>
        </Grid>
      );
    },
  },
];

export const supplierColumns: Column[] = [
  {
    title: 'RMATV',
    keyName: 'rocky_mountain_atvmc_1',
    align: 'center',
    customComponent: function rmatv(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.rocky_mountain_atvmc_1 == row?.minCost ? 'green' : 'red',
          }}>
            {row.rocky_mountain_atvmc_1 ? `$${row.rocky_mountain_atvmc_1}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'WPS',
    keyName: 'western_power_sports_2',
    align: 'center',
    customComponent: function wps(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.western_power_sports_2 == row?.minCost ? 'green' : 'red',
          }}>
            {row.western_power_sports_2 ? `$${row.western_power_sports_2}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'AD',
    keyName: 'automatic_distributors_3',
    align: 'center',
    customComponent: function ad(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.automatic_distributors_3 == row?.minCost ? 'green' : 'red',
          }}>
            {row?.automatic_distributors_3 ? `$${row?.automatic_distributors_3}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Dzone',
    keyName: 'dzone_7',
    align: 'center',
    customComponent: function dzone(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.dzone_7 == row?.minCost ? 'green' : 'red',
          }}>
            {row?.dzone_7 ? `$${row?.dzone_7}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Monarch',
    keyName: 'monarch_products_inc_4',
    align: 'center',
    customComponent: function monarch(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.monarch_products_inc_4 == row?.minCost ? 'green' : 'red',
          }}>
            {row?.monarch_products_inc_4 ? `$${row?.monarch_products_inc_4}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'MU',
    keyName: 'moto_united_8',
    align: 'center',
    customComponent: function mu(row) {
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          <Typography style={{
            color: row?.moto_united_8 == row?.minCost ? 'green' : 'red',
          }}>
            {row?.moto_united_8 ? `$${row?.moto_united_8}` : `-`}
          </Typography>
        </Grid>
      );
    },
  },
];
