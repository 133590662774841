import BaseApi from '../BaseApi';

class OrderIssueApi extends BaseApi {
  constructor() {
    super();
  }

  post(payload: any) {
    return this.postBase(`/api/v1/orders/issue/${payload.orderId}`, payload);
  }

  put(id: number, payload: any) {
    return this.putBase(`/api/v1/orders/issue/${id}`, payload)
  }
}

export default OrderIssueApi;