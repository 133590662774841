import React, { useMemo } from 'react';
import useLocation from '@src/hooks/swr/useLocation';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import recoilAnalyticsOptions from '../../../Dashboards/Analytics/AnalyticsHooks/AnalyticsOptions';

const LocationSelect = (props) => {
  const { data: locations } = useLocation();
  const [
    reportOptions,
    setAnalyticsOptions,
  ] = recoilAnalyticsOptions.useAnalyticsOptionsState();

  const options = useMemo(() => {
    if (!locations) return [];
    return locations.map((location) => ({
      value: location.id,
      label: location.name,
    }));
  }, [locations]);

  const updateAnalytics = (input: any) => {
    setAnalyticsOptions((previous) => ({
      ...previous,
      supplierId: input === 0 ? null : input,
    }));
  };
  return (
    <AppSelect
      options={options}
      value={options.find(
        (option) => option.value === reportOptions.supplierId,
      )}
      onChange={(option) => updateAnalytics(option?.value || 0)}
      label="Supplier"
      isClearable
      style={{ zIndex: 999 }}
    />
  );
};

export default LocationSelect;
