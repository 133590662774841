import useMagikTime from '../hooks/useMagikTime';

import { channelOptions } from './constants';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

function ChannelOptions() {
  const [{ channelId }, updateState] = useMagikTime.useState();

  const changeChannelId = (channelId: number) => {
    updateState((previous) => ({
      ...previous,
      channelId,
      lostMoneyOrders: previous.lostMoneyOrders.map((order) => ({
        ...order,
        selected: false,
      })),
    }));
  };

  return (
    <Grid item container xs={12} lg={6} spacing={2}>
      {channelOptions.map((channel) => (
        <Grid item key={channel.value}>
          <Button
            onClick={() => changeChannelId(channel.value)}
            color={channelId === channel.value ? 'primary' : 'inherit'}
            variant={channelId === channel.value ? 'contained' : 'outlined'}
          >
            {channel.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChannelOptions;
