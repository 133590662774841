import { useState, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRoles, useRoleCategories } from '@src/hooks/swr/useRoles';
import useUser from '@src/hooks/swr/useUser';

import { findIndex, startCase, lowerCase } from 'lodash';
import { headers } from './utils';

import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';

import {
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import type { UserRole } from '@oneAppCore/types/roleTypes';
import type { Params } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  subTitle: {
    color: theme.palette.grey[500],
    fontWeight: 500,
  },
  tableMargin: {
    marginBottom: '10px',
  },
  tableMarginUnset: {
    marginBottom: 'unset',
  },
  rowCategoryHeader: {
    width: '30%',
  },
}));

function UserPermissions({ user, edit }: { user: any; edit: Boolean }) {
  const classes = useStyles();

  const [form, setForm] = useState<UserRole[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { id } = useParams<Params>();
  const { data: roles } = useRoles();
  const { data: categories } = useRoleCategories();
  // const { data: user } = useUser(Number(id));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user) {
      setForm(user.roles?.filter((a) => a) || []);
    }
  }, [user]);

  const update = (roleId: number, key: string, value: boolean) => {
    const updatedForm = form.filter((a) => a).slice();
    const index = findIndex(updatedForm, { roleId });
    if (index < 0) {
      updatedForm.push({
        userId: Number(id),
        roleId,
        [key]: value,
      });
    } else {
      updatedForm[index] = {
        ...updatedForm[index],
        [key]: value,
      };
    }
    setForm(updatedForm);
  };

  const handleSelectAll = (value: boolean) => {
    setSelectAll(value);
    const updatedForm = roles.filter((a) => a).filter((r) => ![9, 1].includes(r.roleCategoryId)).map(({ id: roleId }) => ({
      roleId,
      userId: user.id,
      read: value,
      write: value,
      update: value,
      delete: value,
    }));
    console.log('reqUser', roles);
    console.log('form', form);
    setForm(updatedForm);
  };

  useEffect(() => {
    const allChecked = form.filter((a) => a).every(
      (role) => role.read && role.write && role.update && role.delete,
    );
  }, [form]);

  const submit = async () => {
    try {
      await Api.post(`/api/v1/users/${id}/roles`, { userRoles: form });
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const rolesInCategories = categories?.map((category) => {
    const { name } = category;
    return {
      name,
      list: roles
        ?.filter((role) => role.displayName !== 'Remove')
        .filter((role) => role.roleCategoryId === category.id),
    };
  });

  return (
    // <Paper elevation={1} className={classes.root}>
    <Grid item container /* justify="center" */ alignItems="center" spacing={1}>
      <Grid item container style={{ justifyContent: 'center' }} xs={12}>
        <Grid item xs={5} style={{ justifyContent: 'flex-start' }}>
          <Checkbox
            color="primary"
            checked={selectAll}
            onChange={(e) => handleSelectAll(e.target.checked)}
          />
          Select All
        </Grid>
        <Grid item xs={7} style={{ justifyContent: 'center' }}>
          <Typography variant="h5">Role Permissions</Typography>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
          <Typography variant="body1" className={classes.subTitle}>
            Permission according to roles
          </Typography>
        </Grid> */}
      {rolesInCategories?.map((category, index) => {
        return (
          <Table
            key={`role-category-${category.name}-${index}`}
            className={
              index < rolesInCategories.length - 1
                ? classes.tableMarginUnset
                : classes.tableMargin
            }
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  key={`${category.name}-${index}`}
                  className={classes.rowCategoryHeader}
                >
                  {category.name}
                </TableCell>
                {headers
                  .filter((header, index) => index > 0)
                  .map((header) => (
                    <TableCell align="center" key={`${header}-${index}`}>
                      {header}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {category.list?.map((role) => {
                const userRole =
                  form?.find((val) => val?.roleId === role?.id) || {};
                return (
                  <TableRow key={role.id}>
                    <TableCell align="left">
                      {startCase(lowerCase(role.name))}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        disabled={!edit}
                        color="primary"
                        checked={Boolean(userRole.read)}
                        onChange={(e) =>
                          update(role.id, 'read', e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        disabled={!edit}
                        color="primary"
                        checked={Boolean(userRole.write)}
                        onChange={(e) =>
                          update(role.id, 'write', e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        disabled={!edit}
                        color="primary"
                        checked={Boolean(userRole.update)}
                        onChange={(e) =>
                          update(role.id, 'update', e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        disabled={!edit}
                        color="primary"
                        checked={Boolean(userRole.delete)}
                        onChange={(e) =>
                          update(role.id, 'delete', e.target.checked)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        );
      })}
      {edit && (
        <Grid item container justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={submit}>
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default UserPermissions;
