import {
  Card,
  CardContent,
  Grid,
  Box,
  Divider,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { primary } from '@src/Components/Theme/themes/constants';

const keyframes = `
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    borderRadius: 10,
    background: `
                      linear-gradient(-45deg, #A3A6A9, #FFFFFF,
                        #A3A6A9, #FFFFFF,#A3A6A9, #FFFFFF)
                      0% 50%
                    `,
    backgroundSize: '200% 200%',
    animation: 'Gradient 10s ease infinite',
  },
  varBox: {
    borderRadius: 5,
    background: `
                      linear-gradient(-45deg, #A3A6A9, #FFFFFF,
                        #A3A6A9, #FFFFFF,#A3A6A9, #FFFFFF)
                      0% 50%
                    `,
    backgroundSize: '200% 200%',
    animation: 'Gradient 10s ease infinite',
  },
}));

function Loading() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={9} lg={9} style={{ width: '100%' }}>
        <Grid
          container
          xs={12}
          justifyContent="flex-end"
          alignItems="flex-end"
          flex-start
          spacing={3}
          wrap="wrap"
          style={{ padding: 10 }}
        >
          <Card style={{ width: '100%', padding: 20, marginBottom: 30 }}>
            {/* <CardContent> */}
            <Grid item container xs={12} justifyContent="center">
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                justifyContent="center"
                style={{ padding: 15, marginRight: 20 }}
              >
                <Box
                  className={classes.box}
                  style={{
                    height: '100%',
                    width: '70%',
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                style={{ marginTop: 10, padding: 10 }}
              >
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    height: '45px',
                  }}
                ></Box>
              </Grid>
              <Grid container xs={12}>
                <Divider style={{ background: primary.main, marginBottom: 5 }} />
              </Grid>
              <Grid item xs={11} style={{ justifyContent: 'center' }}>
                <Box
                  className={classes.box}
                  style={{
                    marginBottom: 10,
                    marginTop: 20,
                    height: '100px',
                  }}
                ></Box>
              </Grid>
            </Grid>
            {/* </CardContent> */}
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} lg={3} justifyContent="flex-start">
        <Grid container xs={12} alignItems="flex-start">
          <Card
            style={{
              marginLeft: 20,
              marginBottom: 20,
              width: '100%',
              padding: 15,
            }}
          >
            <CardContent>
              <Grid container xs={12}>
                <Grid item container xs={12} justifyContent="center">
                  {/* <Grid item xs={6}> */}
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '50%' }}
                  ></Box>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-start">
                  {/* <Grid item xs={6}> */}
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '50%' }}
                  ></Box>

                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-start">
                  {/* <Grid item xs={6}> */}
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '70%' }}
                  ></Box>
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '70%' }}
                  ></Box>
                  <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent="space-around">
                  {/* <Grid item xs={6}> */}
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '30%' }}
                  ></Box>
                  <Box
                    className={classes.box}
                    style={{ height: '45px', marginBottom: 10, width: '30%' }}
                  ></Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Card style={{ width: '100%' }}>
          <Box
            className={classes.box}
            style={{ height: 55, marginBottom: 20 }}
          ></Box>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-around"
            style={{ marginBottom: 20 }}
          >
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 20 }}></Box>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20, padding: 20 }}>
            <Grid item xs={1}>
              <Box className={classes.varBox} style={{ height: 45 }}></Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Loading;
