export type SelectorOption = {
  value: number;
  label: string;
};

export const limitOptions: SelectorOption[] = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '150',
  },
  // {
  //   value: 100,
  //   label: '100',
  // },
  {
    value: 200,
    label: '200',
  },
  // {
  //   value: 300,
  //   label: '300',
  // },
  // {
  //   value: -1,
  //   label: 'All',
  // },
];
