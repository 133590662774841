import BaseApi from '../BaseApi';

import type { ManufacturerSupplier } from 'types/supplierTypes';

class ManufacturerSuppliersApi extends BaseApi {
  constructor() {
    super();
  }

  put(id: number, payload: Partial<ManufacturerSupplier>) {
    return this.putBase(`/api/v1/manufacturer-suppliers/${id}`, payload);
  }

  putByManSup(
    manNo: number,
    supplierId: number,
    payload: Partial<ManufacturerSupplier>,
  ) {
    return this.putBase(
      `/api/v1/manufacturer-suppliers/${manNo}/${supplierId}`,
      payload,
    );
  }

  post(payload: Partial<ManufacturerSupplier>) {
    return this.postBase(`/api/v1/manufacturer-suppliers`, payload);
  }

  getById(id: number) {
    return this.getBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers/${id}`,
    );
  }

  getBarcodesById(id: number) {
    return this.getBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers/barcode/${id}`,
    );
  }

  getSuppliers() {
    return this.getBase<ManufacturerSupplier>(
      `/api/v1/manufacturer-suppliers/search`,
    );
  }

  deleteById(id: number | string) {
    return this.deleteBase(`/api/v1/manufacturer-suppliers/${id}`);
  }

  deleteUPCToVariation(upcId: number | string) {
    return this.deleteBase(`/api/v1/upc-to-variation/${upcId}`);
  }
  bulkUpdate(payload) {
    return this.putBase(`/api/v1/manufacturer-suppliers/bulkupdate`, payload);
  }
}

export default ManufacturerSuppliersApi;
