import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  SwipeableDrawer,
  Grid,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Props } from './types';
import { columns } from './constants';
import useInventoryScanHist from '@src/hooks/swr/Inventory/useInventoryScanHistory';
import dayjs from 'dayjs';
import { numberGrouper } from '@oneAppCore/utils/formatters';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '400px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: 20,
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

function ViewInventoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [scanList, setScanList] = useState<any[]>([]);
  const [scanFullList, setScanFullList] = useState<any[]>([]);
  const [fitmentList, setFitmentList] = useState<any[]>([]);
  const [fitmentFullList, setFitmentFullList] = useState<any[]>([]);
  const [logList, setLogList] = useState<any[]>([]);
  const [scanPage, setScanPage] = useState(1);
  const [scanLimit, setScanLimit] = useState(10);
  const [fitmentPage, setFitmentPage] = useState(1);
  const [fitmentLimit, setFitmentLimit] = useState(5);

  const classes = useStyles({ mobile });

  const {
    data: { scanned, purchased, fitted, ordered, logged },
  } = useInventoryScanHist(row.id);

  const noTypes = ['Inbound'];
  const noBeforeTypes = ['Override'];
  const qtyMinus = ['Order'];


  useEffect(() => {
    let scanArray: any[] = [];

    scanArray = scanArray.concat(scanned);
    scanArray = scanArray.concat(purchased);
    scanArray = scanArray.concat(ordered);

    setLogList(logged);

    const sortedScans = (scanArray.sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()).reverse());
    const filteredScans = sortedScans.filter((_, index) => index < scanLimit * scanPage && index >= scanLimit * (scanPage - 1));

    setScanFullList(sortedScans);
    setScanList(filteredScans);

    const sortedFitment = fitted.sort((a, b) => (a.make as string)?.localeCompare(b.make) || (a.model as string)?.localeCompare(b.model));
    const filterFitment = sortedFitment.filter((_, index) => index < fitmentLimit * fitmentPage && index >= fitmentLimit * (fitmentPage - 1))

    setFitmentFullList(sortedFitment);
    setFitmentList(filterFitment);

  }, [scanned, purchased, ordered, fitted, fitmentLimit, scanLimit]);

  useEffect(() => {
    setIsLoading(false);
  }, [scanList, fitmentList]);

  useEffect(() => {
    const filtered = scanFullList.filter((_, index) => index < scanLimit * scanPage && index >= scanLimit * (scanPage - 1));
    setScanList(filtered);
  }, [scanPage]);
  useEffect(() => {
    const filtered = fitmentFullList.filter((_, index) => index < fitmentLimit * fitmentPage && index >= fitmentLimit * (fitmentPage - 1));
    setFitmentList(filtered);
  }, [fitmentPage]);

  const handleClose = () => {
    setIsLoading(true);
    return setDrawerOpen();
  }

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => handleClose()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container>
          <Grid container alignItems="center" justifyContent="center">
            <Typography
              variant="h4"
              color="primary"
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Item Details
            </Typography>
          </Grid>
          {columns.map((item, i) => {
            if (item.customComponent) {
              const divider = (<Grid item xs={12}><Divider className={classes.divider} /></Grid>);
              return (
                <>
                  <item.customComponent row={row} />
                  {i < columns.length - 1 ? divider : null}
                </>
              );
            }
            return (
              <Grid key={item.key} container style={{ marginTop: 5 }}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontSize: '1.15rem' }}>
                    {row[item.key]}
                  </Typography>
                </Grid>
                {item.key === 'supplierName' ? (
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                ) : null}
              </Grid>
            );
          })}
          {!isLoading ?
            <>
              <Grid item xs={12} container >
                {scanList.length && scanFullList.length > scanLimit ? <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(scanFullList.length / scanLimit)}
                    color="primary"
                    page={scanPage}
                    onChange={(_, page) => setScanPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid> : null}
                <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  {scanList.length ? <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date (by)</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>From (to)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scanList.map((scanItem, scanIndex) => {
                        let before: any = null;
                        let after: any = scanItem.total;
                        const itemDate = dayjs(scanItem.date);
                        const prevItems = scanFullList.filter((sc) => dayjs(sc.date).valueOf() > itemDate.valueOf());
                        let prevTotal = prevItems.filter((sc) => qtyMinus.includes(sc.scanType)).map((pi) => pi.quantity).reduce((prev, curr) => {
                          return prev + Math.abs(curr);
                        }, 0);
                        prevTotal += prevItems.filter((sc) => sc.scanType === 'Scan').map((pi) => pi.quantity).reduce((prev, curr) => prev + curr, 0);
                        if (noTypes.includes(scanItem.scanType)) {
                          after = null;
                        } else if (noBeforeTypes.includes(scanItem.scanType)) {
                          after = scanItem.total;
                        } else if (qtyMinus.includes(scanItem.scanType)) {
                          before = prevTotal;
                          after = prevTotal + (scanItem.quantity);
                        } else {
                          before = prevTotal;
                          after = prevTotal + (scanItem.quantity);
                        }
                        const beforeAfter = !before && !after ? null : `${before} (${after})`
                        return (
                          <TableRow key={`scanlist-${scanPage}-${scanIndex}`} style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{itemDate.format('MMM DD, YY')}<br />({scanItem.username})</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{scanItem.scanType}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{scanItem.quantity}</TableCell>
                            <TableCell style={{ paddingBottom: '4px', paddingTop: '4px' }}>{beforeAfter}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table> : <p>No History for Part</p>}
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                {fitmentList.length && fitmentFullList.length > fitmentLimit ? <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
                  <Pagination
                    shape={'rounded'}
                    variant={'text'}
                    size="small"
                    count={Math.ceil(fitmentFullList.length / fitmentLimit)}
                    color="primary"
                    page={fitmentPage}
                    onChange={(_, page) => setFitmentPage(page)}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </Grid> : null}
                {fitmentList.length ? <Grid item xs={12} container style={{ padding: 0, maxHeight: '275px', overflowY: 'scroll', marginTop: 15 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fitmentList.map(({ make, model, years }) => {
                        return (
                          <TableRow key={`fitment-${row.id}-${make}-${model}`}>
                            <TableCell>{numberGrouper({ years, formatResult: true })}</TableCell>
                            <TableCell>{make}</TableCell>
                            <TableCell>{model}</TableCell>
                            <TableCell title='This is currently hard coded pending data validation'>ATV</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid> : <p>No Fitment for Part</p>}
              </Grid>
            </>
            : null
          }
        </Grid>
      </SwipeableDrawer>
    </>
  );
}

export default ViewInventoryDrawer;
