import { useMemo } from 'react';
import useMe from '@src/hooks/swr/useMe';

import { ROLE_NAMES } from '@oneAppCore/constants/roles';

import type {
  RoleNames,
  RolePermissions,
  UserRole,
} from '@oneAppCore/types/roleTypes';

type Options = {
  roleNeeded: RoleNames;
  permissionNeeded: RolePermissions;
};

export const useEvaluatedPermissions = ({
  roleNeeded,
  permissionNeeded,
}: Options) => {
  const { data: me } = useMe();

  const hasPermission = useMemo(() => {
    if (!me?.roles) return false;
    return Boolean(
      me.roles.find(
        (role: UserRole) =>
          ROLE_NAMES[role.roleId] === roleNeeded && role[permissionNeeded],
      ),
    );
  }, [roleNeeded, permissionNeeded, me]);

  return hasPermission;
};

export const useHasAdminPermissions = () => {
  const { data: me } = useMe();
  const hasPermission = useMemo(() => {
    if (!me?.roles) return false;
    return Boolean(
      me.roles.find((role: UserRole) =>
        role.roleId === 1 && (role.read || role.write || role.update || role.delete))
    );
  }, []);
  return hasPermission;
};
