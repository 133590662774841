import { borderRadius, primary, greys, boxShadowConfig } from '../constants';

const baseOptions = ({
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'serif'].join(','),
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          marginTop: 5,
          '&&:hover': {
            paddingLeft: 20,
            transition: '.25s ease-in-out',
          },
          '&.Mui-selected': {
            borderRadius,
            backgroundColor: primary.light,
            boxShadow: `${boxShadowConfig} ${primary.light}`,
            '&:hover': {
              backgroundColor: primary.light,
            },
          },
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius,
        },
        elevation1: {
          boxShadow: `${boxShadowConfig} rgba(34, 41, 47, .05)`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: 'none',
          boxShadow: `${boxShadowConfig} rgba(34, 41, 47, 10%)`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&:not(.Mui-focused)': {
            top: -6,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: 3,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: greys.light,
          borderTop: `.5px solid ${greys.border}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          color: greys.main,
          paddingTop: '8px',
          paddingBottom: '8px',
          textTransform: 'uppercase',
          fontSize: '.75rem',
          letterSpacing: 0.75,
        },
        body: {
          color: greys.main,
        },
      },
    },
  },
});

export default baseOptions;
