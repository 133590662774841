import recoilAnalyticsOptions from '../AnalyticsHooks/AnalyticsOptions';

import Grid from '@mui/material/Grid';

import NewerThanSelect from './NewerThanSelect';
import LocationSelect from '../../../common/Selects/LocationSelect';
import ChannelSelect from './ChannelSelect';

function AnalyticsOptions() {
  const reportOptions = recoilAnalyticsOptions.useAnalyticsOptions();

  if (!reportOptions) {
    return <></>;
  }

  return (
    <Grid container item spacing={1}>
      <NewerThanSelect />
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <LocationSelect />
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <ChannelSelect />
      </Grid>
    </Grid>
  );
}

export default AnalyticsOptions;
