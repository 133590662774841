import recoilSearch from '../../hooks/useSearchOptions';
import { useState } from 'react';
import ChannelOptions from '../../ChannelOptions';
import { useHistory } from 'react-router-dom';
import {
  MenuItem,
  Select,
  Button,
  Grid,
  Tooltip,
  Modal,
  Typography,
} from '@mui/material';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import SearchFilters from '../SearchFilters';
import DownloadButton from './DownloadButton';
import ExportButton from '@src/Components/common/containers/SearchView/SearchTable/SearchBar/ExportButton';
import BatchPrintButton from './BatchPrintButton';
import ApproveButton from './ApproveButton';
import BrandDrawer from '@src/Components/Products/Brands/BrandDrawer';
import type { Props } from './types';
import CreationDrawer from '../../CreationDrawer';
import RestockButton from './RestockButton';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CreateButton from '@src/Components/Products/Search/CreateButton';

const SearchBar = ({
  supportsDownload,
  showChannelOptions,
  filters,
  constantFilters,
  exportOptions,
  selectedRows,
  select,
  selectTypes,
  deselectAllRows,
  carrierData,
  selectRow,
  customComponents,
  me,
  resetPagination,
  setResetPagination,
  selectedFilterValues,
  setSelectedFilterValues,
  hasUnsavedRows,
  undo,
  saveChanges,
}: Props) => {
  const [searchState, setSearchState] = recoilSearch.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const update = (limit: number) => {
    setSearchState((previous) => ({
      ...previous,
      limit,
    }));
  };

  const newUser = selectTypes?.includes('create') && (
    <AddCircleRoundedIcon
      color="primary"
      onClick={() => history.push('/admin/users/create')}
      fontSize="small"
    />
  );

  const newCompany = selectTypes?.includes('createCompany') && (
    <AddCircleRoundedIcon
      color="primary"
      onClick={() => history.push('/admin/companies/create')}
      fontSize="small"
    />
  );

  const newStoreChannel = selectTypes?.includes('createSChannel') && (
    <AddCircleRoundedIcon
      color="primary"
      onClick={() => history.push('/store-channel/create')}
      fontSize="small"
    />
  );

  const newProduct = selectTypes?.includes('createProduct') && <CreateButton />;

  // const dayChange = selectTypes?.includes('')

  const brandCreation = selectTypes?.includes('createBrand') && (
    <CreationDrawer Form={BrandDrawer} />
  );

  // const inventoryCreation = selectTypes?.includes('createInventory') && (
  //   <CreationDrawer Form={InventoryDrawer} />
  // );

  const download = supportsDownload && (
    <DownloadButton
      ids={selectedRows.map((sr) => (sr.id ? sr.id : sr)).filter((a) => a)}
    />
  );
  // const exports = exportOptions && (
  //   <ExportButton
  //     selectedRows={selectedRows}
  //     deselectAllRows={deselectAllRows}
  //     selectRow={selectRow}
  //     exportOptions={exportOptions}
  //   />
  // );

  const exports = select && selectTypes?.includes('bulkExport') && (
    <ExportButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      exportOptions={exportOptions}
    />
  );
  const batchPrint = select && selectTypes?.includes('batch-print') && (
    <BatchPrintButton
      carrierData={carrierData}
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      me={me}
    />
  );

  const approveButton = select && selectTypes.includes('approve') && (
    <ApproveButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  );

  const restockButton = select && selectTypes.includes('restock') && (
    <RestockButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  );

  // const channelTabs = showChannelOptions && <ChannelOptions />;

  const bulkActions = select ? (
    <>
      {download}
      {/* {exports} */}
      {batchPrint}
      {approveButton}
      {restockButton}
      {/* {supportsDownload && <DownloadButton />} */}
      {exports}
      {/* {exportOptions && (
        <ExportButton
          exportOptions={exportOptions}
          selectedRows={selectedRows}
          deselectAllRows={deselectAllRows}
          selectRow={selectRow}
        />
      )} */}
    </>
  ) : (
    <></>
  );

  return (
    <Grid
      container
      spacing={3}
      style={{
        margin: 8,
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Grid item xs={12} md={8} container spacing={2} alignItems="flex-start">
        <Grid
          item
          xs={12}
          md={7}
          lg={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {bulkActions}
          {!select && download}
          {newUser}
          {newStoreChannel}
          {newProduct}
          {newCompany}
          {/* {inventoryCreation} */}
          {brandCreation}
          {/* {channelTabs} */}
          {customComponents}
          {hasUnsavedRows ? (
            <>
              <Grid item style={{ marginLeft: 10 }}>
                <Tooltip title={'Save Changes'}>
                  <div
                    style={{
                      color: 'rgb(94, 53, 177)',
                      background: 'rgb(237, 231, 246',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                      width: '34px',
                      height: '34px',
                      fontSize: '1.2rem',
                      transition: 'all 0.2s ease-in-out 0s',
                      cursor: 'pointer',
                    }}
                    // onClick={() => saveChanges() }
                    onClick={() => setModal(true)}
                  >
                    <SaveTwoToneIcon />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <Tooltip title={'Discard Changes'} onClick={undo}>
                  <div
                    style={{
                      color: 'rgb(94, 53, 177)',
                      background: 'rgb(237, 231, 246',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                      width: '34px',
                      height: '34px',
                      fontSize: '1.2rem',
                      transition: 'all 0.2s ease-in-out 0s',
                      cursor: 'pointer',
                    }}
                  >
                    <ReplayTwoToneIcon />
                  </div>
                </Tooltip>
              </Grid>
            </>
          ) : (
            <>
              <Grid item style={{ marginLeft: 10 }}>
                <Tooltip title={'Save Changes'}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '34px',
                      height: '34px',
                      fontSize: '1.2rem',
                      transition: 'all 0.2s ease-in-out 0s',
                      cursor: 'pointer',
                    }}
                  >
                    <SaveTwoToneIcon style={{ color: 'gray' }} />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <Tooltip title={'Discard Changes'}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '34px',
                      height: '34px',
                      fontSize: '1.2rem',
                      transition: 'all 0.2s ease-in-out 0s',
                      cursor: 'pointer',
                    }}
                  >
                    <ReplayTwoToneIcon style={{ color: 'gray' }} />
                  </div>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        {filters && (
          <SearchFilters
            // resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            filters={filters}
            constantFilters={constantFilters}
            selectedFilterValues={selectedFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
          />
        )}
      </Grid>
      <Grid item container alignItems="center" justifyContent="center">
        <Modal
          open={modal}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
          }}
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                // backgroundColor: '#7970dc',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h3>Save Changes?</h3>
              <Grid item container xs={10} style={{ justifyContent: 'center' }}>
                <p
                  style={{
                    fontSize: '20px',
                    marginTop: 25,
                    marginBottom: 25,
                    textAlign: 'center',
                  }}
                >
                  This will submit all changes made!
                </p>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: 20, justifyContent: 'center' }}
              >
                <Grid
                  item
                  container
                  xs={6}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    // color="crimson"
                    style={{
                      color: 'crimson',
                      border: '1px solid crimson',
                    }}
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      saveChanges();
                      setModal(false);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
