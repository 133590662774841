

export const columns = [
  {
    id: 1,
    title: 'In Stock Inventory :',
    key: 'quantity',
  },
  {
    id: 2,
    title: 'Inbound Inventory :',
    key: 'inbound',
  },
  {
    id: 3,
    title: 'Low Stock Products :',
    key: 'lowStock',
  },
  {
    id: 4,
    title: 'Zero Sales Inventory :',
    key: 'zeroSales',
  }
];

export const defaultCardResponse = {
  qty: null,
  value: NaN,
};