import clsx from 'clsx';
import { options } from './constants';
import { useState } from 'react';
import { Grid, Button, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import recoilSearch from '../hooks/useSearchOptions';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import ToggleButton from '@mui/lab/ToggleButton';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundImage: 'linear-gradient(147deg, #4f46b0 0%, #7970dc 90%)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundImage: 'linear-gradient(147deg, #4ea3de 0%, #1a8cdb 74%)',
      color: theme.palette.common.white,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 10,
      marginTop: 10,
      minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {},
    minWidth: 'max-content',
    overflow: 'wrap',
    paddingLeft: '16px',
  },
  toggleGroup: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
}));

function InventoryOptions() {
  const [invOptions, setInvOptions] = recoilSearch.useState();
  const [filterType, setFilterType] = useState('all');
  const classes = useStyles();

  const inventoryChange = (supplierId?: any) => {
    setInvOptions((previous) => ({
      ...previous,
      supplierType: supplierId ? supplierId : null,
    }));
  };

  return (
    <Grid item container alignItems="center" className={classes.responsiveUi}>
      <ToggleButtonGroup size="small" className={classes.toggleGroup}>
        {options.map((option) => (
          <ToggleButton
            value={option.name}
            key={option.name}
            style={{ fontSize: '10px', fontWeight: 600 }}
            className={clsx(
              {
                [classes.selectedButton]:
                  (!invOptions.supplierType && option.value === null) ||
                  invOptions.supplierType === option.value,
              },
              {
                [classes.nonSelectedButton]:
                  (invOptions.supplierType && option.value === null) ||
                  invOptions.supplierType !== option.value,
              },
            )}
            onClick={() => inventoryChange(option.value)}
          >
            {option.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

export default InventoryOptions;
