import BaseApi from '../BaseApi';

class ToolsApi extends BaseApi {
  constructor() {
    super();
  }

  getLostMoney(newerThan: Date, channelId: number, storeId: number) {
    return this.getBase<any[]>(`/api/v1/tools/magik-time/lost-money`, {
      newerThan,
      channelId,
      storeId,
    });
  }

  getRepriceItems(variationIds: number[], channelId: number, storeId: number) {
    return this.getBase<any[]>(`/api/v1/tools/magik-time/reprice-items`, {
      variationIds,
      channelId,
      storeId,
    });
  }

  getMagikTimeCSV(
    selectedVariations: any[],
    channelId: number,
    storeId: number,
  ) {
    return this.postBase<string>(`/api/v1/tools/magik-time/download`, {
      selectedVariations,
      channelId,
      storeId,
    });
  }
}

export default ToolsApi;
