import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Theme,
  Button,
  SwipeableDrawer,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import useVehicleMake from '@src/hooks/swr/useVehicleMake';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/index';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { searchOptionsDefault } from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchPaginator from '@src/Components/common/containers/SearchView/SearchTable/SearchPaginator';
import SearchBar from '@src/Components/common/containers/SearchView/SearchTable/SearchBar';
import { Backdrop } from '@src/Components/common/Loaders';
import DescIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/DescIcon';
import AscIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/AscIcon';
import { FontAwesome } from '@src/Components/common';
import MakeDrawer from './FitmentDrawers/MakeDrawer';
import ModelDrawer from './FitmentDrawers/ModelDrawer';
import useProducts from '@src/hooks/swr/useProducts';
import { bool } from 'joi';
import { Products } from '@src/Components/Products/ProductPage/types';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

function FitmentSearch() {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      margin: theme.spacing(3, 0),
    },
    headerColor: {
      border: 0,
      margin: 5,
    },
    fontColor: {
      color: '#FFF',
      backgroundColor: '#6e6b7b',
      fontSize: 10,
    },
    tableContainer: {
      borderRadius: 0,
    },
    collapseTableContaainder: {
      maxHeight: 250,
      maxWidth: '100%',
      overflowX: 'hidden',
      margin: 10,
      borderRadius: 5,
      border: '1px solid #6e6b7b',
    },
    cellContentWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    filter: {
      width: 20,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(0.5),
    },
    drawerPaper: {
      width: 400,
      padding: theme.spacing(2),
    },
  }));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [resetPagination, setResetPagination] = useState(false);

  const { data: vehicleMakes = [] } = useVehicleMake();
  const makeOptions = vehicleMakes.map((make) => ({
    label: make.name,
    value: make.name,
  }));

  const [, setSearchOptions] = recoilSearch.useState();
  const { data } = useSearch();
  const url = '/api/v1/fitment/search';
  const classes = useStyles();

  const toggleOpen = (drawerType: string = 'editModel') => {
    setDrawerType(drawerType);
    setDrawerOpen(true);
  };
  const close = () => {
    setDrawerType('');
    setDrawerOpen(false);
  };

  const currentYear = new Date().getUTCFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const yearsProps = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row">
            {row?.model}
          </TableCell>
          <TableCell>{row?.make}</TableCell>
          <TableCell align="center">
            {row?.modelYears?.map((year, index, arr) => {
              return (index + 1) % 10 === 0 ? (
                <>
                  {year}
                  <br />
                </>
              ) : index === 0 && arr.length === 1 ? (
                `${year}`
              ) : index === arr.length - 1 ? (
                `${year}`
              ) : (
                `${year}, `
              );
            })}
          </TableCell>
          <TableCell align="center">
            <div>
              <Tooltip title="Edit Fitment">
                <IconButton
                  onClick={() => {
                    setSelectedRow(row);
                    toggleOpen('editModel');
                  }}
                >
                  <EditTwoToneIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <TableContainer className={classes.collapseTableContaainder}>
                  <Table size="small" aria-label="purchases" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.fontColor}>
                          PRODUCT NAME
                        </TableCell>
                        <TableCell align="left" className={classes.fontColor}>
                          SKU
                        </TableCell>
                        <TableCell align="left" className={classes.fontColor}>
                          Edit
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {row?.items?.[0] != null && (
                      <TableBody>
                        {row?.items?.map((item) => (
                          <TableRow key={item?.id}>
                            <TableCell component="th" scope="row">
                              {item?.name}
                            </TableCell>
                            <TableCell align="left">{item?.sku}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() =>
                                  history.push(
                                    `/fitment/view/${item?.variationid}/${item?.name}`,
                                  )
                                }
                              >
                                <EditTwoToneIcon
                                  color="primary"
                                  
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                    {row?.items?.[0] == null && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classes.headerColor}
                            align="center"
                          >
                            This Fitment has no product attached to it.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  useEffect(() => {
    if (url) {
      setSearchOptions({
        ...searchOptionsDefault,
        url,
      });
    }
  }, [url]);

  const createButtons = (
    <>
      <Grid item container xs={12}>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px', maxHeight: '40px', whiteSpace: 'nowrap' }}
            onClick={() => {
              toggleOpen('make');
            }}
          >
            New Make
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            style={{
              margin: '5px',
              maxHeight: '40px',
              whiteSpace: 'nowrap',
            }}
            onClick={() => {
              toggleOpen('model');
            }}
          >
            New Model
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <SwipeableDrawer
        open={drawerOpen}
        onClose={close}
        onOpen={() => null}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        {drawerType === 'editModel' && (
          <ModelDrawer close={close} selectedRow={selectedRow} />
        )}
        {drawerType === 'make' && <MakeDrawer close={close} />}
        {drawerType === 'model' && <ModelDrawer close={close} />}
      </SwipeableDrawer>
      {data ? (
        <>
          <SearchPaginator showChannelOptions={false} resetPagination={resetPagination} setResetPagination={setResetPagination} />
          <Paper className={classes.root}>
            <SearchBar
              customComponents={createButtons}
              filters={[
                {
                  type: 'selector',
                  name: 'Make',
                  keyName: 'make',
                  properties: makeOptions,
                },
                {
                  type: 'multiSelector',
                  name: 'Year',
                  keyName: 'filterYear',
                  properties: yearsProps,
                },
                {
                  type: 'selector',
                  name: 'Has Products',
                  keyName: 'hasProducts',
                  properties: [
                    { label: 'Has Products', value: 'hasProducts' },
                    { label: 'Does Not Have Products', value: 'noProducts' },
                  ],
                },
                {
                  type: 'string',
                  name: 'Model',
                  keyName: 'model',
                },
              ]}
              resetPagination={resetPagination}
              setResetPagination={setResetPagination}
              selectedFilterValues={selectedFilterValues}
              setSelectedFilterValues={setSelectedFilterValues}
            />
            <Grid container item xs={12} style={{ overflowX: 'initial' }}>
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow className={classes.headerColor}>
                      <TableCell align="left">
                        <div className={classes.cellContentWrapper}>
                          <div>MODEL</div>
                          <div className={classes.filter}>
                            <AscIcon keyName="model" />
                            <DescIcon keyName="model" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">MAKE</TableCell>
                      <TableCell align="center">YEAR(S)</TableCell>
                      <TableCell align="center">EDIT</TableCell>
                      <TableCell align="right">VIEW PRODUCTS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row) => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
          <SearchPaginator />
        </>
      ) : (
        <>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <Grid
              item
              container
              xs={5}
              md={3}
              lg={1}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default FitmentSearch;
