import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
} from '@mui/material/styles';
import useVehicleMake from '@src/hooks/swr/useVehicleMake';
import SearchView from '@src/Components/common/containers/SearchView';

import { columns, childColumns } from './constants';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(3, 0),
  },
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  tableContainer: {
    borderRadius: 0,
  },
  collapseTableContaainder: {
    maxHeight: 250,
    maxWidth: '100%',
    overflowX: 'hidden',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
  cellContentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },
  drawerPaper: {
    width: 400,
    padding: theme.spacing(2),
  },
}));

function FitmentSearch() {
  const url = '/api/v1/fitment/search';
  const classes = useStyles();
  const { data: vehicleMakes = [] } = useVehicleMake();
  const makeOptions = vehicleMakes.map((make) => ({
    label: make.name,
    value: make.name,
  }));

  const currentYear = dayjs().year();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const yearsProps = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  return (
    <>
      <SearchView
        url={url}
        // supportsDownload
        columns={columns}
        childColumns={childColumns}
        filters={[
          {
            type: 'selector',
            name: 'Make',
            keyName: 'make',
            properties: makeOptions,
          },
          {
            type: 'multiSelector',
            name: 'Year',
            keyName: 'filterYear',
            properties: yearsProps,
          },
          {
            type: 'selector',
            name: 'Has Products',
            keyName: 'hasProducts',
            properties: [
              { label: 'Has Products', value: 'hasProducts' },
              { label: 'Does Not Have Products', value: 'noProducts' },
            ],
          },
          {
            type: 'string',
            name: 'Model',
            keyName: 'model',
          },
        ]}
      />
    </>
  );
}

export default FitmentSearch;
