import Papa from 'papaparse';
import { useSnackbar } from 'notistack';
import ImportApi from '@oneAppCore/services/apis/Imports';
import { Button, Select, MenuItem, Modal, Grid } from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { tableList, customImports } from '@oneAppCore/constants/import';
import { tableNames } from '@oneAppCore/constants/db';
import { Props } from './types'


const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

const ImportData = ({
  title = 'Import!',
  handleCSVUpload,
  options,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTable, setSelectedTable] = useState<string>('');
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<string>('table');

  const [provider, setProvider] = useState('capone');
  const [fileColumns, setFileColumns] = useState([]);
  const [mapperColumns, setMapperColumns] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [tableSelOpts, setTableSelOpts] = useState<any[]>([]);

  useEffect(() => {
    const hasTableName = options?.tableName;
    const hasTableList = options?.tableList;
    if (hasTableName) {
      setSelectedTable(hasTableName);
      handleCustom(hasTableName);
    } else if (hasTableList) {
      const isString = (typeof hasTableList[0] === 'string');
      if (isString) {
        setTableSelOpts(hasTableList.map((table) => formatOptions(table)));
      } else {
        setTableSelOpts(hasTableList);
      }
    } else {
      const func = async () => {
        const tables = await ImportApi.getTables();
        setTableSelOpts(tables.map((table) => formatOptions(table)));
      };
      func();
    }
  }, []);

  const formatOptions = (option) => {
    const textArray = option?.split('_') || [];
    const label = textArray
      .map((text: string) => {
        const res = `${text[0].toUpperCase()}${text.slice(1)}`;
        return res;
      })
      .join(' ');
    return { label, value: option };
  };

  const handleCustom = (tableName) => {
    if (Object.keys(customImports).includes(tableName)) setUploadType('custom');
  }

  const handleTableChange = (tableName) => {
    setSelectedTable(tableName);
    handleCustom(tableName)
  };

  const tableSelectMapper = (value, index) => {
    let data = mapperColumns;
    data[index].mappedColumn = value;
    setMapperColumns([...data]);
  };

  const parseOptions = {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    delimiter: ',',
  };
  const callHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const csvString = reader.result.toString();
      const parsed = Papa.parse(csvString, parseOptions);
      parsed?.data?.length > 0 && handleCSVSubmit(parsed);
    };

    reader.readAsBinaryString(file);
    e.target.value = '';
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTable(options?.tableName || '');
    setParsedData([]);
    setFileColumns([]);
    setMapperColumns([]);
  };

  const handleSubmit = async () => {
    try {
      let payload = [];
      parsedData.forEach((parsedDatum) => {
        let mappedObject = {};
        mapperColumns.forEach((elem: any) => {
          mappedObject[elem.databaseColumn] = parsedDatum[elem.mappedColumn];
        });
        payload.push(mappedObject);
      });
      await ImportApi.upload(selectedTable,
        uploadType, {
        data: payload,
        opts: { provider },
        // ! this value was added for the variationId to get passed in for the vehicle_fitment custom import feature
        customValues: options?.customValues ? options?.customValues : {},
      });
      enqueueSnackbar(`Data Submitted Sucessfully`, { variant: 'success' });
      handleClose();
    } catch (e) {
      enqueueSnackbar(`Error: ${e?.message || e}`, { variant: 'error' });
    }
  };

  const checkForSpecifcTableAndColumn = (
    parsedRow = [],
    col: string = 'debit',
    tableName: string = tableNames.creditCardTransactions,
  ) => {
    const hasColumn = parsedRow.includes(col);
    const isTable = selectedTable === tableName;
    return hasColumn && isTable;
  };

  const handleCSVSubmit = async (parsedData) => {
    try {
      setParsedData([...parsedData.data]);
      let columnsArray = [];
      let columnsObject = {};
      let fileColumns = [];
      // ! this is the custom logic for the vehicle_fitment table using make, model, and years rather than the db table columns
      if (uploadType.includes('custom')) {
        const columnData: any = { data: customImports[selectedTable] };
        columnData?.data?.forEach((colName, index) => {
          columnsObject[colName.toLowerCase()] = index;
          columnsArray.push({
            databaseColumn: colName,
            mappedColumn: '',
          });
        });
      } else {
        const columnData: any = await ImportApi.getTableColumns(selectedTable);
        const filterColArray: any[] = tableList[selectedTable] || [];
        columnData?.data
          .filter(
            (colName) => !filterColArray.concat(['skip']).includes(colName),
          )
          .map((colName, index) => {
            columnsObject[colName.toLowerCase()] = index;
            columnsArray.push({
              databaseColumn: colName,
              mappedColumn: '',
            });
          });
      }
      Object.keys(parsedData?.data[0]).map((colName) => {
        fileColumns.push(colName);
        const colIndex = columnsObject[colName.toLowerCase()];
        if (typeof colIndex === 'number') {
          columnsArray[colIndex].mappedColumn = colName;
        }
      });
      if (checkForSpecifcTableAndColumn(Object.keys(parsedData?.data[0]))) {
        setProvider('amex');
      }

      setMapperColumns(columnsArray);
      setFileColumns(fileColumns);
      setOpen(true);
    } catch (e) {
      enqueueSnackbar(`Error: ${e?.message || e}`, { variant: 'error' });
    }
  };
  return (
    <>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={callHandler}
        disabled={selectedTable === ''}
      />
      <label htmlFor="contained-button-file">
        <Button
          disabled={selectedTable === ''}
          variant="contained"
          color="primary"
          component="span"
        >
          {title}
        </Button>
      </label>

      {!options?.hideTable && <Select
        value={selectedTable}
        style={{ marginLeft: '30px', width: '150px' }}
        onChange={(e: any) => handleTableChange(e?.target?.value)}
        disabled={options?.tableName && selectedTable !== ''}
      >
        {tableSelOpts.map((option) => (
          <MenuItem
            key={`${option.value}-${option.label}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>}

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          style={{
            width: '60%',
            height: 'auto',
            backgroundColor: 'white',
            padding: 10,
          }}
        >
          <Grid item container xs={12} style={{ justifyContent: 'center' }}>
            <h1>Map Data Columns</h1>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {mapperColumns.map((item, i) => (
              <Grid
                key={i}
                item
                xs={3}
                md={2}
                lg={2}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <span>{item.databaseColumn}</span>
                <Select
                  value={item.mappedColumn}
                  style={{ marginTop: '10px' }}
                  onChange={(e: any) => tableSelectMapper(e?.target?.value, i)}
                >
                  <MenuItem key={`select-00`} value={''} selected>
                    Select File Column
                  </MenuItem>
                  {fileColumns?.map((columnName, index) => {
                    return (
                      <MenuItem
                        key={`${columnName}-${index}`}
                        value={`${columnName}`}
                      >
                        {columnName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            xs={12}
            spacing={5}
            style={{ padding: 20, justifyContent: 'flex-end' }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ background: 'rgb(244, 67, 54)', color: '#fff' }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ImportData;
