import recoilChartOptions from '../SalesChartHooks/SalesChartOptions';
import recoilAnalyticsOptions from '../AnalyticsHooks/AnalyticsOptions';

import Grid from '@mui/material/Grid';

import SalesChartSelect from './SalesChartSelect';
import UnitsSoldChart from '../UnitsSoldChart';
import GrossRevenueByDayChart from '../GrossRevenueByDayChart/GrossRevenueByDayChart';
import OrdersByChannelBar from '../OrdersByChannelBar';

function SalesChartOptions() {
  const chartOptions = recoilChartOptions.useChartOptions();
  const analyticsOptions = recoilAnalyticsOptions.useAnalyticsOptions();

  if (!chartOptions.chart) {
    return <></>;
  }

  return (
    <Grid container item spacing={1}>
      {chartOptions.chart === 'units-sold' && (
        <UnitsSoldChart supplierId={analyticsOptions.supplierId} unitsBack={analyticsOptions.newerThan} />
      )}
      {chartOptions.chart === 'orders-by-channel-bar' && (
        <OrdersByChannelBar unitsBack={analyticsOptions.newerThan} />
      )}
      {chartOptions.chart === 'gross-revenue' && (
        <GrossRevenueByDayChart unitsBack={analyticsOptions.newerThan} />
      )}
    </Grid>
  );
}

export default SalesChartOptions;
