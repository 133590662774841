import numberUtils from '@oneAppCore/utils/numbers';

import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { formatCentsToDollars } from '@src/utils/currency';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      fontWeight: 'bold',
    },
  }),
);

const profitRows = [
  {
    keyName: 'cost',
    title: 'Total Cost',
    type: 'money',
    column: 1,
  },
  {
    keyName: 'price',
    title: 'Total Revenue',
    type: 'money',
    column: 1,
  },
  {
    keyName: 'profitMargin',
    title: 'Total Profit Margin',
    type: 'percent',
    column: 1,
  },
  {
    keyName: 'profit',
    title: 'Total Profit',
    type: 'money',
    column: 1,
  },
  {
    keyName: 'firstProf',
    title: 'Orders < 4%',
    type: 'number',
    column: 2,
  },
  {
    keyName: 'secProf',
    title: 'Orders < 10%',
    type: 'number',
    column: 2,
  },
  {
    keyName: 'thirdProf',
    title: 'Orders < 20%',
    type: 'number',
    column: 2,
  },
  {
    keyName: 'fourProf',
    title: 'Orders > 20%',
    type: 'number',
    column: 2,
  },
  // {
  //   keyName: '',
  //   title: '',
  // },
];

function CostBreakdown(props: any) {
  const classes = useStyles();

  const oldRows = (
    <Grid item container xs={6} direction="column">
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">{'Orders < 1%: '}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {props?.zeroProfitCount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Orders 1-5%: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {props?.oneToFiveProfitCount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Orders 5-10%: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {props?.fiveToTenProfitCount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Orders 10-15%: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {props?.tenToFifteenProfitCount}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Orders over 15%: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {props?.overFifteenProfitCount}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
  const oldTotals = (
    <Grid item container xs={6} direction="column">
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Total Cost: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {formatCentsToDollars(props?.cost)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Total Revenue: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {formatCentsToDollars(props?.price)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Total Profit Margin: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {numberUtils.formatNumber(props?.profitMargin, '0.00')}%
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Total Profit: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.number}>
            {formatCentsToDollars(props?.profit)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <SearchViewMiniReport>
      <Tooltip
        title="Orders that are not unassigned or have an issue"
        placement="top"
      >
        <Grid container xs={12}>
          {/* {oldTotals} */}
          <Grid item container xs={6} direction="column">
            {profitRows.filter(({ column }) => column === 1).map(({ title, keyName, type }) => {
              let value = props?.[keyName];
              if (type === 'money') {
                value = formatCentsToDollars(props?.[keyName]);
              } else if (type === 'percent') {
                value = `${numberUtils.formatNumber(props?.[keyName], '0.00')}%`;
              }
              return (
                <Grid item container spacing={1} key={`${keyName}-totals`}>
                  <Grid item>
                    <Typography variant="body1">{title}: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={classes.number}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {/* {oldRows} */}
          <Grid item container xs={6} direction="column">
            {profitRows.filter(({ column }) => column === 2).map(({ title, keyName }) => {
              return (
                <Grid item container spacing={1} key={`${keyName}-prof`}>
                  <Grid item>
                    <Typography variant='body1'>{title}: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' className={classes.number}>
                      {props?.[keyName] || 0}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default CostBreakdown;
