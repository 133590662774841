import dayjs from 'dayjs';

export const options = [
  {
    label: 'Last Day',
    value: dayjs().subtract(1, 'days').toISOString(),
  },
  {
    label: 'Last 2 Days',
    value: dayjs().subtract(2, 'days').toISOString(),
  },
  {
    label: 'Last 7 Days',
    value: dayjs().subtract(7, 'days').toISOString(),
  },
  {
    label: 'Last 30 Days',
    value: dayjs().subtract(30, 'days').toISOString(),
  },
  {
    label: 'Last 60 Days',
    value: dayjs().subtract(60, 'days').toISOString(),
  },
  {
    label: 'Last 90 Days',
    value: dayjs().subtract(90, 'days').toISOString(),
  },
];
