import { Grid } from '@mui/material';

import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import BrandDrawer from './BrandDrawer';

function BrandSearch() {
  const { data: suppliers = [] } = useSuppliers();

  const supplierOptions = suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="/api/v1/brands/list"
        columns={columns}
        selectTypes={['createBrand']}
        filters={[
          {
            type: 'selector',
            name: 'Supplier',
            keyName: 'supplierId',
            properties: supplierOptions,
          },
        ]}
      />
    </Grid>
  );
}

export default BrandSearch;
