import { useRecoilValue, useRecoilState } from 'recoil';

import { chartOptionsAtom } from './atoms';

import type { ChartOptionsAtom } from './types';

export function useChartOptionsState() {
  return useRecoilState<ChartOptionsAtom>(chartOptionsAtom);
}

export function useChartOptions() {
  return useRecoilValue<ChartOptionsAtom>(chartOptionsAtom);
}
