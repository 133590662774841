import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Tooltip,
  Grid,
  Button,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import FontAwesome from '@src/Components/common/FontAwesome';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';
import { formatCentsToDollars, convertDollarsToCents } from '@oneAppCore/utils/currency';
import RestockApi from '@oneAppCore/services/apis/Restock';
import { WpsItem, WpsCart, WpsCartResponse, PoItem } from './types';
import { WPSIR, supplierIds, AD } from '@oneAppCore/constants/suppliers';
import ManufacturerSuppliersApi from '@oneAppCore/services/apis/ManufacturerSuppliers';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  table: {
    borderCollapse: 'collapse',
  },
  cell: {
  },
  header: {
  },
}));



function RestockButton({ selectedRows, deselectAllRows }) {
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [groupedRows, setGroupedRows] = useState({});
  const [loading, setLoading] = useState(false);
  const { mutate } = useSearch();
  const { enqueueSnackbar } = useSnackbar();
  const calculatedRows = [];

  const submitWpsCart = async (po: string) => {
    const response = await RestockApi.submitWpsRestockCart(po);
    return response;
  }
  const deleteWpsCart = async (poNumber: string, supplierId: number = 2) => {
    const response = await RestockApi.deleteWpsCart(poNumber);
    const tempRows = { ...groupedRows };
    tempRows[supplierId] = null;
    setGroupedRows(tempRows);
    return response;
  }
  const getWpsOrderDetails = async (poNumber: string, supplierId: number = 2) => {
    const response = await RestockApi.getWpsOrder(poNumber);
    return response;
  }
  const placeAutoDistOrder = async (row) => {
    const itemOrderRequestObj = row.items.map((item) => ({
      quantity: item.quantity, number: item.supplierItemNo
    }));
    const createAutoDistOrderResponse: any = await RestockApi.placeAutoDistOrder(row.po, itemOrderRequestObj);
    if (createAutoDistOrderResponse && createAutoDistOrderResponse.header && createAutoDistOrderResponse.header.message === 'sales order created successfully') {
      return 'OK'
    } else return null;

  }


  const approveOrders = async () => {
    setClicked(true);
    setIsLoading(true);

    try {
      const processedSupplierIds = new Set();
      const promiseArray = Object.keys(groupedRows).map(async (key) => {
        const supplierId = parseInt(key);
        const po = groupedRows[key].po;
        let addRecord = true;
        if (groupedRows[key].api) {
          if (supplierId === supplierIds[WPSIR]) {
            const submitWpsCartResponse = await submitWpsCart(po.toString());
            if (submitWpsCartResponse !== 'OK') {
              addRecord = false;
              deleteWpsCart(po.toString());
            }
            const wpsOrder: any = await getWpsOrderDetails(po.toString());
            groupedRows[key].actualCost = wpsOrder.order_details[0].order_total;
            const newGroupedRowsItems = wpsOrder.order_details[0].items.map((item) => {
              const amountPurchased = item.ship_quantity;
              const matchedRow = groupedRows[key].items.find((e) => e.supplierItemNo === item.sku);
              const manufacturerId = matchedRow.id;
              return { amountPurchased, manufacturerId };
            });
            groupedRows[key].items = newGroupedRowsItems;
          }
          if (supplierId === supplierIds[AD]) {
            const submitAutoDistOrderResponse = await placeAutoDistOrder(groupedRows[key])
            if (submitAutoDistOrderResponse !== 'OK') {
              addRecord = false;
            }
          }
        }
        if (!processedSupplierIds.has(supplierId) && addRecord) {
          processedSupplierIds.add(supplierId);
          const apiCall = RestockApi.create({
            purchaseOrderNo: po,
            supplierId: supplierId,
            items: groupedRows[key].items.map((item) => ({ amountPurchased: item.amountPurchased, manufacturerId: item.manufacturerId })),
            totalCost: convertDollarsToCents(groupedRows[key].actualCost),
            estTotalCost: groupedRows[key].estCost,
          });
          return apiCall;
        }

      });

      await Promise.all(promiseArray);
      enqueueSnackbar(`Placed orders successfully`, {
        variant: 'success',
      });
      mutate();
      deselectAllRows();
    } catch (error) {
      enqueueSnackbar(`Error placing internal order`, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  }

  const handleOpen = async () => {
    setClicked(true);
    setOpen(true);
    setLoading(true);
  };

  const handleClose = () => {
    if (groupedRows[supplierIds[WPSIR]] && groupedRows[supplierIds[WPSIR]].po) {
      deleteWpsCart(groupedRows[supplierIds[WPSIR]].po);
    }
    setClicked(false);
    setOpen(false);
    setLoading(false);
    setIsLoading(false);
  };
  const checkCartExistence = async (poNumber: string): Promise<WpsCartResponse> => {
    const response = await RestockApi.showWpsCart(poNumber);
    return response;
  }

  const createWpsCart = async (poNumber: string): Promise<string> => {
    const response = await RestockApi.createWpsCart(poNumber)
    return response as string;
  }
  const addItemsToWpsCart = async (po: string, wpsPoItems: WpsItem[]): Promise<any> => {
    const response = await RestockApi.addItemsToWpsCart(po, wpsPoItems);
    return response;
  }
  const wpsRowCount = selectedRows?.reduce((acc, curr) => {
    const counter = curr.selectedSupplier === supplierIds[WPSIR] ? 1 : 0;
    return acc + counter
  }, 0);
  const totalOrderedQuantity = selectedRows?.reduce((acc, curr) => {
    return acc + curr.qtyNeeded;
  }, 0)

  const verifyWpsCart = async (po: string, wpsItemAddResponse: WpsItem[]): Promise<WpsItem[]> => {
    const currentWpsCart = await checkCartExistence(po);
    const items = currentWpsCart?.items?.length ? currentWpsCart.items : []
    const totalCartQuantity = items.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0);
    if (items?.length > wpsRowCount || totalCartQuantity > totalOrderedQuantity || items?.length === 0) {
      await deleteWpsCart(po);
      return [{ quantity: 0 }];
    }
    const confirmedItems = items.map((item) => {
      const matchedRow = wpsItemAddResponse.find((e) => e.supplierItemNo === item.item)
      return { ...item, ...matchedRow };
    })
    return confirmedItems;
  }
  const wpsPoItems: WpsItem[] = selectedRows.filter((row) => row.selectedSupplier === supplierIds[WPSIR]).map((item) => ({
    amountPurchased: item.qtyNeeded,
    manufacturerId: item.id,
  }));

  const wpsCartFunction = async (lpo) => {
    try {
      const lpoString = lpo.toString();
      let wpsCartCheckResponse = await checkCartExistence(lpoString);
      if (wpsCartCheckResponse === `P/O number does not exist. ${lpoString}`) {
        await createWpsCart(lpoString);
        const wpsItemAddResponse = await addItemsToWpsCart(lpo, wpsPoItems);
        const verifyCartResponse: WpsItem[] = await verifyWpsCart(lpoString, wpsItemAddResponse);
        return { po: lpo, items: [...verifyCartResponse], api: true };
      } else {
        const wpsItemAddResponse = await addItemsToWpsCart(lpo, wpsPoItems);
        const verifyCartResponse: WpsItem[] = await verifyWpsCart(lpoString, wpsItemAddResponse);
        return { po: lpo, items: [...verifyCartResponse], api: true };
      }

    } catch (error) {
      console.log(error);
    }
  };
  const autoDistCalculateFunction = async (po, filteredRows) => {
    for await (const row of filteredRows) {
      await new Promise(resolve => setTimeout(resolve, filteredRows.length * 150));
      const manSupRow = await ManufacturerSuppliersApi.getById(row.supplierId);
      const supplierItemNo = manSupRow?.supplierItemNo ?? null;
      if (supplierItemNo) {
        const itemCaluculateRequestObj = {
          quantity: row.amountPurchased, number: supplierItemNo
        }
        const adApiResponse: any = await RestockApi.calculateAutoDistOrder(po, itemCaluculateRequestObj);
        if (adApiResponse && adApiResponse.totalInvoiceAmount) {
          const rowWithSku = {
            ...row, supplierItemNo, actualCost: convertDollarsToCents(adApiResponse.totalInvoiceAmount)
          }
          calculatedRows.push(rowWithSku);
        }
      }
    }
    return { po, calculatedRows, api: true };
  }


  const groupRowsFunction = async () => {
    if (open) {
      let { lastPO: lpo } = await Api.get(
        '/api/v1/manufacturer-suppliers-restock/po',
      );
      lpo = parseInt(lpo);
      lpo += 1; // adding one because api sends last po number.
      let temp = {};
      for (let index = 0; index < selectedRows.length; index++) {
        const element = selectedRows[index];
        if (element.selectedSupplier) {
          const supplierData = element?.supplierData?.find((s) => s?.supplierId == parseInt(element?.selectedSupplier));
          if (!temp[element.selectedSupplier]) {
            temp[element.selectedSupplier] = {
              name: supplierData.name,
              estCost: supplierData.cost * element.qtyNeeded,
              actualCost: null,
              po: lpo,
              quantity: element.qtyNeeded,
              items: [{ manufacturerId: element.id, amountPurchased: element.qtyNeeded }],
              api: supplierData.supplierId === supplierIds[WPSIR] || supplierData.supplierId === supplierIds[AD] ? true : false,
            };
            lpo += 1;
          } else {
            let tempObj = temp[element.selectedSupplier];
            tempObj.estCost = tempObj.estCost + (supplierData.cost * element.qtyNeeded);
            tempObj.quantity = tempObj.quantity + element.qtyNeeded;
            tempObj.items.push({ manufacturerId: element.id, amountPurchased: element.qtyNeeded });
            temp[element.selectedSupplier] = tempObj;
          };
        };
      };
      setGroupedRows(temp);
      if (selectedRows?.some((row) => row.selectedSupplier === supplierIds[WPSIR])) {
        const selectedPo = temp[supplierIds[WPSIR]]?.po ? temp[supplierIds[WPSIR]].po : lpo;
        const wpsCart: WpsCart = await wpsCartFunction(selectedPo);
        const estCost = wpsCart?.items?.reduce((acc, curr) => {
          return acc + (curr.quantity * curr.cost)
        }, 0);
        const po = wpsCart?.po ? wpsCart.po : '';
        const items = wpsCart?.items ? wpsCart.items : [];
        const api = wpsCart?.api ? wpsCart.api : false;

        const quantity = wpsCart?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0)
        temp[supplierIds[WPSIR]] = {
          name: WPSIR,
          estCost,
          actualCost: formatCentsToDollars(estCost, { removeMoneySign: true }),
          po,
          quantity,
          items,
          api,
        };
        lpo += 1;
      }
      if (selectedRows?.some((row) => row.selectedSupplier === supplierIds[AD])) {
        const selectedPo = temp[supplierIds[AD]]?.po ? temp[supplierIds[AD]].po : lpo;
        const filteredRows = selectedRows.filter((supplier) => supplier.selectedSupplier === supplierIds[AD]).map((row) => {
          const adSupplierData = row?.supplierData?.filter((e) => e.supplierId === supplierIds[AD]);
          const available = adSupplierData[0]?.quantity;
          const supplierId = adSupplierData[0]?.mid;
          const returnObject = {
            amountPurchased: row.qtyNeeded,
            quantity: row.qtyNeeded,
            available: available,
            cost: row.cost,
            supplierId,
            manufacturerId: row.id,
          }
          return returnObject;
        });

        const autoDistRows = await autoDistCalculateFunction(selectedPo, filteredRows);
        const estCost = autoDistRows?.calculatedRows?.reduce((acc, curr) => {
          return acc + (curr.quantity * curr.cost)
        }, 0);
        const po = autoDistRows?.po ? autoDistRows.po : '';
        const items = autoDistRows?.calculatedRows ? autoDistRows.calculatedRows : [];
        const api = autoDistRows?.api ? autoDistRows.api : false;

        const quantity = autoDistRows?.calculatedRows?.reduce((acc, curr) => {
          return acc + curr.quantity;
        }, 0);
        const actualCost = formatCentsToDollars(autoDistRows?.calculatedRows?.reduce((acc, curr) => {
          return acc + curr.actualCost;
        }, 0), { removeMoneySign: true });
        temp[supplierIds[AD]] = {
          name: AD,
          estCost,
          actualCost,
          po,
          quantity,
          items,
          api,
        };
        lpo += 1;
      }
      setGroupedRows(temp);
      setLoading(false);
    } else {
      setGroupedRows({});
    };

  };


  useEffect(() => {
    if (open) {
      groupRowsFunction();
    }
    if (!open) {
      if (groupedRows[supplierIds[WPSIR]] && groupedRows[supplierIds[WPSIR]].po) {
        deleteWpsCart(groupedRows[supplierIds[WPSIR]].po);
      }
    }
  }, [open]);



  const updateFunction = (key, value, property) => {
    let tempObj = { ...groupedRows };
    tempObj[key][property] = parseFloat(value);
    setGroupedRows(tempObj);
  };

  return (
    <>
      <Grid item container xs={8} spacing={1} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={5}>
          {!clicked && (
            <Tooltip title={'Place internal order'}>
              <span>
                <Button
                  disabled={selectedRows?.length < 1}
                  variant="contained"
                  color="primary"
                  style={{ width: 'calc(100%)' }}
                  onClick={async () => await handleOpen()}
                >
                  Order {selectedRows.length > 0 ? selectedRows.length : ''}
                </Button>
              </span>
            </Tooltip>
          )}
          {clicked && (
            <Tooltip title={'loading'}>
              <span>
                <Button
                  disabled
                  color="secondary"
                  variant="contained"
                  style={{ width: 'calc(100%)' }}
                  startIcon={
                    <FontAwesome
                      name="circle-notch"
                      type="fas"
                      form="fa"
                      spin
                    />
                  }
                >
                  Loading
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 700,
              backgroundColor: 'white',
              borderRadius: 10,
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: '-40px',
                }}
              >
                <Button onClick={() => handleClose()}>
                  <FontAwesome
                    type="fa"
                    form="fa"
                    name="times"
                  />
                </Button>
              </Grid>
              <h1>Internal Restock</h1>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead className={classes.header}>
                    <TableRow>
                      <TableCell className={classes.cell}>
                        Supplier
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Qty
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Estimated
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Actual
                      </TableCell>
                      <TableCell className={classes.cell}>
                        PO#
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(groupedRows).filter((e) => groupedRows[e]).map((key) => (

                      <TableRow key={key}>
                        <TableCell className={classes.cell}>
                          {groupedRows[key]?.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {groupedRows[key]?.quantity}
                        </TableCell>
                        <TableCell className={classes.cell} style={{ color: 'green' }}>
                          {formatCentsToDollars(groupedRows[key]?.estCost)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <FormControl style={{ margin: 1, width: 100 }}>
                            {loading && groupedRows[key].api ?
                              (<FontAwesome
                                name="circle-notch"
                                type="fas"
                                form="fa"
                                spin
                              />) :
                              (<OutlinedInput
                                id="standard-adornment-act-cost"
                                type="number"
                                disabled={groupedRows[key].api}
                                value={
                                  groupedRows[key]?.actualCost || ''}
                                error={!groupedRows[key]?.actualCost || groupedRows[key]?.actualCost < 0}
                                onChange={(e) => {
                                  let value = e.currentTarget.value;
                                  updateFunction(key, value, 'actualCost');
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <div style={{ color: !groupedRows[key]?.actualCost || groupedRows[key]?.actualCost < 0 ? 'red' : 'black' }}>$</div>
                                  </InputAdornment>
                                }
                              />)}

                          </FormControl>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <FormControl style={{ margin: 1, width: 100 }}>
                            <OutlinedInput
                              id="standard-adornment-po"
                              type="number"
                              value={groupedRows[key]?.po || ''}
                              error={!groupedRows[key]?.po || groupedRows[key]?.po < 0}
                              onChange={(e) => {
                                let value = e.currentTarget.value;
                                updateFunction(key, value, 'po');
                              }}
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 20, justifyContent: 'flex-end' }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClose()}
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => approveOrders()}
                style={{ marginLeft: 5, marginRight: 5 }}
                disabled={Object.keys(groupedRows).findIndex((key) => !groupedRows[key]?.actualCost || groupedRows[key]?.actualCost < 0) > -1 || isLoading}
              >
                Approve
              </Button>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default RestockButton;
