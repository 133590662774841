import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  text: {
    fontSize: '1.3rem',
  },
  highlight: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <Grid container id="footer" className={classes.root}>
      <Grid item>
        <Typography variant="body1">
          Copyright &#169; 2023 OneApp; All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
