import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { colorTheme } from '@oneAppCore/constants/internalvdropship';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LineChart from '@src/Components/common/charts/LineChart';
import ReportApi from '@oneAppCore/services/apis/Reports';
import { Props } from '../types';
import { unitDateLabel } from '../utilities';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function ShippingImpactChart({ unitsBack, unitType, channelId }: Props) {
  const [data, setData] = useState<any[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
        setData([]);
        const response = await ReportApi.get('SHIPPING_IMPACT_REPORT', {
          channelId,
          newerThan,
          page: 1,
          limit: 1000000,
        });

        const today = dayjs().startOf('day');
        const yesterday = today.subtract(1, 'day');

        if (!response || !response.rows) {
          console.error('Invalid response structure:', response);
          return;
        }

        const rows = response.rows;
        const colorMapping = {
          '1 Day': '#F3AC74',
          '2 Day': '#2188E5',
          '3 Day': '#B4EDD2',
          '4 Day': '#5F37B1',
          '5+ Days': '#73BF44',
        };

        const isToday = rows.some((row: any) => 'hour' in row);

        const isYesterday = rows.some((row) =>
          dayjs(row.hour).isSame(yesterday, 'day'),
        );
        setYesterday(isYesterday);

        if (isToday || isYesterday) {
          const hours = Array.from({ length: 24 }, (_, i) => {
            const hour = i % 12 || 12;
            const period = i < 12 ? 'AM' : 'PM';
            return `${hour.toString().padStart(2, '0')}:00 ${period}`;
          });

          const shippingTypes = ['1 Day', '2 Day', '3 Day', '4 Day', '5+ Days'];
          const hourlyData: { [key: string]: { [key: string]: number } } = {};

          rows.forEach((row: any) => {
            const hour = dayjs(row.hour).format('hh:00 A');
            shippingTypes.forEach((type) => {
              if (!hourlyData[type]) hourlyData[type] = {};
              hourlyData[type][hour] =
                (hourlyData[type][hour] || 0) + Number(row[type]);
            });
          });

          console.log('Hourly Data:', hourlyData);

          const datasets = shippingTypes.map((type) => ({
            label: type,
            data: hours.map((hour) => hourlyData[type][hour] || 0),
            backgroundColor: colorMapping[type] || 'gray',
            borderColor: colorMapping[type] || 'gray',
            hoverOffset: 5,
          }));

          setLabels(hours);
          setData(datasets);
        } else {
          const shippingTypes = ['1 Day', '2 Day', '3 Day', '4 Day', '5+ Days'];
          const dailyData: { [key: string]: { [key: string]: number } } = {};

          rows.forEach((row: any) => {
            const date = dayjs(row['Purchase Date']).format('MM/DD/YYYY');
            shippingTypes.forEach((type) => {
              if (!dailyData[type]) dailyData[type] = {};
              dailyData[type][date] =
                (dailyData[type][date] || 0) + Number(row[type]);
            });
          });

          const dateList = [];
          let tempBack = 0;
          while (tempBack < unitsBack) {
            dateList.push(dayjs().subtract(tempBack, 'days').format('MM/DD/YYYY'));
            tempBack++;
          }

          const sortedDates = dateList.sort((a, b) =>
            dayjs(a, 'MM/DD/YYYY').isBefore(dayjs(b, 'MM/DD/YYYY')) ? -1 : 1,
          );

          const datasets = shippingTypes.map((type) => ({
            label: type,
            data: sortedDates.map((date) => dailyData?.[type]?.[date]/*  || 0 */),
            backgroundColor: colorMapping[type] || 'gray',
            borderColor: colorMapping[type] || 'gray',
            hoverOffset: 5,
          }));
          setLabels(sortedDates);
          setData(datasets);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    })();
  }, [unitsBack, unitType, channelId]);

  const unitsLabel = `Order Shipping Methods ${unitDateLabel({
    unitsBack,
    unitType,
  })}`;

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">{unitsLabel}</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <LineChart
          labels={labels}
          data={data}
          stacked
          title={unitsLabel}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ShippingImpactChart;
