import useProductOperations from './hooks/useProductOperations';

import dayjs from 'dayjs';
import numberUtils from '@oneAppCore/utils/numbers';

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FontAwesome from '@src/Components/common/FontAwesome';
import { CircularProgress, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  'bg-synced': {
    backgroundColor: 'rgba(97,185,137, .1) !important',
  },
  'bg-nah': {
    backgroundColor: 'rgba(207,131,132, .1) !important',
  },
}));

function ProductOperations() {
  const classes = useStyles();
  const { data } = useProductOperations();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Store Name</TableCell>
            <TableCell>Channel</TableCell>
            <TableCell>Synced</TableCell>
            <TableCell>Channel Listings</TableCell>
            <TableCell>One App Listings</TableCell>
            <TableCell>Variance</TableCell>
            <TableCell>Scanned At</TableCell>
          </TableRow>
        </TableHead>
        {data ? (
          <TableBody>
            {(data || []).map((row) => {
              const synced = dayjs(row.scannedAt).isAfter(
                dayjs().subtract(21, 'minutes'),
              );
              const badVariance = row.variance < -40 || row.variance > 40;
              return (
                <TableRow
                  key={row.storeName + row.channelName}
                  className={synced ? classes[`bg-synced`] : classes[`bg-nah`]}
                >
                  <TableCell>{row.storeName}</TableCell>
                  <TableCell>
                    <img
                      src={row.icon}
                      height="30px"
                      width="30px"
                      style={{ borderRadius: 5 }}
                    />
                  </TableCell>
                  <TableCell>
                    {synced ? (
                      <FontAwesome
                        name="check-circle"
                        type="fa"
                        form="fa"
                        color="green"
                      />
                    ) : (
                      <FontAwesome
                        name="times-circle"
                        type="fa"
                        form="far"
                        color="red"
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.channelListings}</TableCell>
                  <TableCell>
                    {numberUtils.formatNumber(row.oneAppListings, '0,0')}
                  </TableCell>
                  <TableCell style={{ color: badVariance ? 'red' : 'black' }}>
                    {(row.variance || 0) + '%'}
                  </TableCell>
                  <TableCell>
                    {dayjs(row.scannedAt).format('MM/DD/YYYY hh:mma')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <Grid
            item
            container
            xs={12}
            style={{
              marginTop: 20,
              marginBottom: 20,
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
      </Table>
    </TableContainer>
  );
}

export default ProductOperations;
