import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Collapse } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RecoilRoot } from 'recoil';
import Theme from './Components/Theme';
import Routes from './routes';

function App() {
  return (
    <Theme>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RecoilRoot>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3} TransitionComponent={Collapse}>
              <Routes />
            </SnackbarProvider>
          </BrowserRouter>
        </RecoilRoot>
      </LocalizationProvider>
    </Theme>
  );
}

export default App;
