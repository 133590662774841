import { useState, useEffect, FormEvent } from 'react';
import {
  Modal,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Grid,
  Button,
  Typography,
  TableBody,
} from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import useVarDataBulk from '@src/Components/Inventory/Management/hooks/useVarDataBulk';
import type { ListingModalProps } from './types';
import { useSnackbar } from 'notistack';
import { useApprovePendingListings } from './utils';
import { CircularProgress } from '@mui/material';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

const placeholderImageUrl = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function ListingModal({
  modalOpen,
  setModalOpen,
  onClose,
  selectedRows,
  channels,
  productData,
  setProductData,
  setLoading,
  onlyNewListings = false,
  createdListings,
  setCreatedListings,
}: ListingModalProps) {
  const [selectedRowsData, setSelectedRows] = useState([]);
  const [variationData, setVariationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  
  const close = () => {
    setModalOpen(false);
    setProductData([]);
  };
  // const { id: paramId } = useParams<RouteParams>();
  // const productIds = selectedRowsData.map((item) => item.id);

  /*  const { data: variations, mutate: mutateVariations } = useVariationData({
     id: Number(productData.id), onlyNewListings
   }); */

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await useVarDataBulk(productData.map((row) => row.id), onlyNewListings);
        setVariationData(data);
      } catch (error) {
        console.error('Error fetching variation data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [productData, setIsLoading, onlyNewListings]);

  /*   useEffect(() => {
      mutateVariations();
    }, [modalOpen]); */
  const variationsBulk = variationData.flat();

  /* const listings = variations?.map((variation, i) => {
    return variation.listings;
  }); */
  const bulkListings = variationsBulk?.map((variation, i) => {
    return variation?.listings;
  });
  const approveListings = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const prepSnackBarKey = enqueueSnackbar(`Approving Listings...`, {
      variant: 'info',
      autoHideDuration: null,
    });
    try {
      const listingIdsForApproval = createdListings.map((listing) => listing.id);
      await useApprovePendingListings(listingIdsForApproval);
      enqueueSnackbar(`Approved Listings!`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
      close();
      closeSnackbar(prepSnackBarKey);
      localStorage.removeItem('listings');
    }
  };

  return (
    <Grid container xs={12} style={{ justifyContent: 'center' }}>
      <Modal className={classes.modal} open={modalOpen} onClose={onClose}>
        <Grid
          container
          style={{
            width: 600,
            backgroundColor: 'white',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 15,
          }}
        >
          <Grid item style={{ marginBottom: 15 }}>
            <Typography variant="h6">Created Listings</Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            style={{
              justifyContent: 'center',
              marginBottom: 10,
              maxHeight: '200px',
              overflowY: 'scroll',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: '#f3f2f7',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Item Name</TableCell>
                  <TableCell align="center">Channel</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {listings !== undefined
                  ? listings?.map((innerListings, index) =>
                    innerListings?.map((listing, i) => {
                      const variation = variations[index];
                      return (
                        <TableRow key={`${listing.id}-${i}`}>
                          <TableCell align="center">
                            <img
                              height={40}
                              width={40}
                              src={variation.imageUrl}
                            />
                          </TableCell>
                          <TableCell>
                            {variation.name} {variation.variation}
                          </TableCell>
                          <TableCell>
                            <img height={40} width={40} src={listing.icon} />
                          </TableCell>
                          <TableCell>{listing.status}</TableCell>
                          <TableCell>
                            {formatCentsToDollarsIntl(listing.price)}
                          </TableCell>
                        </TableRow>
                      );
                    }),
                  ) 
                  :*/}
                {bulkListings?.map((row, index) =>
                  row?.map((item, i) => {
                    const variation = variationsBulk[index];
                    const imageUrlArray = [];
                    if (variation.imageUrl && variation.imageUrl !== '') {
                      imageUrlArray.push(variation.imageUrl);
                    };
                    if (variation.productImages && variation.productImages[0]) {
                      imageUrlArray.push(...variation.productImages.flat());
                    };
                    imageUrlArray.push(placeholderImageUrl);
                    const imageUrl = imageUrlArray[0];
                    return (
                      <TableRow key={`${item.id}-${i}`}>
                        <TableCell align="center">
                          <img
                            height={40}
                            width={40}
                            src={imageUrl}
                          />
                        </TableCell>
                        <TableCell>
                          {variation.name} {variation.variation}
                        </TableCell>
                        <TableCell>
                          <img height={40} width={40} src={item.icon} />
                        </TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(item.price)}
                        </TableCell>
                      </TableRow>
                    );
                  }),
                )}
              </TableBody>
            </Table>
          </Grid>

          <Grid item container style={{ justifyContent: 'flex-end' }}>
            {/* <Grid item>
              <Button
                onClick={() => {
                  onClose();
                  setProductData([]);
                }}
                style={{ color: 'red' }}
              >
                Close
              </Button>
            </Grid> */}
            <Button
              onClick={() => history.push('/listings/draft')}
              color="primary"
            >
              View In Drafts
            </Button>
            <Grid item>
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={(e) => approveListings(e)}
                color="primary"
              >
                {isLoading && <CircularProgress color='info' />}
                Approve Listings
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
}

export default ListingModal;
