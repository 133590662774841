import { selector } from 'recoil';
import dayjs from 'dayjs';
import qs from 'query-string';

import { searchOptionsAtom } from './atoms';

export const searchOptionsUrlSelector = selector<string>({
  key: 'searchOptionsUrlSelector',
  get: ({ get }) => {
    const searchOptions = get(searchOptionsAtom);
    const { filters: optFilt, channel: channelId, searchFilter: optionsSearchFilter, url, csv, ...rest } = searchOptions;
    if (!url) return '';
    const basicSearchOptsFIltered = Object.assign({}, rest);
    const optionsFilters = Object.assign({}, optFilt);
    Object.keys(rest).forEach(key => {
      if (key.includes('startDate') || key.includes('endDate')) {
        basicSearchOptsFIltered[key] = dayjs(basicSearchOptsFIltered[key]).toISOString();
      }
    })
    Object.keys(optionsFilters).forEach(key => {
      if (key.includes('startDate') || key.includes('endDate')) {
        if(!optionsFilters[key]) {
          delete optionsFilters[key];
        } else {
          optionsFilters[key] = dayjs(optionsFilters[key]).toISOString();
        }
      }
    })
    const basicSearchOptions = qs.stringify(basicSearchOptsFIltered, { arrayFormat: 'bracket' });
    const channel = channelId ? `&${qs.stringify({ channelId }, { arrayFormat: 'bracket' })}` : '';
    const searchFilter = optionsSearchFilter ? `&${qs.stringify({ searchFilter: optionsSearchFilter }, { arrayFormat: 'bracket' })}` : '';
    const filters = `&${qs.stringify(optionsFilters, { arrayFormat: 'bracket' })}`;
    const csvFilter = searchOptions?.csv ? `&csv=true` : '';

    return `${url}?${basicSearchOptions}${filters}${channel}${searchFilter}${csvFilter}`;
  },
});
