import { atom } from 'recoil';

import type { ChartOptionsAtom } from './types';
import { ANALYTIC_INS } from '@oneAppCore/constants/listings';

export const chartOptionsAtom = atom<ChartOptionsAtom>({
  key: 'chart3-options',
  default: {
    chart: ANALYTIC_INS,
  },
});
