import React from 'react';

import { tabs } from '../constants';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import type { Props } from './types';
import type { OperationTab } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function OperationTabs({ selectedTab, changeTab }: Props) {
  const classes = useStyles();

  const handleChange = (_: React.ChangeEvent<{}>, newTab: OperationTab) => {
    changeTab(newTab);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {tabs.map((tab) => (
            <Tab label={tab} value={tab} key={tab} />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
