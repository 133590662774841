import { useState, useEffect, useMemo } from 'react';
import useChannelCategories from '@src/hooks/swr/useChannelCategories';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import NestedCategory from './NestedCategory';

import type { Props, StyleProps } from './types';
import colors from '@oneAppCore/constants/colors';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    marginTop: -7,
    paddingBottom: 0,
  },
  listItemRoot: {
    border: `1px solid ${colors.GREYS.lightest}`,
    borderRadius: 5,
    height: 40,
  },
  primaryText: {
    fontSize: 11,
  },
  secondaryText: ({ hasSelectedId }: StyleProps) => ({
    fontSize: 15,
    color: hasSelectedId ? theme.palette.black.main : theme.palette.greys.main,
  }),
}));

function ChannelSelect({
  onChange,
  required,
  value,
  backgroundColor,
  mutateVar,
  selectedLabel = 'Selected',
  hasSelectLabel = true,
}: Props) {
  const { data: channelCategories, mutate } = useChannelCategories();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = useState<number | null>(value ?? null);

  const classes = useStyles({ hasSelectedId: Boolean(selectedId) });

  useEffect(() => {
    if (value !== undefined) {
      setSelectedId(value);
    }
  }, [value]);

  useEffect(() => {
    mutate();
  }, [mutateVar]);
  // useEffect(() => {
  //   if (selectedId) {
  //     const selectedCategory = channelCategories?.find(
  //       (category) => category.id === selectedId,
  //     );
  //     onChange(selectedId, selectedCategory?.categoryName);
  //     updateUnsavedChangesRef(selectedId, selectedCategory.categoryName);
  //   }
  // }, [selectedId, channelCategories, updateUnsavedChangesRef]);

  useEffect(() => {
    if (selectedId && channelCategories?.length > 0) {
      const selectedCategory = channelCategories?.find(
        (category) => category?.id === selectedId,
      );

      if (selectedCategory) {
        // Trigger the onChange to update the parent
        onChange(selectedId, selectedCategory.categoryName);
      }
    }
  }, [selectedId]);

  const coreOptions = useMemo(() => {
    if (!channelCategories) return [];
    return channelCategories
      .filter((channelCategory) => !channelCategory.parentCategoryId)
      .map((parentChannelCategory) => {
        const hasChildren = Boolean(
          channelCategories?.find(
            (channelCategory) =>
              channelCategory?.parentCategoryId === parentChannelCategory?.id,
          ),
        );
        return {
          value: parentChannelCategory.id,
          label: parentChannelCategory.categoryName,
          parentId: parentChannelCategory.parentCategoryId,
          hasChildren,
        };
      });
  }, [channelCategories]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const primaryLabel = <Typography variant="caption">Category{required ? '*' : ''}</Typography>;
  const selectLabel = hasSelectLabel ? `${selectedLabel}: ` : '';
  const secondaryLabel = `${selectLabel}${channelCategories?.find((channelCategory) => channelCategory?.id === selectedId)?.categoryName}`;

  return (
    <>
      {hasSelectLabel ? primaryLabel : null}
      <List
        disablePadding
        classes={{
          root: classes.listRoot,
        }}
        style={{
          backgroundColor: backgroundColor || 'transparent',
        }}
      >
        <ListItem
          button
          onClick={handleClick}
          classes={{
            root: classes.listItemRoot,
          }}
        >
          <ListItemText
            classes={{
              primary: classes.primaryText,
              secondary: classes.secondaryText,
            }}
            secondary={
              selectedId
                ? (secondaryLabel)
                : 'Category...'
            }
          />
        </ListItem>
      </List>
      <Menu
        id="category-channel"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableAutoFocusItem
      >
        {coreOptions.map((option) => {
          if (option.hasChildren) {
            return (
              <NestedCategory
                categoryId={option.value}
                selectedId={selectedId}
                onChange={(id) => setSelectedId(id)}
                key={option.value}
                label={option.label}
              />
            );
          } else {
            return (
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                onClick={() => setSelectedId(option.value)}
                selected={selectedId === option.value}
                key={option.value}
                style={{
                  fontSize: 15,
                  padding: 0,
                  paddingLeft: 5,
                }}
              >
                {option.label}
              </MenuItem>
            );
          }
        })}
      </Menu>
      {required && (
        <input
          required={Boolean(required)}
          tabIndex={-1}
          autoComplete="off"
          name="Channel"
          style={{
            opacity: 0,
            height: 0,
            width: '100%',
          }}
          value={selectedId ?? ''}
          onChange={() => null}
        />
      )}
    </>
  );
}

export default ChannelSelect;
