import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

import type { ChannelCategoryResponse } from './types';

export default function useChannelCategories() {
  return useSWR<ChannelCategoryResponse[]>(
    `/api/v1/channel-categories`,
    Api.get,
  );
}
