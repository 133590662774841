import { Grid, Tabs, Tab, AppBar, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { PageProps, TabPanelProps } from './types'


const ImportData = ({
  tabs = [],
  options = {
    variant: 'standard',
    scrollButtons: 'auto',
    orientation: 'horizontal',
  },
}: PageProps) => {
  const [tabTitles, setTabTitles] = useState<string[]>([]);
  const [tabContents, setTabContents] = useState<any[]>([]);
  const [currentIndex, setTabIndex] = useState<number>(0);
  const [tabLength, setTabLength] = useState<number>(0);



  const TabPanel = (props: TabPanelProps) => {
    const { children, currentIndex, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={currentIndex !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {currentIndex === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    let titles = tabs.map((_, index) => `Tab ${index + 1}`);
    let contents = tabs;
    let length = tabs.length;
    let disabledTabs = 0;
    if (tabs[0].title) {
      titles = tabs.map((tab) => tab.title);
      contents = tabs.map((tab) => tab.child);
      tabs.forEach((tab) => {
        if (tab.options?.disabled) disabledTabs++;
      });
      length -= disabledTabs
    }
    setTabLength(length);
    setTabTitles(titles);
    setTabContents(contents);
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const tabBar = (
    <AppBar position="static">
      <Tabs value={currentIndex} onChange={handleTabChange} aria-label="simple tabs example">
        {tabTitles.map((tabTitle, index) => <Tab key={`tab-${index + 1}`} label={tabTitle} {...a11yProps(index)} />)}
      </Tabs>
    </AppBar>
  );

  return (
    <>
      {tabLength > 1 ? tabBar : null}
      <Grid xs={12}>
        {tabContents.map((tabContent, index) => <TabPanel currentIndex={currentIndex} index={index} key={`content-${index + 1}`}>{tabContent}</TabPanel>)}
      </Grid>
    </>
  );
};

export default ImportData;
