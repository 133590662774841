import { DatePicker } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import type { Props } from './types';
import type { Dayjs } from 'dayjs';
import djs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

export default function DateSelect(props: Props) {
  const classes = useStyles();

  const {
    containerClass,
    label,
    labelClass,
    value,
    onChange,
    startOfDay,
    endOfDay,
    ...rest
  } = props;

  const handleChange = (input: any) => {
    const date = (() => {
      if (!input) return null;
      if (startOfDay) {
        return djs(input).startOf('day');
      }
      if (endOfDay) {
        return djs(input).endOf('day');
      }
      return djs(input);
    })();
    return onChange(date);
  };
  return (
    <div className={containerClass || classes.selectContainer}>
      {label && (
        <Typography
          variant="caption"
          component="span"
          className={labelClass || classes.label}
        >
          {`${label}${props.required ? '*' : ''}`}
        </Typography>
      )}
      <DatePicker
        value={value || null}
        onChange={(dayjs: any) => handleChange(dayjs)}
        // inputVariant="outlined"
        // variant="dialog"
        // clearable
        // autoOk
        {...rest}
      />
    </div>
  );
}
