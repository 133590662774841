import dateUtils from '@oneAppCore/utils/dates';

export const dateOptions = [
  {
    label: 'Last Day',
    value: dateUtils.createDate().subtract(1, 'days').toDate(),
  },
  {
    label: 'Last 2 Day',
    value: dateUtils.createDate().subtract(2, 'days').toDate(),
  },
  {
    label: 'Last 7 Days',
    value: dateUtils.createDate().subtract(7, 'days').toDate(),
  },
  {
    label: 'Last 15 Days',
    value: dateUtils.createDate().subtract(15, 'days').toDate(),
  },
  {
    label: 'Last 30 Days',
    value: dateUtils.createDate().subtract(30, 'days').toDate(),
  },
];
