import { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import ReportApi from '@oneAppCore/services/apis/Reports';
import { Props } from '../types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { EllipseLoader } from '@src/Components/common/Loaders';
import PieceRateChart from '../PieceRateChart';
import TopProducts from '../TopProductsCard';
import InventoryStats from '../InventoryStatsCard';
import TopCategories from '../TopCategories';
import ShipStationFee from '../ShipStationFee';
import formatters from '@oneAppCore/utils/formatters';
import SalesTrendsCard from '../SalesTrendsChart/SalesTrendsChart';
import { CANCELLED, ISSUE, UNASSIGNED } from '@oneAppCore/constants/orders';
import styles from './HeaderCards.style';
import recoilAnalyticsOptions from '../AnalyticsHooks/AnalyticsOptions';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import dateUtils from '@oneAppCore/utils/dates';
import { ORDER_GROSS } from '@oneAppCore/constants/analytics';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';

const useStyles = makeStyles(styles);

function HeaderCards({ supplierId, unitsBack, unitType, channelId }: Props) {
  const classes: any = useStyles();
  const [cardData, setCardData] = useState(null);
  const [pastCardData, setPastCardData] = useState(null);
  const [orderCounts, setOrderCounts] = useState({ current: 0, previous: 0 });
  const [prevDays, setPrevDays] = useState(0);
  const isMobile = useMediaQuery('(max-width:900px)');

  const [productCostData, setProductCostData] = useState(null);
  const [totalSalesData, setTotalSalesData] = useState(null);
  const [totalData, setTotalData] = useState<any>({});
  const analyticsOptions = recoilAnalyticsOptions.useAnalyticsOptions();

  useEffect(() => {
    (async () => {
      setCardData(null);
      const { daysPrev } = dateUtils.getDaysBack({
        unitsBack,
        unitType,
      });
      setPrevDays(daysPrev);
      const response = await ReportApi.get('ANALYTICS', {
        timeframe: String(unitsBack),
        page: 1,
        limit: 1,
      });
      const pastResponse = await ReportApi.get('ANALYTICS', {
        timeframe: `${unitsBack}p`,
        page: 1,
        limit: 1,
      });

      const reportObj: any = {}
      const pastReportObj: any = {}
      response.rows.forEach((row) => {
        if (!reportObj[row.subCategory]) reportObj[row.subCategory] = row.total ?? -1;
      });
      pastResponse.rows.forEach((row) => {
        if (!pastReportObj[row.subCategory]) pastReportObj[row.subCategory] = row.total ?? -1;
      });
      const report = {
        ...reportObj,
        sales: reportObj.gross - reportObj.fees,
        // orders: ordersCount ?? -1,
        profit: (reportObj.gross - reportObj.fees) - reportObj.cost,
      }
      const pastReport = {
        ...pastReportObj,
        sales: pastReportObj.gross - pastReportObj.fees,
        // orders: pastOrdersCount ?? -1,
        profit: (pastReportObj.gross - pastReportObj.fees) - pastReportObj.cost,
      }


      setCardData(report);
      setPastCardData(pastReport);
    })();
  }, [unitsBack]);
  // useEffect(() => {
  //   (async () => {
  //     const { daysPrev } = dateUtils.getDaysBack({
  //       unitsBack,
  //       unitType,
  //     });
  //     setPrevDays(daysPrev);
  //     const newerThan = dayjs().endOf('day').subtract(unitsBack + 1, 'days').toISOString();
  //     const newerThanOld = dayjs().endOf('day').subtract((unitsBack + 1) * 2, 'days').toISOString();

  //     const orderResp = await ReportApi.get('ORDER_ITEMS', {
  //       supplierId,
  //       newerThan,
  //       page: 1,
  //       limit: 1000000,
  //     });
  //     const pastOrderResp = await ReportApi.get('ORDER_ITEMS', {
  //       supplierId,
  //       newerThan: newerThanOld,
  //       olderThan: newerThan,
  //       page: 1,
  //       limit: 1000000,
  //     });
  //     // setOrderCounts({ current: orderResp.count ?? -1, previous: pastOrderResp.count ?? -1 });
  //   })();
  // }, [supplierId]);

  let datesObject = {};

  let currentDate = dayjs().subtract(unitsBack, 'day');

  [...Array(unitsBack)].forEach((item) => {
    const date = dayjs(currentDate).format('MM/DD/YYYY');
    datesObject[date] = 0;
    currentDate = dayjs(currentDate).add(1, 'day');
  });

  const productDataSet = (totalData && Object.keys(totalData) || []).reduce((prev, curr) => {
    const total =
      totalData[curr].subtotal +
      totalData[curr].taxes +
      totalData[curr].shipping;
    const date = dayjs(curr/* , 'MM-DD-YYYY HH:mm:ss' */).format(
      'MM/DD/YYYY',
    );
    if (!prev[date] && prev[date] !== 0) prev[date] = total;
    if (prev[date] || prev[date] === 0) {
      prev[date] = prev[date] + total;
    }
    return prev;
  }, datesObject);
  const salesDataSet = (totalData && Object.keys(totalData) || []).reduce((prev, curr) => {
    const total = totalData[curr].gross - totalData[curr].fees;
    const date = dayjs(curr/* , 'MM-DD-YYYY HH:mm:ss' */).format(
      'MM/DD/YYYY',
    );
    if (!prev[date] && prev[date] !== 0) prev[date] = total;
    if (prev[date] || prev[date] === 0) {
      prev[date] = prev[date] + total;
    }
    return prev;
  }, {});

  let costData = {
    labels: Object.keys(productDataSet),
    datasets: [
      {
        label: 'Total Cost',
        data: Object.values(productDataSet).map((a: number) => formatCentsToDollars(a, { removeMoneySign: true, ignoreCommas: true })),
        fill: false,
        borderColor: 'white',
      },
    ],
  };
  /* 
    const salesDataSet = (totalSalesData || []).reduce((prev, curr) => {
      const sales = curr['After Fees'];
      const date = dayjs(curr['Purchase Date'], 'MM-DD-YYYY HH:mm:ss').format(
        'MM/DD/YYYY',
      );
      if (prev[date] || prev[date] === 0) {
        prev[date] = prev[date] + sales;
      }
      return prev;
    }, datesObject);

    let costData = {
      labels: Object.keys(productDataSet),
      datasets: [
        {
          label: 'Total Cost',
          data: Object.values(productDataSet),
          fill: false,
          borderColor: 'white',
        },
      ],
    };
  */

  let salesData = {
    labels: Object.keys(salesDataSet),
    datasets: [
      {
        label: 'Total Sales',
        data: Object.values(salesDataSet).map((a: number) => formatCentsToDollars(a, { removeMoneySign: true, ignoreCommas: true })),
        fill: false,
        borderColor: 'white',
      },
    ],
  };

  return (
    <>
      <Grid className={classes.container}>
        <Grid>
          <Card>
            <CardContent
              className={formatters.classNames([
                classes.totalSalesCard,
                classes.headerCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" container className={classes.jstSpcBtw}>
                <Grid item xs={2}>
                  <Grid className={classes.headerIcon}>
                    <FontAwesome
                      name="dollar-sign"
                      type="fas"
                      form="fa"
                    ></FontAwesome>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {cardData ? (
                    <>
                      <p className={classes.cardFont}>
                        <span>Revenue</span>
                        <br />
                        {isMobile && cardData.sales > 1000
                          ? `${formatCentsToDollars(Math.round(cardData.sales) / 1000)}K`
                          : formatCentsToDollars(cardData.sales)}
                      </p>
                    </>
                  ) : (
                    <EllipseLoader style={{ display: 'flex' }} />
                  )}
                </Grid>
                <Grid
                  direction="row"
                  container
                  item
                  xs={6}
                  className={classes.jstSpcBtw}
                  style={{ position: 'relative' }}
                >
                  <div
                    style={{
                      width: '90%',
                      height: '115%',
                      position: 'absolute',
                      right: 0,
                      bottom: '10%',
                      zIndex: 50,
                    }}
                  >
                    {/* <Line
                      data={salesData}
                      options={{
                        elements: {
                          point: {
                            borderWidth: 0,
                            radius: 10,
                            backgroundColor: 'rgba(0,0,0,0)',
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                        scales: {
                          x: {
                            display: false,
                            grid: {},
                          },

                          y: {
                            display: false,
                            grid: {},
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    /> */}
                  </div>
                </Grid>
              </Grid>
              {cardData ? (
                <Grid container>
                  <Grid item xs={4}>
                    <p
                      className={formatters.classNames([
                        classes.cardFont,
                        'mini',
                      ])}
                    >
                      <span>Gross Sales</span>
                      <br />
                      {isMobile && cardData.gross > 1000
                        ? `${formatCentsToDollars(Math.round(cardData.gross) / 1000)}K`
                        : formatCentsToDollars(cardData.gross)}
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      className={formatters.classNames([
                        classes.cardFont,
                        'mini',
                      ])}
                    >
                      <span>Est. Fees</span>
                      <br />
                      {isMobile && cardData.fees > 1000
                        ? `${formatCentsToDollars(Math.round(cardData.fees) / 1000)}K`
                        : formatCentsToDollars(cardData.fees)}
                    </p>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span>Prev. Revenue</span>
                        <br />
                        {isMobile && pastCardData?.sales > 1000
                          ? `${formatCentsToDollars(Math.round(pastCardData?.sales) / 1000)}K`
                          : formatCentsToDollars(pastCardData?.sales)}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      {pastCardData?.sales > cardData.sales ? (
                        <div>
                          <ArrowCircleDownTwoToneIcon />
                        </div>
                      ) : (
                        <div>
                          <ArrowCircleUpTwoToneIcon />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.mobileMarginTop}>
          <Card>
            <CardContent
              className={formatters.classNames([
                classes.totalCostCard,
                classes.headerCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid container direction="row" className={classes.jstSpcBtw}>
                <Grid container item xs={2}>
                  <Grid
                    className={classes.headerIcon}
                    style={{ backgroundColor: 'rgb(21, 101, 192)' }}
                  >
                    <FontAwesome
                      name="dollar-sign"
                      type="fas"
                      form="fa"
                    ></FontAwesome>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  {cardData ? (
                    <>
                      <p className={classes.cardFont}>
                        <span style={{ color: 'lightblue' }}>Total Cost</span>
                        <br />
                        {isMobile && cardData.cost > 1000
                          ? `${formatCentsToDollars(Math.round(cardData.cost) / 1000)}K`
                          : formatCentsToDollars(cardData.cost)}
                      </p>
                    </>
                  ) : (
                    <EllipseLoader style={{ display: 'flex' }} />
                  )}
                </Grid>
                <Grid
                  direction="row"
                  container
                  item
                  xs={6}
                  className={classes.jstSpcBtw}
                  style={{ position: 'relative' }}
                >
                  <div
                    style={{
                      width: '90%',
                      height: '115%',
                      position: 'absolute',
                      right: 0,
                      bottom: '10%',
                      zIndex: 50,
                    }}
                  >
                    {/* <Line
                      data={costData}
                      options={{
                        elements: {
                          point: {
                            borderWidth: 0,
                            radius: 10,
                            backgroundColor: 'rgba(0,0,0,0)',
                          },
                          line: {
                            tension: 0.5,
                          },
                        },
                        scales: {
                          x: {
                            display: false,
                            grid: {},
                          },

                          y: {
                            display: false,
                            grid: {},
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    /> */}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                {cardData ? (
                  <>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>
                          Subtotal Costs
                        </span>
                        <br />
                        {isMobile && cardData?.subtotal > 1000
                          ? `${formatCentsToDollars(Math.round(cardData?.subtotal) / 1000)}K`
                          : formatCentsToDollars(cardData?.subtotal)}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>
                          Shippping Cost
                        </span>
                        <br />
                        {isMobile && cardData?.shipping > 1000
                          ? `${formatCentsToDollars(Math.round(cardData?.shipping) / 1000)}K`
                          : formatCentsToDollars(cardData?.shipping)}
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        className={formatters.classNames([
                          classes.cardFont,
                          'mini',
                        ])}
                      >
                        <span style={{ color: 'lightblue' }}>Taxes</span>
                        <br />
                        {isMobile && cardData?.taxes > 1000
                          ? `${formatCentsToDollars(Math.round(cardData?.taxes) / 1000)}K`
                          : formatCentsToDollars(cardData?.taxes)}
                      </p>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Grid className={classes.profitCards}>
            <Grid>
              <Card className={[classes.marginBottom].join(' ')}>
                <CardContent
                  className={formatters.classNames([
                    classes.profitCard,
                    classes.headerCard,
                    'mini',
                    classes.rowHalfHgt,
                  ])}
                >
                  <Grid
                    container
                    item
                    xs={11}
                    direction="row"
                    className={formatters.classNames([classes.cardFont])}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={formatters.classNames([
                        classes.headerIcon,
                        'green',
                      ])}
                    >
                      <FontAwesome
                        name="money-bill"
                        type="fas"
                        form="fa"
                      ></FontAwesome>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {cardData ? (
                          <p className={classes.cardFont}>
                            {isMobile && cardData.profit > 1000
                              ? `${formatCentsToDollars(Math.round(cardData.profit) / 1000)}K`
                              : formatCentsToDollars(cardData.profit)}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>
                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          Total Profit
                        </p>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {pastCardData ? (
                          <p className={classes.cardFont}>
                            {isMobile && pastCardData.profit > 1000
                              ? `${formatCentsToDollars(Math.round(pastCardData.profit) / 1000)}K`
                              : formatCentsToDollars(pastCardData.profit || 0)}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                        <Grid>
                          <p
                            className={formatters.classNames([
                              classes.cardFont,
                              'profit',
                            ])}
                          >
                            {/* {prevDays > 1
                              ? `Last ${prevDays} days`
                              : 'Last day'} */}
                            Prior Period
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {(pastCardData?.sales - pastCardData?.cost) / 2 >
                          (cardData?.sales - cardData?.cost) / 2 ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-down"
                            style={{ color: '#B61F1F' }}
                          />
                        ) : (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-up"
                            style={{ color: '#10960E' }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent
                  className={formatters.classNames([
                    classes.orderCard,
                    classes.headerCard,
                    'mini',
                    classes.rowHalfHgt,
                  ])}
                >
                  <Grid
                    container
                    item
                    xs={11}
                    direction="row"
                    className={formatters.classNames([classes.cardFont])}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      className={formatters.classNames([
                        classes.headerIcon,
                        'gold',
                      ])}
                    >
                      <FontAwesome
                        name="shopping-cart"
                        type="fas"
                        form="fa"
                      ></FontAwesome>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {cardData?.count > -1 ? (
                          <p className={classes.cardFont}>
                            {isMobile && cardData?.count > 1000
                              ? `${(Math.round(cardData?.count) / 1000).toFixed(2)}K`
                              : cardData?.count}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>
                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          Orders
                        </p>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item>
                        {pastCardData?.count > -1 ? (
                          <p className={classes.cardFont}>
                            {isMobile && pastCardData?.count > 1000
                              ? `${(Math.round(pastCardData?.count) / 1000)}K`
                              : pastCardData?.count}
                          </p>
                        ) : (
                          <EllipseLoader dark style={{ display: 'flex' }} />
                        )}
                      </Grid>

                      <Grid>
                        <p
                          className={formatters.classNames([
                            classes.cardFont,
                            'profit',
                          ])}
                        >
                          {/* {prevDays > 1 ? `Last ${prevDays} days` : 'Last day'} */}
                          Prior Period
                        </p>
                      </Grid>
                    </Grid>

                    <Grid>
                      <Grid item>
                        {orderCounts.previous > orderCounts.current ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-down"
                            style={{ color: '#B61F1F' }}
                          />
                        ) : orderCounts.previous < orderCounts.current ? (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="arrow-circle-up"
                            style={{ color: '#10960E' }}
                          />
                        ) : (
                          <FontAwesome
                            form="fas"
                            type="fa"
                            name="minus-circle"
                            style={{ color: '#f5811b' }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.container}>
        <Grid>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.inventoryCard,
                classes.bottomCard,
                classes.headerCard,
                !isMobile && classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" container className={classes.jstSpcBtw}>
                <Grid direction="column" container>
                  <InventoryStats
                    supplierId={
                      analyticsOptions.supplierId
                    } /* unitsBack={unitsBack} */
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.categoryCard,
                classes.onlyScrollY,
                classes.bottomCard,
                classes.headerCard,
                classes.rowTwoHgt,
              ])}
            >
              <Grid direction="row" container className={classes.jstSpcBtw}>
                <Grid direction="column" container>
                  <TopCategories unitsBack={unitsBack} channelId={channelId} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card className={classes.marginTop} style={{ maxHeight: 150 }}>
            <CardContent
              className={formatters.classNames([
                classes.bottomCard,
                classes.rowOneHgt,
              ])}
            >
              <Grid direction="row" container className={classes.jstSpcBtw}>
                <Grid direction="column" container>
                  <ShipStationFee unitsBack={unitsBack} channelId={channelId} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.marginTop}>
            <CardContent
              className={formatters.classNames([
                classes.rateCard,
                classes.bottomCard,
                classes.headerCard,
                classes.rowThreeHgt,
              ])}
            >
              <SalesTrendsCard unitsBack={unitsBack} channelId={channelId} />
            </CardContent>
          </Card>
        </Grid>
        <Grid>
          <Card
            className={classes.marginTop}
            style={{ maxHeight: '555px', overflow: 'scroll' }}
          >
            <CardContent
              className={formatters.classNames([
                classes.bottomCard,
                classes.headerCard,
                classes.rowFourHgt,
              ])}
            >
              <TopProducts unitsBack={unitsBack} channelId={channelId} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderCards;
