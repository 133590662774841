import { useState, useEffect} from 'react';
import recoilAnalyticsOptions from '../AnalyticsHooks/AnalyticsOptions';
import recoilChartOptions from '../SupplierHooks';
import dayjs from 'dayjs';

import Grid from '@mui/material/Grid';

import SupplierChartSelect from './SupplierChartSelect';
import SupplierCostChart from './Chart';
import { Props as SelfProps } from './types';
import { Props } from '@src/Components/Dashboards/Analytics/types';
import dateUtils from '@oneAppCore/utils/dates';
import { TIME_ZONES } from '@oneAppCore/constants/dates';

function SupplierCostReport({ unitsBack, unitType }: Props & SelfProps) {
  const chartOptions = recoilChartOptions.useChartOptions();

  if (!chartOptions.chart) {
    return <></>;
  }

  return (
    <>
      <SupplierChartSelect />
      <Grid container item spacing={1}>
        <SupplierCostChart
          supplierName={chartOptions.chart}
          unitsBack={unitsBack}
          unitType={unitType}
          supplierId={chartOptions.chartId}
        />
      </Grid>
    </>
  );
}

export default SupplierCostReport;
