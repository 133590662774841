import { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import {
  SwipeableDrawer,
  Grid,
  Button,
  Typography,
  Divider,
  Stack,
  Box,
  IconButton,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import type { DrawerProps } from './types';
import { columns, issueColumns } from './constants';
import { WPS, AD, DZONE, T14 } from '@oneAppCore/constants/suppliers';
import OrderDrawerItemsTable from '@src/Components/Orders/OrderDrawerItemsTable';
import OrdersApi from '@oneAppCore/services/apis/Orders';
import suppliersApi from '@oneAppCore/services/apis/Suppliers';
import DefaultWarehouseSelector from '../DefaultWarehouseSelector';

import { useSnackbar } from 'notistack';

import { convertDollarsToCents } from '@src/utils/currency';
type SupplierTotals = {
  total: number;
  supplier: string;
  available: boolean;
  calculatedResponse?: boolean;
  quoteId?: number;
  shippingId?: number;
  lineItems?: Array<any>;
  shippingCost?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '600px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    flexShrink: 0,
    borderLeft: 'none',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    paddingTop: 10,
    paddingBottom: 20,
  },
}));
const getWpsItemInventory = async (sku, enqueueSnackbar) => {
  try {
    const response: any = await suppliersApi.getWpsItemInventory(sku);
    return response;
  } catch (error) {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
    });
  }
};

const calculateAutoDistOrder: any = async (row) => {
  const response = await suppliersApi.calculateAutoDistOrder(row);
  return response;
};

const calculateTurn14Order: any = async (row) => {
  const items = row?.orderItems?.map(item => {
    const turn14SupplierIndex = item?.allSuppliersArray?.[0]?.name?.findIndex(supplier => supplier.includes("Turn 14"));
    return {
      quantity: item.quantity,
      supplierItemNo: turn14SupplierIndex > -1 ? item?.allSuppliersArray?.[0]?.supplierItemNo[turn14SupplierIndex] : null
    };
  });
  const response = await suppliersApi.calculateTurn14Order({ ...row, orderItems: items });
  return response;
};

const postSupplierOrder = async (
  row,
  supplier,
  signatureChecked,
  setIsLoading,
  setDrawerOpen,
  enqueueSnackbar,
  defaultWarehouse?,
  calculatedResponse?,
  totals?,
) => {
  setIsLoading(true);
  const supplierConfig = {
    [AD]: {
      field: 'signatureRequired',
      apiMethod: OrdersApi.postToAutoDist.bind(OrdersApi),
      calculatedResponse,
    },
    [WPS]: {
      field: 'proof_of_delivery',
      apiMethod: OrdersApi.postToWps.bind(OrdersApi),
      defaultWarehouse,
    },
    [T14]: {
      field: 'signatureRequired',
      apiMethod: OrdersApi.postToTurn14.bind(OrdersApi),
      calculatedResponse,
    },
  };
  const config = supplierConfig[supplier];
  if (!config) {
    return;
  }
  let payload: any = {
    id: row.id,
    [config.field]: signatureChecked,
    defaultWarehouse: defaultWarehouse ? defaultWarehouse : '',
    calculatedResponse: calculatedResponse ?
      calculatedResponse
      : false,
  };
  if (supplier === T14) {
    let turn14TotalObj = totals.find((e) => e.supplier === T14);
    payload = {
      ...payload,
      orderItems: row?.orderItems?.map(item => {
        const turn14SupplierIndex = item?.allSuppliersArray?.[0]?.name?.findIndex(supplier => supplier.includes("Turn 14"));
        return {
          id: item.id,
          quantity: item.quantity,
          supplierItemNo: turn14SupplierIndex > -1 ? item?.allSuppliersArray?.[0]?.supplierItemNo[turn14SupplierIndex] : null
        };
      }),
      quoteId: turn14TotalObj?.quoteId,
      shippingId: turn14TotalObj?.shippingId,
      channelOrderId: row.orderNumber,
      isCaliforniaOrder: row?.state === 'CA' ? true : false,
    }
  };
  try {
    const response: any = await config.apiMethod(payload);
    if (response === `${row.orderNumber} successfully placed on ${supplier}`) {
      enqueueSnackbar(`${response}`, {
        variant: 'success',
      });
      return response;
    } else {
      enqueueSnackbar(`${response}`, {
        variant: 'error',
      });
    }
  } catch (error) {
    enqueueSnackbar(`${error.message}`, {
      variant: 'error',
    });
  } finally {
    setIsLoading(false);
    setDrawerOpen(false);
  }
};

export default function OrderDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
  shipRates,
}: DrawerProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [item, setItem] = useState(0);
  const orderItems = row.orderItems ? row.orderItems[item] : null;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureChecked, setSignatureChecked] = useState(false);
  const [wpsInventoryLoaded, setWpsInventoryLoaded] = useState(false);
  const [wpsInventory, setWpsInventory] = useState([]);
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRadioChange = (value) => {
    setSupplierRadioSelection(value);
  };
  const handleSignatureRequiredCheckboxChange = (event) => {
    setSignatureChecked(event.target.checked);
  };
  const [defaultWarehouse, setDefaultWarehouse] = useState('');
  const handleDefaultWarehouseChange = (event) => {
    setDefaultWarehouse(event.target.value);
  };

  const classes = useStyles({ mobile });
  const [calculatedResponse, setCalculatedResponse] = useState(false);

  const defaultTotals = () => {
    const separateRowsTotals = row?.orderItems?.flatMap((item) =>
      item?.allSuppliersArray[0]?.cost.map((cost, index) => {
        return {
          totalItemCost: cost * item.quantity,
          shippingCost: item.allSuppliersArray[0].estimatedShipping[index],
          supplier: item.allSuppliersArray[0].name[index],
          available:
            item.supplierName === item.allSuppliersArray[0].name[index]
              ? true
              : item.quantity > item.allSuppliersArray[0].available[index]
                ? false
                : true,
          calculatedResponse: false,
        };
      }),
    );
    const combinedRowsTotals = separateRowsTotals?.reduce((acc, curr) => {
      if (curr) {
        const existingSupplier = acc.find(
          (item) => item.supplier === curr.supplier,
        );
        if (existingSupplier) {
          if (existingSupplier.shippingCost < curr.shippingCost) {
            existingSupplier.shippingCost = curr.shippingCost;
          }
          existingSupplier.totalItemCost += curr.totalItemCost;
          existingSupplier.available =
            existingSupplier.available && curr.available;
        } else {
          acc.push({ ...curr });
        }
      }
      return acc;
    }, []);
    return combinedRowsTotals?.map((totalRow) => {
      const total = totalRow.shippingCost + totalRow.totalItemCost;
      return { ...totalRow, total };
    });
  };
  const [totals, setTotals] = useState<SupplierTotals[]>(defaultTotals());
  const wpsInventoryPromises = (row, enqueueSnackbar) =>
    row?.orderItems.flatMap((item) => {
      return item?.allSuppliersArray[0]?.name.map(async (name, i) => {
        if (name === WPS) {
          const wpsItemInventory = await getWpsItemInventory(
            item?.allSuppliersArray[0]?.supplierItemNo[i],
            enqueueSnackbar,
          );

          const filteredWpsItemInventoryArray =
            wpsItemInventory && wpsItemInventory[0]
              ? Object.entries(wpsItemInventory[0]).filter(
                ([key, qty]) =>
                  key.includes('_warehouse') && qty >= item.quantity,
              )
              : null;
          setWpsInventory(filteredWpsItemInventoryArray);
        }
      });
    });

  const calculateWpsInventory = async () => {
    await wpsInventoryPromises(row, enqueueSnackbar);
    setWpsInventoryLoaded(true);
    return { supplier: WPS, success: true };
  };

  const calculateAdInventory = async () => {
    try {
      const adData = await calculateAutoDistOrder(row);
      setCalculatedResponse(true);

      if (adData && adData.totalInvoiceAmount && adData.totalOrderValue && adData?.totalInvoiceAmount !== '.00' &&
        adData?.totalOrderValue !== '.00' && adData.totalOrderValue === adData?.totalInvoiceAmount) {
        const newTotal = convertDollarsToCents(adData.totalInvoiceAmount) +
          convertDollarsToCents(adData.totalShippingCost);

        setTotals(prevTotals => prevTotals.map(e =>
          e.supplier === AD ? {
            ...e,
            total: newTotal,
            available: true,
            calculatedResponse: true,
          } : e
        ));
        return { supplier: AD, success: true };
      } else {
        setTotals(prevTotals => prevTotals.map(e =>
          e.supplier === AD ? { ...e, available: false, calculatedResponse: true, } : e
        ));
        return { supplier: AD, success: false };
      };
    } catch (error) {
      setTotals(prevTotals => prevTotals.map(e =>
        e.supplier === AD ? { ...e, available: false } : e
      ));
      return { supplier: AD, success: false };
    }
  };

  const calculateT14Inventory = async () => {
    try {
      const t14Data = await calculateTurn14Order(row);
      setCalculatedResponse(true);

      if (t14Data?.status === 'success' && t14Data?.quoteId) {
        const lowestShipping = t14Data?.shippingOptions?.reduce(
          (min, quote) => quote.cost < min.cost ? quote : min
        );
        const newTotal = convertDollarsToCents(t14Data?.totalItemCost) +
          convertDollarsToCents(lowestShipping?.cost);

        setTotals(prevTotals => prevTotals.map(e =>
          e.supplier === T14 ? {
            ...e,
            total: newTotal,
            totalItemCost: convertDollarsToCents(t14Data?.totalItemCost),
            available: true,
            calculatedResponse: true,
            quoteId: t14Data?.quoteId,
            shippingCost: convertDollarsToCents(lowestShipping?.cost),
            shippingId: lowestShipping?.shipping_quote_id,
            lineItems: t14Data?.items,
          } : e
        ));
        return { supplier: T14, success: true };
      } else {
        setTotals(prevTotals => prevTotals.map(e =>
          e.supplier === T14 ? { ...e, available: false, calculatedResponse: true, } : e
        ));
        return { supplier: T14, success: false };
      }
    } catch (error) {
      setTotals(prevTotals => prevTotals.map(e =>
        e.supplier === T14 ? { ...e, available: false, calculatedResponse: true, } : e
      ));
      return { supplier: T14, success: false };
    }
  };

  useEffect(() => {
    const calculateAllOrders = async () => {
      if (row.orderStatus !== 'UNASSIGNED' && row.orderStatus !== 'ISSUE') {
        return;
      }

      try {
        const promises = [];
        if (row.orderItems.some((e) => e.allSuppliersArray[0]?.name.includes(T14))) {
          promises.push(calculateT14Inventory());
        };
        if (row.orderItems.some((e) => e.allSuppliersArray[0]?.name.includes(AD))) {
          promises.push(calculateAdInventory());
        };
        if (row.orderItems.some((e) => e.allSuppliersArray[0]?.name.includes(WPS))) {
          promises.push(calculateWpsInventory());
        };
        await Promise.all(promises);
      } catch (error) {
      }
    };

    calculateAllOrders();
  }, [row]);

  const defaultRadioSelection = () => {
    if (orderItems.supplierName === DZONE) {
      return DZONE;
    }
    const filteredTotals = totals?.filter((item) => item.available);
    if (filteredTotals && filteredTotals.length > 0) {
      filteredTotals.sort((a, b) => a.total - b.total);
      return filteredTotals[0].supplier;
    }
    return '';
  };
  const [supplierRadioSelection, setSupplierRadioSelection] = useState(defaultRadioSelection());
  const placeOrderButtonLoading = () => {
    if (!supplierRadioSelection) return true;
    if (isLoading) return true;
    if (!totals) return true;
    switch (supplierRadioSelection) {
      case AD: {
        const adTotalsObj = totals.find((t) => {
          if (t.supplier === AD) {
            return t;
          }
        });
        if (adTotalsObj.available && adTotalsObj.calculatedResponse) {
          return false;
        } else return true;
      }
      case WPS: {
        const wpsTotalsObj = totals.find((t) => {
          if (t.supplier === WPS) {
            return t;
          }
        });
        if (wpsTotalsObj.available && wpsInventoryLoaded) {
          return false;
        } else return true;
      }
      case T14: {
        const t14TotalsObj = totals.find((t) => {
          if (t.supplier === T14) {
            return t;
          }
        });
        if (t14TotalsObj.available && t14TotalsObj.calculatedResponse) {
          return false;
        } else return true;
      }
      default: return true;
    };
  };
  return (
    <>
      {orderItems === null ? (
        <></>
      ) : (
        <SwipeableDrawer
          open={isDrawerOpen}
          onClose={() => setDrawerOpen()}
          onOpen={() => null}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          anchor="right"
        >
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2.5, pt: 1.5 }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center' }}
                >
                  <IconButton
                    onClick={() => setDrawerOpen()}
                    sx={{ mr: '10px', padding: matchesXs ? '0px' : '' }}
                  >
                    {theme.direction === 'rtl' ? (
                      <ChevronRight />
                    ) : (
                      <ChevronLeft />
                    )}
                  </IconButton>
                  <h3>Order Details</h3>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={{ xs: 1, sm: 2.5 }}
                ></Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 2.5 }}>
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: 'left',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Order Items
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OrderDrawerItemsTable
                      row={row}
                      signatureChecked={signatureChecked}
                      handleSignatureRequiredCheckboxChange={
                        handleSignatureRequiredCheckboxChange
                      }
                      isLoading={isLoading}
                      onRadioChange={handleRadioChange}
                      supplierRadioSelection={supplierRadioSelection}
                      totals={totals}
                      calculatedResponse={calculatedResponse}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 2.5 }}>
                <Grid container spacing={2.5}>

                  <Grid item xs={12}>
                    {(row.store === 'dzone' || row.store === 'ads') &&
                      (row.orderStatus === 'UNASSIGNED' ||
                        row.orderStatus === 'ISSUE') &&
                      orderItems &&
                      orderItems?.allSuppliersArray.length >= 1 ? (
                      <>
                        <Button
                          size="small"
                          style={{ margin: 15 }}
                          disabled={placeOrderButtonLoading() || isLoading}
                          key={`place-${row.id}`}
                          onClick={() => [
                            postSupplierOrder(
                              row,
                              supplierRadioSelection,
                              signatureChecked,
                              setIsLoading,
                              setDrawerOpen,
                              enqueueSnackbar,
                              defaultWarehouse,
                              calculatedResponse,
                              totals,
                            ),
                            setIsLoading(true),
                          ]}
                          variant="contained"
                          color="primary"
                        >
                          {supplierRadioSelection === AD ||
                            supplierRadioSelection === WPS || supplierRadioSelection === T14
                            ? `Place ${supplierRadioSelection} Order`
                            : `${supplierRadioSelection} Order`}
                        </Button>
                        {supplierRadioSelection === WPS &&
                          wpsInventory?.length >= 1 &&
                          wpsInventoryLoaded ? (
                          <DefaultWarehouseSelector
                            wpsInventory={wpsInventory}
                            defaultWarehouse={defaultWarehouse}
                            handleDefaultWarehouseChange={
                              handleDefaultWarehouseChange
                            }
                          />
                        ) : null}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ px: 2.5 }}>
                {columns(shipRates)
                  .filter((col) => !issueColumns.includes(col.key))
                  .map((name) => {
                    if (name.customComponent) {
                      return (
                        <>
                          <name.customComponent
                            order={row}
                            itemIndex={item}
                            supplierRadioSelection={supplierRadioSelection}
                            totals={totals}
                          />
                          {name.key === 'price' ? (
                            <Grid item xs={12}>
                              <Divider className={classes.divider} />
                            </Grid>
                          ) : null}
                          {name.key === 'purchaseDate' ? (
                            <Grid item xs={12}>
                              <Divider className={classes.divider} />
                            </Grid>
                          ) : null}
                        </>
                      );
                    }
                  })}
              </Box>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      )}
    </>
  );
}
