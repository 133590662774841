import { Grid } from '@mui/material';
import { ISSUE, issueList } from '@oneAppCore/constants/orders';
import SearchView from '@src/Components/common/containers/SearchView';
import { columns } from './constants';
import dayjs from 'dayjs';

function IssuesSearch() {
  const issueTypeList = issueList.map((iss) => ({
    value: iss,
    label: iss.replace(/_/g, ' '),
  }));

  const dateRangeOptions = [
    {
      label: 'Today',
      value: [
        dayjs().format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: 'Yesterday',
      value: [
        dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '7 Days',
      value: [
        dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '30 Days',
      value: [
        dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '60 Days',
      value: [
        dayjs().subtract(60, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '90 Days',
      value: [
        dayjs().subtract(90, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
    {
      label: '120 Days',
      value: [
        dayjs().subtract(120, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
  ];
  const defaultStartDate = dayjs().startOf('day').subtract(30, 'days').format('YYYY-MM-DD');
  const defaultEndDate = dayjs().add(1, 'days').format('YYYY-MM-DD');
  const defaultDateFilter = `${defaultStartDate}--${defaultEndDate}`;

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="api/v1/orders/search"
        constantFilters={{
          status: ISSUE,
          purchasedAt: defaultDateFilter,
        }}
        filters={[
          {
            type: 'array',
            name: 'Issue Type',
            keyName: 'issueType',
            operator: 'is',
            properties: issueTypeList,
          },
          {
            type: 'date range select',
            name: 'Purchased At',
            keyName: 'purchasedAt',
            properties: dateRangeOptions.map(({ label, value }) => ({ label, value: value.filter((a) => a).join('--') })),
          },
          // {
          //   type: 'date range',
          //   name: 'Purchased At',
          //   keyName: 'purchasedAt',
          // },
        ]}
        columns={columns()}
        showChannelOptions
      />
    </Grid>
  );
}

export default IssuesSearch;
