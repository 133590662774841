import { FEDEX, FEDEX_CODE, PRIORITY_MAIL, UPS, UPS_CODE, USPS, USPS_CODE } from "@oneAppCore/constants/shipping";
import ShipStationApi from "@oneAppCore/services/apis/ShipStation";

// function to find the lowest index.
const findLowestCostIndex = (arr) => {
  let lowestCost = Number.POSITIVE_INFINITY;
  let lowestIndex = 0;

  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];
    const totalCost = element.otherCost + element.shipmentCost;

    if (totalCost < lowestCost) {
      lowestCost = totalCost;
      lowestIndex = i;
    }
  }

  return lowestIndex;
};

export async function getShipRates(rateForm) {
  const promiseArray = [];
  const allCarriersResponse = [];
  [UPS_CODE, USPS_CODE, FEDEX_CODE]?.forEach((carrier) => {
    promiseArray.push(ShipStationApi.getAllRates({ ...rateForm, carrierCode: carrier, }));
  });
  for (const iterator of promiseArray) {
    try {
      const response = await iterator;
      if (response.status == 'success') {
        allCarriersResponse.push(response);
      };
    } catch (e) {
      throw (e);
    };
  };
  return allCarriersResponse;
};

export function getCheapestSupplierDetails(allCarriersResponse, orderCosts) {
  let cheapestCarrier = 'stamps_com';
  let serviceRates = [];
  let allRates = {};
  let badGateWayError = false;
  let lowestCarrierCost = Number.POSITIVE_INFINITY;
  allCarriersResponse.forEach((carrierResponse) => {
    if (carrierResponse.status == 'fail') {
      // error from get rates.
      badGateWayError = true;
    } else {
      let filteredServices = [];
      if (carrierResponse.carrierCode === USPS_CODE) {
        carrierResponse.serviceRates.forEach((service) => {
          if (!service.serviceName.includes(PRIORITY_MAIL) && !service.serviceName.includes('First Class Mail - Package')) {
            filteredServices.push(service);
          };
        });
      } else {
        filteredServices = carrierResponse.serviceRates;
      }
      const sorted = filteredServices
        .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
        .sort((a, b) => a - b);
      const strDex = 0;
      const endDex = sorted.length - 1;
      const minMaxRates = { min: sorted[strDex], max: sorted[endDex] };
      allRates[carrierResponse.carrierCode] = {
        minMaxRates: minMaxRates,
        serviceRates: carrierResponse.serviceRates,
      };
      if (Number(minMaxRates?.min) < lowestCarrierCost) {
        lowestCarrierCost = minMaxRates.min;
        serviceRates = filteredServices;
        cheapestCarrier = carrierResponse.carrierCode;
      }
    }
  });

  if (badGateWayError) {
    // error in getting shipping rates
    return { badGateWayError };
  };
  const serviceRateArray = Array.isArray(serviceRates)
    ? serviceRates
    : [serviceRates];

  const lowestIndex = findLowestCostIndex(serviceRateArray);
  const selectedService = serviceRateArray[lowestIndex];
  const profit =
    orderCosts?.['itemPrice'] -
    Math.floor((orderCosts?.['itemPrice'] * 0.12)) -
    orderCosts?.['subTotal'] -
    Math.floor(serviceRateArray?.[lowestIndex]?.shipmentCost * 100) - Math.floor(serviceRateArray?.[lowestIndex]?.otherCost * 100);

  let packageCode = 'package';
  const defaultCarrierOptions = [
    {
      "name": USPS,
      "code": USPS_CODE,
    },
    {
      "name": FEDEX,
      "code": FEDEX_CODE,
    },
    {
      "name": UPS,
      "code": UPS_CODE,
    }
  ];
  return { badGateWayError, cheapestCarrier, defaultCarrierOptions, serviceRateArray, lowestIndex, packageCode, allRates, profit, selectedService }
};