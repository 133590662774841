import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';
import recoilSearch from '../useSearchOptions';
import { useSnackbar } from 'notistack';

import type { SearchResponse } from './types';

export default function useSearch(
  revalidateOnFocus = true,
  shouldRetryOnError = false,
  focusThrottleInterval = 3500,
  dedupingInterval = 10000
) {
  const url = recoilSearch.useUrl();
  const { enqueueSnackbar } = useSnackbar();

  return useSWR<SearchResponse>(url ? url : null, Api.get, {
    revalidateOnFocus,
    shouldRetryOnError,
    focusThrottleInterval,
    dedupingInterval,
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });
}
