import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Tooltip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import StyledTooltip from './StyledTooltip';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import type {
  Column,
} from '@src/Components/common/containers/SearchView/types';
import Info from '@mui/icons-material/Info';



const useStyles = makeStyles((theme: Theme) => ({
  tablePaper: {
    // width: '425px',
    borderRadius: '10px',
    padding: 0,
    // padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  arrow: {
    color: 'rgb(128, 128, 128) !important',
  },
  tooltip: {
    maxWidth: '530px !important',
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: `#000 !important`,
    padding: '0 !important',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

function TooltipTable({ children, columns, rows }: { children?: any, columns: Column[], rows: any[] }) {
  const classes = useStyles();
  return (
    <StyledTooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      enterDelay={200}
      leaveDelay={450}
      title={
        <>
          <TableContainer className={classes.tablePaper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={`column-${column.keyName}`}>{column.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow key={`row-${rowIndex + 1}`}>
                    {columns.map((column, colIndex) => {
                      let cellData;
                      if (column.customComponent) {
                        cellData = (<>{column.customComponent(row)}</>)
                      } else {
                        cellData = column.type === 'money' ? formatCentsToDollarsIntl(row?.[column.keyName] || '0') : row?.[column.keyName] || 'N/A';
                      }
                      return (<TableCell key={`row-${rowIndex + 1}-cell-${colIndex + 1}`}>{cellData}</TableCell>)
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    >
      <Link style={{ position: 'relative' }}>
        <Info style={{ position: 'absolute', top: 0, right: 0, }} color='info' />
        {children}
      </Link>
    </StyledTooltip>
  );
}

export default TooltipTable;
