import useSearch from '../hooks/useSearch';

import type { Props } from './types';

function MiniReports({ MiniReportComponent, swrOptions }: Props) {
  const { data } = useSearch(swrOptions.revalidateOnFocus || false, false, swrOptions.focusThrottleInterval);

  return <MiniReportComponent {...(data?.miniReports || {})} />;
}

export default MiniReports;
