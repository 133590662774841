import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';

import type { VariationProps, VariationResponse } from './types';
import { SearchResponse } from '@src/Components/common/containers/SearchView/hooks/useSearch/types';

export default function useVariations(props: VariationProps) {
  if (props?.value) {
    return useSWR<SearchResponse>(`/api/v1/variations/${props.value}`, Api.get);

  }
  if (props?.brandId) {
    if (props?.mpn ) {
      return useSWR<SearchResponse>(`/api/v1/variations/search?manufacturerNo=${props.mpn}&brandId=${props.brandId}`, Api.get);
    } else {
      return useSWR<SearchResponse>(`/api/v1/variations/search?brandId=${props.brandId}`, Api.get);
    }
  } else {
    return useSWR<SearchResponse>(`/api/v1/variations/search?limit=10000`, Api.get);
  }
}
