import { UNASSIGNED, ISSUE } from '@oneAppCore/constants/orders';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { convertDollarsToCents } from '@oneAppCore/utils/currency';
const PLACEHOLDER_IMAGE = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;

export const getItemImageUrl = (orderItem) => {
  const imageUrlArray = [];
  if (orderItem?.variationImages?.[0]) {
    imageUrlArray.push(orderItem.variationImages[0]);
  };
  if (orderItem?.images?.[0]?.imageUrl) {
    imageUrlArray.push(orderItem.images[0].imageUrl)
  }
  if (orderItem?.productImages?.[0]) {
    if (Array.isArray(orderItem.productImages[0])) {
      imageUrlArray.push(...orderItem.productImages[0].flat());
    } else {
      imageUrlArray.push(orderItem.productImages[0]);
    };
  };
  imageUrlArray.push(PLACEHOLDER_IMAGE);
  if (imageUrlArray[0] === PLACEHOLDER_IMAGE) {
    const firstElement = imageUrlArray.shift();
    imageUrlArray.push(firstElement);
  };
  const imageUrl = imageUrlArray[0];
  return imageUrl;
};

const shipReduce = (acc, curr) => acc + curr.supplierShippingCost;

export const getShipping = (shipRates, order, drawer = false, foundTotal?) => {
  let serviceName = '';
  let shippingCost = 0;
  if (shipRates?.[order.id]) {
    serviceName = shipRates?.[order.id]?.selectedServiceName;
    const serviceOption = shipRates?.[order.id]?.serviceOptions?.find(
      (service) => service?.serviceName == serviceName,
    );

    if (serviceOption) {
      let shipMin = 0;
      if (order.serviceRates) {
        const flatRates: any[] = order.serviceRates?.flatMap((rate) =>
          rate.serviceRates.map((sr) => sr.shipmentCost),
        ).filter((cost) => cost > 0);
        /**/
        shipMin = Math.min(...flatRates)
      }
      /**/

      shippingCost =
        serviceOption?.shipmentCost > 0
          ? serviceOption?.shipmentCost + serviceOption?.otherCost
          : order.supplierShippingCost > 0
            ? order.supplierShippingCost
            : order.orderItems.every((item) => item.supplerShippingCost)
              ? order.orderItems.reduce(shipReduce, 0)
              : isNaN(shipMin) ? 0 : shipMin;
      /**/
    }
  };

  if (!shippingCost) {
    if (drawer) {

      let supplierItemShipping = 0;
      const totalItemShipping = order.orderItems?.reduce((acc, curr) => {
        const { allSuppliersArray, supplierId } = curr;
        const suppIndex = allSuppliersArray[0]?.sid.indexOf(supplierId);

        if (suppIndex > -1) {
          if (allSuppliersArray[0].estimatedShipping[suppIndex]) {
            supplierItemShipping += allSuppliersArray[0].estimatedShipping[suppIndex];
          } else if (allSuppliersArray[0].shippingCost[suppIndex]) {
            supplierItemShipping += allSuppliersArray[0].shippingCost[suppIndex];
          }
        }
        return acc + curr.supplierShippingCost;
      }, 0);

      shippingCost = order.orderStatus === (UNASSIGNED || ISSUE) && foundTotal?.shippingCost
        ? foundTotal.shippingCost
        : shippingCost
          ? shippingCost
          : totalItemShipping
            ? totalItemShipping
            : supplierItemShipping
              ? supplierItemShipping
              : 0;
    } else {
      shippingCost =
        order.supplierShippingCost > 0
          ? order.supplierShippingCost
          : order.orderItems.every((item) => item.supplerShippingCost)
            ? order.orderItems.reduce(shipReduce, 0)
            : 0;
    }
  }
  if (`${shippingCost}`.includes('.')) {
    shippingCost = convertDollarsToCents(`${shippingCost}`);
  }

  return shippingCost;
};