import { unitDateLabelProps } from './types';

export const thing = () => { };
export const unitDateLabel = ({unitsBack, unitType}: unitDateLabelProps)  => {
  const unitString = unitType ? unitType : 'days';
  if (unitsBack) {
    if (unitsBack === 1) {
      return 'Since Yesterday';
    } else {
      return `in the Last ${unitsBack} ${unitString}`;
    }
  } else {
    if (unitsBack === 0) {
      return 'Today';
    } else {
      return `in the Last 14 ${unitString}`;
    }
  }
};