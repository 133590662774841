import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { reposState as reposAtom } from '../../common/containers/SearchView/SearchTable/atoms';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMeStores from '@src/hooks/swr/useMeStores';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import supplierApi from '@oneAppCore/services/apis/Suppliers';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { capitalize } from '@oneAppCore/utils/formatters';

function RolesSearch() {

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/permissions/search"
        // select
        columns={columns({})}
        filters={[
        ]}
      />
    </Grid>
  );
}

export default RolesSearch;
