import {
  AD,
  DZONE,
  supplierIds,
} from '@oneAppCore/constants/internalvdropship';
import { startCase } from 'lodash';

export const options = [
  {
    value: null,
    name: 'All',
  },
  {
    value: supplierIds[DZONE],
    name: 'Internal',
  },
  {
    value: Object.keys(supplierIds)
      .filter((id) => id !== 'DZONE')
      .map((id) => supplierIds[id]),
    name: 'External',
  },
];
