import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useProducts from '@src/hooks/swr/useProducts';
import Loading from './Loading';
import ProductApi from '@oneAppCore/services/apis/Products';
import InventoryApi from '@oneAppCore/services/apis/Inventory';
import VariationsApi from '@oneAppCore/services/apis/Variations';
import SupplierApi from '@oneAppCore/services/apis/Suppliers';
import FitmentApi from '@oneAppCore/services/apis/Fitment';
import ItemGroupsApi from '@oneAppCore/services/apis/ItemGroups';
import ExportButton from '@src/Components/common/containers/SearchView/SearchTable/SearchBar/ExportButton';
import { exportOptions } from './constants';

import { pick } from 'lodash';

import {
  Button,
  ButtonGroup,
  Grid,
  Card,
  Theme,
  CardContent,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  Collapse,
  Chip,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import ParentProduct from './ParentProduct';
import VariationsTable from './VariationsTable';
import GroupedTable from './GroupedTable';
import GroupedFLSTable from './GroupedFLSTables';
import {
  Params,
  Form,
  AIForm,
  Supplier,
  VariationsForm,
  GroupForm,
  FitmentForm,
  ManufacturerSupplierForm,
} from './types';
import { ProductPayload } from '@oneAppCore/services/apis/Products/types';
import { CreateVariation } from './VariationsTable/addVariation/types';
import { convertDollarsToCents } from '@oneAppCore/utils/currency';
import { FontAwesome } from '@src/Components/common';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: 10,
  },
  primaryButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    fontWeight: 'bold',
    // color: `${theme.palette.primary.contrastText}`,
    '&$selected': {
      color: `${theme.palette.primary.contrastText}`,
    },

    '&:hover': {
      backgroundColor: `lightgrey !important`,
      color: '#363636 !important',
    },
    color: 'white !important',
  },
  secondaryButton: {
    // backgroundColor: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    color: '#363636 !important',
  },
  defaultButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#ffffff',

    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  cardChildren: {
    '& div.seoHolder': {
      '& > div': {
        padding: '5px 20px 5px 20px',
      },
      '& > div.seoDivider': {
        // margin: '5px 0 5px 0',
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
}));

function ProductPage(props?: any, variation?: any, mutate?: any) {
  const classes = useStyles();
  const { data: product, mutate: mutateProduct } = useProducts.useProductData();
  const {
    data: keywordList = [],
    mutate: mutateKeywordList,
  } = useProducts.useProductKeywords();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  // const { data: suppTest, mutate: mutateSupp } = useSupplierData();

  const { id: paramId } = useParams<Params>();
  const [form, setForm] = useState<Form>({});
  const [aiForm, setAiForm] = useState<AIForm>({});
  const [groupForm, setGroupForm] = useState<GroupForm[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [fitmentList, setFitmentList] = useState<any[]>([]);
  const [suppList, setSuppList] = useState<any[]>([]);
  const [listingList, setListingList] = useState<any[]>([]);
  const [variationForm, setVariationForm] = useState<VariationsForm[]>([]);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [supplierForm, setSupplierForm] = useState<ManufacturerSupplierForm[]>(
    [],
  );
  const [open, setOpen] = useState(false);
  const [varSuppData, setVarSuppData] = useState({});
  const [values, setValues] = useState<string>();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isProducts, setIsProducts] = useState<boolean>(true);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [savedImages, setSavedImages] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [storeError, setStoreError] = useState(false);
  const [brandError, setBrandError] = useState(false);
  // const [skuError, setSkuError] = useState(false);
  const [skuErrors, setSkuErrors] = useState(false);
  const [mpnError, setMpnError] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [newVariation, setNewVariation] = useState<CreateVariation>({
    title: '',
    sku: '',
    manufacturerNo: '',
    productId: 0,
    upc: '',
    quantityPerBox: 0,
    itemPrice: 0,
  });

  useEffect(() => {
    const existingImages = localStorage.getItem('productImages');
    if (existingImages) {
      setSavedImages(JSON.parse(existingImages));
    }
  }, []);

  useEffect(() => { }, [savedImages]);

  const updateList = (list: any[], listType: string) => {
    switch (listType) {
      case 'group': {
        setGroupList(list);
        break;
      }
      case 'fitment': {
        setFitmentList(list);
        break;
      }
      case 'suppliers': {
        setSuppList(list);
        break;
      }
      case 'listings': {
        setListingList(list);
        break;
      }
      default: {
      }
    }
  };

  const updateForm = <K extends keyof Form>(key: K, value: Form[K]) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateAiForm = <K extends keyof AIForm>(key: K, value: AIForm[K]) => {
    setAiForm({
      ...aiForm,
      [key]: value,
    });
  };

  const handleToggle = (e, newValue: string) => {
    setValues(newValue);
  };

  const createNewFitment = () => {
    const newFitment = fitment?.slice() || [];
    newFitment.push([]);
    setFitment(newFitment);
  };

  const addVariation = () => {
    const updatedVariations = variationForm?.slice() || [];
    createNewFitment();
    updatedVariations.push({
      // brand: brand ?? '',
    });
    setVariationForm(updatedVariations);
  };

  const activeStatus = product?.statuses.filter(
    (status) => status === 'ACTIVE',
  );

  const addProduct = () => {
    const updatedGroup = groupForm?.slice() || [];
    updatedGroup.push({});
    setGroupForm(updatedGroup);
  };

  const onChange = <K extends keyof VariationsForm>(
    index: number,
    key: K,
    value: VariationsForm[K],
  ) => {
    const updatedVariations = variationForm?.slice() || [];
    updatedVariations[index] = {
      ...updatedVariations[index],
      [key]: value,
    };
    setVariationForm(updatedVariations);
  };

  const getSuppliers = async () => {
    const supplierList = await InventoryApi.getSuppliers();
    setSuppliers(supplierList);
  };
  useEffect(() => {
    getSuppliers();
    if (paramId && product) {
      setIsViewMode(true);
      setForm((previous) => ({
        ...previous,
        ...product,
      }));
    }
  }, [paramId, product]);

  const deleteProduct = async () => {
    try {
      const payload = { deactivatedAt: new Date() as any };
      await ProductApi.put(Number(paramId), payload);
      await mutateProduct();
      history.push('/products/list');
      enqueueSnackbar('Product deactivated successfully', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const save = async () => {
    // e.preventDefault();
    try {
      if (
        !form.name ||
        form?.name?.length < 1 ||
        !form.description ||
        form?.description?.length < 1 ||
        !form.storeId
      ) {
        if (!form.name || form?.name?.length < 1) {
          setNameError(true);
        } else {
          setNameError(false);
        }
        if (!form.description || form?.description?.length < 1) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
        }
        if (!form.storeId) {
          setStoreError(true);
        } else {
          setStoreError(false);
        }
        if (!form.brandId) {
          setBrandError(true);
        } else {
          setBrandError(false);
        }
        throw new Error('Please make sure all required fields are filled out');
      }
      if (paramId && product) {
        await ProductApi.put(
          Number(paramId),
          pick(form, [
            'name',
            'description',
            'storeId',
            'categories',
            'keywords',
            'bulletPoints',
            'brandId',
            'channelCategoryId',
          ]) as ProductPayload,
        );
        const savedImages = localStorage.getItem('productImages');
        if (savedImages) {
          const parsedImageData = JSON.parse(savedImages);
          if (Array.isArray(parsedImageData) && parsedImageData.length > 0) {
            await ProductApi.createImage(
              { imageUrl: parsedImageData[0] },
              Number(paramId),
            );
          }
          //   else {
          //     enqueueSnackbar('Saving with placeholder image', {
          //       variant: 'warning',
          //     });
          //   }
          // } else {
          //   enqueueSnackbar('Saving with placeholder image', {
          //     variant: 'warning',
          //   });
        }
        await mutateProduct();
        await mutateKeywordList();
        setIsViewMode(true);
        enqueueSnackbar('Saved Product!', { variant: 'success' });
      } else {
        let productObj = {
          name: form.name,
          description: form.description,
          bulletPoints: form.bulletPoints,
          storeId: form.storeId,
          categories: form.categories ?? '',
          channelCategoryId: form.channelCategoryId,
          keywords: form.keywords,
          brandId: form.brandId,
          // url: savedImages,
        };

        let createdProduct = { id: 0 };

        if (isChecked === true) {
          const vars: number[] = [];
          const qty: number[] = [];
          form.groups.forEach((group) => {
            if (!group.quantity) {
              qty.push(1);
            } else {
              qty.push(group.quantity);
            }
            if (group.variations?.length === 1) {
              vars.push(variation?.[0].variationId);
            } else {
              vars.push(group.variationId);
            }
          });
          const createdGroup = await ItemGroupsApi.post({
            name: productObj.name,
            kit: true,
            variationId: vars,
            quantity: qty,
          });
          const newProductObj = {
            ...productObj,
            groupId: createdGroup.id,
          };
          createdProduct = await ProductApi.post(newProductObj);
        } else {
          createdProduct = await ProductApi.post(productObj);

          const savedImages = localStorage.getItem('productImages');
          if (savedImages) {
            const parsedImageData = JSON.parse(savedImages);
            if (Array.isArray(parsedImageData) && parsedImageData.length > 0) {
              await ProductApi.createImage(
                { imageUrl: parsedImageData[0] },
                createdProduct.id,
              );
            } else {
              enqueueSnackbar('Saving with placeholder image', {
                variant: 'warning',
              });
            }
          } else {
            enqueueSnackbar('Saving with placeholder image', {
              variant: 'warning',
            });
          }

          for await (const variation of form.variations) {
            const { manufacturerNo } = variation;

            const createdVariation = await VariationsApi.post({
              sku: variation.sku,
              manufacturerNo: variation.manufacturerNo,
              productId: createdProduct.id,
              variation: variation.variation,
              brand: form.brandId ?? 1,
              upc: variation.upc,
              quantityPerBox: variation.quantityPerBox,
              itemPrice: variation.itemPrice,
              map: variation.map,
              msrp: variation.msrp,
              variantType: variation.variantType,
              variantTypeTwo: variation.variantTypeTwo,
              variationTwo: variation.variationTwo,
            });

            if (variation.suppliers?.length) {
              for await (const supplier of variation.suppliers) {
                const createdSupplier = await SupplierApi.createManufacturerSupplier(
                  {
                    ...supplier,
                    brandId: form.brandId ?? 1,
                    manufacturerNo,
                    cost: convertDollarsToCents(supplier.cost),
                    shippingCost: convertDollarsToCents(supplier.shippingCost),
                    variationId: createdVariation.id,
                  },
                );
              }
            }
            if (variation.fitment?.length) {
              for await (const fitment of variation.fitment) {
                const { model, years: year } = fitment;
                const fitmentBody = {
                  variationId: createdVariation.id,
                  fitment: { model, year },
                };
                const createdFitment = await FitmentApi.createFitment(
                  fitmentBody,
                );
              }
            }
          }
        }
        enqueueSnackbar('Created Product', { variant: 'success' });
        history.push(`/products/view/${createdProduct.id}`);
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      localStorage.removeItem('productImages');
    }
  };

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('productImage');
  });
  const titleScore = form?.name?.length || 0 * 0.25;
  const descriptionWordCount = form?.description
    ?.split(/\s+/)
    .filter((word) => word.length > 0).length;
  const descriptionScore = descriptionWordCount || 0 * 0.15;
  const bpScore = form?.bulletPoints?.filter((bp) => bp !== '').length * 2;
  const imageScore = form?.productImages?.length * 5;
  const seoScore =
    (titleScore >= 30 ? 30 : titleScore) +
    (descriptionScore >= 30 ? 30 : descriptionScore) +
    (bpScore >= 10 ? 10 : bpScore ? bpScore : 0) +
    (form?.keywords ? 10 : 0) +
    (form?.image ? (imageScore >= 25 ? 25 : imageScore) : 0) +
    (form?.channelCategoryId ? 5 : 0);

  return (
    <div>
      {!product && paramId ? (
        <Loading />
      ) : (
        <>
          <Grid container sx={{ pl: 2 }}>
            <Grid item xs={12} md={9} lg={9}>
              <ParentProduct
                keywordList={keywordList}
                form={form}
                updateForm={updateForm}
                aiForm={aiForm}
                updateAiForm={updateAiForm}
                isViewMode={isViewMode}
                isGrouped={isChecked}
                mutate={mutateProduct}
                nameError={nameError}
                descriptionError={descriptionError}
                storeError={storeError}
                brandError={brandError}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3} justifyContent="flex-start">
              <Grid container xs={12} alignItems="flex-start">
                <Card
                  style={{
                    marginLeft: 20,
                    marginBottom: 20,
                    width: '100%',
                    // paddingTop: 15,
                    // paddingBottom: 15,
                    padding: 15,
                  }}
                >
                  <CardContent
                    style={{
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                    className={classes.cardChildren}
                  >
                    <Grid item container justifyContent="center" xs={12}>
                      <Typography variant="h4">Publish</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    </Grid>
                    <Grid>
                      {isViewMode ? (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                size="medium"
                                disabled={true}
                                color="secondary"
                                checked={product?.groupId !== null}
                              />
                            }
                            label={
                              <span style={{ fontSize: '20px' }}>
                                Create Grouped Product
                              </span>
                            }
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                color="secondary"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                              />
                            }
                            label={'Create Grouped Product'}
                          />
                        </FormGroup>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    </Grid>
                    <Grid container style={{ justifyContent: 'space-between' }}>
                      <Grid
                        item
                        container
                        xs={6}
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <Typography
                          style={{ marginBottom: 10, fontSize: '20px' }}
                        >
                          Active Listings{' '}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={5}
                        style={{ justifyContent: 'flex-end', marginRight: 20 }}
                      >
                        <Typography
                          style={{ marginBottom: 10, fontSize: '18px' }}
                        >
                          {product?.statuses?.length > 0
                            ? `${activeStatus?.length} / ${product?.statuses.length}`
                            : 'No Listings Created'}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <Grid item container xs={6}>
                          <Typography style={{ fontSize: '20px' }}>
                            SEO Score
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          style={{
                            justifyContent: 'flex-end',
                            // marginRight: 20,
                          }}
                        >
                          <Chip
                            key={`product-page-column-SEO`}
                            style={{
                              color:
                                seoScore <= 49
                                  ? 'crimson'
                                  : seoScore >= 90
                                    ? 'green'
                                    : 'darkorange',

                              fontWeight: 'bold',
                              border: `1px solid ${seoScore <= 49
                                ? 'crimson'
                                : seoScore >= 90
                                  ? 'green'
                                  : 'darkorange'
                                }`,
                              backgroundColor:
                                seoScore <= 49
                                  ? '#FBE9E7'
                                  : seoScore >= 90
                                    ? '#B9F6CA'
                                    : '#f7e0cb',
                            }}
                            label={`${seoScore.toFixed(2)}`}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={1}
                          style={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginRight: 20,
                          }}
                        >
                          <FontAwesome
                            name="angle-down"
                            type="fa"
                            form="fa"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) =>
                              open === false ? setOpen(true) : setOpen(false)
                            }
                          />
                        </Grid>
                        <Grid item container xs={12}>
                          <Collapse in={open}>
                            <Grid
                              item
                              xs={12}
                            // style={{ marginTop: 20, marginBottom: 5 }}
                            >
                              <Divider
                                aria-hidden="true"
                                style={{
                                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
                                }}
                              />
                            </Grid>
                            <Grid container className='seoHolder'>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                >
                                  Title:
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={4}
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  justifyContent={'center'}
                                >
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color:
                                        titleScore <= 10
                                          ? 'crimson'
                                          : form?.name?.length >= 80
                                            ? 'green'
                                            : 'darkorange',
                                    }}
                                  >
                                    {form?.name?.length >= 80
                                      ? '20/20'
                                      : `${titleScore}/20`}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className='seoDivider'
                              >
                                <Divider />
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={8}
                                >
                                  Description:
                                </Grid>
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={4}
                                  justifyContent={'center'}
                                >
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color:
                                        descriptionWordCount >= 200
                                          ? 'green'
                                          : descriptionScore <= 15
                                            ? 'crimson'
                                            : 'darkorange',
                                    }}
                                  >
                                    {descriptionWordCount >= 200
                                      ? '30/30'
                                      : `${descriptionScore.toFixed(1)}/30`}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className='seoDivider'
                              >
                                <Divider />
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                >
                                  Bullet Points:
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  justifyContent={'center'}
                                >
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      color:
                                        bpScore >= 10
                                          ? 'green'
                                          : bpScore < 5
                                            ? 'crimson'
                                            : 'darkorange',
                                    }}
                                  >
                                    {bpScore >= 10
                                      ? '10/10'
                                      : `${bpScore ? bpScore : 0}/10`}
                                  </span>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className='seoDivider'
                              >
                                <Divider />
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                >
                                  Keywords:
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  justifyContent={'center'}
                                >
                                  {form?.keywords ? (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'green',
                                      }}
                                    >
                                      10/10
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'crimson',
                                      }}
                                    >
                                      0/10
                                    </span>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className='seoDivider'
                              >
                                <Divider />
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={8}
                                >
                                  Images:
                                </Grid>
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={4}
                                  justifyContent={'center'}
                                >
                                  {!product?.image ? (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'crimson',
                                      }}
                                    >
                                      0/25
                                    </span>
                                  ) : imageScore >= 25 ? (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'green',
                                      }}
                                    >
                                      25/25
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color:
                                          imageScore > 10 && imageScore < 25
                                            ? 'darkorange'
                                            : imageScore <= 10
                                              ? 'crimson'
                                              : 'green',
                                      }}
                                    >
                                      {imageScore}/25
                                    </span>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className='seoDivider'
                              >
                                <Divider />
                              </Grid>
                              <Grid
                                item
                                container
                                xs={12}
                              >
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={8}
                                >
                                  Categories:
                                </Grid>
                                <Grid
                                  item
                                  container
                                  style={{ paddingTop: 5, paddingBottom: 5 }}
                                  xs={4}
                                  justifyContent={'center'}
                                >
                                  {form?.channelCategoryId ? (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'green',
                                      }}
                                    >
                                      5/5
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'crimson',
                                      }}
                                    >
                                      0/5
                                    </span>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      {product && (
                        <Grid
                          item
                          container
                          xs={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ButtonGroup>
                            <Button
                              onClick={() => setIsViewMode((prev) => !prev)}
                              className={classes.button}
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{ marginBottom: 10 }}
                            >
                              {isViewMode ? 'Edit Product' : 'View Product'}
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      )}

                      {isViewMode ? (
                        <Grid
                          item
                          container
                          xs={5}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ButtonGroup>
                            <ExportButton
                              exportOptions={exportOptions(paramId)}
                            />
                          </ButtonGroup>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          container
                          xs={product ? 6 : 12}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ButtonGroup>
                            <Button
                              onClick={(e) => {
                                save();
                                // handleCreateVariation();
                              }}
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ width: '100%' }}
                              disabled={
                                ((!form?.variations || !form?.variations?.length) && !product && !isChecked)
                              }
                            >
                              {/* Save Changes */}
                              {!paramId
                                ? 'Create Product'
                                : isViewMode
                                  ? 'Save Changes'
                                  : 'Publish Now'}
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      )}
                      {product && (
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            <Divider
                              style={{ marginTop: 15, marginBottom: 15 }}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={7}
                            style={{ justifyContent: 'center' }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => setOpenDelete(true)}
                              style={{
                                color: 'crimson',
                                border: 'crimson 1px solid',
                                width: '100%',
                              }}
                              size="small"
                            >
                              Delete Product
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      <Modal
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                        }}
                        open={openDelete}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Grid
                          container
                          style={{
                            width: 500,
                            backgroundColor: 'white',
                            borderRadius: 10,
                            justifyContent: 'center',
                          }}
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              justifyContent: 'center',
                              // backgroundColor: '#7970dc',
                              width: '100%',
                              borderRadius: '10px 10px 0px 0px',
                            }}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              style={{
                                justifyContent: 'flex-end',
                                marginTop: 10,
                                marginRight: 10,
                                marginBottom: '-40px',
                              }}
                            >
                              {/* <Button>
                                <FontAwesome
                                  type="fa"
                                  form="fa"
                                  name="times"
                                  // color="#ffffff"
                                />
                              </Button> */}
                            </Grid>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 'bold', marginTop: 20 }}
                            >
                              Are you sure you want to delete this product?
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={10}
                            style={{ justifyContent: 'center' }}
                          >
                            <p
                              style={{
                                fontSize: '20px',
                                marginTop: 25,
                                marginBottom: 25,
                                textAlign: 'center',
                              }}
                            >
                              This will also delete product variations and
                              listings.
                            </p>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            style={{ padding: 20, justifyContent: 'center' }}
                          >
                            <Grid
                              item
                              container
                              xs={6}
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenDelete(false)}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={6}
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: 'crimson',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  deleteProduct();
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Modal>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {product?.groupId || isChecked ? (
        <>
          {product && (
            <ToggleButtonGroup
              exclusive
              style={{ margin: 10 }}
              onChange={handleToggle}
            >
              <ToggleButton
                onClick={() => setIsProducts(true)}
                value={'products'}
                className={
                  isProducts ? classes.primaryButton : classes.secondaryButton
                }
              >
                Product
              </ToggleButton>
              <ToggleButton
                onClick={() => setIsProducts(false)}
                value={'Additional Info'}
                className={
                  !isProducts ? classes.primaryButton : classes.secondaryButton
                }
              >
                Additional Info
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {isProducts ? (
            <GroupedTable
              updateProductForm={updateForm}
              product={product}
              {...props}
              updateList={updateList}
              defaultList={groupList}
            />
          ) : (
            <>
              <GroupedFLSTable />
            </>
          )}
        </>
      ) : (
        <>
          {!product && paramId ? null : (
            <VariationsTable
              {...props}
              skuError={skuErrors}
              mpnError={mpnError}
              updateProductForm={updateForm}
              product={product}
              // brand={form?.brand || ''}
              supps={suppliers}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
            />
          )}
        </>
      )}
      {/* {product && <VariationsTable product={product} suppliers={suppliers} />} */}
    </div>
  );
}

export default ProductPage;
