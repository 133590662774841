import { useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';

import FileApi from '@oneAppCore/services/apis/File';
import { IMAGES_ALLOWED } from './FileUpload.constants';

import Button from '@mui/material/Button';
import FontAwesome from '@src/Components/common/FontAwesome';

import type { Props } from './FileUpload.types';

function FileUpload({
  onUpload,
  allowedTypes,
  id,
  name = 'file-upload',
  disabled = false,
  buttonText = 'UPLOAD',
}: Props) {
  const [saving, setSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const upload = async (files: FileList) => {
    setSaving(true);
    try {
      const urls = await FileApi.post(files);
      onUpload(urls);
      enqueueSnackbar('Uploaded Images', { variant: 'success' });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error: ${e.message}`, { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const accept = (allowedTypes || IMAGES_ALLOWED).join(',');

  return (
    <Fragment key={id}>
      <input
        type="file"
        accept={accept}
        id={id}
        name={name}
        disabled={disabled}
        onChange={(e) => upload(e.currentTarget.files)}
        style={{ display: 'none' }}
        multiple
      />
      <label
        htmlFor={id}
        style={{ width: '100%', height: '100%', margin: 'none' }}
      >
        <Button
          color="secondary"
          variant="outlined"
          component="span"
          size="small"
          disabled={saving || disabled}
          fullWidth
          style={{ width: 'max-content', alignItems: 'center' }}
        >
          <FontAwesome name="plus" type="fas" form="fa" size="small" />
          Upload Image
        </Button>
      </label>
    </Fragment>
  );
}

export default FileUpload;
