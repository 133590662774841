export const RMATV = 'Rocky Mountain ATVMC';
export const WPS = 'Western Power Sports';
export const AD = 'Automatic Distributors';
export const MONARCH = 'Monarch Products Inc';
export const TUCKER = 'Tucker Powersports';
export const ARCLITE = 'Arclite Optics';
export const DZONE = 'dzone';
export const INTERNALORDER = 'Internal';
export const DROPSHIPORDER = 'Dropship';
export const WPSIR = 'Western Power Sports Internal Restock';

export const supplierIds = {
  [RMATV]: 1,
  [WPS]: 2,
  [AD]: 3,
  [MONARCH]: 4,
  [TUCKER]: 5,
  [ARCLITE]: 6,
  [DZONE]: 7,
  [WPSIR]: 20,
};

export const orderTypes = {
  [INTERNALORDER]: 7,
  [DROPSHIPORDER]: [1, 2, 3, 4, 5, 6]
}

// export const InternalOrder = [7];

// export const Dropship = [1, 2, 3, 4, 5, 6]

export const colorTheme = {
  palette: {
    [INTERNALORDER]: { main: '#5F37B1' },
    [DROPSHIPORDER]: { main: '#2188E5' },

    contrastThreshold: 3,
    tonalOffset: 0.2,
    green: {
      main: '#0f0',
      light: '#0f0',
      dark: '#0f0',
      lightest: '#0f0',
      darkest: '#0f0',
      contrastText: '#fff',
    },
    orange: {
      main: '#FFA500',
      light: '#FFA500',
      dark: '#FFA500',
      lightest: '#FFA500',
      darkest: '#FFA500',
      contrastText: '#fff',
    },
    red: {
      main: '#f00',
      light: '#f00',
      dark: '#f00',
      lightest: '#f00',
      darkest: '#f00',
      contrastText: '#fff',
    },
    purple: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      lightest: '#800080',
      darkest: '#800080',
      contrastText: '#fff',
    },
    blue: {
      main: '#00f',
      light: '#00f',
      dark: '#00f',
      lightest: '#00f',
      darkest: '#00f',
      contrastText: '#fff',
    },
    greys: {
      main: '#999',
      light: '#999',
      dark: '#999',
      lightest: '#999',
      darkest: '#999',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      lightest: '#000',
      darkest: '#000',
      contrastText: '#fff',
    },
    light: '',
  },
};
