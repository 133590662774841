import recoilSearch from '../../hooks/useSearchOptions';
import useSearch from '../../hooks/useSearch';
import { limitOptions } from '../SearchBar/constants';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import { Props } from './types';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChannelOptions from '@src/Components/common/containers/SearchView/ChannelOptions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        // margin: theme.spacing(0.5, 0),
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
        width: '100%',
      },
      justifyContent: 'space-between',
    },
    show: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 20,
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 5,
      },
      zIndex: 11,
    },
    pagination: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: 20,
      },
      [theme.breakpoints.down('md')]: {},
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
);

function SearchPaginator({
  showChannelOptions,
  showCount = true,
  showPerPage = true,
  showFirst = true,
  showLast = true,
  showPrev = true,
  showNext = true,
  siblingCount = 0,
  boundaryCount = 1,
  variant = 'text',
  shape = 'rounded',
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
  currentPage,
  resetPagination,
  setResetPagination,
  hasUnsavedRows,
  menuPlacement = 'bottom',
}: Props) {
  const classes = useStyles();
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [searchState, setSearchState] = recoilSearch.useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(currentPage);
  const isMobile = useMediaQuery('(max-width:900px)');

  const { data } = useSearch(
    swrOptions.revalidateOnFocus,
    false,
    swrOptions.focusThrottleInterval,
  );

  const totalPages = Math.max(Math.ceil(data?.count / searchOptions?.limit), 1);

  const update = (limit: number) => {
    setSearchState((previous) => ({
      ...previous,
      limit,
    }));
    setResetPagination(true);
  };

  const updatePage = (page: number) => {
    setSearchOptions((previous) => ({
      ...previous,
      page,
    }));
    setResetPagination(false);
  };

  if (resetPagination === true) {
    updatePage(1);
  }

  return isMobile ? (
    <Grid item container xs={12}>
      <Grid item container xs={12} style={{ justifyContent: 'flex-end' }}>
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold', color: '#686868' }}
        >
          Count: {Number(data?.count || 0).toLocaleString('en')}
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid
          item
          container
          xs={5}
          style={{
            alignItems: 'center',
            marginTop: 10,
          }}
        >
          <Grid item xs={4}>
            Show:
          </Grid>
          <Grid item xs={7}>
            <AppSelect
              options={limitOptions}
              value={
                limitOptions.find(
                  (option) => option.value === searchState.limit,
                ) || {}
              }
              onChange={(option) => update(option.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={7} style={{ justifyContent: 'flex-end' }}>
          <Pagination
            shape={shape}
            variant={variant}
            size="small"
            count={totalPages}
            color="primary"
            page={searchOptions.page}
            onChange={(_, page) => updatePage(page)}
            style={{ justifyContent: 'flex-end' }}
            showFirstButton={showFirst}
            showLastButton={showLast}
            hidePrevButton={!showPrev}
            hideNextButton={!showNext}
            siblingCount={siblingCount}
            boundaryCount={boundaryCount}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 10 }}>
        {showChannelOptions && <ChannelOptions />}
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      container
      alignItems="center"
      // spacing={1}
      xs={12}
      className={classes.root}
    >
      <Grid container xs={12} style={{ justifyContent: 'space-between' }}>
        <Grid
          item
          container
          xs={11}
          md={4}
          lg={12}
          className={classes.pagination}
        >
          {showCount ? (
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: 'flex-end', marginRight: 10 }}
            >
              <Typography
                variant="body2"
                style={{ fontWeight: 'bold', color: '#686868' }}
              >
                Count: {Number(data?.count || 0).toLocaleString('en')}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          item
          container
          xs={11}
          md={12}
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          {showPerPage ? (
            <Grid item container xs={6} className={classes.show}>
              <Grid item xs={6} sm={4} md={1}>
                Show:
              </Grid>
              <Grid item xs={6} sm={4} md={2} xl={2}>
                <AppSelect
                  menuPlacement={menuPlacement}
                  options={limitOptions}
                  value={
                    limitOptions.find(
                      (option) => option.value === searchState.limit,
                    ) || {}
                  }
                  onChange={(option) => update(option.value)}
                />
              </Grid>
              <Grid item xs={3}>
                {showChannelOptions && <ChannelOptions />}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            item
            container
            xs={showPerPage ? 5 : 11}
            style={{ justifyContent: 'flex-end' }}
          >
            <Pagination
              shape={shape}
              variant={variant}
              size="small"
              count={totalPages}
              color="primary"
              page={searchOptions.page}
              onChange={(_, page) => updatePage(page)}
              style={{ justifyContent: 'flex-end' }}
              showFirstButton={showFirst}
              showLastButton={showLast}
              hidePrevButton={!showPrev}
              hideNextButton={!showNext}
              siblingCount={siblingCount}
              boundaryCount={boundaryCount}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchPaginator;
