import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import type { Props } from './types';

function AllowSkuDialog({ open, onConfirm, close }: Props) {
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onConfirm(true);
    };
  };
  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" onKeyDown={handleEnterKeyPress}>
      <DialogTitle id="form-dialog-title">Confirm item in PO?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Item not in PO, add anyway?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="secondary"
          onClick={() => onConfirm(false)}
        >
          No
        </Button>
        <Button type="submit" color="primary" onClick={() => onConfirm(true)}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AllowSkuDialog;
