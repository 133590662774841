import Grid from '@mui/material/Grid';
import NumberBlock from './NumberBlock';

import { ChannelReportProp } from './types';

function ChannelReports(props: ChannelReportProp) {
  const { trackNumbers } = props;
  return (
    <Grid item container spacing={1}>
      {console.log((trackNumbers?.manSupTotal) && (trackNumbers?.outOfStock) ? '1': '0')}
      <NumberBlock
        value={
          ((trackNumbers?.manSupTotal) && (trackNumbers?.outOfStock))
            ? (
                ((trackNumbers?.outOfStock) /
                  (trackNumbers?.manSupTotal)) *
                100
              ).toFixed(2) + '%'
            : 'N/A'
        }
        title="Difference"
        description="Difference between out of stock and the total supplier count"
      />
      <NumberBlock
        value={Number(trackNumbers?.outOfStock || 0).toLocaleString()}
        title="Out of Stock"
        description="Numbers of items that are out of stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.inStock || 0).toLocaleString()}
        title="In Stock"
        description="Numbers of items that are in stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.tempOutOfStock || 0).toLocaleString()}
        title="Temp Out of Stock"
        description="Numbers of items that are temporarily out of stock"
      />
      <NumberBlock
        value={Number(trackNumbers?.staticQuantity || 0).toLocaleString()}
        title="Static Quantity"
        description="Numbers of items with a static quantity"
      />
    </Grid>
  );
}

export default ChannelReports;
