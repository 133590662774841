import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  SwipeableDrawer,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import type { DrawerProps } from './types';
import { columns } from './constants';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

const drawerWidth = 400;
const mobileWidth = 260;

interface StyleProps {
  mobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    // width: 400,
    [theme.breakpoints.up('md')]: {
      // width: ({ mobile }: StyleProps) => (mobile ? mobileWidth : drawerWidth),
      flexShrink: 0,
      width: '475px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },
  // typography: {
  //   marginLeft: 10,
  //   marginTop: 5,
  //   fontSize: 14,
  // },
  // button: {
  //   margin: 5,
  // },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  // spacing: {
  //   marginTop: 5,
  // },
}));

export default function RestockDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: DrawerProps) {
  const [item, setItem] = useState(0);
  const classes = useStyles({ mobile });

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setDrawerOpen()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              style={{ marginBottom: 15, fontWeight: 500 }}
            >
              {row.productName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {row.variation !== null && (
              <Typography variant="h6" align="center">
                {row.variation}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" style={{ flexBasis: 0 }}>
          {row.images?.[0] ? (
            <img
              style={{
                height: 100,
                width: 100,
                marginTop: 20,
                marginBottom: 20,
              }}
              src={row.images?.[0]?.imageUrl}
            />
          ) : (
            <Grid>
              <img
                height={100}
                width={100}
                src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                alt="placeholder image for product image"
              />
            </Grid>
          )}
        </Grid>
        {columns.map((column) => {
          if (column.customComponent) {
            return (
              <Grid item key={row.id} style={{ marginTop: 10 }}>
                {column.customComponent(row)}
              </Grid>
            );
          }
          return (
            <>
              <Grid key={column.key} container style={{ marginTop: 5 }}>
                <Grid key={`${column.key}-title`} item xs={6} md={6} lg={6}>
                  <Typography
                    style={{ fontWeight: 600, fontSize: '1.15rem' }}
                    key={column.id}
                  >
                    {column.title}
                  </Typography>
                </Grid>
                <Grid key={`${column.key}-value`} item xs={6} md={6} lg={6}>
                  <Typography style={{ fontSize: '1.15rem' }}>
                    {row[column.key]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item key={`${column.key}-div-parent`}>
                {column.key === 'manufacturerNo' ? (
                  <Divider key={`${column.key}-div`} className={classes.divider} />
                ) : null}

                {column.key === 'test' ? (
                  <Divider key={`${column.key}-div`} className={classes.divider} />
                ) : null}

                {column.key === 'location' ? (
                  <Divider key={`${column.key}-div`} className={classes.divider} />
                ) : null}

                {column.key === 'brand' ? (
                  <Divider key={`${column.key}-div`} className={classes.divider} />
                ) : null}

                {column.key === 'salesInterval' ? (
                  <Divider key={`${column.key}-div`} className={classes.divider} />
                ) : null}
              </Grid>
            </>
          );
        })}
      </SwipeableDrawer>
    </>
  );
}
