import { uneditableColumns as uec, tableNames } from './db';

export const FITMENT_IMPORT = 'Fitment_Search_import';
export const PRODUCT_SINGLE_IMPORT = 'Product_Variation_Mansupp';

export const tableList = {
  [tableNames.creditCardTransactions]: [
    'importId',
    'matchType',
    'matchId',
    'matchNo',
  ],
};

export const pickFields = [
  'brands',
  'channelCategories',
  'itemGroups',
  'storeProducts',
  'manufacturerSuppliers',
  'storeListings',
  'variations',
  'itemGroupContents',
];

export const uneditableColumns = [
  'skip',
  ...uec,
];

export const customImports = {
  [FITMENT_IMPORT]: ['years', 'make', 'model'],
  [PRODUCT_SINGLE_IMPORT]: [
    'name', // p
    'description', // p
    'brand', // p,v,ms
    'sku', // v
    'mpn', // v, ms
    // 'variant', // v
    // 'variantType', // v
    'price', // v, ls?
    'image', // vi
    // 'weight', // v
    'cost', // ms - need to check if cents
    'supplier', // ms - maybe needs to be supplier id first
    // 'quantity', // ms
    // 'location', // ms
    // 'hasStaticQuantity', // ms need to trigger a check not just put into DB
    'upc', // utv
    'keywords', // p
    'bullet1', // p
    'bullet2', // p
    'bullet3', // p
    'bullet4', // p
    'bullet5', // p
    'category', // p
    // 'listingSku', // ls
  ],
};

