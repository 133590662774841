import { FormEvent, useState, useEffect } from 'react';
import useInventoryHistory from '../hooks/useInventoryHistory';

import { useSnackbar } from 'notistack';
import SupplierApi from '@oneAppCore/services/apis/Suppliers';
import RestockApi from '@oneAppCore/services/apis/Restock';
import { ManufacturerSupplierInternal } from '@oneAppCore/types/restockTypes';

import {
  Paper,
  Grid,
  Switch,
  FormControlLabel,
  Button,
} from '@mui/material';
import AppSelect from '@src/Components/common/Selects/AppSelect';
import TextField from '@src/Components/common/TextField';
import { Backdrop } from '@src/Components/common/Loaders';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import CreateMPUDrawer from './CreateUPCDrawer';
import AddLocationDialog from './AddLocationDialog';
import OverrideDialog from './OverrideDialog';
import AllowSkuDialog from './allowSkuDialog';
import ClosePoDialog from './closePoDialog';
// import PurchaseOrderDialog from './PurchaseOrderDialog';

import type { ScanForm, Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
}));

const defaultScanForm = {
  barcode: '',
  quantity: 1,
};

function AddInventory({ page, limit }: Props) {
  const classes = useStyles();

  const [remove, setRemove] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [location, setLocation] = useState('');
  const [purchaseOrderNo, setPurchaseOrderNo] = useState<number>(-1);
  const [poId, setPoId] = useState<number>(-1);
  const [overrideQuantity, setOverrideQuantity] = useState(false);

  const [restockList, setRestockList] = useState<any[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');

  const [scanForm, setScanForm] = useState<ScanForm>(defaultScanForm);
  const [saving, setSaving] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [allowSku, setAllowSku] = useState(false);
  const [unfound, setUnfound] = useState(false);
  const [foundData, setFoundData] = useState({});

  console.log(foundData, unfound, 'DATA');

  const { enqueueSnackbar } = useSnackbar();

  const update = <K extends keyof ScanForm>(key: K, value: ScanForm[K]) => {
    setScanForm({
      ...scanForm,
      [key]: value,
    });
  };

  useEffect(() => {
    const func = async () => {
      const { data } = await RestockApi.readAllPO({
        notZero: true,
        orderByColumn: ['purchaseOrderNo'],
        orderByDirection: ['desc'],
      });
      const listOfPO = data.filter(po => po.purchaseOrderNo >= 100 || po.purchaseOrderNo == 0)
        .map((po) => ({
        label: po.purchaseOrderNo == 0 ? 'Not Found' : po.purchaseOrderNo,
        // label: `PO ${po.purchaseOrderNo}`,
        value: po.purchaseOrderNo,
      }));
      // setRestockList([{ label: 'Automatic', value: -1 }, ...listOfPO]);
      setRestockList(listOfPO);
    };
    func();
  }, []);

  const { mutate: mutateInventoryHistory } = useInventoryHistory(page, limit);

  const sendInventoryUpdate = async (
    params: any = {
      overriding: overrideQuantity,
      po: purchaseOrderNo,
      extraSku: allowSku,
    },
  ) => {
    const { overriding, po, extraSku } = params;
    if (overriding) {
      setDialogType('override');
      setOpenDialog(true);
      return;
    }
    setSaving(true);
    try {
      const data: any = await SupplierApi.updateInventoryByBarcode({
        barcode: scanForm.barcode.trim(),
        quantity: remove ? -scanForm.quantity : scanForm.quantity,
        overrideQuantity,
        location,
        purchaseOrderNo: po,
        allowSku: extraSku,
      });
      if (data) {
        setFoundData(data);
      } else {
        setUnfound(true);
      }
      console.log('data', data);
      if (data.message?.includes('No record found in')) {
        console.log(data.result);
        setOpenDrawer(true);
        enqueueSnackbar(data.message, { variant: 'error' });
        return;
      }
      if (data.message == 'allowSku') {
        setDialogType('allowSku');
        setOpenDialog(true);
        return;
      }
      if (data?.lastOrExtraItem && data?.poId) {
        setDialogType('lastOrExtraItem');
        setOpenDialog(true);
        setPoId(data?.poId);
        return;
      };
      setScanForm(defaultScanForm);
      setLocation('');
      setDialogType('');
      setOpenDialog(false);
      enqueueSnackbar('Updated', { variant: 'success' });
    } catch (e) {
      if (e.message.includes('No record found in')) {
        console.log(e);
        setOpenDrawer(true);
      }
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setSaving(false);
      setAllowSku(false);
      // setOpenDialog(false);
      // setPurchaseOrderNo(-1);
      await mutateInventoryHistory();
    }
    setOpenDialog(false);
  };

  const onSubmit = async (e?: FormEvent) => {
    if (e) e.preventDefault();
    // const { rows } = await Api.get(`/api/v1/manufacturer-suppliers-restock/upc/${scanFOrm?.upc || '0'}`, { notZero: true });
    // console.log('rows', rows);
    // let prordno = purchaseOrderNo;
    // if (rows.length === 1) {
    //   setPurchaseOrderNo(rows[0].purchaseOrderNo);
    //   prordno = rows[0].purchaseOrderNo;
    // } else {
    //   setRestockList(rows);
    // }
    if (purchaseOrderNo === -1) {
      enqueueSnackbar('Select a PO#', { variant: 'error' });
      return;
    }
    if (addLocation) {
      setDialogType('location');
      setOpenDialog(true);
    } else {
      await sendInventoryUpdate({
        overriding: overrideQuantity,
        po: purchaseOrderNo,
      });
    }
  };

  const handleOverrideDialogConfirm = (confirmed: boolean) => {
    setDialogType('');
    setOpenDialog(false);
    if (confirmed) {
      sendInventoryUpdate({ overriding: false, po: purchaseOrderNo });
    }
  };

  const handleAllowSkuDialogConfirm = (confirmed: boolean) => {
    setDialogType('');
    setOpenDialog(false);
    if (confirmed) {
      setAllowSku(true);
      sendInventoryUpdate({
        overriding: false,
        po: purchaseOrderNo,
        extraSku: true,
      });
    }
  };

  const handlePoClose = () => {
    setScanForm(defaultScanForm);
    setLocation('');
    setDialogType('');
    setOpenDialog(false);
    setPurchaseOrderNo(-1);
  };

  const handlePODialogConfirm = (confirm: boolean) => {
    setDialogType('');
    setOpenDialog(false);
    if (confirm) {
      sendInventoryUpdate();
    }
  };

  const close = async (saved = false) => {
    if (saved) {
      await sendInventoryUpdate();
    }
    setOpenDrawer(false);
  };

  const toggleOverrideQuantity = (value: boolean) => {
    setRemove(false);
    setOverrideQuantity(value);
  };

  return (
    <Paper className={classes.root}>
      <Grid item container spacing={2} justifyContent="center" alignItems="center">
        <Grid item container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={remove}
                  onChange={(e) => setRemove(e.target.checked)}
                  name="checkedA"
                  color="primary"
                  disabled={overrideQuantity}
                />
              }
              label="Remove"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={addLocation}
                  onChange={(e) => setAddLocation(e.target.checked)}
                  name="checkedA"
                  color="primary"
                />
              }
              label="Add Location"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={overrideQuantity}
                  onChange={(e) => toggleOverrideQuantity(e.target.checked)}
                  name="checkedA"
                  color="primary"
                />
              }
              label="Override Quantity"
            />
          </Grid>
        </Grid>
        <form onSubmit={onSubmit}>
          <Grid item container alignItems="center" spacing={2}>
            <Grid item style={{ zIndex: 10 }}>
              <AppSelect
                options={restockList}
                label="Scan PO"
                value={restockList.find(
                  (option) => option.value === purchaseOrderNo,
                )}
                onChange={(e) => setPurchaseOrderNo(e.value as number)}
              />
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <TextField
                label="Barcode"
                value={scanForm.barcode}
                name="barcode"
                id="barcode"
                onChange={(e) => update('barcode', e.target.value)}
                autoFocus
              />
            </Grid>
            <Grid item>
              <TextField
                label="Quantity"
                value={scanForm.quantity}
                type="number"
                name="quantity"
                id="quantity"
                onChange={(e) =>
                  update(
                    'quantity',
                    e.currentTarget.value !== ''
                      ? Number(e.currentTarget.value)
                      : null,
                  )
                }
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ marginTop: 15 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <CreateMPUDrawer
        open={openDrawer}
        close={close}
        upc={scanForm.barcode}
        data={foundData}
      />
      {dialogType === 'location' ? (
        <AddLocationDialog
          open={openDialog}
          close={() => {
            setDialogType('');
            setOpenDialog(false);
            setLocation('');
          }}
          location={location}
          setLocation={setLocation}
          submit={sendInventoryUpdate}
        />
      ) : (
        <></>
      )}
      {dialogType === 'override' ? (
        <OverrideDialog
          open={openDialog}
          onConfirm={handleOverrideDialogConfirm}
          close={() => {
            setDialogType('');
            setOpenDialog(false);
          }}
        />
      ) : (
        <></>
      )}
      {dialogType === 'allowSku' ? (
        <AllowSkuDialog
          open={openDialog}
          onConfirm={handleAllowSkuDialogConfirm}
          close={() => {
            setDialogType('');
            setOpenDialog(false);
          }}
        />
      ) : (
        <></>
      )}
      {dialogType === 'lastOrExtraItem' ? (
        <ClosePoDialog
          openDialog={openDialog}
          poId={poId}
          poNumber={purchaseOrderNo}
          handlePoClose={handlePoClose}
        />
      ) : (
        <></>
      )}
      {/* {dialogType === 'po' ? <PurchaseOrderDialog
        open={openDialog}
        onConfirm={handlePODialogConfirm}
        update={(po: number) => {
          setPurchaseOrderNo(po);
        }}
        // upc={scanFOrm?.upc || '0'}
        restockList={restockList}
        close={() => {
          setDialogType('');
          setOpenDialog(false);
          // setPurchaseOrderNo(-1);
        }}
      /> : <></>} */}
      <Backdrop open={saving} />
    </Paper>
  );
}

export default AddInventory;
