import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { reposState as reposAtom } from '../../common/containers/SearchView/SearchTable/atoms';
import { PENDING, statusFilter, DRAFT } from '@oneAppCore/constants/listings';
import useUsers from '@src/hooks/swr/useUsers';

function PendingListings() {
  const tableRowsState = useRecoilValue(reposAtom);
  const isBotOptions = [
    { label: 'TRUE', value: true },
    { label: 'FALSE', value: false },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverOpen, setHoverOpen] = useState(false);
  const [popperStates, setPopperStates] = useState({});

  const handleMouseEnter = (e, rowId) => {
    setHoverOpen(true);
    setAnchorEl(e.currentTarget);

    // Set the Popper state for the current row to true
    setPopperStates({ ...popperStates, [rowId]: true });
  };

  const handleMouseLeave = (rowId) => {
    setHoverOpen(false);
    setPopperStates({ ...popperStates, [rowId]: false });
  };

  const changePriceFunc = (props: string): void => {
    console.log(props);
  };

  const { data: companyUsers = [] } = useUsers();
  const createcByOptions = companyUsers.map((user) => ({
    label: user.username,
    value: user.id,
  }));

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/store-listings/pending"
        showChannelOptions
        select
        selectTypes={['approve', 'refresh']}
        columns={columns(
          changePriceFunc,
          handleMouseEnter,
          handleMouseLeave,
          tableRowsState,
          anchorEl,
          hoverOpen,
          popperStates,
        )}
        // constantFilters={{
        //   status: DRAFT,
        // }}
        filters={[
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
          {
            type: 'selector',
            name: 'Draft-Status',
            keyName: 'status',
            properties: statusFilter,
            options: {
              hideDefaultNoneOption: true,
            },
          },
          {
            type: 'selector',
            name: 'Bot User',
            keyName: 'isBot',
            properties: isBotOptions,
          },
          {
            type: 'selector',
            name: 'Created By',
            keyName: 'createdBy',
            properties: createcByOptions,
          },
        ]}
        // MiniReportComponent={MiniReports}
      />
    </Grid>
  );
}

export default PendingListings;
